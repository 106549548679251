//#region ng
import {
  Pipe,
  PipeTransform,
  inject
} from '@angular/core';
//#endregion

//#region 3rd
import { Observable } from 'rxjs';
//#endregion

//#region models
import { IProProduto } from '../../_misc/_models/_interfaces/_cols';
//#endregion

//#region services
import { ProProdutosService } from '../_services';
//#endregion

@Pipe({ name: 'proLfixes' })
export class ProProLfixesPipe implements PipeTransform {
  //#region injects
  #produtosServ = inject(ProProdutosService);
  //#endregion

  transform(val: IProProduto[], ignoraImagens: boolean = false): Observable<IProProduto[]> {
    return this.#produtosServ.lfixes(val, ignoraImagens);
  }
}
