//#region ng
import {
  Component,
  EventEmitter,
  inject,
  Inject,
  Input,
  Output,
  signal,
  ViewChild
} from '@angular/core';
import {
  AbstractControl,
  FormBuilder,
  FormGroup,
  Validators
} from '@angular/forms';
//#endregion

//#region firebase
import {
  arrayUnion,
  doc,
  DocumentReference,
  Firestore,
  serverTimestamp,
} from '@angular/fire/firestore';
import { getApp } from 'firebase/app';
import {
  getFunctions,
  httpsCallable
} from 'firebase/functions';
//#endregion

//#region mat
import { MatStepper } from '@angular/material/stepper';
import {
  MatDialogRef,
  MAT_DIALOG_DATA
} from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
// import { MatStepper } from '@angular/material/stepper';
//#endregion

//#region 3rd
import { Subscription } from 'rxjs';
import {
  finalize,
  first,
} from 'rxjs/operators';
//#endregion

//#region models
import { IOperadorHabilitarParams } from '../../models/interfaces/params';
import { CorFormValidation } from '../../_shared/_core/_ng/_models/_classes';
import { FOCUS_TIMEOUT } from '../../_shared/_core/_misc/_models/consts';
import {
  IConta,
  ILoja
} from '../../_shared/_mercadeiro/_misc/_models/_interfaces/_cols';
// import { environment } from 'src/environments/environment';
// import { CorFormValidation } from '../../_shared/_core/_ng/_models/_classes';
// import { FOCUS_TIMEOUT } from '../../_shared/_core/_misc/_models/consts';
// import {
//   IConta,
//   ILoja
// } from '../../_shared/_mercadeiro/_misc/_models/_interfaces/_cols';
// const STEPS: string[] = ['integradora', 'tipo', 'meilisearch', 'loja', 'endereco'];
// import { environment } from 'src/environments/environment';
// // import { CorFormValidation } from '../../_shared/_core/_ng/_models/_classes';
// import {
//   IConta,
//   IIntegradora,
//   ILoja,
//   IMeilisearch,
//   IRede,
//   ITipoLojas,
//   // ITipoLojas
// } from '../../_shared/_mercadeiro/_misc/_models/_interfaces/_cols';
// import { IEditLojaParams } from '../../_shared/_mercadeiro/_misc/_models/_interfaces/_params';
// import { IViacepApi } from '../../_shared/_core/_misc/_models/_interfaces/_apis';
// import { FOCUS_TIMEOUT } from '../../_shared/_core/_misc/_models/consts';
//#endregion

//#region libs
// import { calcLojaId } from '../../_shared/_mercadeiro/_misc/_libs';
//#endregion

//#region services
import {
  CorLoaderService,
  CorMessagesService
} from '../../_shared/_core/_ng/_services';
import {
  ContasService,
  LojasService
} from '../../_shared/_mercadeiro/_ng/_services';
import { TOperadorRole } from '../../_shared/_mercadeiro/_misc/_models/_types';
import { ILojaIdInfoRet } from '../../_shared/_mercadeiro/_misc/_models/_interfaces/_rets';
import { AppService } from '../../services';
// import { ContasService, LojasService } from '../../_shared/_mercadeiro/_ng/_services';
// import { CorLoaderService } from '../../_shared/_core/_ng/_services';
// import { MeilisearchService } from '../../_shared/_mercadeiro/_ng/_services';
// import {
//   LojasService,
//   RedesService
// } from '../../_shared/_mercadeiro/_ng/_services';
//#endregion

//#region components
import { OperadorTipoFormComponent } from '../../components';
// import { LojaLojaFormComponent } from '.';
// import { CorLoaderService } from '../../_shared/_core/_ng/_services';
// import { TOperadorRole } from '../../_shared/_mercadeiro/_misc/_models/_types';
// import { LojaLojaFormComponent } from './loja-loja-form/loja-loja-form.component';
// import { CdMatManageEnderecoComponent } from '../../_shared/_core/_ng-ui/_mat';
// import { IntegradoraSelComponent } from '../../components';
//#endregion

@Component({
  selector: 'app-operador-habilitar-modal',
  templateUrl: './operador-habilitar.modal.html',
  styleUrls: ['./operador-habilitar.modal.scss']
})
export class OperadorHabilitarModal {
  //#region viewchilds
  @ViewChild('stepper', { static: true }) stepperRef: MatStepper;
  @ViewChild(OperadorTipoFormComponent) operadorTipoFormRef: OperadorTipoFormComponent;
  //#endregion

  //#region publics
  changes = signal<Partial<IConta>>(null);
  contaForm = signal<FormGroup>(null);
  // contaOperador = signal<IConta>(null);
  fv = signal<CorFormValidation>(null);
  //#endregion

  //#region privates
  #conta: IConta;
  #loja: ILoja;
  //#endregion

  //#region events
  emailFocusEvent$ = signal<EventEmitter<boolean>>(new EventEmitter<boolean>()).asReadonly();
  //#endregion

  //#region injects
  // #db = inject(Firestore);
  #contasServ = inject(ContasService);
  #dialogRef = inject(MatDialogRef<OperadorHabilitarModal>);
  #fb = inject(FormBuilder);
  #loaderServ = inject(CorLoaderService);
  #lojasServ = inject(LojasService);
  #msgServ = inject(CorMessagesService);
  #snackBar = inject(MatSnackBar);
  //#endregion

  //#region constructor
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: IOperadorHabilitarParams,
  ) {
    this.fv.set(new CorFormValidation());
  }
  //#endregion

  //#region lifecycles
  ngOnInit() {
    this.#msgServ.send();
    this.#loja = !!this.data?.loja ? this.#lojasServ.fix(this.data?.loja) : null;
    this.#conta = !!this.data?.conta ? this.#contasServ.fix(this.data?.conta) : null;

    this.contaForm.set(
      this.#fb.group({
        email: ['', [Validators.required, Validators.email]],
        id: ['', [Validators.required]],
      })
    );
  }

  ngAfterViewInit() {
    this._focus();
  }
  //#endregion

  //#region Controls getters
  get contaEmailRef(): AbstractControl { return this.contaForm()?.get('email'); }
  get contaIdRef(): AbstractControl { return this.contaForm()?.get('id'); }
  //#endregion

  //#region functions
  _focus() {
    setTimeout(
      () => this.emailFocusEvent$().emit(true),
      FOCUS_TIMEOUT
    );
  }
  //#endregion

  //#region methods
  onModalCloseClick() {
    this.#dialogRef.close();
  }

  async onStepChanged(e: any) {
    // console.log(e);
    switch (+e?.selectedIndex) {
      case 0:
        break;

      case 1:
        this._focus();
        break;

      case 2:
        if (
          !!this.#loja?.id
          && !!this.#conta?.id
        ) {
          this.operadorTipoFormRef?.operadorTipoForm().patchValue({ operador: { _lojasIds: arrayUnion(this.#loja?.id) } });
          this.changes.set(
            {
              ...this.operadorTipoFormRef?.operadorTipoForm()?.value,
              _modificadoEm: serverTimestamp(),
              _modificadoPor: {
                id: this.#conta?.id || '',
                nome: this.#conta?.__nomeCompleto || ''
              },
            }
          );
          // console.log(this.changes);
        } // if

        break;
    } // switch
  }

  async onVerificaEmailClick() {
    // console.log(this.contaEmailRef?.value);
    if (!!this.contaEmailRef?.value) {
      // console.log(this.contaEmailRef?.value);
      this.#loaderServ.lstart();
      try {
        const RESP: any = await this.#contasServ.userFromEmail(this.contaEmailRef?.value);
        const USER: any = RESP?.data?.data || null;
        // console.log(USER);
        if (!!USER) {
          const SUB: Subscription = this.#loaderServ.showUntilCompleted(
            this.#contasServ.contaFromUid(USER?.uid)
              .pipe(first(), finalize(() => SUB?.unsubscribe()))
          )
            .subscribe(async (conta: IConta) => {
              // console.log(conta);
              if (!!conta) {
                const ROLE: TOperadorRole = conta?.operador?.role;
                // console.log(ROLE);
                if (!!ROLE && ROLE !== this.operadorTipoFormRef?.operadorRoleRef?.value) {
                  this.#msgServ.send(`Conta não pode ser utilizada. Tipo ${ROLE}.`, 'warning', 'dialog');
                } else {
                  this.contaIdRef.setValue(conta?.id || '');
                  this.stepperRef?.next();
                } // else
              } else {
                this.#msgServ.send(`Conta ${this.contaEmailRef?.value} não concluída.`, 'warning', 'dialog');
              } // else
            });
        } else {
          this.#loaderServ.lstop();
          this.#msgServ.send(`Cadastro ${this.contaEmailRef?.value} não encontrado.`, 'warning', 'dialog');
        } // if
      } finally {
        this.#loaderServ.lstop();
      } // try-finally
    } // if
  }

  onHabilitarOperador() {
    // console.log(this.contaIdRef?.value);
    if (
      !!this.contaIdRef?.value
      && this.changes()
    ) {
      const SUB: Subscription = this.#loaderServ.showUntilCompleted(
        this.#contasServ.update(this.contaIdRef?.value, this.changes())
          .pipe(first(), finalize(() => SUB?.unsubscribe()))
      )
        .subscribe(
          async () => {
            /* AppService.operadorHabilitadoChanged$.emit(
              {
                ...this.operador,
                ...this.changes
              }
            ); */
            // this.#loaderServ.lstop();
            // console.log(rede);
            this.#snackBar.open(
              'Conta habilitada.',
              (this.changes, 'email') || ''
            );
            this.onModalCloseClick();
          }
        );
    } // if

  }
  //#endregion

  // submit
  // console.log(this._integradoraId);
  /* const SUB: Subscription = this.#loaderServ.showUntilCompleted(
   this.#contasServ.update(
     conta?.id || '',
     {
       operador: {
         role: 'parceiro',
         integradora: {
           id: this._integradoraId,
         }
       }
     }
   )
     .pipe(first(), finalize(() => SUB?.unsubscribe()))
 )
   .subscribe(
     () => {
       this.#snackBar.open('Operador habilitado.', '');
       // this.searchTermAction$.next('');
     }
   ); */


  /*

  //#region publics
  changes: Partial<IConta>;
  conta: IConta;
  operador: IConta;
  loja: ILoja;
  //#endregion

  //#region functions
  _focus() {
    setTimeout(
      () => { this.emailFocusEvent.emit(true); },
      FOCUS_TIMEOUT
    );
  }

  private async _verificaUsuario(email: string): Promise<any> {
    const functions = getFunctions(
      getApp(),
      environment?.firebase?.region
    );
    const fn = httpsCallable(functions, 'onUidByEmail');
    return fn({ email });
  }
  //#endregion

  //#region methods
  }

  async onVerificaEmailClick() {
    this.#loaderServ.lstart();
    const RESP: any = await this._verificaUsuario(this.contaEmailRef.value);
    const USER: any = RESP?.data?.data || null;
    // console.log(USER);
    this.fv.setApiErrors({ email: '' });
    // this.#loaderServ.lstop();

    if (!!USER) {
      const SUB: Subscription = this.#contasServ
        .contaFromUid(USER?.uid)
        .pipe(
          first(),
          finalize(() => {
            SUB?.unsubscribe();
            this.#loaderServ.lstop();
          })
        )
        .subscribe(async (conta: IConta) => {
          // console.log(conta);
          if (conta) {
            const ROLE: TOperadorRole = conta?.operador?.role;
            // console.log(ROLE);
            if (!ROLE) {
              this.operador = this.#contasServ.fix(conta);
              this.contaIdRef.setValue(this.operador?.id || '');
              this.stepperRef.next();
              // this._focus();
            } else {
              this.#loaderServ.lstop();
              this.fv.setApiErrors({ email: `Conta não pode ser utilizada. Tipo ${ROLE}.` });
              this.stepperRef.previous();
            } // else
          } else {
            this.#loaderServ.lstop();
            this.fv.setApiErrors({ email: 'Conta não encontrada.' })
            this.stepperRef.previous();
          } // else
        });
    } else {
      this.#loaderServ.lstop();
      this.fv.setApiErrors({ email: 'Cadastro não encontrado.' })
      this.stepperRef.previous();
    } // if
  }
  //#endregion
  */
}
