<div class="modal-body p-relative">
  <mat-toolbar class="header"
               color="primary">
    <mat-toolbar-row class="d-flex justify-content-between">
      <h3> Galeria </h3>

      <button mat-icon-button
              class="ms-3"
              (click)="onModalCloseClick()">
        <mat-icon>close</mat-icon>
      </button>
    </mat-toolbar-row>
  </mat-toolbar>

  <div class="p-3">
    <!-- <p>sugestao() {{ sugestao() }}</p> -->
    <!-- <p>excecoes() {{ excecoes() }}</p> -->

    <app-galeria-get [sugestao]="sugestao()"
                     [excecoes]="excecoes()"
                     (select$)="onSelected($event)" />
  </div>
</div>