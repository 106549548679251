<ng-container *ngIf="vm$() | async as vm">
  <div class="box">
    <nav aria-label="breadcrumb">
      <ol class="breadcrumb">
        <li class="breadcrumb-item">
          <span class="icon-o-home primary tint o-50 pointer"
                routerLink="/notificacoes">
          </span>
        </li>

        <li class="breadcrumb-item active">
          Notificações
        </li>
      </ol>
    </nav>

    <me-mat-notificacoes [conta]="vm?.conta"> </me-mat-notificacoes>
  </div>
</ng-container>
