<ng-container *ngIf="vm$() | async as vm">

  <div class="box">
    <nav aria-label="breadcrumb">
      <ol class="breadcrumb">
        <li class="breadcrumb-item">
          <span class="icon-o-home primary tint o-50 pointer"
                routerLink="/dashboard"></span>
        </li>

        <li class="breadcrumb-item active">
          Operadores
        </li>
      </ol>
    </nav>

    <!-- <p>vm {{ vm | json }}</p> -->

    <div class="d-flex justify-content-between">
      <div class="my-auto">
        <mat-form-field appearance="outline"
                        class="w-100">
          <mat-label>Nome</mat-label>
          <button mat-button
                  matPrefix
                  mat-icon-button>
            <mat-icon class="o-50">search</mat-icon>
          </button>
          <input matInput
                 type="text"
                 [focus]="searchFocusEvent$()"
                 [ngModel]="searchTermAction$ | async"
                 (ngModelChange)="searchTermAction$?.next($event)">
          <button mat-button
                  *ngIf="!!(searchTermAction$ | async)"
                  matSuffix
                  mat-icon-button
                  (click)="searchTermAction$?.next('')">
            <mat-icon class="o-50">close</mat-icon>
          </button>
        </mat-form-field>
      </div>

      <div class="ms-3 mt-1"
           *ngIf="!!vm?.contas?.length">
        <cor-mat-pag-sel-offset size="lg"
                                [storageKey]="offsetStorageKey()"
                                (change$)="pagItemsPerPageAction$.next($event)" />
      </div>

      <div class="ms-3 mt-1"
           *ngIf="!!vm?.contas?.length">
        <cor-mat-ngx-pagination-nav pagId="contas"
                                    size="lg"
                                    [isMobile]="true"
                                    (pageChange$)="pagPageAction$.next($event)" />
      </div>
    </div>

    <div class="d-flex">
      <button type="button"
              class="btn btn-light btn-lg flex-1 mb-4"
              (click)="onOperadorAddClick()">
        <span class="icon-r-plus o-30 me-2"></span> Habilitar operador
      </button>
    </div>

    <div *ngIf="!!vm?.contas?.length; else semContas">
      <table class="table table-striped">
        <thead>
          <tr>
            <th scope="col"></th>
            <th scope="col"
                class="text-center">
              Ativo
            </th>
            <th scope="col">Nome / E-mail </th>
            <th scope="col">Tipo</th>
            <th scope="col">Fones</th>
            <!-- <th scope="col"></th> -->
          </tr>
        </thead>

        <tbody>
          <tr *ngFor="let o of vm?.contas | paginate: { id: 'contas', itemsPerPage: vm?.pag?.itemsPerPage, currentPage: vm?.pag?.page, totalItems: vm?.contas?.length }; trackBy:tid_con"
              [class.off]="!o?.ativo?._status">
            <td>
              <img [src]="o?.__urlFoto"
                   alt=""
                   class="img-fluid avatar"
                   *ngIf="o?.__urlFoto">
            </td>

            <td class="text-center">
              <cor-html-status-checkbox [status]="o?.ativo?._status" />
            </td>

            <td>
              {{ o?.__nomeCompleto }}
              <div *ngIf="o?.email">
                <small class="o-50">
                  {{ o?.email }}
                </small>
              </div>
            </td>

            <td>
              <div class="pill">
                {{ o?.operador?._role }}
              </div>
            </td>

            <td>
              <span *ngIf="o?.fones?.celular">
                <span class="icon-o-smartphone o-50 me-2"></span>{{ o?.fones?.celular | fone }}
                <br>
              </span>

              <span *ngIf="o?.fones?.fixo">
                <span class="icon-s-phone o-50 me-2"></span>{{ o?.fones?.fixo | fone }}
                <br>
              </span>

              <span *ngIf="o?.fones?.mensagens">
                <span class="icon-s-whatsapp o-50 me-2"></span>{{ o?.fones?.mensagens | fone }}
              </span>
            </td>

            <!-- <td>
              <div class="d-flex justify-content-around">
                <button mat-icon-button
                        matTooltip="Editar operador"
                        (click)="onOperadorEditClick(o)">
                  <mat-icon>edit</mat-icon>
                </button>

                <button mat-icon-button
                        matTooltip="Remover operador"
                        (click)="onOperadorDelClick(o)">
                  <mat-icon>delete</mat-icon>
                </button>
              </div>
            </td> -->
          </tr>
        </tbody>
      </table>
    </div>
    <ng-template #semContas>
      <div class="alert alert-light text-center"
           role="alert">
        Nenhum operador.
      </div>
    </ng-template>
  </div>
</ng-container>
