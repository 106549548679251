//#region ng
import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
//#endregion

//#region pipes
import {
  ProGalLfixesPipe,
  ProProLfixesPipe,
} from '.';
//#endregion

@NgModule({
  imports: [
    CommonModule,
  ],
  declarations: [
    ProProLfixesPipe,
    ProGalLfixesPipe,
  ],
  exports: [
    ProProLfixesPipe,
    ProGalLfixesPipe,
  ]
})
export class ProPipesModule { }
