<!-- <ng-container *ngIf="vm$() | async as vm"> -->
<div class="box">
  <nav aria-label="breadcrumb">
    <ol class="breadcrumb">
      <li class="breadcrumb-item">
        <span class="icon-o-home primary tint o-50 pointer"
              routerLink="/dashboard"></span>
      </li>

      <li class="breadcrumb-item active">
        Config
      </li>
    </ol>
  </nav>

  <div class="container-fluid">
    <!-- <p>vm {{ vm | json }}</p> -->

    <app-config-form [loja]="loja()" />
  </div>
</div>
<!-- </ng-container> -->
