//#region ng
import {
  Component,
  inject,
  signal
} from '@angular/core';
import { Router } from '@angular/router';
//#endregion

//#region 3rd
import {
  Observable,
  Subject
} from 'rxjs';
import {
  map,
  takeUntil
} from 'rxjs/operators';
//#endregion

//#region models
interface IVm {
  conta: IConta;
};
import { IConta } from '../../_shared/_mercadeiro/_misc/_models/_interfaces/_cols';
import { HOME_ROUTE } from '../../models/consts';
//#endregion

//#region libs
import { onDestroy } from '../../_shared/_core/_ng/_libs';
//#endregion

//#region services
import { ROTAS } from '../../services';
//#endregion

//#region stores
import { ContasStore } from '../../_shared/_mercadeiro/_ng/_stores';
//#endregion

@Component({
  selector: 'notificacoes-page',
  templateUrl: './notificacoes.page.html',
  styleUrls: ['./notificacoes.page.scss']
})
export class NotificacoesPage {
  //#region actions
  #destroyAction$: Subject<void> = onDestroy();
  //#endregion

  //#region publics
  vm$ = signal<Observable<IVm>>(null);
  //#endregion

  //#region injects
  #contasStore = inject(ContasStore);
  #router = inject(Router);
  //#endregion

  //#region lifecycles
  ngOnInit() {
    this.#contasStore.contaStateChanged$
      .pipe(takeUntil(this.#destroyAction$))
      .subscribe(
        (conta: IConta) => !!conta
          && !ROTAS?.notificacoes?.__valid?.status
          && this.#router.navigateByUrl(HOME_ROUTE)
      );

    this.vm$.set(
      this.#contasStore.contaStateChanged$
        .pipe(
          map((conta: IConta) => {
            const VM: IVm = {
              conta
            };
            return VM;
          })
        )
    );
  }
  //#endregion
}
