//#region ng
import {
  Component,
  EventEmitter,
  Inject,
  effect,
  inject,
  signal,
} from '@angular/core';
//#endregion

//#region firebase
import {
  arrayUnion,
  Unsubscribe as FbUnsubscribe,
} from '@angular/fire/firestore';
import { getApp } from 'firebase/app';
import {
  getFunctions,
  httpsCallable
} from 'firebase/functions';
//#endregion

//#region mat
import {
  MatDialogRef,
  MAT_DIALOG_DATA
} from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
//#endregion

//#region 3rd
import Fuse from 'fuse.js';
import {
  first,
  finalize,
  takeUntil,
  distinctUntilChanged,
  debounceTime,
} from 'rxjs/operators';
import {
  BehaviorSubject,
  Subject,
  Subscription
} from 'rxjs';
//#endregion

//#region models
import { environment } from 'src/environments/environment';
import {
  ILocalidadeBairro,
  ILoja,
  ISetorEntrega
} from '../../_shared/_mercadeiro/_misc/_models/_interfaces/_cols';
import {
  LocalidadeBairrosService,
  LojasService,
  SetoresEntregaService
} from '../../_shared/_mercadeiro/_ng/_services';
import { FOCUS_TIMEOUT } from '../../_shared/_core/_misc/_models/consts';
import { IBairroAddParams } from '../../models/interfaces/params';
//#endregion

//#region libs
import { onDestroy } from '../../_shared/_core/_ng/_libs';
//#endregion

//#region services
import { CorLoaderService } from '../../_shared/_core/_ng/_services';
//#endregion

@Component({
  selector: 'app-bairro-add-modal',
  templateUrl: './bairro-add.modal.html',
  styleUrls: ['./bairro-add.modal.scss']
})
export class BairroAddModal {
  //#region actions
  #destroyAction$: Subject<void> = onDestroy();
  searchTermAction$ = signal<BehaviorSubject<string>>(new BehaviorSubject<string>(''));
  //#endregion

  //#region publics
  bairros = signal<ILocalidadeBairro[]>(null);
  excecoes = signal<string[]>([]);
  loja = signal<ILoja>(null);
  setor = signal<ISetorEntrega>(null);
  //#endregion

  //#region privates
  #bairros: ILocalidadeBairro[];
  //#endregion

  //#region methods
  onSearchFocusEvent$ = signal<EventEmitter<boolean>>(new EventEmitter<boolean>());
  //#endregion

  //#region injects
  #bairrosServ = inject(LocalidadeBairrosService);
  #dialogRef = inject(MatDialogRef<BairroAddModal>);
  #loaderServ = inject(CorLoaderService);
  #lojasServ = inject(LojasService);
  #setoresServ = inject(SetoresEntregaService);
  #snackBar = inject(MatSnackBar);
  //#endregion

  //#region constructor
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: IBairroAddParams,
  ) {
    effect(
      () => {
        const LOJA_ID: string = this.loja()?.id || '';
        if (!!LOJA_ID) {
          const LOCALIDADE_PATH: string = this.loja()?.__idInfo?.localidadePath || '';
          // console.log(LOCALIDADE_PATH);
          const SUB: Subscription = this.#loaderServ.showUntilCompleted(
            this.#bairrosServ.docs(LOCALIDADE_PATH)
              .pipe(first(), finalize(() => SUB?.unsubscribe()))
          )
            .subscribe((bairros: ILocalidadeBairro[]) => {
              this.#bairros = this.#bairrosServ.fixes(bairros);
              // this.onSearchSubmit('');
            });
        } // if
      }
    );
  }
  //#endregion

  //#region lifecycles
  ngOnInit() {
    this.excecoes.set(this.data?.excecoes || []);
    this.loja.set(!!this.data?.loja ? this.#lojasServ.fix(this.data?.loja) : null);
    this.setor.set(!!this.data?.setor ? this.#setoresServ.fix(this.data?.setor) : null);

    this.searchTermAction$()
      .pipe(
        debounceTime(500),
        distinctUntilChanged(),
        takeUntil(this.#destroyAction$)
      )
      .subscribe(
        (searchTerm: string) => {
          // console.log(searchTerm);
          const CEP: string = (searchTerm || '').replace(/\D/g, '') || '';
          const IS_CEP: boolean = CEP.length === 8;
          // console.log(CEP, IS_CEP);
          if (!!IS_CEP) {
            this.#loaderServ.lstart();
            try {
              const functions = getFunctions(getApp(), environment?.firebase?.region);
              const fn = httpsCallable(functions, 'onVerificaCep');
              fn({ data: CEP })
                .then((RET: any) => {
                  // console.log(RET);
                  this.bairros.set([RET?.data || null]);
                });
            } finally {
              this.#loaderServ.lstop();
            } // try-finally
          } else if (!!searchTerm) {
            const FUSE: any = new Fuse(
              this.#bairros,
              {
                includeScore: false,
                keys: [
                  '_nome',
                ],
                // minMatchCharLength: 3,
              }
            );
            const HITS: any[] = FUSE.search(searchTerm);
            // console.log(HITS);
            this.bairros.set(HITS.map((i: any) => i?.item));
          } else {
            this.bairros.set(this.#bairros);
          } // else
        }
      );
  }

  ngAfterViewInit() {
    this.#focus();
  }
  //#endregion

  //#region functions
  tids(index: any, item: ILocalidadeBairro): string { return item?.id || ''; }

  #focus() {
    setTimeout(
      () => { this.onSearchFocusEvent$().emit(true); },
      FOCUS_TIMEOUT
    );
  }
  //#endregion

  //#region methods
  onModalCloseClick() {
    this.#dialogRef.close();
  }
  //#endregion

  //#region methods
  onBairroClick(b: ILocalidadeBairro) {
    // console.log(b);
    if (!this.excecoes().includes(b?._nome)) {
      const BAIRRO_NOME: string = b?._nome || '';
      const SETOR_ID: string = this.setor()?.id || '';
      // console.log(SETOR_ID);
      const LOJA_PATH: string = this.loja()?.__idInfo?.lojaPath || '';
      if (BAIRRO_NOME && SETOR_ID && LOJA_PATH) {
        const SUB: Subscription = this.#loaderServ.showUntilCompleted(
          this.#setoresServ.update(
            LOJA_PATH,
            SETOR_ID,
            { bairros: arrayUnion(BAIRRO_NOME) }
          )
            .pipe(first(), finalize(() => SUB?.unsubscribe()))
        )
          .subscribe(
            () => {
              this.#snackBar.open(`Bairro ${BAIRRO_NOME} adicionado.`);
              this.onModalCloseClick();
            }
          );
      } // if
    } // if
  }
  //#endregion
}
