//#region ng
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
//#endregion

//#region mat
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatToolbarModule } from '@angular/material/toolbar';
//#endregion

//#region 3rd
import { NgxPaginationModule } from 'ngx-pagination';
//#endregion

//#region modules
import { CorDirectivesModule } from '../../_shared/_core/_ng/_directives/cor-directives.module';
import { MeMatComponentsModule } from '../../_shared/_mercadeiro/_ng-ui/_mat/me-mat-components.module';
// import { ProMatComponentsModule } from '../../_shared/_produtos/_ng-ui/_mat/pro-mat-components.module';
import { ComponentsModule } from '../../components/components.module';
//#endregion

//#region pages
import { GaleriaGetModal } from '..';
//#endregion

@NgModule({
  imports: [
    CorDirectivesModule,
    CommonModule,
    ComponentsModule,
    FormsModule,
    MatButtonModule,
    MatIconModule,
    MeMatComponentsModule,
    MatToolbarModule,
    NgxPaginationModule,
    // ProMatComponentsModule,
  ],
  declarations: [
    // LoginFormComponent,
    GaleriaGetModal,
  ]
})
export class GaleriaGetModalModule { }
