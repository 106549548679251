//#region ng
import {
  Component,
  Input,
  inject,
  signal
} from '@angular/core';
//#endregion

//#region mat
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
//#endregion

//#region firebase
import {
  arrayRemove,
  doc,
  Firestore,
  onSnapshot,
  Unsubscribe as FbUnsubscribe,
} from '@angular/fire/firestore';
//#endregion

//#region 3rd
import { Subscription } from 'rxjs';
import {
  finalize,
  first,
} from 'rxjs/operators';
//#endregion

//#region models
import {
  IConta,
  ILoja
} from '../../../_shared/_mercadeiro/_misc/_models/_interfaces/_cols';
//#endregion

//#region services
import {
  ContasService,
  LojasService
} from '../../../_shared/_mercadeiro/_ng/_services';
import {
  CorFirestoreService,
  CorLoaderService
} from '../../../_shared/_core/_ng/_services';
//#endregion

//#region components
import {
  CredenciadoHabilitarModal,
  DistribuidoraCredenciadoFormModal
} from '../../../modals';
//#endregion

@Component({
  selector: 'app-distribuidora-credenciados',
  templateUrl: './distribuidora-credenciados.component.html',
  styleUrls: ['./distribuidora-credenciados.component.scss']
})
export class DistribuidoraCredenciadosComponent {
  //#region inputs
  // conta
  @Input({ required: true }) conta: IConta;
  // loja
  #loja: ILoja;
  get loja(): ILoja { return this.#loja; }
  @Input({ required: true }) set loja(val: ILoja) {
    this.#loja = !!val ? this.#lojasServ.fix(val) : null;
    // console.log(val);
    const LOJA_PATH: string = val?.__idInfo?.lojaPath || '';
    if (!!LOJA_PATH) {
      this.#lojaUnsub && this.#lojaUnsub();
      this.#lojaUnsub = onSnapshot(
        doc(this.#db, LOJA_PATH),
        snap => {
          this.#refresh(snap.get('distribuidora.idsCredenciados') || []);
        }
      );
    } // if
  }
  //#endregion

  //#region credenciados
  credenciados = signal<IConta[]>(null);
  //#endregion

  //#region privates
  #lojaUnsub: FbUnsubscribe;
  //#endregion

  //#region injects
  #contasServ = inject(ContasService);
  #db = inject(Firestore);
  #firestoreServ = inject(CorFirestoreService);
  #loaderServ = inject(CorLoaderService);
  #lojasServ = inject(LojasService);
  #modal = inject(MatDialog);
  #snackBar = inject(MatSnackBar);
  //#endregion

  //#region lifecycles
  ngOnDestroy(): void {
    !!this.#lojaUnsub && this.#lojaUnsub();
  }
  //#endregion

  //#region functions
  tid_con(index: any, item: IConta): string { return item?.id || ''; }

  async #refresh(ids: string[] = null) {
    // console.log(ids);
    this.credenciados.set(
      this.#contasServ.fixes(
        (await this.#firestoreServ.getPathIdsDocs(
          '/_contas',
          ids || (this.credenciados() || []).map((c: IConta) => c?.id) || [],
        ))
      )
    );
  }
  //#endregion

  //#region methods
  onCredenciadoHabilitarClick() {
    const DIALOG_EXISTS = this.#modal.getDialogById('credenciado-add');
    if (!DIALOG_EXISTS) {
      this.#modal.open(
        CredenciadoHabilitarModal,
        {
          id: 'credenciado-add',
          panelClass: 'no-border-dialog-container',
          data: {
            // conta: this.conta,
            loja: this.loja,
          }
        }
      );
    } // if
  }

  onCredenciadoEditClick(c: IConta = null) {
    const DIALOG_EXISTS = this.#modal.getDialogById('credenciado-edit');
    if (!DIALOG_EXISTS) {
      const DIALOG_REF: any = this.#modal.open(
        DistribuidoraCredenciadoFormModal,
        {
          id: 'credenciado-edit',
          panelClass: 'no-border-dialog-container',
          data: {
            credenciado: c,
            loja: this.loja
          }
        }
      );

      const SUB: Subscription = DIALOG_REF
        .afterClosed()
        .pipe(first(), finalize(() => SUB?.unsubscribe()))
        .subscribe(
          () => {
            // console.log(resp);
            this.#refresh();
          }
        );
    } // if
  }

  onCredenciadoDelClick(c: IConta) {
    // console.log(c);
    const CONTA_ID: string = c?.id || '';
    const LOJA_ID: string = this.#loja?.id || '';
    // console.log(LOJA_ID, CONTA_ID);

    if (!!CONTA_ID && !!LOJA_ID) {
      const LOJA_CHANGES: any = {
        distribuidora: {
          idsCredenciados: arrayRemove(CONTA_ID)
        },
      };

      const CONTA_CHANGES: any = {
        credenciado: {
          [LOJA_ID]: {
            idsFormasPgto: []
          }
        }
      };
      // console.log(LOJA_CHANGES);
      // console.log(CONTA_CHANGES);

      const SUB: Subscription = this.#loaderServ.showUntilCompleted(
        this.#contasServ.update(CONTA_ID, CONTA_CHANGES)
          .pipe(first(), finalize(() => SUB?.unsubscribe()))
      )
        .subscribe(
          () => {
            this.#loaderServ.lstart();
            const SUB2: Subscription = this.#lojasServ.update(
              this.loja?.id || '',
              LOJA_CHANGES
            )
              .pipe(first(), finalize(() => SUB2?.unsubscribe()))
              .subscribe(
                async () => {
                  this.#snackBar.open('Credenciado removido.');
                }
              );
          }
        );
    } // if
  }
  //#endregion
}
