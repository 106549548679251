//#region ng
import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Inject,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
  computed,
  effect,
  inject,
  signal
} from '@angular/core';
import {
  AbstractControl,
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
  ValidationErrors
} from '@angular/forms';
//#endregion

//#region mat
// import { MatStepper } from '@angular/material/stepper';
import {
  MatDialogRef,
  MAT_DIALOG_DATA
} from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
//#endregion

//#region firebase
import { getApp } from 'firebase/app';
import {
  getFunctions,
  httpsCallable
} from 'firebase/functions';
//#endregion

//#region 3rd
import { get } from 'lodash';
import { Subject, Subscription } from 'rxjs';
import {
  finalize,
  first,
  takeUntil,
} from 'rxjs/operators';
import Swal from 'sweetalert2';
//#endregion

//#region models
import {
  ILoja,
  IPTag,
} from '../../_shared/_mercadeiro/_misc/_models/_interfaces/_cols';
import { IPTagEditParams } from '../../models/interfaces/params';
import { FOCUS_TIMEOUT } from '../../_shared/_core/_misc/_models/consts';
import { CorFormValidation } from '../../_shared/_core/_ng/_models/_classes';
import { TNullable } from '../../_shared/_core/_misc/_models/_types';
//#endregion

//#region libs
import { onDestroy } from '../../_shared/_core/_ng/_libs';
//#endregion

//#region services
import { PTagsService } from '../../_shared/_mercadeiro/_ng/_services';
import { CorLoaderService } from '../../_shared/_core/_ng/_services';
//#endregion

//#region components
import { LojasStore } from '../../_shared/_mercadeiro/_ng/_stores';
//#endregion

@Component({
  selector: 'app-p-tag-form-modal',
  templateUrl: './p-tag-form.modal.html',
  styleUrls: ['./p-tag-form.modal.scss']
})
export class PTagFormModal {
  //#region actions
  #destroyAction$: Subject<void> = onDestroy();
  //#endregion

  //#region inputs
  // isMobile
  @Input() isMobile: boolean = false;
  // // lojaPath
  // @Input() lojaPath: string;
  //#endregion

  //#region outputs
  // @Output() submit$ = new EventEmitter<Partial<IPTag>>();
  //#endregion

  //#region events
  captionFocusEvent$ = signal<EventEmitter<boolean>>(new EventEmitter<boolean>()).asReadonly();
  //#endregion

  //#region publics
  caption = computed<string>(() => this.pTag()?.caption || 'Nova tag');
  fv = signal<CorFormValidation>(null);
  pTag = signal<IPTag>(null);
  ptagForm = computed<FormGroup>(() => {
    const PTAG: IPTag = this.pTag();
    // console.log(PTAG);
    return this.#fb.group({
      caption: [PTAG?.caption || '', [Validators.required]],
      theme: [PTAG?.theme || '', [Validators.required]],
    });
  });
  //#endregion

  //#region privates
  #lojaPath: string;
  //#endregion

  //#region Controls getters
  get captionRef(): AbstractControl { return this.ptagForm()?.get('nome'); }
  get themeRef(): AbstractControl { return this.ptagForm()?.get('theme'); }
  //#endregion

  //#region injects
  #lojasStore = inject(LojasStore);
  #dialogRef = inject(MatDialogRef<PTagFormModal>);
  #fb = inject(FormBuilder);
  #loaderServ = inject(CorLoaderService);
  #pTagsServ = inject(PTagsService);
  #snackBar = inject(MatSnackBar);
  //#endregion

  //#region constructor
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: IPTagEditParams,
  ) {
    this.fv.set(new CorFormValidation());
  }
  //#endregion

  //#region lifecycles
  ngOnInit() {
    this.pTag.set(this.#pTagsServ.fix(this.data?.pTag));

    this.#lojasStore.lojaStateChanged$
      .pipe(takeUntil(this.#destroyAction$))
      .subscribe((loja: ILoja) => this.#lojaPath = loja?.__idInfo?.lojaPath || '');
  }

  ngAfterViewInit() {
    this.focus();
  }
  //#endregion

  //#region functions
  focus() {
    !this.isMobile && setTimeout(
      () => { this.captionFocusEvent$().emit(true); },
      FOCUS_TIMEOUT
    );
  }
  //#endregion

  //#region methods
  onTemaClick(theme: string) {
    // console.log(tema);
    this.ptagForm()?.patchValue({ theme });
  }

  onModalCloseClick(data: TNullable<Partial<IPTag>> = null) {
    this.#dialogRef.close(data);
  }

  onSubmit() {
    const ID: string = this.pTag()?.id || '';
    // const CHANGES: Partial<IPTag> = { ...this.ptagForm()?.value };
    // // delete CHANGES.id;
    // console.log(CHANGES);

    if (!ID) {
      const CHANGES: Partial<IPTag> = {
        ...this.ptagForm()?.value,
        _isSistema: false,
      };
      const SUB: Subscription = this.#loaderServ.showUntilCompleted(
        this.#pTagsServ.add(
          this.#lojaPath,
          CHANGES
        )
          .pipe(first(), finalize(() => SUB?.unsubscribe()))
      )
        .subscribe(() => {
          this.#snackBar.open('Tag de produto adicionada.');
          this.onModalCloseClick(CHANGES);
        });
    } else {
      const CHANGES: Partial<IPTag> = {
        ...this.ptagForm()?.value,
        _isSistema: false,
      };
      const SUB: Subscription = this.#loaderServ.showUntilCompleted(
        this.#pTagsServ.update(
          this.#lojaPath,
          ID,
          CHANGES,
        )
          .pipe(first(), finalize(() => SUB?.unsubscribe()))
      )
        .subscribe(() => {
          this.#snackBar.open('Tag de produto modificada.');
          this.onModalCloseClick(CHANGES);
        });
    } // else
  }
  //#endregion
}
