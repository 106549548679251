//#region ng
import {
  Component,
  Inject,
  computed,
  inject,
  signal,
} from '@angular/core';
import { Router } from '@angular/router';
//#endregion

//#region firebase
import {
  doc,
  Firestore,
  onSnapshot,
  Unsubscribe as FbUnsubscribe,
} from '@angular/fire/firestore';
//#endregion

//#region mat
import {
  MatBottomSheet,
  MatBottomSheetRef,
  MAT_BOTTOM_SHEET_DATA
} from '@angular/material/bottom-sheet';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatDialog } from '@angular/material/dialog';
//#endregion

//#region 3rd
import {
  Subject,
  Subscription
} from 'rxjs';
import {
  first,
  finalize,
  takeUntil
} from 'rxjs/operators';
import { get } from 'lodash';
//#endregion

//#region models
import {
  HOME_ROUTE,
  THEME
} from '../../models/consts';
import {
  IConta,
  ILoja
} from '../../_shared/_mercadeiro/_misc/_models/_interfaces/_cols';
import { IHorarioAgendamentoMap } from '../../_shared/_mercadeiro/_misc/_models/_interfaces/_maps';
import { DOW } from '../../_shared/_core/_misc/_models/consts';
import { IHorariosBSParams } from '../../models/interfaces/params';
import { THorarioAgendamento } from '../../_shared/_mercadeiro/_misc/_models/_types';
//#endregion

//#region libs
import { onDestroy } from '../../_shared/_core/_ng/_libs';
//#endregion

//#region services
import {
  ContasService,
  LojasService
} from '../../_shared/_mercadeiro/_ng/_services';
import {
  AppService,
  ROTAS
} from '../../services';
import { CorLoaderService } from '../../_shared/_core/_ng/_services';
import { compareValues } from '../../_shared/_libs/_misc/_arrays';
//#endregion

//#region stores
import {
  ContasStore,
  LojasStore
} from '../../_shared/_mercadeiro/_ng/_stores';
//#endregion

//#region components
import { HorarioFormModal } from '../../modals';
//#endregion

@Component({
  selector: 'app-horarios-bottom-sheet',
  templateUrl: 'bottom-sheet.html',
})
export class HorariosBottomSheetComponent {
  //#region publics
  copyFrom = signal<{
    tipo: THorarioAgendamento;
    dow: DOW;
  }>(null);
  dow = signal<DOW>(null);
  horario: IHorarioAgendamentoMap;
  tipo: THorarioAgendamento;
  //#endregion

  //#region injects
  #bottomSheetRef = inject(MatBottomSheetRef<HorariosBottomSheetComponent>);
  //#endregion

  //#region constructor
  constructor(
    @Inject(MAT_BOTTOM_SHEET_DATA) public data: IHorariosBSParams,
  ) { }
  //#endregion

  //#region lifecycles
  ngOnInit() {
    // console.log(this.data);
    this.tipo = this.data?.tipo;
    this.dow.set(this.data?.dow);
    this.horario = this.data?.horario;
  }
  //#endregion

  //#region functions
  onCloseLink(action: string = '') {
    this.#bottomSheetRef.dismiss(action);
  }
  //#endregion
}

@Component({
  selector: 'horarios-page',
  templateUrl: './horarios.page.html',
  styleUrls: ['./horarios.page.scss']
})
export class HorariosPage {
  //#region actions
  #destroyAction$: Subject<void> = onDestroy();
  //#endregion

  //#region publics
  copyFrom = signal<{
    tipo: THorarioAgendamento;
    dow: DOW;
  }>(null);
  loja = signal<ILoja>(null);
  // horarios = signal<string[]>(['funcionamento']);
  tiposHorarios = computed<THorarioAgendamento[]>(
    () => {
      const LOJA: ILoja = this.loja() || null;
      const ENTREGA: boolean = !!LOJA?.entrega?.status;
      const TIPOS_HORARIOS: THorarioAgendamento[] = ['funcionamento'];
      !!ENTREGA && TIPOS_HORARIOS.push('entrega');
      return TIPOS_HORARIOS;
    }
  );
  //#endregion

  //#region privates
  #lojaUnsub: FbUnsubscribe;
  //#endregion

  //#region injects
  // #appServ = inject(AppService);
  #bottomSheet = inject(MatBottomSheet);
  // #contasServ = inject(ContasService);
  #contasStore = inject(ContasStore);
  #db = inject(Firestore);
  #loaderServ = inject(CorLoaderService);
  #lojasServ = inject(LojasService);
  #lojasStore = inject(LojasStore);
  #modal = inject(MatDialog);
  #router = inject(Router);
  #snackBar = inject(MatSnackBar);
  //#endregion

  //#region lifecycles
  ngOnInit() {
    this.#contasStore.contaStateChanged$
      .pipe(takeUntil(this.#destroyAction$))
      .subscribe(async (conta: IConta) => {
        // console.log(conta);
        if (!!conta) {
          // const ROLE: TOperadorRole = get(conta, 'operador.role');
          !ROTAS?.horarios?.__valid?.status &&
            this.#router.navigateByUrl(HOME_ROUTE);
        } // if
      });

    this.#lojasStore.lojaStateChanged$
      .pipe(takeUntil(this.#destroyAction$))
      .subscribe(async (loja: ILoja) => {
        // console.log(loja);
        const LOJA_PATH: string = loja?.__idInfo?.lojaPath || '';
        if (!!LOJA_PATH) {
          !!this.#lojaUnsub && this.#lojaUnsub();
          this.#lojaUnsub = onSnapshot(
            doc(this.#db, LOJA_PATH),
            snap => {
              // console.log(snap.data());
              this.loja.set(this.#lojasServ.fix({ id: snap.id, ...snap.data() }));
            }
          );
        } // if
      });
  }

  ngOnDestroy() {
    !!this.#lojaUnsub && this.#lojaUnsub();
  }
  //#endregion

  //#region functions
  tid_tip(index: any, item: THorarioAgendamento): string { return item || ''; }
  //#endregion

  //#region methods
  onClonarClick(tipo: THorarioAgendamento, dow: number) {
    this.copyFrom.set({ tipo, dow: dow as DOW })
  }

  onHorarioClick(
    tipo: any,
    dow: any,
    horario: IHorarioAgendamentoMap
  ) {
    // console.log(tipo, dow, horario);
    const SHEET_REF: any = this.#bottomSheet.open(
      HorariosBottomSheetComponent,
      {
        data: {
          tipo,
          dow,
          horario,
          loja: this.loja,
        }
      }
    );

    const SUB: Subscription = SHEET_REF
      .afterDismissed()
      .pipe(first(), finalize(() => SUB?.unsubscribe()))
      .subscribe(async (action: any) => {
        // console.log(action);
        switch (action) {
          case 'edit':
            this.onHorarioEditClick(tipo, dow, horario);
            break;

          case 'del':
            const HORARIO_KEY: string = `horarios.${tipo}.dow${dow}`;
            const HORARIOS: IHorarioAgendamentoMap[] = (get(this.loja(), HORARIO_KEY) || [])
              .filter((h: IHorarioAgendamentoMap) => h?.id !== horario?.id);
            // console.log(HORARIO_KEY, get(this.loja, HORARIO_KEY) || [], HORARIOS);
            const SUB: Subscription = this.#loaderServ.showUntilCompleted(
              this.#lojasServ.update(
                this.loja()?.id,
                {
                  horarios: {
                    [tipo]: {
                      [`dow${dow}`]: HORARIOS
                        .sort(compareValues('inicio', 'asc'))
                    }
                  }
                }
              )
                .pipe(first(), finalize(() => SUB?.unsubscribe()))
            )
              .subscribe(() => {
                AppService.horarioChanged$().emit(null);
                this.#snackBar.open('Horário removido.');
                // this.#lojasServ.setLojaState(this.lojaState);
              });
            break;
        } // switch
      });
  }

  onHorarioEditClick(
    tipo: any,
    dow: any,
    horario: IHorarioAgendamentoMap
  ) {
    // console.log(tipo, dow, horario);
    const DIALOG_EXISTS = this.#modal.getDialogById('horario-form');
    if (!DIALOG_EXISTS) {
      // const DIALOG_REF: any =
      this.#modal.open(
        HorarioFormModal,
        {
          panelClass: 'no-border-dialog-container',
          id: 'horario-form',
          data: {
            tipo,
            dow,
            horario,
            loja: this.loja(),
          }
        }
      );
    } // if

  }

  onCopiarClick(dowPara: number) {
    const TIPO: string = this.copyFrom()?.tipo;
    // console.log(TIPO);
    const DOW_FROM_TAG: string = `dow${this.copyFrom()?.dow}`;
    // console.log(DOW_FROM_TAG);
    const DOW_TO_TAG: string = `dow${dowPara}`;
    // console.log(DOW_TO_TAG);

    if (
      !!TIPO
      && !!DOW_FROM_TAG
      && !!DOW_TO_TAG
    ) {
      const HORARIOS: any = this.loja().horarios[TIPO][DOW_FROM_TAG];
      // console.log(HORARIOS);

      this.#loaderServ.lstart();
      const SUB: Subscription = this.#loaderServ.showUntilCompleted(
        this.#lojasServ.update(
          this.loja()?.id,
          { horarios: { [TIPO]: { [DOW_TO_TAG]: HORARIOS } } })
          .pipe(first(), finalize(() => SUB?.unsubscribe()))
      )
        .subscribe(() => {
          AppService.horarioChanged$().emit(null);
          this.#snackBar.open('Horário copiado.');
          // this.#lojasServ.setLojaState(this.lojaState);
        }
        );
    } // if
  }
  //#endregion

  /*
  //#region publics
  conta: IConta;
  //#endregion

  //#region loja
  private _loja: ILoja;
  set loja(val: ILoja) {
    this._loja = val ? this.#lojasServ.fix(val) : null;
  }
  get loja(): ILoja {
    return this._loja;
  }
  //#endregion

  //#region privates
  private _subs: Subscription[] = [];
  //#endregion

  //#region lifecycles
  ngOnInit(): void {
    this._subs.push(
      AppService.lojaStateChanged$
        .subscribe((loja: ILoja) => {
          // console.log(loja.horarios);
          this.loja = loja;
          const ENTREGA: boolean = !!loja?.entrega?.status
          ENTREGA && !this.horarios.includes('entrega') && this.horarios.push('entrega');
        }),

      AppService.horarioChanged$
        .subscribe((horario: IHorarioAgendamentoMap) => {
          // console.log(horario);
          const LOJA_ID: string = this.loja?.id || '';
          if (LOJA_ID) {
            const SUB: Subscription = this.#lojasServ.doc(LOJA_ID)
              .pipe(
                first(),
                finalize(() => SUB && SUB.unsubscribe())
              )
              .subscribe(
                async (loja: ILoja) => {
                  // console.log(loja);
                  this.#lojasStore.setState(loja, THEME);
                });
          } // if
        }),
    );
  }

  ngOnDestroy(): void {
    this._subs.forEach((s: Subscription) => s && s.unsubscribe());
  }
  //#endregion
  */
}
