<!-- <p>produto {{ produto | json }}</p> -->
<!-- <p>produto?.tags {{ produto?.tags | json }}</p> -->
<!-- <p>produtoForm()?.value {{ produtoForm()?.value | json }}</p> -->
<!-- <p>pids() {{ pids() }}</p> -->

<div class="container-fluid">
  <form novalidate
        [formGroup]="produtoForm()"
        *ngIf="!!produtoForm()">

    <mat-card class="mb-4">
      <mat-card-title>Status</mat-card-title>

      <mat-card-content>
        <div class="d-flex flex-column">
          <div class="d-flex m-auto toggle-wrapper pb-2"
               formGroupName="ativo">
            <div class="flex-1 off">
              Ativo
            </div>
            <span class="my-auto">
              <mat-slide-toggle formControlName="status"
                                class="off"
                                [disabled]="true" />
            </span>
          </div>

          <div class="d-flex m-auto toggle-wrapper pb-2"
               formGroupName="ativo">
            <div class="flex-1 off">
              Consistente
            </div>
            <span class="my-auto">
              <mat-slide-toggle formControlName="_consistente"
                                class="off"
                                [disabled]="true" />
            </span>
          </div>

          <div class="d-flex m-auto toggle-wrapper pb-2"
               formGroupName="ativo">
            <div class="flex-1">
              Online
            </div>
            <span class="my-auto">
              <mat-slide-toggle formControlName="online" />
            </span>
          </div>
        </div>
      </mat-card-content>
    </mat-card>

    <mat-card class="mb-4">
      <mat-card-title>Fracionado</mat-card-title>

      <mat-card-content>
        <div formGroupName="fracionado"
             class="d-flex flex-column">
          <div class="d-flex m-auto toggle-wrapper pb-2">
            <div class="flex-1 off">
              Status
            </div>
            <span class="my-auto">
              <mat-slide-toggle formControlName="_status"
                                class="off"
                                [disabled]="true" />
            </span>
          </div>

          <div *ngIf="fracionadoStatusRef.value"
               formGroupName="unidade"
               class="m-auto">
            <mat-form-field class="mb-2">
              <mat-label>Tipo unidade</mat-label>
              <mat-select formControlName="tipo"
                          class="off"
                          [disabled]="true">
                <mat-option value=""> -- Selecione -- </mat-option>
                <mat-option *ngFor="let tipoUnidade of tiposUnidade; trackBy:tids_pro"
                            [value]="tipoUnidade"
                            class="off"
                            [disabled]="true">
                  {{ tipoUnidade }}
                </mat-option>
              </mat-select>
              <mat-hint *ngIf="fv()?.error(fracionadoUnidadeTipoRef, 'fracionadoTipo')">
                <span class="danger">
                  {{ fv()?.error(fracionadoUnidadeTipoRef, 'fracionadoTipo') }}
                </span>
              </mat-hint>
            </mat-form-field>
          </div>

          <div *ngIf="!!fracionadoStatusRef.value"
               formGroupName="unidade"
               class="m-auto">
            <mat-form-field class="mb-2"
                            appearance="outline">
              <mat-label>Fração</mat-label>
              <input matInput
                     type="number"
                     class="text-end"
                     formControlName="fracao"
                     onReturn>
              <mat-hint>
                <div class="danger"
                     *ngIf="fv()?.error(fracionadoUnidadeFracaoRef, 'fracionadoFracao') || fracionadoUnidadeFracaoRef?.errors?.['fracao']; else fracaoHint">
                  {{ fv()?.error(fracionadoUnidadeFracaoRef, 'fracionadoFracao') || 'Inválido.' }}
                </div>
                <ng-template #fracaoHint>
                  Ex: 0,5Kg ou 500g...
                </ng-template>
              </mat-hint>
            </mat-form-field>
          </div>

          <!-- <div *ngIf="!!fracionadoStatusRef.value"
               formGroupName="unidade"
               class="m-auto">
            <mat-form-field class="mb-2"
                            appearance="outline">
              <mat-label>Fração aproximada unidade</mat-label>
              <input matInput
                     type="number"
                     class="text-end"
                     formControlName="fracaoAproxUn"
                     onReturn>
              <mat-hint>
                <div class="danger"
                     *ngIf="fv()?.error(fracionadoFracaoAproxUnRef, 'fracionadoFracaoAproxUn') || fracionadoFracaoAproxUnRef?.errors?.['fracaoAproxUn']; else fracaoAproxUnitHint">
                  {{ fv()?.error(fracionadoFracaoAproxUnRef, 'fracionadoFracaoAproxUn') || 'Inválido.' }}
                </div>
                <ng-template #fracaoAproxUnitHint>
                  Ex: banana 0,1Kg ou 100g.
                </ng-template>
              </mat-hint>
            </mat-form-field>
          </div> -->
        </div>
      </mat-card-content>
    </mat-card>

    <mat-card class="mb-4">
      <mat-card-title>
        Tags <small class="o-50"> ( {{ pids()?.length }} )</small>
      </mat-card-title>

      <mat-card-content>
        <!-- <p>tags() {{ tags() | json }}</p> -->

        <!-- <mat-checkbox class="me-3"
                      *ngFor="let t of ptags(); trackBy:tid_ptags"
                      [checked]="pids().includes(t?.id)"
                      (click)="onTagEditClick(t)">
          {{ t?.caption }}
        </mat-checkbox> -->

        <mat-chip-list>
          <mat-chip *ngFor="let t of ptags(); trackBy:tid_ptags"
                    [class.bg-primary]="pids().includes(t?.id)"
                    (click)="onTagEditClick(t)">
            <span class="icon-b-check o-50"
                  *ngIf="pids().includes(t?.id)">
            </span>&nbsp;{{ t?.caption }}
          </mat-chip>
        </mat-chip-list>
      </mat-card-content>
    </mat-card>

    <div class="row">
      <div class="col">
        <div class="d-flex">
          <div class="flex-1">
            <button type="button"
                    class="pointer w-100"
                    mat-raised-button
                    color="primary"
                    (click)="onSubmitClick()"
                    [disabled]="!produtoForm()?.valid">
              CONFIRMAR
            </button>
          </div>
        </div>
      </div>
    </div>
  </form>
</div>