//#region ng
import {
  Component, inject, signal,
} from '@angular/core';
import { Router } from '@angular/router';
//#endregion

//#region 3rd
import { Subject } from 'rxjs';
import {
  filter,
  takeUntil
} from 'rxjs/operators';
//#endregion

//#region models
import {
  HOME_ROUTE,
  ID_MODULO
} from '../../models/consts';
import {
  IConta,
  ILoja,
  IPedido
} from '../../_shared/_mercadeiro/_misc/_models/_interfaces/_cols';
// import { TOperadorRole } from '../../_shared/_mercadeiro/_misc/_models/_types';
//#endregion

//#region libs
import { onDestroy } from '../../_shared/_core/_ng/_libs';
//#endregion

//#region services
import { ROTAS } from '../../services';
//#endregion

//#region stores
import {
  ContasStore,
  LojasStore
} from '../../_shared/_mercadeiro/_ng/_stores';
//#endregion

@Component({
  selector: 'movimento-page',
  templateUrl: './movimento.page.html',
  styleUrls: ['./movimento.page.scss']
})
export class MovimentoPage {
  //#region actions
  #destroyAction$: Subject<void> = onDestroy();
  //#endregion

  //#region publics
  idModulo = signal<string>(ID_MODULO).asReadonly();
  pre = signal<string>('');
  //#endregion

  //#region injects
  #contasStore = inject(ContasStore);
  #lojasStore = inject(LojasStore);
  #router = inject(Router);
  //#endregion

  //#region lifecycles
  ngOnInit(): void {
    this.#contasStore.contaStateChanged$
      .pipe(takeUntil(this.#destroyAction$))
      .subscribe(
        (conta: IConta) => !!conta
          && !ROTAS?.movimento?.__valid?.status
          && this.#router.navigateByUrl(HOME_ROUTE)
      );

    this.#lojasStore.lojaStateChanged$
      .pipe(takeUntil(this.#destroyAction$))
      .pipe(filter((loja: ILoja) => !!loja))
      .subscribe(
        (loja: ILoja) => /* console.log(loja) */ this.pre.set(`${loja?.__idInfo?.lojaPath || ''}/`)

      );
  }
  //#endregion

  //#region methods
  onPedidoClick(pedido: IPedido) {
    console.log(pedido);
    /* const LOJA_ID: string = pedido?.carrinho?.__loja?.id;
    const LOJA_PATH: string = calcLojaIdInfo(LOJA_ID)?.lojaPath || ''
    // console.log(LOJA_PATH);
    const ID_MODAL: number = +guid(10, 10);
    this.#bsPedidoModalRef?.hide();
    this.#bsPedidoModalRef = this.#bsModalServ.show(
      AppPedidoDetalhesModal,
      {
        class: 'modal-dialog-centered modal-lg',
        id: ID_MODAL,
        initialState: {
          isMobile: false,
          idModal: ID_MODAL,
          pedido,
          lojaPath: LOJA_PATH
        },
      }); */
  }
  //#endregion
}
