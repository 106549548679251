<ng-container *ngIf="vm$() | async as vm">
  <div *ngIf="loja?.entrega?.status?.includes('E'); else semEntrega">
    <div class="d-flex justify-content-between">
      <button type="button"
              class="my-auto btn btn-light btn-lg"
              (click)="onSetorEditClick()">
        <span class="o-50 icon-r-plus me-2"></span>Adicionar setor
      </button>

      <div class="my-auto d-flex">
        <div *ngIf="!!setores()?.length">
          <cor-mat-pag-sel-offset size="lg"
                                  [storageKey]="offsetStorageKey()"
                                  (change$)="pagItemsPerPageAction$().next($event)" />
        </div>

        <div class="ms-3"
             *ngIf="!!setores()?.length">
          <cor-mat-ngx-pagination-nav pagId="setores"
                                      size="lg"
                                      [isMobile]="true"
                                      (pageChange$)="pagPageAction$().next($event)" />
        </div>
      </div>
    </div>

    <!-- <p>vm {{ vm | json }}</p> -->
    <!-- <p>taxaForm()?.value {{ taxaForm()?.value | json }}</p> -->
    <!-- <p>taxaForm()?.valid {{ taxaForm()?.valid }}</p> -->
    <!-- <p>taxaEntregaDescPercRef?.valid {{ taxaEntregaDescPercRef?.valid }}</p> -->

    <mat-card class="my-3">
      <form novalidate
            [formGroup]="taxaForm()"
            *ngIf="!!taxaForm()">

        <div class="d-flex flex-column"
             formGroupName="entrega">
          <div class="row"
               formGroupName="taxaGeral">
            <div class="col-4">
              <mat-slide-toggle class="mb-2"
                                formControlName="status"
                                labelPosition="before">
                <span> Taxa geral </span>
              </mat-slide-toggle>

              <mat-form-field class="w-100 mb-2"
                              appearance="outline">
                <mat-label>
                  Valor entrega <small class="o-50"> (R$) </small>
                </mat-label>
                <input matInput
                       type="number"
                       class="text-end"
                       formControlName="valor"
                       onReturn>
                <mat-hint *ngIf="fv()?.error(valorTaxaRef) || valorTaxaRef?.errors?.['valor']">
                  <span class="danger"> {{ fv()?.error(valorTaxaRef) || 'Inválido.' }} </span>
                </mat-hint>
              </mat-form-field>
            </div>

            <div class="col-8"
                 formGroupName="desc">
              <!-- <div mat-subheader
                   class="mb-2">
                Desconto
              </div> -->
              <mat-slide-toggle class="mb-2"
                                formControlName="status"
                                labelPosition="before">
                <span> Desconto </span>
              </mat-slide-toggle>

              <div class="d-flex">
                <mat-form-field class="w-100"
                                appearance="outline">
                  <mat-label>Valor mínimo carrinho</mat-label>
                  <input type="number"
                         class="text-end"
                         matInput
                         formControlName="minTotalCarrinho"
                         onReturn>
                  <!-- <mat-hint *ngIf="fv()?.error(taxaEntregaDescMinCarrinhoRef, 'descMinCarrinho')">
                  <span class="danger"> {{ fv()?.error(taxaEntregaDescMinCarrinhoRef, 'descMinCarrinho') }}
                  </span>
                </mat-hint> -->
                </mat-form-field>

                <mat-form-field class="ms-4 w-100"
                                appearance="outline">
                  <mat-label>Perc (%)</mat-label>
                  <input type="number"
                         class="text-end"
                         matInput
                         formControlName="perc"
                         onReturn>
                  <mat-hint *ngIf="fv()?.error(taxaEntregaDescPercRef) || taxaEntregaDescPercRef?.errors?.['percDesc']">
                    <span class="danger"> {{ fv()?.error(taxaEntregaDescPercRef) || 'Inválido.' }} </span>
                  </mat-hint>
                </mat-form-field>
              </div>
            </div>
          </div>

          <button mat-flat-button
                  color="primary"
                  [disabled]="!taxaForm()?.valid"
                  (click)="onSubmitTaxaGeralClick()">
            GRAVAR
          </button>
        </div>

        <!-- <div formGroupName="entrega">
          <div class="d-flex"
               formGroupName="taxaGeral">
            <mat-slide-toggle class="my-auto"
                              formControlName="status"
                              labelPosition="before">
              <span class="mr-4">
                Taxa geral
              </span>
            </mat-slide-toggle>

            <div class="my-auto mx-4 edit-taxa-geral">
              <mat-form-field class="w-100 mb-2"
                              appearance="outline">
                <mat-label>
                  Valor entrega <small class="o-50"> (R$) </small>
                </mat-label>
                <input matInput
                       type="number"
                       class="text-end"
                       formControlName="valor"
                       onReturn>
              </mat-form-field>
            </div>

            <mat-card formGroupName="taxaEntrega">
              <div mat-subheader
                   class="mb-2">Desconto</div>

              <div class="d-flex"
                   formGroupName="desc">
                <div>
                  <mat-form-field class="w-100"
                                  appearance="outline">
                    <mat-label>Valor mínimo carrinho</mat-label>
                    <input type="number"
                           class="text-end"
                           matInput
                           formControlName="minTotalCarrinho"
                           onReturn>
                    <mat-hint *ngIf="fv()?.error(taxaEntregaDescMinCarrinhoRef, 'descMinCarrinho')">
                        <span class="danger"> {{ fv()?.error(taxaEntregaDescMinCarrinhoRef, 'descMinCarrinho') }}
                        </span>
                      </mat-hint>
                  </mat-form-field>
                </div>

                <div>
                  <mat-form-field class="w-100"
                                  appearance="outline">
                    <mat-label>Perc (%)</mat-label>
                    <input type="number"
                           class="text-end"
                           matInput
                           formControlName="perc"
                           onReturn>
                    <mat-hint
                                *ngIf="fv()?.error(taxaEntregaDescPercRef) || taxaEntregaDescPercRef?.errors?.['percDesc']">
                        <span class="danger"> {{ fv()?.error(taxaEntregaDescPercRef) || 'Inválido.' }} </span>
                      </mat-hint>
                  </mat-form-field>
                </div>
              </div>
            </mat-card>

            <div class="my-auto">
              <button mat-flat-button
                      style="height: 62px"
                      color="primary"
                      (click)="onSubmitTaxaGeralClick()">
                GRAVAR
              </button>
            </div>
          </div>
        </div> -->
      </form>
    </mat-card>

    <mat-form-field appearance="outline"
                    class="w-100"
                    *ngFor="let s of setores() | paginate: { id: 'setores',itemsPerPage: vm?.pag?.itemsPerPage, currentPage: vm?.pag?.page, totalItems: setores()?.length }; trackBy:tid_set">
      <div class="alert alert-light text-center d-flex justify-content-between"
           role="alert">
        <div class="my-auto">
          <div class="badge rem-12 bg-light o-60">{{ s?.nome }}</div>
        </div>

        <div class="my-auto">
          <small class="o-50">Taxa entrega </small><strong>{{ s?.taxaEntrega?.val | currency: "BRL":"symbol":"1.2-2" }}
          </strong> <small *ngIf="!!s?.taxaEntrega?.desc?.status"> ( {{ s?.taxaEntrega?.desc?.perc }}% desconto acima de {{ s?.taxaEntrega?.desc?.minTotalCarrinho | currency: "BRL":"symbol":"1.2-2" }} )</small>
          <button mat-icon-button
                  color="dark"
                  [matMenuTriggerFor]="menuSetor"
                  (click)="setorMenu.set(s)">
            <mat-icon>more_vert</mat-icon>
          </button>
        </div>
      </div>

      <!-- <mat-label>{{ s?.nome }}</mat-label> -->
      <!-- <p>s {{ s | json }}</p> -->

      <!-- <div class="alert alert-light text-center"
           role="alert">
        <small class="o-50">Taxa entrega </small><strong>{{ s?.taxaEntrega?.val | currency: "BRL":"symbol":"1.2-2" }}
        </strong> <small *ngIf="!!s?.taxaEntrega?.desc?.__status"> ( {{ s?.taxaEntrega?.desc?.perc }}% desconto acima de
          {{ s?.taxaEntrega?.desc?.minTotalCarrinho | currency: "BRL":"symbol":"1.2-2" }} )</small>
      </div> -->

      <mat-chip-list>
        <mat-chip *ngFor="let b of s?.bairros">
          {{ b }}
          <button matChipRemove
                  (click)="onBairroDelClick(s, b)">
            <mat-icon>cancel</mat-icon>
          </button>
        </mat-chip>

        <!-- <div class="ms-auto">
          <button class="mat-mini-fab pt-2 me-2 bg-primary tint"
                  [matMenuTriggerFor]="menuSetor"
                  (click)="setorMenu.set(s)">
            <span class="icon-r-more-v rem-12"></span>
          </button>
        </div> -->
      </mat-chip-list>

      <div class="alert alert-light text-center"
           role="alert"
           *ngIf="!s?.bairros?.length">
        <small class="o-50">Nenhum bairro.</small>
      </div>
    </mat-form-field>

    <div class="alert alert-light text-center"
         role="alert"
         *ngIf="!setores()?.length">
      Nenhum setor.
    </div>
  </div>
</ng-container>

<ng-template #semEntrega>
  <div class="alert alert-light text-center"
       role="alert">
    Entregas desativadas.
  </div>
</ng-template>

<mat-menu #menuSetor="matMenu">
  <button mat-menu-item
          (click)="onBairroAddClick(setorMenu())">
    <mat-icon>add</mat-icon>Adicionar bairro
  </button>

  <button mat-menu-item
          (click)="onSetorEditClick(setorMenu())">
    <mat-icon>edit</mat-icon>Editar setor {{ setorMenu()?.nome }}
  </button>

  <button mat-menu-item
          (click)="onSetorDelClick(setorMenu())">
    <mat-icon>remove</mat-icon>Excluir setor {{ setorMenu()?.nome }}
  </button>
</mat-menu>