//#region ng
import {
  Component,
  inject,
  signal
} from '@angular/core';
//#endregion

//#region mat
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
//#endregion

//#region firebase
import {
  doc,
  Firestore,
  onSnapshot,
  Unsubscribe as FbUnsubscribe,
} from '@angular/fire/firestore';

//#region 3rd
import {
  filter,
  finalize,
  first,
  switchMap,
  takeUntil,
} from 'rxjs/operators';
import {
  Subject,
  Subscription
} from 'rxjs';
//#endregion

//#region models
import {
  ILoja,
  IPTag,
} from '../../../_shared/_mercadeiro/_misc/_models/_interfaces/_cols';
import { ODOCS_TAG } from '../../../_shared/_core/_misc/_models/consts';
//#endregion

//#region libs
import { onDestroy } from '../../../_shared/_core/_ng/_libs';
//#endregion

//#region stores
import { LojasStore } from '../../../_shared/_mercadeiro/_ng/_stores';
//#endregion

//#region services
import { PTagsService } from '../../../_shared/_mercadeiro/_ng/_services';
import { CorLoaderService } from '../../../_shared/_core/_ng/_services';
//#endregion

//#region components
import { CorMatConfirmDialog } from '../../../_shared/_core/_ng-ui/_mat';
import { PTagFormModal } from '../../../modals';
import { TNullable } from 'src/app/_shared/_core/_misc/_models/_types';
//#endregion

@Component({
  selector: 'app-produtos-p-tags',
  templateUrl: './p-tags.component.html',
  styleUrls: ['./p-tags.component.scss']
})
export class ProdutosPTagsComponent {
  //#region actions
  #destroyAction$: Subject<void> = onDestroy();
  //#endregion

  //#region publics
  ptags = signal<IPTag[]>(null);
  //#endregion

  //#region privates
  #deptosUnsub: FbUnsubscribe;
  #lojaPath: string;
  //#endregion

  //#region injects
  #db = inject(Firestore);
  #lojasStore = inject(LojasStore);
  #ptagsServ = inject(PTagsService);
  #modal = inject(MatDialog);
  #loaderServ = inject(CorLoaderService);
  #snackBar = inject(MatSnackBar);
  //#endregion

  //#region lifecycles
  ngOnInit() {
    this.#lojasStore.lojaStateChanged$
      .pipe(
        filter((loja: ILoja) => !!loja),
        takeUntil(this.#destroyAction$),
      )
      .subscribe(
        (loja: ILoja) => {
          this.#lojaPath = loja?.__idInfo?.lojaPath || '';
          if (!!this.#lojaPath) {
            const PATH: string = `${this.#lojaPath}/p-tags/${ODOCS_TAG}`;
            // console.log(PATH);
            !!this.#deptosUnsub && this.#deptosUnsub();
            this.#deptosUnsub = onSnapshot(
              doc(this.#db, PATH),
              snap => {
                const PTAGS: IPTag[] = ((Object.values(snap.get('_odocs') || {}) || []) as IPTag[]);
                // console.log(PTAGS);
                this.ptags.set(this.#ptagsServ.fixes(PTAGS));
              }
            );
          } // if
        });
  }

  ngOnDestroy(): void {
    !!this.#deptosUnsub && this.#deptosUnsub();
  }
  //#endregion

  //#region functions
  tid_ptags(index: any, item: IPTag): string { return item?.id || ''; }
  //#endregion

  //#region methods
  onDelTagClick(t: IPTag) {
    // console.log(t);
    if (!!t && !t?._isSistema) {
      const DIALOG_EXISTS = this.#modal.getDialogById('confirm-del');
      if (!DIALOG_EXISTS) {
        const DIALOG_REF: any = this.#modal.open(
          CorMatConfirmDialog,
          {
            id: 'confirm-del',
            panelClass: 'no-border-dialog-container',
            data: {
              title: t?.caption,
              cancelCaption: 'Não',
              confirmCaption: 'Sim',
              msg: `Deseja remover essa tag de produto?`,
            }
          }
        );

        const SUB: Subscription = DIALOG_REF
          .afterClosed()
          .pipe(first(), finalize(() => SUB?.unsubscribe()))
          .pipe(filter((val: any) => !!val))
          .subscribe(async (resp: boolean) => {
            // console.log({ resp });
            // console.log(this.conta?.id, t?.id);
            if (
              !!this.#lojaPath
              && !!t?.id
            ) {
              const SUB: Subscription = this.#loaderServ.showUntilCompleted(
                this.#ptagsServ.delete(this.#lojaPath, t?.id)
                  .pipe(first(), finalize(() => SUB?.unsubscribe()))
              )
                .subscribe(() => {
                  this.#snackBar.open('Tag de produto removida.');
                });
            } // if
          });
      } // if
    } // if
  }

  onTagEditClick(t: TNullable<Partial<IPTag>> = null) {
    // console.log(t);    
    this.#modal.open(
      PTagFormModal,
      {
        panelClass: 'no-border-dialog-container',
        data: {
          // lojaPath: this.#lojaPath,
          pTag: t,
          // rede
        }
      }
    );
  }
  //#endregion
}
