//#region ng
import { NgModule } from '@angular/core';
import {
  CommonModule,
  NgOptimizedImage
} from '@angular/common';
import {
  FormsModule,
  ReactiveFormsModule
} from '@angular/forms';
//#endregion

//#region mat
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatChipsModule } from '@angular/material/chips';
// import { MatDialogModule } from '@angular/material/dialog';
// import { MatExpansionModule } from '@angular/material/expansion';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
// import { MatListModule } from '@angular/material/list';
import { MatMenuModule } from '@angular/material/menu';
import { MatRadioModule } from '@angular/material/radio';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
// import { MatSliderModule } from '@angular/material/slider';
// import { MatStepperModule } from '@angular/material/stepper';
// import { MatTabsModule } from '@angular/material/tabs';
// import { MatToolbarModule } from '@angular/material/toolbar';
// import { MatTooltipModule } from '@angular/material/tooltip';
// import { MatTreeModule } from '@angular/material/tree';
//#endregion

//#region 3rd
import { NgxPaginationModule } from 'ngx-pagination';
//#endregion

//#region modules
import { CorBsComponentsModule } from '../_shared/_core/_ng-ui/_bs/cor-bs-components.module';
import { CorPipesModule } from '../_shared/_core/_ng/_pipes/cor-pipes.module';
import { MeMatComponentsModule } from '../_shared/_mercadeiro/_ng-ui/_mat/me-mat-components.module';
import { CorMatComponentsModule } from '../_shared/_core/_ng-ui/_mat/cor-mat-components.module';
import { CorDirectivesModule } from '../_shared/_core/_ng/_directives/cor-directives.module';
import { ProPipesModule } from '../_shared/_produtos/_ng/_pipes/pro-pipes.module';
import { ProHtmlComponentsModule } from '../_shared/_produtos/_ng-ui/_html/pro-html-components.module';
//#endregion

//#region components
import {
  CredenciadoFormasPgtoFormComponent,
  GaleriaGetComponent,
  Horario2strComponent,
  OperadorTipoFormComponent,
  SetoresEntregaComponent
} from '.';
//#endregion

@NgModule({
  imports: [
    CorBsComponentsModule,
    CorDirectivesModule,
    CorMatComponentsModule,
    CorPipesModule,
    ProHtmlComponentsModule,
    ProPipesModule,
    CommonModule,
    FormsModule,
    MatButtonModule,
    MatCardModule,
    MatChipsModule,
    MatFormFieldModule,
    MatIconModule,
    MatInputModule,
    MatMenuModule,
    MatRadioModule,
    MatSlideToggleModule,
    MeMatComponentsModule,
    NgOptimizedImage,
    NgxPaginationModule,
    ReactiveFormsModule
  ],
  declarations: [
    CredenciadoFormasPgtoFormComponent,
    GaleriaGetComponent,
    Horario2strComponent,
    OperadorTipoFormComponent,
    SetoresEntregaComponent,
  ],
  exports: [
    CredenciadoFormasPgtoFormComponent,
    GaleriaGetComponent,
    Horario2strComponent,
    OperadorTipoFormComponent,
    SetoresEntregaComponent,
  ],
})
export class ComponentsModule { }
