<div class="modal-body">
  <mat-toolbar class="header"
               color="primary">
    <mat-toolbar-row class="d-flex justify-content-between">
      <div class="my-auto">
        <h3> {{ caption() }} </h3>
      </div>

      <div class="my-auto">
        <button mat-icon-button
                (click)="onModalCloseClick()">
          <mat-icon>close</mat-icon>
        </button>
      </div>
    </mat-toolbar-row>
  </mat-toolbar>

  <!-- <p>setorForm().value {{ setorForm().value | json }}</p> -->
  <!-- <p>novo {{ novo }}</p> -->
  <!-- <p>idLoja {{ idLoja }}</p> -->

  <div class="p-3">
    <form novalidate
          [formGroup]="setorForm()"
          (ngSubmit)="onSubmitClick()"
          *ngIf="!!setorForm()">
      <div class="row">
        <div class="col"
             formGroupName="ativo">
          <mat-slide-toggle formControlName="status"
                            labelPosition="before">
            <span class="me-4">
              Ativo
            </span>
          </mat-slide-toggle>
        </div>
      </div>

      <div class="row my-3">
        <div class="col">
          <mat-form-field class="w-100"
                          appearance="outline">
            <mat-label>Nome</mat-label>
            <input type="text"
                   matInput
                   formControlName="nome"
                   [focus]="nomeFocusEvent$()"
                   onReturn>
            <mat-hint *ngIf="fv()?.error(nomeRef, 'nome')">
              <span class="danger"> {{ fv()?.error(nomeRef, 'nome') }} </span>
            </mat-hint>
          </mat-form-field>
        </div>

        <div class="col"
             formGroupName="taxaEntrega">
          <mat-form-field class="w-100"
                          appearance="outline">
            <mat-label>Valor entrega</mat-label>
            <input type="number"
                   class="text-end"
                   matInput
                   formControlName="val"
                   onReturn>
            <mat-hint *ngIf="fv()?.error(taxaEntregaRef, 'taxaEntrega')">
              <span class="danger"> {{ fv()?.error(taxaEntregaRef, 'taxaEntrega') }} </span>
            </mat-hint>
          </mat-form-field>
        </div>
      </div>

      <div class="row"
           formGroupName="taxaEntrega">
        <div class="col"
             formGroupName="desc">
          <mat-card>
            <!-- <div mat-subheader
                 class="mb-2">Desconto</div> -->
            <div class="row">
              <div class="col mb-3">
                <mat-slide-toggle formControlName="status"
                                  labelPosition="before">
                  <span class="me-4">
                    Desconto
                  </span>
                </mat-slide-toggle>
              </div>
            </div>

            <div class="row">
              <div class="col-6">
                <mat-form-field class="w-100"
                                appearance="outline">
                  <mat-label>Valor mínimo carrinho</mat-label>
                  <input type="number"
                         class="text-end"
                         matInput
                         formControlName="minTotalCarrinho"
                         onReturn>
                  <mat-hint *ngIf="fv()?.error(taxaEntregaDescMinCarrinhoRef, 'descMinCarrinho')">
                    <span class="danger"> {{ fv()?.error(taxaEntregaDescMinCarrinhoRef, 'descMinCarrinho') }} </span>
                  </mat-hint>
                </mat-form-field>
              </div>

              <div class="col-6">
                <mat-form-field class="w-100"
                                appearance="outline">
                  <mat-label>Perc (%)</mat-label>
                  <input type="number"
                         class="text-end"
                         matInput
                         formControlName="perc"
                         onReturn>
                  <mat-hint *ngIf="fv()?.error(taxaEntregaDescPercRef) || taxaEntregaDescPercRef?.errors?.['percDesc']">
                    <span class="danger"> {{ fv()?.error(taxaEntregaDescPercRef) || 'Inválido.' }} </span>
                  </mat-hint>
                </mat-form-field>
              </div>
            </div>
          </mat-card>
        </div>
      </div>

      <div class="row mt-3">
        <div class="col d-flex">
          <button type="submit"
                  class="flex-1 pointer"
                  mat-raised-button
                  color="primary"
                  [disabled]="!setorForm()?.valid">
            CONFIRMAR
          </button>
        </div>
      </div>
    </form>
  </div>
</div>