<div class="box d-flex flex-column">
  <nav aria-label="breadcrumb">
    <ol class="breadcrumb">
      <li class="breadcrumb-item">
        <span class="icon-o-home primary tint o-50 pointer"
              routerLink="/dashboard"></span>
      </li>

      <li class="breadcrumb-item active">
        Produtos
      </li>

      <li class="ms-auto">
        <div class="segment mb-4 flex-1 d-flex justify-content-center">
          <ul class="nav justify-content-center nav-pills">
            <li class="nav-item"
                (click)="tab.set('produtos')">
              <a class="nav-link"
                 [class.active]="tab() === 'produtos'">
                Produtos
              </a>
            </li>

            <li class="nav-item"
                (click)="tab.set('p-tags')">
              <a class="nav-link"
                 [class.active]="tab() === 'p-tags'">
                Tags
              </a>
            </li>
          </ul>
        </div>
      </li>
    </ol>
  </nav>

  <div [ngSwitch]="tab()">
    <div *ngSwitchCase="'p-tags'">
      <app-produtos-p-tags />
    </div>

    <div *ngSwitchDefault>
      <app-produtos-produtos />
    </div>
  </div>
</div>
