//#region misc
export const HOME_ROUTE: string = '/dashboard';
export const ID_MODULO: string = 'mer_lojistas_www';
export const THEME: string = 'medium';
export const PEDIDOS_POR_PAG: string = '9';
//#endregion

//#region storage
export const KEY_LOJA_ID: string = `LOJA_ID_${ID_MODULO}`.toLowerCase();
// export const KEY_LOJA: string = `LOJA_${ID_MODULO}`.toLowerCase();
export const KEY_OFFSET_LOJAS: string = `OFFSET_LOJAS_${ID_MODULO}`.toLowerCase();
export const KEY_OFFSET_NOTIFICACOES: string = `OFFSET_NOTIFICACOES_${ID_MODULO}`.toLowerCase();
export const KEY_OFFSET_OPERADORES: string = `OFFSET_OPERADORES_${ID_MODULO}`.toLowerCase();
export const KEY_OFFSET_PEDIDOS: string = `OFFSET_PEDIDOS_${ID_MODULO}`.toLowerCase();
export const KEY_OFFSET_PRODUTOS: string = `OFFSET_PRODUTOS_${ID_MODULO}`.toLowerCase();
export const KEY_OFFSET_SETORES_ENTREGA: string = `OFFSET_SETORES_ENTREGA_${ID_MODULO}`.toLowerCase();
export const KEY_VIEWSTYLE_LOJAS: string = `VIEWSTYLE_LOJAS_${ID_MODULO}`.toLowerCase();
export const KEY_VIEWSTYLE_PRODUTOS: string = `VIEWSTYLE_PRODUTOS_${ID_MODULO}`.toLowerCase();
//#endregion
