//#region ng
import {
  Component,
  Inject,
  Input,
  inject,
} from '@angular/core';
//#endregion

//#region mat
import {
  MatDialogRef,
  MAT_DIALOG_DATA
} from '@angular/material/dialog';
//#endregion

//#region models
import {
  IConta,
  ILoja,
} from '../../_shared/_mercadeiro/_misc/_models/_interfaces/_cols';
import { ICorLojaInfoParams } from '../../_shared/_mercadeiro/_misc/_models/_interfaces/_params';
//#endregion

//#region services
import {
  ContasService,
  LojasService
} from '../../_shared/_mercadeiro/_ng/_services';
//#endregion

@Component({
  selector: 'app-loja-info-modal',
  templateUrl: './loja-info.modal.html',
  styleUrls: ['./loja-info.modal.scss']
})
export class LojaInfoModal {
  //#region publics
  conta: IConta;
  loja: ILoja;
  //#endregion

  //#region injects
  #contasServ = inject(ContasService);
  #dialogRef = inject(MatDialogRef<LojaInfoModal>);
  #lojasServ = inject(LojasService);
  //#endregion

  //#region constructor
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: ICorLojaInfoParams,
  ) { }
  //#endregion

  //#region lifecycles
  ngOnInit() {
    this.conta = this.data?.conta ? this.#contasServ.fix(this.data?.conta) : null;
    this.loja = this.data?.loja ? this.#lojasServ.fix(this.data?.loja) : null;
  }
  //#endregion

  //#region methods
  onModalCloseClick() {
    this.#dialogRef.close();
  }
  //#endregion
}
