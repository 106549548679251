//#region ng
import {
  Component,
  Input,
  inject,
  signal,
} from '@angular/core';
//#endregion

//#region mat
import { MatSnackBar } from '@angular/material/snack-bar';
//#endregion

//#region firebase
import {
  doc,
  Firestore,
  onSnapshot,
  Unsubscribe as FbUnsubscribe,
} from '@angular/fire/firestore';
//#endregion

//#region 3rd
import { Subscription } from 'rxjs';
import {
  finalize,
  first
} from 'rxjs/operators';
//#endregion

//#region models
import { ILoja } from '../../../_shared/_mercadeiro/_misc/_models/_interfaces/_cols';
import { ICorViacepApi } from '../../../_shared/_core/_misc/_models/_interfaces/_apis';
import { ILocalidadeRotaMap } from '../../../_shared/_mercadeiro/_misc/_models/_interfaces/_maps';
//#endregion

//#region libs
import { splitLocalidadeId } from '../../../_shared/_mercadeiro/_misc/_libs';
//#endregion

//#region services
import { LojasService } from '../../../_shared/_mercadeiro/_ng/_services';
import {
  CorCepService,
  CorLoaderService,
  CorMessagesService
} from '../../../_shared/_core/_ng/_services';
//#endregion

@Component({
  selector: 'app-distribuidora-rotas-localidade',
  templateUrl: './distribuidora-rotas-localidade.component.html',
  styleUrls: ['./distribuidora-rotas-localidade.component.scss']
})
export class DistribuidoraRotasLocalidadeComponent {
  //#region inputs
  #loja: ILoja;
  get loja(): ILoja { return this.#loja; }
  @Input({ required: true }) set loja(val: ILoja) {
    this.#loja = !!val ? this.#lojasServ.fix(val) : null;
    const LOJA_PATH: string = this.loja?.__idInfo?.lojaPath || '';
    // console.log(LOJA_PATH);
    if (!!LOJA_PATH) {
      // this.#loaderServ.lstart();
      const PATH: string = LOJA_PATH;
      // console.log(PATH);
      this.#lojaUnsub && this.#lojaUnsub();
      this.#lojaUnsub = onSnapshot(
        doc(this.#db, PATH),
        snap => {
          // console.log(snap?.data());
          this.localidades.set((snap?.data() as ILoja)?.distribuidora?.localidades || []);
          // this.setores = this._setoresServ.fixes(get(snap.data(), '_docs'));
          // this._montaEnderecos();
          // this.#loaderServ.lstop();
        }
      );
    } // if
  }
  //#endregion

  //#region publics
  cep = signal<string>('');
  localidades = signal<ILocalidadeRotaMap[]>(null);
  //#endregion

  //#region privates
  #lojaUnsub: FbUnsubscribe;
  //#endregion

  //#region injects
  #cepServ = inject(CorCepService);
  #db = inject(Firestore);
  #loaderServ = inject(CorLoaderService);
  #lojasServ = inject(LojasService);
  #msgServ = inject(CorMessagesService);
  #snackBar = inject(MatSnackBar);
  //#endregion

  //#region lifecycles
  ngOnDestroy(): void {
    !!this.#lojaUnsub && this.#lojaUnsub();
  }
  //#endregion

  //#region functions
  tid_loc(index: any, item: ILocalidadeRotaMap): string { return item?.id || ''; }

  private _updateLoja(
    changes: Partial<ILoja>,
    deleting: boolean
  ) {
    const LOJA_ID: string = this.loja?.id || '';
    // console.log(LOJA_ID);
    if (!!LOJA_ID) {
      const SUB: Subscription = this.#loaderServ.showUntilCompleted(
        this.#lojasServ.update(LOJA_ID, { ...changes })
          .pipe(first(), finalize(() => SUB?.unsubscribe()))
      )
        .subscribe(() => this.#snackBar.open(`Localidade ${deleting ? 'removida' : 'adicionada'}.`));
    } // if
  }
  //#endregion

  //#region methods
  onLocalidadeAddClick() {
    // const CEP = this.cepRef?.value || '';
    // console.log(CEP);
    const SUB: Subscription = this.#loaderServ.showUntilCompleted(
      this.#cepServ.cep(this.cep())
        .pipe(first(), finalize(() => SUB?.unsubscribe()))
    )
      .subscribe(
        (viacep: ICorViacepApi) => {
          // console.log(viacep);
          const ERRO: boolean = !!viacep?.erro;
          if (!!viacep) {
            this.cep.set('');
            // this.cepForm.reset();
            if (!!ERRO) {
              this.#msgServ.send('CEP inválido ou não encontrado!', 'warning');
            } else {
              // console.log(viacep);
              viacep.complemento = '';
              const LOCALIDADE_ID: string =
                splitLocalidadeId(
                  viacep?.uf,
                  viacep?.localidade
                )?.[2] || '';
              // console.log(LOCALIDADE_ID);

              if (
                (this.localidades() || [])
                  .some((l: ILocalidadeRotaMap) => LOCALIDADE_ID === l?.id)
              ) {
                this.#snackBar.open(
                  `Localidade ${viacep?.localidade} já cadastrada.`,
                  ''
                );
              } else {
                this.localidades()?.push(
                  {
                    id: LOCALIDADE_ID,
                    nome: viacep?.localidade
                  }
                );
                const CHANGES: Partial<ILoja> = {
                  distribuidora: {
                    localidades: [...this.localidades()]
                  }
                };
                // console.log(CHANGES);
                this._updateLoja(CHANGES, false);
              } // else
            } // else
          } else {
            this.#msgServ.send('CEP inválido ou não encontrado!', 'warning');
          } // else
        }
      );
  }

  onLocalidadeDelClick(localidade: ILocalidadeRotaMap) {
    // console.log(localidade);
    const LOCALIDADES: ILocalidadeRotaMap[] = (this.localidades() || [])
      .filter((l: ILocalidadeRotaMap) => localidade?.id !== l?.id)
    // console.log(LOCALIDADES);
    const CHANGES: Partial<ILoja> = {
      distribuidora: {
        localidades: [...LOCALIDADES]
      }
    };
    // console.log(CHANGES);
    this._updateLoja(CHANGES, true);
  }
  //#endregion

}
