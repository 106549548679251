//#region ng
import {
  Component,
  inject,
  signal,
} from '@angular/core';
import { Router } from '@angular/router';
//#endregion

//#region mat
import { MatDialog } from '@angular/material/dialog';
//#endregion

//#region 3rd
import {
  combineLatest,
  Observable,
} from 'rxjs';
import { map } from 'rxjs/operators';
//#endregion

//#region models
interface IVm {
  // conta: IConta | null;
  lojas: ILoja[] | null;
  loja: ILoja | null;
}
import { ILoja } from '../../_shared/_mercadeiro/_misc/_models/_interfaces/_cols';
import {
  HOME_ROUTE,
  KEY_OFFSET_LOJAS,
  KEY_VIEWSTYLE_LOJAS,
  THEME
} from '../../models/consts';
//#endregion

//#region stores
import { LojasStore } from '../../_shared/_mercadeiro/_ng/_stores';
import { AppLojasStore } from 'src/app/stores';
//#endregion

//#region components
import { LojaInfoModal } from '../../modals';
//#endregion

@Component({
  selector: 'lojas-page',
  templateUrl: './lojas.page.html',
  styleUrls: ['./lojas.page.scss']
})
export class LojasPage {
  //#region publics
  keyOffsetLojas = signal<string>(KEY_OFFSET_LOJAS).asReadonly();
  keyViewStyleLojas = signal<string>(KEY_VIEWSTYLE_LOJAS).asReadonly();
  vm$ = signal<Observable<IVm>>(null);
  //#endregion

  //#region injects
  #appLojasStore = inject(AppLojasStore);
  #lojasStore = inject(LojasStore);
  #modal = inject(MatDialog);
  #router = inject(Router);
  //#endregion

  //#region lifecycles
  ngOnInit() {
    this.vm$.set(
      combineLatest([
        this.#appLojasStore.lojasContaStateChanged$,
        this.#lojasStore.lojaStateChanged$,
      ])
        .pipe(
          map(
            ([lojas, loja]) => {
              const VM: IVm = {
                lojas,
                loja
              };
              // console.log(VM);
              return VM;
            }
          )
        )
    );
  }
  //#endregion

  //#region methods
  onSearchClick(loja: ILoja) {
    // console.log(loja);
    const DIALOG_EXISTS = this.#modal.getDialogById('loja-info');
    if (!DIALOG_EXISTS && !!loja) {
      this.#modal.open(
        LojaInfoModal,
        {
          id: 'loja-info',
          panelClass: 'no-border-dialog-container',
          data: {
            loja
          }
        }
      );
    } // if
  }

  onCheckClick(loja: ILoja) {
    // console.log(loja);
    if (!!loja) {
      this.#router.navigateByUrl(HOME_ROUTE);
      this.#appLojasStore.setState(loja, THEME);
    } // if
  }
  //#endregion
}
