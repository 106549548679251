//#region ng
import {
  Component,
  inject,
  signal,
} from '@angular/core';
import { Router } from '@angular/router';
//#endregion

//#region 3rd
import {
  Observable,
  Subject,
  combineLatest
} from 'rxjs';
import {
  map,
  takeUntil
} from 'rxjs/operators';
//#endregion

//#region models
interface IVm {
  loja: ILoja;
};
import {
  IConta,
  ILoja
} from '../../_shared/_mercadeiro/_misc/_models/_interfaces/_cols';
import { HOME_ROUTE } from '../../models/consts';
//#endregion

//#region libs
import { onDestroy } from '../../_shared/_core/_ng/_libs';
//#endregion

//#region services
import { ROTAS } from '../../services';
//#endregion

//#region stores
import {
  ContasStore,
  LojasStore
} from '../../_shared/_mercadeiro/_ng/_stores';
//#endregion

@Component({
  selector: 'setores-entrega-page',
  templateUrl: './setores-entrega.page.html',
  styleUrls: ['./setores-entrega.page.scss']
})
export class SetoresEntregaPage {
  //#region actions
  #destroyAction$: Subject<void> = onDestroy();
  //#endregion

  //#region publics
  vm$ = signal<Observable<IVm>>(null);
  //#endregion

  //#region injects
  #contasStore = inject(ContasStore);
  #lojasStore = inject(LojasStore);
  #router = inject(Router);
  //#endregion

  //#region lifecycles
  ngOnInit() {
    this.#contasStore.contaStateChanged$
      .pipe(takeUntil(this.#destroyAction$))
      .subscribe(
        (conta: IConta) => !!conta
          && !ROTAS?.setoresEntrega?.__valid?.status
          && this.#router.navigateByUrl(HOME_ROUTE)
      );

    this.vm$.set(
      combineLatest([
        this.#lojasStore.lojaStateChanged$
      ])
        .pipe(
          map(([loja]) => {
            const VM: IVm = {
              loja
            };
            // console.log(VM);
            return VM;
          })
        )
    )
  }
  //#endregion
}
