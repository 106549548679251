<ng-container *ngIf="vm$() | async as vm">
  <div class="d-flex">
    <div class="col search">
      <mat-form-field appearance="outline"
                      class="w-100">
        <mat-label>Nome</mat-label>
        <input matInput
               type="text"
               [focus]="searchFocusEvent$()"
               [ngModel]="searchTermAction$() | async"
               (ngModelChange)="searchTermAction$()?.next($event)">
        <button mat-button
                *ngIf="!!vm?.searchTerm"
                matSuffix
                mat-icon-button
                (click)="searchTermAction$()?.next('')">
          <mat-icon class="o-50">close</mat-icon>
        </button>
      </mat-form-field>
    </div>

    <div class="ms-3 mt-1"
         *ngIf="!!produtos()?.length">
      <cor-mat-pag-sel-offset size="lg"
                              [storageKey]="offsetStorageKey()"
                              (change$)="pagItemsPerPage.set($event)" />
    </div>

    <div class="ms-3 mt-1"
         *ngIf="!!produtos()?.length">
      <cor-mat-ngx-pagination-nav pagId="produtos"
                                  size="lg"
                                  [isMobile]="true"
                                  (pageChange$)="pagPage.set($event)" />
    </div>

    <div class="ms-3 mt-1"
         *ngIf="!!produtos()?.length">
      <cor-mat-viewstyle-sel size="lg"
                             [storageKey]="viewStyleStorageKey()"
                             (change$)="viewStyle.set($event)" />
    </div>
  </div>

  <mat-form-field appearance="outline"
                  class="w-100">
    <mat-label>Filtros</mat-label>

    <mat-chip-list #chipList>
      <mat-chip *ngFor="let f of pagFilters(); trackBy:tid_pag">
        <span class="o-30 me-1"
              [class]="f?.icon">
        </span>
        {{ f?.extra1 }} {{ f?.extra2 }} {{ f?.tristate === 'checked' ? 'Sim' : 'Não' }}
        <button matChipRemove
                (click)="onDelFiltroClick(f)">
          <mat-icon>cancel</mat-icon>
        </button>
      </mat-chip>

      <button class="mat-mini-fab pt-1 rem-13 ms-auto me-2 bg-primary tint"
              matTooltip="Modificar filtros"
              (click)="onAddFiltroClick()">
        <div class="icon-o-filter"></div>
      </button>
    </mat-chip-list>
  </mat-form-field>

  <div class="w-cards produtos"
       *ngIf="viewStyle() === 'cards'; else viewTable">
    <div class="w-card produto"
         [class.off]="p?.estoqueCritico || !p?.ativo?._status || !p?.ativo?.online"
         *ngFor="let p of (produtos() | paginate: { id: 'produtos', itemsPerPage: +pagItemsPerPage(), currentPage: pagPage(), totalItems: produtos()?.length } | produtosLfixes) | async; trackBy:tid_pro">
      <div class="w-card--thumb p-relative">
        <app-produtos-produto-icons [produto]="p"
                                    (imgClick$)="onAddImgClick(p)" />

        <div class="m-auto p-relative">
          <img [src]="p?.__img?.thumb"
               class="my-2 img-fluid"
               *ngIf="!!p?.__img?.thumb">

          <span class="volume"
                *ngIf="p?._base?._vol?.val">
            <small>
              {{ p?._base?._vol?.val }}
            </small>
          </span>

          <span class="imgs-tag"
                *ngIf="p?.imgs?.length > (!!p?._industrializado ? 0 : 1)">
            <span class="icon-b-img o-50"></span>&nbsp;{{ p?.imgs?.length - (!!p?._industrializado ? 0 : 1)
            }}<span class="o-50 rem-12">+</span>
          </span>
        </div>

        <span class="p-tags d-flex flex-column">
          <small class="badge bg-{{ t?.theme }}"
                 *ngFor="let t of p?.tags">
            {{ t?.caption }}
          </small>
        </span>
      </div>

      <div class="w-card--content">
        <div class="flex-1">
          <small>{{ p?.nome }}</small>
          <span *ngIf="p?._base?.embalagem || p?._base?.dimensoes">
            <br><small class="off">
              {{ p?._base?.embalagem }} {{ p?._base?.embalagem && p?._base?.dimensoes ? '-' : '' }} {{
              p?._base?.dimensoes }}
            </small>
          </span>
          <p>
            <span>
              <small class="o-50"> Cód.</small>
              <strong>
                {{ p?.id }}
              </strong>
            </span>
            <br>
            <span *ngIf="p?.barcode">
              <small class="o-50">GTIN</small>
              <strong>
                {{ p?.barcode }}
              </strong>
            </span>
            <ng-container *ngIf="!!p?._base?.marca?.nome">
              <br>
              <span class="badge text-bg-light">
                {{ p?._base?.marca?.nome }}
                <small *ngIf="p?._base?.marca?.linha?.nome"
                       class="o-50">
                  / {{ p?._base?.marca?.linha?.nome }}
                </small>
              </span>
            </ng-container>
            <br>
            <small>
              {{ p?.departamentos?.d1?.nome }}
              <small *ngIf="!!p?.departamentos?.d2?.nome"
                     class="o-50">
                / {{ p?.departamentos?.d2?.nome }}
              </small>
              <small *ngIf="!!p?.departamentos?.d3?.nome"
                     class="o-50">
                / {{ p?.departamentos?.d3?.nome }}
              </small>
            </small>
          </p>
        </div>
      </div>

      <div class="w-card--footer">
        <div class="d-flex">
          <button mat-icon-button
                  matTooltip="Modifica produto"
                  (click)="onProdutoEditClick(p)"
                  class="me-auto">
            <mat-icon>edit</mat-icon>
          </button>

          <div class="m-auto flex-1">
            {{ p?.preco?._liquido | currency: "BRL":"symbol":"1.2-2" }}
          </div>

          <button mat-icon-button
                  matTooltip="Consulta produto"
                  (click)="onProdutoConsultaClick(p)"
                  class="me-auto">
            <mat-icon>search</mat-icon>
          </button>
        </div>
      </div>
    </div>
  </div>
  <ng-template #viewTable>
    <p>tabela</p>
    <table class="table table-striped">
      <thead>
        <tr>
          <th scope="col"></th>

          <th scope="col"
              class="text-center">
            Ativo
          </th>

          <th scope="col">Status / nome / embalagem</th>

          <th scope="col"
              class="text-center">
            Cód. / barcode
          </th>

          <th scope="col"
              class="text-center">
            Marca / modelo
          </th>
        </tr>
      </thead>

      <tbody>
        <tr *ngFor="let p of (produtos() | paginate: { id: 'produtos', itemsPerPage: +pagItemsPerPage(), currentPage: pagPage(), totalItems: produtos()?.length } | produtosLfixes) | async; trackBy:tid_pro"
            [class.off]="p?.estoqueCritico || !p?.ativo?._status || !p?.ativo?.online">
          <td>
            <img [src]="p?.__img?.thumb"
                 class="my-2 img-fluid"
                 *ngIf="!!p?.__img?.thumb">
          </td>

          <td class="text-center">
            <cor-html-status-checkbox [status]="p?.ativo?._status" />
          </td>

          <td>
            <app-produtos-produto-icons [produto]="p"
                                        (imgClick$)="onAddImgClick(p)" />
            <small>{{ p?.nome }}</small>
            <span *ngIf="p?._base?.embalagem || p?._base?.dimensoes">
              <br><small class="off">
                {{ p?._base?.embalagem }} {{ p?._base?.embalagem && p?._base?.dimensoes ? '-' : '' }} {{
                p?._base?.dimensoes }}
              </small>
            </span>
          </td>

          <td class="text-center">
            <span>
              <small class="o-50"> Cód.</small>
              <strong>
                {{ p?.id }}
              </strong>
            </span>
            <br>
            <span *ngIf="p?.barcode">
              <small class="o-50"> GTIN</small>
              <strong>
                {{ p?.barcode }}
              </strong>
            </span>
          </td>

          <td class="text-center">
            <small>
              {{ p?._base?.marca?.nome }}
              <small *ngIf="p?._base?.marca?.linha?.nome"
                     class="o-50">
                / {{ p?._base?.marca?.linha?.nome }}
              </small>
            </small>
          </td>

          <td>
            <div class="d-flex justify-content-around">
              <button mat-icon-button
                      matTooltip="Consulta produto"
                      (click)="onProdutoConsultaClick(p)">
                <mat-icon>search</mat-icon>
              </button>

              <button mat-icon-button
                      matTooltip="Edita produto"
                      (click)="onProdutoEditClick(p)">
                <mat-icon>edit</mat-icon>
              </button>
            </div>
          </td>
        </tr>
      </tbody>
    </table>
  </ng-template>

  <div class="alert alert-light text-center"
       role="alert"
       *ngIf="!produtos()?.length">
    Nenhum produto.
  </div>
</ng-container>