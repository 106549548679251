<!-- <p>operadorTipoForm()?.value {{ operadorTipoForm()?.value | json}}</p> -->

<form [formGroup]="operadorTipoForm()"
      novalidate
      *ngIf="!!operadorTipoForm()">
  <div class="row">
    <div class="col"
         formGroupName="operador">
      <mat-radio-group class="d-flex flex-column"
                       formControlName="role">
        <mat-radio-button value="gerente"
                          class="mb-3">
          <div class="ms-3">
            <strong>Gerente</strong><br />
            Módulo lojistas:<br />
            <small class="o-70">
              &bullet; Nenhum acesso à área de movimento financeiro. <br />
              &bullet; Acesso parcial à área de operadores. <br />
              &bullet; Acesso parcial à área de configurações.<br />
            </small>
            Módulo monitor de pedidos: <br />
            <small class="o-70">
              &bullet; Acesso pleno.
            </small>
          </div>
        </mat-radio-button>

        <mat-radio-button value="marketing"
                          class="mb-3">
          <div class="ms-3">
            <strong>Marketing</strong><br />
            Módulo lojistas: <br />
            <small class="o-70">
              &bullet; Acesso pleno à área de mídia. <br />
              &bullet; Acesso parcial à área de produtos. <br />
              &bullet; Nenhum acesso às demais áreas. <br />
            </small>
            Módulo monitor de pedidos: <br />
            <small class="o-70">
              &bullet; Nenhum acesso.
            </small>
          </div>
        </mat-radio-button>

        <mat-radio-button value="monitor"
                          class="mb-3">
          <div class="ms-3">
            <strong> Monitor</strong><br />
            Módulo lojistas: <br />
            <small class="o-70">
              &bullet; Nenhum acesso.<br />
            </small>
            Módulo monitor de pedidos: <br />
            <small class="o-70">
              &bullet; Acesso pleno.
            </small>
          </div>
        </mat-radio-button>
      </mat-radio-group>
    </div>
  </div>
</form>
