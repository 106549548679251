<!-- <p>configForm()?.value {{ configForm()?.value | json }} </p> -->
<!-- <p>loja {{ loja | json }}</p> -->

<form novalidate
      (ngSubmit)="onSubmitClick()"
      [formGroup]="configForm()"
      *ngIf="!!configForm() && !!loja">
  <mat-list>
    <mat-card class="w-100 me-2">
      <div formGroupName="entrega"
           class="ms-3">
        <mat-radio-group formControlName="status"
                         [disabled]="true"
                         class="off">
          <mat-radio-button value="E"
                            class="me-3 off">
            Entrega
          </mat-radio-button>

          <mat-radio-button value="R"
                            class="me-3 off">
            Retirada
          </mat-radio-button>

          <mat-radio-button value="ER"
                            class="off">
            Entrega + Retirada
          </mat-radio-button>
        </mat-radio-group>
      </div>
    </mat-card>

    <div *ngIf="!!loja?.faturamento?.servicos?.valor">
      <mat-divider class="mt-3"></mat-divider>

      <div mat-subheader>Taxa serviços</div>

      <mat-card class="w-100 me-2">
        <div class="d-flex ms-3">
          <div class="flex-1 text-center">
            <span class="o-50">Valor</span><br />
            {{ loja?.faturamento?.servicos?.valor | currency: "BRL":"symbol":"1.2-2" }}
          </div>

          <div class="flex-1">
            <div class="text-center">
              <small class="o-50">% cliente</small><br />
            </div>
            <div formGroupName="faturamento">
              <div formGroupName="servicos">
                <mat-slider thumbLabel
                            class="w-100"
                            [disabled]="!loja?.faturamento?.servicos?.valor"
                            [displayWith]="formatLabel"
                            formControlName="percCliente">
                </mat-slider>
                <mat-hint *ngIf="fv()?.error(percServCliRef) || percServCliRef?.errors?.['percServCli']">
                  <span class="danger"> {{ fv()?.error(percServCliRef) || 'Inválido.' }} </span>
                </mat-hint>
              </div>
            </div>
          </div>

          <div class="flex-1 text-center">
            <span class="o-50">Valor loja <small>( {{ 100 - percServCliRef?.value | number:"1.2-2" }}%
                )</small></span><br />
            {{ taxaServ?.loja | currency: "BRL":"symbol":"1.2-2" }}
          </div>

          <div class="flex-1 text-center">
            <span class="o-50">Valor cliente <small>( {{ percServCliRef?.value | number:"1.2-2" }}%
                )</small></span><br />
            {{ taxaServ?.cliente | currency: "BRL":"symbol":"1.2-2" }}
          </div>
        </div>
      </mat-card>
    </div>

    <mat-divider class="mt-3"></mat-divider>

    <div mat-subheader>Pedidos</div>

    <div class="d-flex flex-column ms-3"
         formGroupName="pedidos">

      <div class="d-flex mb-2">
        <mat-card class="w-100 me-2"
                  formGroupName="minimo">
          <mat-card-subtitle>Valores mínimos</mat-card-subtitle>
          <mat-card-content class="d-flex">
            <div class="flex-1 d-flex">
              <mat-form-field class="w-100 mb-2 me-2"
                              appearance="outline"
                              *ngIf="['R', 'ER'].includes(entregaRef.value)">
                <mat-label>Retirada</mat-label>
                <input type="number"
                       class="text-end"
                       matInput
                       formControlName="retirada"
                       onReturn>
              </mat-form-field>

              <mat-form-field class="w-100 mb-2"
                              appearance="outline"
                              *ngIf="['E', 'ER'].includes(entregaRef.value)">
                <mat-label>Entrega</mat-label>
                <input type="number"
                       class="text-end"
                       matInput
                       formControlName="entrega"
                       onReturn>
              </mat-form-field>
            </div>
          </mat-card-content>
        </mat-card>
      </div>

      <div class="d-flex">
        <mat-card class="w-100 me-2"
                  formGroupName="limiteDiasAgendamento">
          <mat-card-subtitle>Limite dias agendamento</mat-card-subtitle>
          <mat-card-content class="d-flex">
            <div class="flex-1 d-flex">
              <mat-form-field class="w-100 mb-2 me-2"
                              appearance="outline"
                              *ngIf="['R', 'ER'].includes(entregaRef.value)">
                <mat-label>Retirada</mat-label>
                <input type="number"
                       class="text-end"
                       matInput
                       formControlName="retirada"
                       onReturn>
              </mat-form-field>

              <mat-form-field class="w-100 mb-2"
                              appearance="outline"
                              *ngIf="['E', 'ER'].includes(entregaRef.value)">
                <mat-label>Entrega</mat-label>
                <input type="number"
                       class="text-end"
                       matInput
                       formControlName="entrega"
                       onReturn>
              </mat-form-field>
            </div>
          </mat-card-content>
        </mat-card>

        <!-- <mat-card class="w-100"
                  *ngIf="['E', 'ER'].includes(entregaRef.value)">
          <mat-card-subtitle>&nbsp;</mat-card-subtitle>
          <mat-card-content class="d-flex">
            <div class="flex-1 d-flex">
              <mat-form-field class="w-100 mb-2 me-2"
                              appearance="outline">
                <mat-label>Valor carrinho entrega grátis</mat-label>
                <input type="number"
                       class="text-end"
                       matInput
                       formControlName="valorCarrinhoEntregaGratis"
                       onReturn>
              </mat-form-field>
            </div>
          </mat-card-content>
        </mat-card> -->
      </div>
    </div>

    <mat-divider class="mt-3"></mat-divider>

    <div mat-subheader>Contato</div>

    <mat-card class="w-100 me-2">
      <div class="d-flex ms-3">
        <mat-form-field class="w-100 mb-2 me-2"
                        appearance="outline">
          <mat-label>Site institucional</mat-label>
          <input type="text"
                 matInput
                 formControlName="siteInstitucional"
                 onReturn>
        </mat-form-field>

        <mat-form-field class="w-100 mb-2 me-2"
                        appearance="outline">
          <mat-label>E-mail</mat-label>
          <input type="email"
                 matInput
                 formControlName="email"
                 onReturn>
          <mat-hint *ngIf="fv()?.error(emailRef)">
            <span class="danger"> {{ fv()?.error(emailRef) }} </span>
          </mat-hint>
        </mat-form-field>
      </div>

      <div class="d-flex ms-3">
        <mat-form-field class="w-100 mb-2 me-2"
                        appearance="outline"
                        formGroupName="fones">
          <mat-label>Celular</mat-label>
          <input type="text"
                 class="text-end"
                 matInput
                 mask="(00) 00000-0000"
                 [showMaskTyped]="true"
                 formControlName="celular"
                 onReturn>
          <mat-hint *ngIf="fv()?.error(foneCelRef) || (foneCelRef?.errors && foneCelRef?.errors['mask'])">
            <span class="danger">
              {{ fv()?.error(foneCelRef) || 'Telefone inválido.' }}
            </span>
          </mat-hint>
        </mat-form-field>

        <mat-form-field class="w-100 mb-2 me-2"
                        appearance="outline"
                        formGroupName="fones">
          <mat-label>Fixo</mat-label>
          <input type="text"
                 class="text-end"
                 matInput
                 mask="(00) 0000-0000"
                 [showMaskTyped]="true"
                 formControlName="fixo"
                 onReturn>
          <mat-hint *ngIf="fv()?.lerror(foneFixoRef) || (foneFixoRef?.errors && foneFixoRef?.errors['mask'])">
            <span class="danger">
              {{ fv()?.error(foneFixoRef) || 'Telefone inválido.' }}
            </span>
          </mat-hint>
        </mat-form-field>

        <mat-form-field class="w-100 mb-2"
                        appearance="outline"
                        formGroupName="fones">
          <mat-label>Mensagens</mat-label>
          <input type="text"
                 class="text-end"
                 matInput
                 formControlName="mensagens"
                 mask="(00) 00000-0000"
                 formControlName="mensagens"
                 onReturn>
          <mat-hint *ngIf="fv()?.lerror(foneMsgsRef) || (foneMsgsRef?.errors && foneMsgsRef?.errors['mask'])">
            <span class="danger"> {{ fv()?.error(foneMsgsRef) || 'Telefone inválido.' }} </span>
          </mat-hint>
        </mat-form-field>
      </div>
    </mat-card>

    <mat-divider class="mt-3"></mat-divider>

    <div mat-subheader>Redes sociais</div>

    <mat-card class="w-100 me-2 d-flex flex-column"
              formGroupName="redesSociais">
      <div class="d-flex">
        <mat-form-field class="w-100 mb-2 me-2"
                        appearance="outline">
          <mat-label>Facebook</mat-label>
          <input type="text"
                 matInput
                 formControlName="facebook"
                 onReturn>
          <div class="span icon-s-facebook social-icon o-30"
               matSuffix>
          </div>
        </mat-form-field>

        <mat-form-field class="w-100 mb-2 me-2"
                        appearance="outline">
          <mat-label>Instagram</mat-label>
          <input type="text"
                 matInput
                 formControlName="instagram"
                 onReturn>
          <div class="span icon-s-instagram social-icon o-30"
               matSuffix>
          </div>
        </mat-form-field>
      </div>

      <div class="d-flex">
        <mat-form-field class="w-100 mb-2 me-2"
                        appearance="outline">
          <mat-label>Twitter</mat-label>
          <input type="text"
                 matInput
                 formControlName="twitter"
                 onReturn>
          <div class="span icon-s-twitter social-icon o-30"
               matSuffix>
          </div>
        </mat-form-field>

        <mat-form-field class="w-100 mb-2"
                        appearance="outline">
          <mat-label>Youtube</mat-label>
          <input type="text"
                 matInput
                 formControlName="youtube"
                 onReturn>
          <div class="span icon-s-youtube social-icon o-30"
               matSuffix>
          </div>
        </mat-form-field>
      </div>
    </mat-card>

    <mat-divider></mat-divider>

    <div class="d-flex mt-2">
      <button type="submit"
              class="pointer flex-1"
              mat-raised-button
              color="primary"
              [disabled]="!configForm().valid">
        GRAVAR
      </button>
    </div>
  </mat-list>
</form>
