<ng-container *ngIf="vm$() | async as vm">
  <div class="box">
    <nav aria-label="breadcrumb">
      <ol class="breadcrumb">
        <li class="breadcrumb-item">
          <span class="icon-o-home primary tint o-50 pointer"
                routerLink="/dashboard"></span>
        </li>

        <li class="breadcrumb-item active">
          Distribuidora
        </li>
      </ol>
    </nav>

    <!-- <p>vm {{ vm | json }}</p> -->
    <!-- <p>distribuidoraForm()?.value {{ distribuidoraForm()?.value | json }}</p> -->
    <!-- <p>loja() {{ loja() | json }}</p> -->

    <div class="segment my-3">
      <ul class="nav nav-pills d-flex justify-content-center">
        <li class="nav-item pointer"
            (click)="tabAction$().next( 'rotas')">
          <a class="nav-link"
             [class.active]="vm?.tab === 'rotas'">
            Rotas
          </a>
        </li>

        <li class="nav-item pointer"
            (click)="tabAction$().next( 'credenciados')">
          <a class="nav-link"
             [class.active]="vm?.tab === 'credenciados'">
            Credenciados <small class="o-50"
                   *ngIf="!!distribuidoraCredenciadosRef?.credenciados?.length">( {{
              distribuidoraCredenciadosRef?.credenciados?.length }} )</small>
          </a>
        </li>

        <li class="nav-item pointer"
            (click)="tabAction$().next( 'formas')">
          <a class="nav-link"
             [class.active]="vm?.tab === 'formas'">
            Formas pgto <small class="o-50"
                   *ngIf="!!distribuidoraFormaPgtoRef?.formas?.length">( {{ distribuidoraFormaPgtoRef?.formas?.length
              }} )</small>
          </a>
        </li>
      </ul>
    </div>

    <div class="d-flex">
      <button type="button"
              class="btn btn-light btn-lg flex-1 mb-4"
              (click)="onNovoCredenciadoClick()"
              *ngIf="vm?.tab === 'credenciados'">
        <span class="o-50 icon-r-plus me-2"></span>Novo credenciado
      </button>
    </div>

    <div class="d-flex">
      <button type="button"
              class="btn btn-light btn-lg flex-1 mb-4"
              (click)="onNovaFormaClick()"
              *ngIf="vm?.tab === 'formas'">
        <span class="o-50 icon-r-plus me-2"></span>Nova forma
      </button>
    </div>

    <div [ngSwitch]="vm?.tab">
      <div *ngSwitchCase="'formas'">
        <app-distribuidora-formas-pgto [loja]="loja()"></app-distribuidora-formas-pgto>
      </div>

      <div *ngSwitchCase="'credenciados'">
        <app-distribuidora-credenciados [conta]="vm?.conta"
                                        [loja]="loja()">
        </app-distribuidora-credenciados>
      </div>

      <div *ngSwitchDefault>
        <form novalidate
              [formGroup]="distribuidoraForm()"
              *ngIf="!!distribuidoraForm()">
          <mat-card class="w-100 me-2">
            <div formGroupName="distribuidora"
                 class="ms-3">
              <mat-radio-group formControlName="tipoRota"
                               [disabled]="true"
                               class="off">
                <mat-radio-button value="bai"
                                  class="me-3">
                  Por bairro
                </mat-radio-button>

                <mat-radio-button value="loc"
                                  class="me-3">
                  Por localidade
                </mat-radio-button>
              </mat-radio-group>
            </div>
          </mat-card>
        </form>

        <div [ngSwitch]="tipoRotaRef?.value">
          <div *ngSwitchCase="'loc'">
            <div class="my-2">
              <app-distribuidora-rotas-localidade [loja]="loja()"></app-distribuidora-rotas-localidade>
            </div>
          </div>

          <div *ngSwitchDefault>
            <div class="my-2">
              <app-setores-entrega [loja]="loja()"></app-setores-entrega>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</ng-container>
