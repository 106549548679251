//#region ng
import { Component, forwardRef, inject, signal } from '@angular/core';
import { Router } from '@angular/router';
//#endregion

//#region mat
import { MatSnackBar } from '@angular/material/snack-bar';
//#endregion

//#region firebase
import {
  arrayRemove,
  arrayUnion,
  doc,
  Firestore,
  onSnapshot,
  Unsubscribe as FbUnsubscribe,
} from '@angular/fire/firestore';
//#endregion

//#region 3rd
import {
  BehaviorSubject,
  Observable,
  Subject,
  Subscription,
  combineLatest
} from 'rxjs';
import {
  first,
  finalize,
  map,
  takeUntil
} from 'rxjs/operators';
import { get } from 'lodash';
//#endregion

//#region models
interface IVm {
  formas: IFormaPgto[];
  loja: ILoja;
  tab: TFormasPgto;
};
import { HOME_ROUTE } from '../../models/consts';
import {
  IConta,
  IFormaPgto,
  ILoja,
} from '../../_shared/_mercadeiro/_misc/_models/_interfaces/_cols';
// import { TOperadorRole } from '../../_shared/_mercadeiro/_misc/_models/_types';
import {
  NOMES_TIPOS_FORMAS_PGTO,
  TIPOS_FORMAS_PGTO
} from '../../_shared/_mercadeiro/_misc/_models/consts';
import { TFormasPgto } from '../../_shared/_mercadeiro/_misc/_models/_types';
//#endregion

//#region libs
import { onDestroy } from '../../_shared/_core/_ng/_libs';
//#endregion

//#region services
import {
  ContasService,
  LojasService
} from '../../_shared/_mercadeiro/_ng/_services';
import { ROTAS } from '../../services';
import { FormasPgtoService } from '../../_shared/_mercadeiro/_ng/_services/formas-pgto.service';
import { CorLoaderService } from '../../_shared/_core/_ng/_services';
//#endregion

//#region stores
import {
  ContasStore,
  LojasStore
} from '../../_shared/_mercadeiro/_ng/_stores';
//#endregion

@Component({
  selector: 'formas-pgto-page',
  templateUrl: './formas-pgto.page.html',
  styleUrls: ['./formas-pgto.page.scss'],
  /*  providers: [
     {
       provide: NG_VALUE_ACCESSOR,
       useExisting: forwardRef(() => FormasPgtoPage),  // replace name as appropriate
       multi: true
     }
   ] */
})
export class FormasPgtoPage {
  //#region actions
  #destroyAction$: Subject<void> = onDestroy();
  #lojaAction$ = new BehaviorSubject<ILoja>(null);
  tabAction$ = signal<BehaviorSubject<TFormasPgto>>(new BehaviorSubject<TFormasPgto>('din'));
  //#endregion

  //#region publics
  // tab = signal<TFormasPgto>('din');
  tiposFormas = signal<string[]>(TIPOS_FORMAS_PGTO).asReadonly();
  nomesTiposFormas = signal<any>(NOMES_TIPOS_FORMAS_PGTO).asReadonly();
  vm$ = signal<Observable<IVm>>(null);
  //#endregion

  //#region privates
  #lojaUnsub: FbUnsubscribe;
  #vm: IVm;
  //#endregion

  //#region injects
  // #contasServ = inject(ContasService);
  #contasStore = inject(ContasStore);
  #db = inject(Firestore);
  #formasServ = inject(FormasPgtoService);
  #loaderServ = inject(CorLoaderService);
  #lojasServ = inject(LojasService);
  #lojasStore = inject(LojasStore);
  #router = inject(Router);
  #snackBar = inject(MatSnackBar);
  //#endregion

  //#region lifecycles
  ngOnInit() {
    this.#contasStore.contaStateChanged$
      .pipe(takeUntil(this.#destroyAction$))
      .subscribe(
        (conta: IConta) => !!conta
          && !ROTAS?.formasPgto?.__valid?.status
          && this.#router.navigateByUrl(HOME_ROUTE)
      );

    this.#lojasStore.lojaStateChanged$
      .pipe(takeUntil(this.#destroyAction$))
      .subscribe(
        (loja: ILoja) => {
          // this._loja = !!val ? this.#lojasServ.fix(val) : null;
          // console.log(val);
          const LOJA_PATH: string = loja?.__idInfo?.lojaPath || '';
          // console.log(LOJA_PATH);
          if (!!LOJA_PATH) {
            // this.#loaderServ.lstart();
            // const PATH: string = `${LOJA_PATH}`;
            // console.log(PATH);
            this.#lojaUnsub && this.#lojaUnsub();
            this.#lojaUnsub = onSnapshot(
              doc(this.#db, LOJA_PATH),
              snap => {
                this.#lojaAction$.next(this.#lojasServ.fix({ id: snap?.id || '', ...snap.data() }));
              }
            );
          } // if


          // console.log(loja);
        }
      );

    this.vm$.set(
      combineLatest([
        this.#lojaAction$,
        this.#formasServ.docs(),
        this.tabAction$()
      ])
        .pipe(
          map(([loja, formas, tab]) => {
            // console.log(formas);
            this.#vm = {
              formas: (formas || [])
                .filter((f: IFormaPgto) => f?._tipo === tab)
                .map(
                  (f: IFormaPgto) => {
                    const FORMA_ID: string = f?.id || '';
                    const FORMA_TIPO: string = f?._tipo || '';
                    const FORMAS_LOJA_E: string[] = get(loja, `formas.entrega.${FORMA_TIPO}`) || [];
                    const FORMAS_LOJA_R: string[] = get(loja, `formas.retirada.${FORMA_TIPO}`) || [];
                    // console.log(FORMA_ID, FORMA_TIPO, FORMAS_LOJA_E, FORMAS_LOJA_R);
                    f.__sel.entrega = FORMAS_LOJA_E.includes(FORMA_ID);
                    f.__sel.retirada = FORMAS_LOJA_R.includes(FORMA_ID);
                    // console.log(f);
                    return f;
                  }
                ),
              loja,
              tab,
            };
            // console.log(this.#vm);
            return this.#vm;
          })
        )
    );
  }

  ngOnDestroy() {
    !!this.#lojaUnsub && this.#lojaUnsub();
  }
  //#endregion

  //#region functions
  tids(index: any, item: any): string { return item || ''; }

  tid_for(index: any, item: IFormaPgto): string { return item?.id || ''; }
  //#endregion

  //#region methods
  onTabClick(tab: string) {
    this.tabAction$().next(tab as TFormasPgto);
  }
  //#endregion

  //#region methods

  onFormaClick(f: IFormaPgto, retirada: boolean) {
    console.log(retirada, JSON.stringify(f));
    const RETIRADA_CAPTION: string = !!retirada ? 'retirada' : 'entrega';
    const LOJA_ID: string = this.#vm?.loja?.id || '';
    const FORMA_ID: string = f?.id || '';
    const FORMA_NOME: string = f?._nome || '';
    const FORMA_TIPO: any = f?._tipo;
    // const FORMA_SEL: boolean = !get(f, `__sel.${RETIRADA_CAPTION}`);
    // console.log(FORMA_SEL);
    if (
      !!FORMA_ID
      && !!FORMA_NOME
      && !!FORMA_TIPO
    ) {
      const FORMAS_TIPO_LOJA: string[] = get(this.#vm?.loja, `formas.${RETIRADA_CAPTION}.${FORMA_TIPO}`) || [];
      const HAS_FORMA: boolean = FORMAS_TIPO_LOJA.includes(FORMA_ID);
      // console.log(FORMA_ID, RETIRADA_CAPTION, FORMA_TIPO, FORMAS_TIPO_LOJA, HAS_FORMA);
      let changes: Partial<ILoja>;
      if (!!HAS_FORMA) {
        changes = {
          formas: {
            [RETIRADA_CAPTION]: {
              [FORMA_TIPO]: arrayRemove(FORMA_ID)
            }
          }
        };
      } else {
        changes = {
          formas: {
            [RETIRADA_CAPTION]: {
              [FORMA_TIPO]: arrayUnion(FORMA_ID)
            }
          }
        };
      } // else
      // console.log(FORMA_SEL, JSON.stringify(changes));
      // console.log(LOJA_ID);
      // console.log(JSON.stringify(changes));
      if (!!LOJA_ID) {
        this.#loaderServ.lstart();
        const SUB: Subscription =
          this.#loaderServ.showUntilCompleted(
            this.#lojasServ.update(LOJA_ID, changes)
              .pipe(first(), finalize(() => SUB?.unsubscribe()))
          )
            .subscribe(() => this.#snackBar.open(`Forma ${FORMA_NOME} modificada.`));
      } // if
    } // if
  }
  //#endregion

  /*
  //#region publics
  conta: IConta;
  formas: IFormaPgto[];
  // loja: ILoja;
  tiposFormas: string[] = TIPOS_FORMAS_PGTO;
  nomesTiposFormas: any = NOMES_TIPOS_FORMAS_PGTO;
  //#endregion

  //#region loja
  private _loja: ILoja;
  set loja(val: ILoja) {
    this._loja = !!val ? this.#lojasServ.fix(val) : null;
    // console.log(val);
    // const LOJA_PATH: string = val?.__idInfo?.lojaPath || '';
    // if (!!LOJA_PATH) {
    //   // this.#loaderServ.lstart();
    //   // const PATH: string = `${LOJA_PATH}`;
    //   // console.log(PATH);
    //   const DOC_REF = doc(
    //     this.#db,
    //     LOJA_PATH
    //   );
    //   this.#lojaUnsub && this.#lojaUnsub();
    //   this.#lojaUnsub = onSnapshot(
    //     DOC_REF,
    //     snap => {
    //       // console.log(snap.data());
    //       this._reselect();
    //       // if (!!snap.id) {
    //       //   this.credenciados = this.#contasServ.fixes(
    //       //     await this._firestoreServ.getPathIdsDocs(
    //       //       '/_contas',
    //       //       snap.get('distribuidora.idsCredenciados') || []
    //       //     )
    //       //   );
    //       // } // if
    //     }
    //   );
    // } // if
    this._reselect();
  }
  get loja(): ILoja {
    return this._loja;
  }
  //#endregion

  //#region tab
  private _tab: TFormasPgto;
  set tab(val: TFormasPgto) {
    this._tab = val;
    // console.log(val);
    // if (!!val) {
    // this.formas = (this._formas || [])
    //   .filter((f: IFormaPgto) => f?._tipo === val);
    this._reselect();
    // } // if
  }
  get tab() {
    return this._tab;
  }
  //#endregion

  */
}
