<ng-container *ngIf="vm$() | async as vm">
  <div class="loader-container"
       *ngIf="vm?.isLoading">
    <div class="spinner-container">
      <mat-spinner class="spinner"></mat-spinner>
    </div>
  </div>

  <!-- <p>vm {{ vm | json }}</p> -->

  <div class="wrapper d-flex flex-column">
    <div class="header d-flex p-2 justify-content-between">
      <div class="my-auto">
        <img src="assets/img/logo_mercadeiro_dark.svg"
             class="ms-2 img-fluid pointer"
             alt=""
             routerLink="/dashboard">
        <span class="my-auto ms-2 primary o-50 rem-20">// lojistas
        </span>
      </div>

      <div class="my-auto d-flex">
        <me-bs-loja class="flex-1 me-2"
                     routerLink="/lojas"
                     [loja]="vm?.loja" />

        <me-mat-operador-actions [conta]="vm?.conta"
                                 (logout$)="onLogoutClick()" />
      </div>
    </div>

    <div class="body d-flex flex-1">
      <div class="nav d-flex flex-column mx-3"
           *ngIf="!!vm?.conta && !!vm?.loja">
        <nav class="nav-menu p-relative">
          <ul class="items">
            <li class="item d-flex flex-column"
                routerLinkActive="item-active"
                [routerLink]="[p.url]"
                *ngFor="let p of vm?.rotas; trackBy:tid_rot">
              <i [class]="p?.icone"></i>
              <div class="caption">{{ p?.titulo }}</div>
            </li>
          </ul>
        </nav>
      </div>

      <div class="routes flex-1 w-100">
        <cor-mat-messages [isMain]="true" />
        <router-outlet></router-outlet>
      </div>
    </div>
  </div>
</ng-container>