<ul class="w-cards galeria">
  <li class="w-card galeria"
      *ngFor="let dummy of [].constructor(30)">
    <div class="thumb">
      <img class="o-50"
           [src]="imgPlaceholder()"
           alt="">
    </div>
    <ngx-skeleton-loader [count]="2" />
  </li>
</ul>