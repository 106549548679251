//#region ng
import {
  Component,
  Inject,
  ViewChild,
  computed,
  effect,
  inject,
  signal,
} from '@angular/core';
//#endregion

//#region firebase
import {
  doc,
  Firestore,
  onSnapshot,
  Unsubscribe as FbUnsubscribe,
} from '@angular/fire/firestore';
//#endregion

//#region mat
import {
  // MatDialog,
  MatDialogRef,
  MAT_DIALOG_DATA,
  MatDialog
} from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
//#endregion

//#region 3rd
// import * as moment from 'moment';
import { Subscription } from 'rxjs';
import {
  first,
  finalize,
  filter
} from 'rxjs/operators';
//#endregion

//#region models
type TProdutoEditTabs = 'imgs' | 'info';
import { IProdutoEditParams } from '../../models/interfaces/params';
// import { environment } from 'src/environments/environment';
// type TProdutoEdit = 'img' | 'status' | 'fracionado';
// import { CorFormValidation } from '../../_shared/_core/_ng/_models/_classes';
import { IProduto } from '../../_shared/_mercadeiro/_misc/_models/_interfaces/_cols';
import { CorFormValidation } from '../../_shared/_core/_ng/_models/_classes';
import {
  PRODUTO_FRACIONADO_TIPOS,
  PRODUTO_FRACIONADO_TIPOS_ALT
} from '../../_shared/_mercadeiro/_misc/_models/consts';
import { IProImgProdutoMap } from '../../_shared/_produtos/_misc/_models/_interfaces/_maps';
//#endregion

//#region libs
import { compareValues } from '../../_shared/_libs/_misc/_arrays';
//#endregion

//#region services
import { AppService } from '../../services';
import { CorLoaderService } from '../../_shared/_core/_ng/_services';
import { ProdutosService } from '../../_shared/_mercadeiro/_ng/_services';
//#endregion

//#region components
// import { GaleriaGetModal } from '..';
import { MeMatProdutoImgsComponent } from '../../_shared/_mercadeiro/_ng-ui/_mat';
import { CorMatConfirmDialog } from '../../_shared/_core/_ng-ui/_mat';
//#endregion

@Component({
  selector: 'produto-edit-modal',
  templateUrl: './produto-edit.modal.html',
  styleUrls: ['./produto-edit.modal.scss']
})
export class ProdutoEditModal {
  //#region viewchilds
  @ViewChild(MeMatProdutoImgsComponent) produtoImgsRef: MeMatProdutoImgsComponent;
  //#endregion

  //#region publics
  // adds: IProImgProdutoMap[] = [];
  // dels: IProImgProdutoMap[] = [];
  tab = signal<TProdutoEditTabs>('imgs');
  // meilisearch: Partial<IMeilisearch>;
  produto = signal<IProduto>(null);
  lojaPath = signal<string>('');
  //#endregion

  //#region privates
  #produtoUnsub: FbUnsubscribe;
  //#endregion

  //#region injects
  #db = inject(Firestore);
  #dialogRef = inject(MatDialogRef<ProdutoEditModal>);
  #loaderServ = inject(CorLoaderService);
  #modal = inject(MatDialog);
  #produtosServ = inject(ProdutosService);
  #snackBar = inject(MatSnackBar);
  //#endregion

  //#region constructor
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: IProdutoEditParams,
  ) { }
  //#endregion

  //#region lifecycles
  ngOnInit() {
    this.lojaPath.set(this.data?.lojaPath || null);
    this.produto.set(!!this.data?.produto ? this.#produtosServ.fix(this.data?.produto) : null);
    const PRODUTO_ID: string = this.produto()?.id || '';
    if (
      !!PRODUTO_ID
      && !!this.lojaPath()
    ) {
      const PATH: string = `${this.lojaPath()}/produtos/${PRODUTO_ID}`;
      // console.log(PATH);
      !!this.#produtoUnsub && this.#produtoUnsub();
      this.#produtoUnsub = onSnapshot(
        doc(this.#db, PATH),
        snap => {
          const PRODUTO: any = {
            id: snap.id,
            ...snap.data()
          };
          // console.log({ PRODUTO });
          this.produto.set(PRODUTO);
        }
      );
    } // if
  }

  ngOnDestroy() {
    !!this.#produtoUnsub && this.#produtoUnsub();
  }
  //#endregion

  //#region methods
  onModalCloseClick(val: IProduto = null) {
    this.#dialogRef.close(val);
  }

  onMoveImgClick(img: IProImgProdutoMap, isLeft: boolean) {
    const PRODUTO_ID: string = this.produto()?.id || '';
    if (
      !!img
      && !!PRODUTO_ID
      && !!this.lojaPath()
    ) {
      // console.log('onMoveImgLeftClick', img);
      const IMGS: IProImgProdutoMap[] = (this.produto()?.imgs || [])
        .sort(compareValues('_pos.val'));
      // console.log(IMGS?.map(v => v?._pos.val).join(','));
      const P: number = IMGS.findIndex((i: IProImgProdutoMap) => i?.id === img?.id);
      // console.log(P);
      if (P !== undefined) {
        const POS_FROM: number = IMGS.at(P)?._pos?.val;
        const POS_TO: number = !!isLeft ? IMGS.at(P - 1)?._pos?.val : IMGS.at(P + 1)?._pos?.val;
        // console.log(POS_FROM, POS_TO);
        IMGS.at(P)._pos.val = POS_TO;
        !!isLeft ? IMGS.at(P - 1)._pos.val = POS_FROM : IMGS.at(P + 1)._pos.val = POS_FROM;
        // console.log(IMGS?.map(v => v?._pos.val).join(','));
        const CHANGES: Partial<IProduto> = { imgs: IMGS };
        // console.log(CHANGES);
        const SUB: Subscription = this.#loaderServ.showUntilCompleted(
          this.#produtosServ.update(this.lojaPath(), PRODUTO_ID, CHANGES)
            .pipe(first(), finalize(() => SUB?.unsubscribe()))
        )
          .subscribe(() => {
            // const PRODUTO: IProduto = {
            //   ...this.produto(),
            //   ...CHANGES,
            //   id: PRODUTO_ID,
            // };
            // console.log(PRODUTO);
            // this.#snackBar.open(`Imagem adicionada ao produto ${PRODUTO?.nome}.`);
            // this.onModalCloseClick(PRODUTO);
          });
      } // if
    } // if
  }

  onDelImgClick(i: IProImgProdutoMap) {
    // console.log(d);
    const DIALOG_EXISTS = this.#modal.getDialogById('del-img');
    if (!DIALOG_EXISTS) {
      const DIALOG_REF: any = this.#modal.open(
        CorMatConfirmDialog,
        {
          id: 'del-img',
          panelClass: 'no-border-dialog-container',
          data: {
            title: 'Excluindo imagem',
            msg: 'Você tem certeza?',
            cancelCaption: 'Não',
            confirmCaption: 'Sim',
          }
        }
      );

      const SUB: Subscription = DIALOG_REF
        .afterClosed()
        .pipe(first(), finalize(() => SUB?.unsubscribe()))
        // .pipe(tap(resp => console.log(resp),
        .pipe(filter(resp => !!resp))
        .subscribe(async (resp: any) => {
          // console.log(resp);
          if (!!resp) {
            // console.log('onDelImgClick', i);
            const PRODUTO_ID: string = this.produto()?.id || '';
            let imgs: IProImgProdutoMap[] = (this.produto()?.imgs || [])
              .filter((img: IProImgProdutoMap) => !(img?.id === i?.id))
              .sort(compareValues('_pos.val'));
            const CHANGES: Partial<IProduto> = { imgs };
            // console.log(CHANGES);

            if (
              !!PRODUTO_ID
              && !!this.lojaPath()
            ) {
              const SUB: Subscription = this.#loaderServ.showUntilCompleted(
                this.#produtosServ.update(this.lojaPath(), PRODUTO_ID, CHANGES)
                  .pipe(first(), finalize(() => SUB?.unsubscribe()))
              )
                .subscribe(() => {
                  const PRODUTO: IProduto = {
                    ...this.produto(),
                    ...CHANGES,
                    id: PRODUTO_ID,
                  };
                  // console.log(PRODUTO);
                  this.#snackBar.open(`Imagem adicionada ao produto ${PRODUTO?.nome}.`);
                  // this.onModalCloseClick(PRODUTO);
                });
            } // if
          } // if
        });
    } // if
  }

  onAddImgClick() {
    AppService.addImgProdutoFromGaleria$().emit(this.produto());
  }

  onFormSubmitted() {
    this.onModalCloseClick(this.produto());
  }

  //#endregion
}
