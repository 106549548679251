//#region ng
import {
  Component,
  EventEmitter,
  Input,
  Output,
  inject,
  signal
} from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  ValidationErrors,
  Validators
} from "@angular/forms";
//#endregion

//#region mat
import { MatSnackBar } from '@angular/material/snack-bar';
//#endregion

//#region 3rd
import { uniq } from 'lodash';
import { Subscription } from 'rxjs';
import {
  finalize,
  first,
} from 'rxjs/operators';
//#endregion

//#region models
import { IPTag, IProduto } from '../../../_shared/_mercadeiro/_misc/_models/_interfaces/_cols';
import {
  PRODUTO_FRACIONADO_TIPOS,
  PRODUTO_FRACIONADO_TIPOS_ALT
} from '../../../_shared/_mercadeiro/_misc/_models/consts';
import { ODOCS_TAG } from '../../../_shared/_core/_misc/_models/consts';
//#endregion

//#region custom validators
class CustomValidator {
  static fracao() {
    return (control: FormControl): ValidationErrors | null => {
      const NRO: number = Number(control.value || '');
      // console.log(NRO);
      return NRO <= 0 ? { fracao: true } : null;
    }
  }
}
//#endregion

//#region services
import { PTagsService, ProdutosService } from '../../../_shared/_mercadeiro/_ng/_services';
import { CorFormValidation } from '../../../_shared/_core/_ng/_models/_classes';
import { CorLoaderService } from '../../../_shared/_core/_ng/_services';
import { compareValues } from 'src/app/_shared/_libs/_misc/_arrays';
//#endregion

@Component({
  selector: 'app-produto-form',
  templateUrl: './produto-form.component.html',
  styleUrls: ['./produto-form.component.scss']
})
export class ProdutoFormComponent {
  //#region inputs
  // lojaPath
  #lojaPath = '';
  get lojaPath(): string { return this.#lojaPath };
  @Input({ required: true }) set lojaPath(val: string) {
    this.#lojaPath = val || '';
    // console.log(val);
    if (!!val) {
      const SUB: Subscription = this.#loaderServ.showUntilCompleted(
        this.#ptagsServ.docs(val)
      )
        .pipe(first(), finalize(() => SUB?.unsubscribe()))
        .subscribe(
          (tags: Partial<IPTag>[]) => {
            // console.log(tags);
            this.ptags.set(
              this.#ptagsServ.fixes(tags)
                .sort(compareValues('caption'))
            );
          }
        );
    } // if

  };
  // produto
  #produto: IProduto;
  get produto(): IProduto { return this.#produto };
  @Input({ required: true }) set produto(val: IProduto) {
    this.#produto = !!val ? this.#produtosServ.fix(val) : null;
    // console.log(val);

    this.pids.set((val?.tags || []).map(p => p?.id || ''));

    if (!!val) {
      this.produtoForm.set(
        this.#fb.group({
          // id: [get(this.produto, 'id')],
          ativo: this.#fb.group({
            online: [!!val?.ativo?.online],
            _consistente: [!!val?.ativo?._consistente],
            status: [!!val?.ativo?.status],
          }),
          // destaque: [!!get(this.produto, 'destaque')],
          fracionado: this.#fb.group({
            _status: [!!val?.fracionado?._status, [Validators.min(0)]],
            unidade: this.#fb.group({
              fracao: [val?.fracionado?.unidade?.fracao || 0, [Validators.required, CustomValidator.fracao()]],
              fracaoAproxUn: [val?.fracionado?.unidade?.fracaoAproxUn || 0, [Validators.min(0)]],
              tipo: [val?.fracionado?.unidade?.tipo, [Validators.required]],
            }),
          }),
        })
      );

      const STATUS: boolean = !!val?.fracionado?._status;
      if (!STATUS) {
        this.fracionadoUnidadeTipoRef?.disable();
        this.fracionadoUnidadeFracaoRef?.disable();
      } // if
    } // if
  }
  //#endregion

  //#region outputs
  @Output() submitted$ = new EventEmitter<void>();
  //#endregion

  //#region Controls getters
  get fracionadoStatusRef() { return this.produtoForm()?.get('fracionado._status'); }
  get fracionadoUnidadeTipoRef() { return this.produtoForm()?.get('fracionado.unidade.tipo'); }
  get fracionadoUnidadeFracaoRef() { return this.produtoForm()?.get('fracionado.unidade.fracao'); }
  get fracionadoFracaoAproxUnRef() { return this.produtoForm()?.get('fracionado.unidade.fracaoAproxUn'); }
  //#endregion

  //#region publics
  fv = signal<CorFormValidation>(null);
  produtoForm = signal<FormGroup>(null);
  ptags = signal<IPTag[]>(null);
  pids = signal<string[]>([]);
  tiposUnidade: any[] = uniq(
    [
      ...PRODUTO_FRACIONADO_TIPOS,
      ...PRODUTO_FRACIONADO_TIPOS_ALT
    ].sort()
  );
  //#endregion

  //#region injects
  #fb = inject(FormBuilder);
  #loaderServ = inject(CorLoaderService);
  #produtosServ = inject(ProdutosService);
  #ptagsServ = inject(PTagsService);
  #snackBar = inject(MatSnackBar);
  //#endregion

  //#region constructor
  constructor() {
    this.fv.set(new CorFormValidation);
  }
  //#endregion

  //#region functions
  tids_pro(index: any, item: string): string { return item || ''; }

  tid_ptags(index: any, item: IPTag): string { return item?.id || ''; }
  //#endregion

  //#region methods
  onTagEditClick(t: IPTag) {
    // console.log(t);
    const ID: string = t?.id || '';
    // const FOUND: boolean = this.pids().includes(ID);
    // let pids: string[] = this.pids().filter((id: string) => id !== ID);
    this.pids().includes(ID)
      ? this.pids.set(this.pids().filter((id: string) => id !== ID))
      : this.pids.set([...this.pids(), ID]);
  }

  onSubmitClick() {
    const PRODUTO_ID: string = this.produto?.id || '';
    if (
      !!PRODUTO_ID
      && !!this.lojaPath
    ) {
      // console.log(this.adds, this.dels);
      const CHANGES: Partial<IProduto> = {
        ...this.produtoForm()?.value,
        tags: this.#ptagsServ.fixes(
          (this.ptags() || []).filter((t: IPTag) => this.pids()?.includes(t?.id || ''))
        )
      };
      delete CHANGES.ativo._consistente;
      delete CHANGES.ativo.status;
      console.log(CHANGES);

      const SUB: Subscription = this.#loaderServ.showUntilCompleted(
        this.#produtosServ.update(this.lojaPath, PRODUTO_ID, CHANGES)
          .pipe(first(), finalize(() => SUB?.unsubscribe()))
      )
        .subscribe(() => {
          const PRODUTO: IProduto = {
            ...this.produto,
            ...CHANGES,
            id: PRODUTO_ID,
          };
          // console.log(PRODUTO);
          this.#snackBar.open(`Produto ${PRODUTO?.nome} modificado.`);
          this.submitted$.emit();
        });
    } // if
  }
  //#endregion
}
