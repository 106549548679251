//#region ng
import {
  Component,
  EventEmitter,
  Inject,
  computed,
  effect,
  inject,
  signal,
} from '@angular/core';
import {
  AbstractControl,
  FormBuilder,
  FormControl,
  FormGroup,
  ValidationErrors,
  Validators
} from "@angular/forms";
//#endregion

//#region mat
import {
  MatDialogRef,
  MAT_DIALOG_DATA
} from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
//#endregion

//#region 3rd
import { get } from 'lodash';
import { Subscription } from 'rxjs';
import {
  first,
  finalize
} from 'rxjs/operators';
//#endregion

//#region models
import { TCorDow } from '../../_shared/_core/_misc/_models/_types';
import { CorFormValidation } from '../../_shared/_core/_ng/_models/_classes';
import { IHorarioAgendamentoMap } from '../../_shared/_mercadeiro/_misc/_models/_interfaces/_maps';
import { THorarioAgendamento } from '../../_shared/_mercadeiro/_misc/_models/_types';
import { ILoja } from '../../_shared/_mercadeiro/_misc/_models/_interfaces/_cols';
import { IHorarioFormParams } from '../../models/interfaces/params';
//#endregion

//#region Custom Validators
class CustomValidator {
  static final() {
    return (control: FormControl): ValidationErrors | null => {
      // console.log(control);
      const FORM_GROUP: FormGroup = get(control, '_parent');
      const INICIO: any = time2min(FORM_GROUP?.get('inicio')?.value);
      const FINAL: any = time2min(control?.value);
      // console.log();
      // console.log('inicio', INICIO);
      // console.log('inicio', FINAL);
      if (INICIO > 0 && FINAL > 0) {
        return FINAL <= INICIO ? { final: true } : null;
      } // if
      return INICIO + FINAL ? null : { final: true };
    }
  }

  static tes() {
    return (control: FormControl): ValidationErrors | null => {
      const NRO: number = Number(control.value || '');
      // console.log(NRO);
      return NRO <= 0 ? { tes: true } : null;
    }
  }
}
//#endregion

//#region libs
import { compareValues } from '../../_shared/_libs/_misc/_arrays';
import { guid } from '../../_shared/_libs/_misc/_strings';
import {
  min2time,
  time2min
} from '../../_shared/_libs/_misc/_convert';
//#endregion

//#region services
import { LojasService } from '../../_shared/_mercadeiro/_ng/_services';
import { CorLoaderService } from '../../_shared/_core/_ng/_services';
import { FOCUS_TIMEOUT } from '../../_shared/_core/_misc/_models/consts';
import { AppService } from '../../services';
//#endregion

@Component({
  selector: 'horario-form-modal',
  templateUrl: './horario-form.modal.html',
  styleUrls: ['./horario-form.modal.scss']
})
export class HorarioFormModal {
  //#region publics
  dow = signal<TCorDow>(null);
  fv = signal<CorFormValidation>(null);
  horario = signal<IHorarioAgendamentoMap>(null);
  horarioForm = computed<FormGroup>(
    () => {
      const HORARIO: IHorarioAgendamentoMap = this.horario();
      return this.#fb.group(
        {
          id: [HORARIO?.id || guid(), [Validators.required,]],
          inicio: [min2time(HORARIO?.inicio || 0, false), [Validators.required]],
          final: [min2time(HORARIO?.final || 0, false), [
            Validators.required, CustomValidator.final()]],
          tempoEstimadoSeparacaoMin: [HORARIO?.tempoEstimadoSeparacaoMin || '', [Validators.required, CustomValidator.tes()],
          ],
        }
      );
    }
  );
  // horarioForm: FormGroup;
  tipo = signal<THorarioAgendamento>(null);
  //#endregion

  //#region privates
  #loja: ILoja;
  #novo: boolean;
  //#endregion

  //#region methods
  inicioFocusEvent$ = signal<EventEmitter<boolean>>(new EventEmitter<boolean>()).asReadonly();
  //#endregion

  //#region injects
  #dialogRef = inject(MatDialogRef<HorarioFormModal>);
  #fb = inject(FormBuilder);
  #loaderServ = inject(CorLoaderService);
  #lojasServ = inject(LojasService);
  #snackBar = inject(MatSnackBar);
  //#endregion

  //#region constructor
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: IHorarioFormParams,
  ) {
    this.fv.set(new CorFormValidation);
    effect(() => this.#novo = !!this.horario());
  }
  //#endregion

  //#region lifecycles
  ngOnInit(): void {
    // this.#novo = !this.horario?.id;
    this.dow.set(this.data?.dow);
    this.horario.set(this.data?.horario);
    this.tipo.set(this.data?.tipo);
    this.#loja = this.data?.loja;
  }

  ngAfterViewInit() {
    this.#focus();
  }
  //#endregion

  //#region Controls getters
  get idRef(): AbstractControl { return this.horarioForm()?.get('id'); }
  get inicioRef(): AbstractControl { return this.horarioForm()?.get('inicio'); }
  get finalRef(): AbstractControl { return this.horarioForm()?.get('final'); }
  get tesRef(): AbstractControl { return this.horarioForm()?.get('tempoEstimadoSeparacaoMin'); }
  //#endregion

  //#region functions
  #focus() {
    setTimeout(
      () => this.inicioFocusEvent$().emit(true),
      FOCUS_TIMEOUT
    );
  }
  //#endregion

  //#region methods
  onModalCloseClick(val: any = null) {
    this.#dialogRef.close(val);
  }

  onSubmitClick() {
    const ID: string = this.idRef.value;
    const ID_LOJA: string = this.#loja?.id || '';
    const INICIO: any = time2min(this.inicioRef?.value);
    const FINAL: any = time2min(this.finalRef?.value);
    // console.log(INICIO, FINAL);
    // console.log(ID, ID_LOJA);
    if (!!ID && !!ID_LOJA) {
      const CHANGES: IHorarioAgendamentoMap = {
        id: ID,
        inicio: INICIO || 0,
        final: FINAL || 0,
        tempoEstimadoSeparacaoMin: Number(this.horarioForm()?.get('tempoEstimadoSeparacaoMin').value || 0),
      };
      // console.log(CHANGES);

      const HORARIO_KEY: string = `horarios.${this.tipo()}.dow${this.dow()}`;
      // console.log(HORARIO_KEY);
      const HORARIOS: IHorarioAgendamentoMap[] = (get(this.#loja, HORARIO_KEY) || [])
        .filter((h: IHorarioAgendamentoMap) => h?.id !== ID);
      // const HORARIOS: IHorarioAgendamentoMap[] = get(this.#loja, HORARIO_KEY) || [];
      // console.log(HORARIOS);
      // console.log(CHANGES);
      const SUB: Subscription = this.#loaderServ.showUntilCompleted(
        this.#lojasServ.update(
          ID_LOJA,
          {
            horarios: {
              [this.tipo()]: {
                [`dow${this.dow()}`]: [...HORARIOS, CHANGES]
                  .sort(compareValues('inicio', 'asc'))
              }
            }
          }
        )
          .pipe(first(), finalize(() => SUB?.unsubscribe()))
      )
        .subscribe(
          () => {
            this.#snackBar.open(`Horário ${this.#novo ? 'adicionado' : 'modificado'}.`);
            AppService.horarioChanged$().emit(CHANGES);
            this.#dialogRef.close();
            // this.#lojasServ.setLojaState(this.lojaState);
          }
        );
    } // if
  }
  //#endregion
}
