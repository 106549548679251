//#region ng
import {
  Component,
  Input,
  inject,
  signal
} from '@angular/core';
//#endregion

//#region mat
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
//#endregion

//#region firebase
import {
  doc,
  Firestore,
  onSnapshot,
  Unsubscribe as FbUnsubscribe,
} from '@angular/fire/firestore';
//#endregion

//#region 3rd
import { Subscription } from 'rxjs';
import {
  first,
  finalize,
  filter
} from 'rxjs/operators';
//#endregion

//#region models
import {
  IFormaPgto,
  ILoja
} from '../../../_shared/_mercadeiro/_misc/_models/_interfaces/_cols';
import { ODOCS_TAG } from '../../../_shared/_core/_misc/_models/consts';
//#endregion

//#region services
import {
  FormasPgtoService,
  LojaFormasPgtoService,
  LojasService
} from '../../../_shared/_mercadeiro/_ng/_services';
//#endregion

//#region components
import { DistribuidoraFormaPgtoFormModal } from '../../../modals';
//#endregion

@Component({
  selector: 'app-distribuidora-formas-pgto',
  templateUrl: './distribuidora-formas-pgto.component.html',
  styleUrls: ['./distribuidora-formas-pgto.component.scss']
})
export class DistribuidoraFormasPgtoComponent {
  //#region inputs
  #loja: ILoja
  get loja(): ILoja { return this.#loja; }
  @Input({ required: true }) set loja(val: ILoja) {
    this.#loja = !!val ? this.#lojasServ.fix(val) : null;
    if (!!this.loja?.__idInfo?.lojaPath) {
      // this._loaderServ.lstart();
      const PATH: string = `${this.loja?.__idInfo?.lojaPath}/formas-pgto/${ODOCS_TAG}`;
      // console.log(PATH);
      /* this.#pedidoUnsub && */ this.#pedidoUnsub?.();
      this.#pedidoUnsub = onSnapshot(
        doc(this.#db, PATH),
        snap => {
          const FORMAS: IFormaPgto[] = Object.values(snap.get('_odocs') || {}) || [];
          // console.log(FORMAS);
          this.formas.set(
            !!FORMAS.length
              ? this.#formasServ.fixes(FORMAS)
              : []
          );
          // if (!!snap.id) {
          //   this.pedido = this._pedidosServ.fix({ id: snap.id, ...snap.data() });
          // } // if
        }
      );
    } // if
  }
  //#endregion

  //#region publics
  formas = signal<IFormaPgto[]>(null);
  //#endregion

  //#region privates
  #pedidoUnsub: FbUnsubscribe;
  //#endregion

  //#region injects
  #db = inject(Firestore);
  #formasServ = inject(FormasPgtoService);
  #lojaFormasServ = inject(LojaFormasPgtoService);
  #lojasServ = inject(LojasService);
  #modal = inject(MatDialog);
  #snackBar = inject(MatSnackBar);
  //#endregion

  //#region lifecycles
  ngOnDestroy(): void {
    !!this.#pedidoUnsub && this.#pedidoUnsub?.();
  }
  //#endregion

  //#region functions
  tid_for(index: any, item: IFormaPgto): string { return item?.id || ''; }
  //#endregion

  //#region methods
  onFormaEditClick(f: IFormaPgto = null) {
    const DIALOG_EXISTS = this.#modal.getDialogById('forma-add');
    if (!DIALOG_EXISTS) {
      const DIALOG_REF: any = this.#modal.open(
        DistribuidoraFormaPgtoFormModal,
        {
          id: 'forma-add',
          panelClass: 'no-border-dialog-container',
          data: {
            forma: f,
            // loja: this.loja
          }
        }
      );

      const SUB: Subscription = DIALOG_REF
        .afterClosed()
        .pipe(first(), finalize(() => SUB?.unsubscribe()))
        .pipe(filter((resp: any) => !!resp))
        .subscribe(async (resp: any) => {
          // console.log(resp);
          if (!!resp) {
            if (!!f?.id) {
              const SUB2: Subscription = this.#lojaFormasServ.update(
                this.loja?.__idInfo?.lojaPath,
                f?.id,
                resp
              )
                .pipe(first(), finalize(() => SUB2?.unsubscribe()))
                .subscribe(
                  () => {
                    this.#snackBar.open(
                      `Forma ${resp?._nome} modificada.`,
                      ''
                    );
                  }
                );
            } else {
              const SUB2: Subscription = this.#lojaFormasServ.add(
                this.loja?.__idInfo?.lojaPath,
                resp
              )
                .pipe(first(), finalize(() => SUB2?.unsubscribe()))
                .subscribe(
                  () => {
                    this.#snackBar.open(
                      `Forma ${resp?._nome} adicionada.`,
                      ''
                    );
                  }
                );
            } // else
          } // if
        });
    } // if
  }
  //#endregion
}
