//#region ng
import {
  Component,
  Input,
  inject
} from '@angular/core';
//#endregion

//#region 3rd
import { Subscription } from 'rxjs';
import {
  finalize,
  first,
} from 'rxjs/operators';
//#endregion

//#region models
import {
  IConta,
  IFormaPgto,
  ILoja
} from '../../_shared/_mercadeiro/_misc/_models/_interfaces/_cols';
//#endregion

//#region services
import {
  ContasService,
  FormasPgtoService,
  LojaFormasPgtoService,
  LojasService
} from '../../_shared/_mercadeiro/_ng/_services';
//#endregion

@Component({
  selector: 'app-credenciado-formas-pgto-form',
  templateUrl: './credenciado-formas-pgto-form.component.html',
  styleUrls: ['./credenciado-formas-pgto-form.component.scss']
})
export class CredenciadoFormasPgtoFormComponent {
  //#region inputs
  // conta
  #conta: IConta;
  get conta(): IConta { return this.#conta; }
  @Input({ required: true }) set conta(val: IConta) {
    this.#conta = !!val ? this.#contasServ.fix(val) : null;
    this.#refresh();
  }
  // loja
  #loja: ILoja;
  get loja(): ILoja { return this.#loja; }
  @Input({ required: true }) set loja(val: ILoja) {
    this.#loja = !!val ? this.#lojasServ.fix(val) : null;
    // console.log(this.loja);
    // this._loaderServ.lstart();
    const LOJA_PATH: string = this.loja?.__idInfo?.lojaPath || '';
    if (!!LOJA_PATH) {
      this.#formas = [];
      const SUB: Subscription = this.#lojasFormasServ.docs(
        LOJA_PATH
      )
        .pipe(first(), finalize(() => SUB?.unsubscribe()))
        .subscribe(
          (docs: any[]) => {
            // console.log(docs);
            this.#formas = this.#formasServ.fixes(docs);
            this.#refresh();
          }
        );
    } // if
  }
  //#endregion

  //#region publics
  formas: IFormaPgto[];
  formasSel: IFormaPgto[];
  //#endregion

  //#region privates
  #formas: IFormaPgto[];
  //#endregion

  //#region injects
  #contasServ = inject(ContasService);
  #lojasServ = inject(LojasService);
  #formasServ = inject(FormasPgtoService);
  #lojasFormasServ = inject(LojaFormasPgtoService);
  //#endregion

  //#region functions
  tid_for(index: any, item: IFormaPgto): string { return item?.id || ''; }

  #refresh() {
    // console.log(this.conta/* , this.loja */);
    if (!!this.conta && !!this.loja) {
      this.formas = [...this.#formas]
        .map((f: IFormaPgto) => {
          // console.log(f);
          f.__sel.distribuidora = this.conta?.credenciado?.[this.loja?.id]?.idsFormasPgto?.includes(f?.id);
          // console.log(f);
          return f;
        });
    } // if
  }
  //#endregion

  //#region methods
  onSelChange(e: any) {
    // console.log(e);
    // console.log(this.formas);
    this.formasSel = (this.formas || [])
      .filter((f: IFormaPgto) => !!f?.__sel?.distribuidora);
  }
  //#endregion
}
