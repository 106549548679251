//#region ng
import { Injectable, inject } from "@angular/core";
import {
  CanActivate,
  Router
} from "@angular/router";
//#endregion

//#region services
import { ILoja } from "../_shared/_mercadeiro/_misc/_models/_interfaces/_cols";
//#endregion

//#region stores
import { AppLojasStore } from "../stores";
//#endregion

@Injectable({
  providedIn: 'root'
})
export class LojaGuard implements CanActivate {
  //#region injects
  #appLojasStore = inject(AppLojasStore);
  #router = inject(Router);
  //#endregion

  canActivate(): boolean {
    // const LOJA: ILoja = this.#appLojasStore.getState() || null;
    const LOJA_ID: string = this.#appLojasStore.lojaIdGet() || '';
    const OK: boolean = !!LOJA_ID;
    // console.log(OK, LOJA_ID);
    !OK && this.#router.navigateByUrl('/lojas');
    return OK;
  }
}
