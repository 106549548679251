<div class="modal-body p-relative">
  <mat-toolbar class="header"
               color="primary">
    <mat-toolbar-row class="d-flex justify-content-between">
      <div class="my-auto">
        <h3> <small class="o-50">Novo bairro em </small>{{ setor()?.nome }} </h3>
      </div>

      <div class="my-auto">
        <button mat-icon-button
                class="ms-3"
                (click)="onModalCloseClick()">
          <mat-icon>close</mat-icon>
        </button>
      </div>
    </mat-toolbar-row>
  </mat-toolbar>

  <!-- <p>vm {{ vm | json }}</p> -->
  <!-- <p>_bairros {{ _bairros | json }}</p> -->
  <!-- <p>excecoes() {{ excecoes() | json }}</p> -->
  <!-- <p>bairros() {{ bairros() | json }}</p> -->
  <!-- <p>setor() {{ setor() | json }}</p> -->
  <!-- <p>loja() {{ loja() | json }}</p> -->

  <div class="p-3">
    <mat-form-field appearance="outline"
                    class="w-100">
      <mat-label>Nome do bairro ou CEP</mat-label>
      <button mat-button
              matPrefix
              mat-icon-button>
        <mat-icon class="o-50">search</mat-icon>
      </button>
      <input matInput
             type="text"
             [focus]="onSearchFocusEvent$()"
             [ngModel]="searchTermAction$() | async"
             (ngModelChange)="searchTermAction$().next($event)">
      <button mat-button
              *ngIf="!!(searchTermAction$() | async)"
              matSuffix
              mat-icon-button
              (click)="searchTermAction$().next('')">
        <mat-icon class="o-50">close</mat-icon>
      </button>
    </mat-form-field>

    <mat-chip-list>
      <mat-chip *ngFor="let b of bairros(); trackBy:tids"
                [disabled]="excecoes().includes(b?._nome)"
                (click)="onBairroClick(b)">
        {{ b?._nome }}
      </mat-chip>
    </mat-chip-list>

    <div class="alert alert-light text-center"
         *ngIf="!bairros()?.length"
         role="alert">
      Nenhum bairro.
    </div>
  </div>
</div>