export * from './config/config.page';
export * from './config/config-form/config-form.component';
export * from './dashboard/dashboard.page';
export * from './departamentos/departamentos.page';
export * from './distribuidora/distribuidora.page';
export * from './formas-pgto/formas-pgto.page';
export * from './horarios/horarios.page';
export * from './login/login.page';
export * from './lojas/lojas.page';
export * from './midia/midia.page';
export * from './movimento/movimento.page';
export * from './notificacoes/notificacoes.page';
export * from './pedidos/pedidos.page';
export * from './produtos/produtos.page';
export * from './produtos/produto-icons/produto-icons.component';
export * from './produtos/p-tags/p-tags.component';
export * from './produtos/produtos/produtos.component';
export * from './operadores/operadores.page';
// export * from './operadores/operador-tipo-form/operador-tipo-form.component';
export * from './setores-entrega/setores-entrega.page';
