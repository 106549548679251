<ng-container *ngIf="vm$() | async as vm">
  <cor-mat-messages />

  <div class="container-fluid">
    <div class="d-flex justify-content-between">
      <div class="search mt-2 flex-1">
        <mat-form-field appearance="outline"
                        class="w-100">
          <mat-label>Nome</mat-label>
          <button mat-button
                  matPrefix
                  mat-icon-button>
            <mat-icon class="o-50">search</mat-icon>
          </button>
          <input matInput
                 type="text"
                 [focus]="searchFocusEvent$()"
                 [ngModel]="searchTermAction$() | async"
                 (ngModelChange)="searchTermAction$()?.next($event)">
          <button mat-button
                  *ngIf="!!(searchTermAction$() | async)"
                  matSuffix
                  mat-icon-button
                  (click)="searchTermAction$()?.next('')">
            <mat-icon class="o-50">close</mat-icon>
          </button>
        </mat-form-field>
      </div>

      <div class="ms-3 move-down">
        <cor-mat-ngx-pagination-nav pagId="galeria"
                                    size="lg"
                                    [isMobile]="true"
                                    (pageChange$)="pagPageAction$().next($event)" />
      </div>
    </div>

    <!-- <p>vm {{ vm | json }}</p> -->
    <!-- <p>vm {{ vm?.galeria?.length | json }}</p> -->
    <!-- <p>sugestao {{ sugestao }}</p> -->
    <!-- <p>excecoes {{ excecoes }}</p> -->
    <!-- <p>searchTermAction$() | async {{ searchTermAction$() | async | json }}</p> -->

    <div *ngIf="!!vm?.galeria; else carregandoItens">
      <ul class="w-cards galeria"
          *ngIf="vm?.galeria?.length">
        <li class="w-card galeria pointer"
            [class.off]="excecoes.includes(i?.id)"
            *ngFor="let i of vm?.galeria | paginate: { id: 'galeria', itemsPerPage: vm?.pag?.itemsPerPage, currentPage: vm?.pag?.page, totalItems: vm?.galeria?.length } | galLfixes; trackBy:tid_gal"
            (click)="select$.emit(i)">
          <div class="w-card--thumb">
            <img [src]="i?.__img?.thumb"
                 class="img-fluid">
          </div>

          <div class="w-card--content">
            <small>
              {{ i?.nome }}
              <br>
              <span class="o-50">[{{ i?.hash }}]</span>
            </small>
          </div>
        </li>
      </ul>

      <div class="alert alert-light text-center"
           role="alert"
           *ngIf="!vm?.galeria?.length">
        Nenhuma ocorrência.
      </div>
    </div>
    <ng-template #carregandoItens>
      <pro-html-produtos-skel />
    </ng-template>
  </div>
</ng-container>