//#region ng
import { Component, inject, signal } from '@angular/core';
import { Router } from '@angular/router';
//#endregion

//#region firebase
import {
  Auth,
  getAuth,
  onAuthStateChanged,
  Unsubscribe as AuthUnsubscribe,
} from '@angular/fire/auth';
// import {
//   doc,
//   Firestore,
//   onSnapshot,
//   Unsubscribe as FbUnsubscribe,
// } from '@angular/fire/firestore';
//#endregion

//#region mat
import { MatSnackBar } from '@angular/material/snack-bar';
//#endregion

//#region 3rd
import {
  Observable,
  Subject,
  Subscription,
  combineLatest
} from 'rxjs';
// import Swal from 'sweetalert2';
import {
  switchMap,
  map,
  tap,
  takeUntil,
  filter,
  first,
  finalize,
} from 'rxjs/operators';
//#endregion

//#region models
interface IVm {
  isLoading: boolean;
  conta: IConta | null;
  loja: ILoja | null;
  rotas: ICorRota[];
};
import { environment } from 'src/environments/environment';
import { ICorRota } from './_shared/_core/_misc/_models/_interfaces/_misc';
import {
  IConta,
  ILoja,
  IProduto
} from './_shared/_mercadeiro/_misc/_models/_interfaces/_cols';
import { TOperadorRole } from './_shared/_mercadeiro/_misc/_models/_types';
import {
  HOME_ROUTE,
  THEME
} from './models/consts';
import { CorFbMessaging } from './_shared/_core/_ng/_models/_classes';
import { ICorFbUserApi } from './_shared/_core/_misc/_models/_interfaces/_apis';
//#endregion

//#region libs
import { setIon4Theme } from './_shared/_libs/_www/_theme';
import { onDestroy } from './_shared/_core/_ng/_libs';
//#endregion

//#region services
// import { AppService } from './services';
// import { ContasService } from './_shared/_mercadeiro/_ng/_services';
import {
  CorFbAuthService,
  CorLoaderService,
  CorMessagesService,
} from './_shared/_core/_ng/_services';
import { AppService } from './services';
import {
  ContasService, LojasService, ProdutosService,
  // LojasService
} from './_shared/_mercadeiro/_ng/_services';
//#endregion

//#region stores
import {
  ContasStore,
  LojasStore
} from './_shared/_mercadeiro/_ng/_stores';
import { AppLojasStore } from './stores';
import { MatDialog } from '@angular/material/dialog';
import { GaleriaGetModal } from './modals';
import { IGaleriaGetParams } from './models/interfaces/params';
import { IProImgProdutoMap } from './_shared/_produtos/_misc/_models/_interfaces/_maps';
import { IProGaleria } from './_shared/_produtos/_misc/_models/_interfaces/_cols';
import { compareValues } from './_shared/_libs/_misc/_arrays';
//#endregion

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  //#region actions
  #destroyAction$: Subject<void> = onDestroy();
  #userAction$ = signal<Subject<ICorFbUserApi | null>>(new Subject<ICorFbUserApi | null>());
  //#endregion

  //#region publics
  vm$ = signal<Observable<IVm>>(null);
  //#endregion

  //#region privates
  #authUnsub: AuthUnsubscribe;
  #messaging: CorFbMessaging;
  #vm: IVm;
  //#endregion

  //#region injects
  #appLojasStore = inject(AppLojasStore);
  #appServ = inject(AppService);
  #authServ = inject(CorFbAuthService);
  #contasServ = inject(ContasService);
  #contasStore = inject(ContasStore);
  // #fireAuth = inject(Auth);
  #loaderServ = inject(CorLoaderService);
  #lojasServ = inject(LojasService);
  #lojasStore = inject(LojasStore);
  #modal = inject(MatDialog);
  #msgServ = inject(CorMessagesService);
  #produtosServ = inject(ProdutosService);
  #snackBar = inject(MatSnackBar);
  #router = inject(Router);
  //#endregion

  //#region constructor
  constructor() {
    this.#messaging = new CorFbMessaging(
      environment?.firebase?.config.vapidKey || '',
      environment?.firebase?.region || ''
    );
  }
  //#endregion

  //#region lifecycles
  ngOnInit() {
    this.#msgServ.send();
    setIon4Theme(THEME);

    AppService.addImgProdutoFromGaleria$()
      .pipe(takeUntil(this.#destroyAction$))
      .subscribe((produto: IProduto) => this.#addImgProdutoFromGaleria(produto));

    combineLatest([
      this.#userAction$(),
      this.#lojasServ.docs(),
    ])
      .pipe(
        // filter((user: ICorFbUserApi) => !!user?.uid),
        switchMap(
          // (user: ICorFbUserApi) => this.#contasServ.contaFromUid(user?.uid)
          ([user, lojas]) => this.#contasServ.contaFromUid(user?.uid)
            .pipe(
              tap(
                (conta: IConta) => {
                  // console.log(lojas);
                  const CONTA: IConta | null = !!conta ? this.#contasServ.fix(conta, user) : null;
                  if (!!user?.uid) {
                    // if (!!user?.uid) {
                    if (!!CONTA) {
                      const ROLE: TOperadorRole = CONTA?.operador?.role;
                      // console.log(ROLE);
                      if (['integradora', 'parceiro', 'dono', 'gerente', 'marketing'].includes(ROLE)) {
                        // TODO: Verificar se é operador dessa loja.
                        const NOME_COMPLETO: string = CONTA?.__nomeCompleto || '';
                        try {
                          this.#messaging.getTokenConta(user?.uid, NOME_COMPLETO)
                            .then((status: boolean) => {
                              console.log(status);
                              Notification.requestPermission()
                                .then(
                                  status => { console.log(status); }
                                );
                            });

                          this.#contasStore.setState(CONTA);
                          this.#snackBar.open(`Olá, ${NOME_COMPLETO}.`);
                          this.#router.url === '/login' && this.#router.navigateByUrl(HOME_ROUTE);
                        } catch (error) {
                          // console.error(error);
                          this.#msgServ.send('Problemas configurando notificações.', 'warning');
                        } // catch

                        // console.log(conta?.operador?._role);
                        let l = this.#lojasServ.fixes(lojas) || [];
                        switch (conta?.operador?._role) {
                          case 'agelo':
                            l = l.filter((l: ILoja) => l?.parceiro?.id === conta?.id);
                            break;

                          case 'integradora':
                          case 'integrador':
                            l = l.filter((l: ILoja) => l?.integradora?.id === conta?.operador?.integradora?.id);
                            break;

                          case 'dono':
                            l = l.filter((l: ILoja) => l?.rede?.id === conta?.operador?.rede?.id);
                            break;

                          default:
                            l = l.filter((l: ILoja) => conta?.operador?._lojasIds.includes(l?.id));
                            break;
                        } // switch
                        // console.log(l);
                        l?.length === 1 && this.#appLojasStore.setState(l?.at(0), THEME);
                        this.#appLojasStore.setLojasContaState(l);

                        this.#router.url === '/login' && this.#router.navigateByUrl(HOME_ROUTE);
                      } else {
                        this.#badUser('Conta inválida.');
                      } // else
                    } else {
                      this.#badUser('Conta não encontrada.');
                    } // else
                  } else {
                    this.#contasStore.setState(null);
                  } // else
                }
              ),
              takeUntil(this.#destroyAction$)
            )
        )
      )
      .subscribe();

    this.vm$.set(combineLatest(
      [
        this.#loaderServ.isLoading$,
        this.#contasStore.contaStateChanged$,
        this.#lojasStore.lojaStateChanged$
      ])
      .pipe(
        map(
          ([isLoading, conta, loja]) => {
            // console.log(conta);
            // console.log(loja);
            const ROTAS: ICorRota[] = this.#appServ
              .getRotas(
                conta?.operador?.role,
                conta?.operador?.integradora?.id,
                loja?.distribuidora?.status
              )
              .filter((r: ICorRota) => !!r?.__valid?.status);
            this.#vm = {
              isLoading,
              conta,
              rotas: ROTAS,
              loja
            };
            // console.log(this.#vm);
            return this.#vm;
          }
        )
      ),
    );

    this.#authUnsub && this.#authUnsub();
    this.#authUnsub = onAuthStateChanged(
      getAuth(),
      (user: any) => { /* console.log(user); */ this.#userAction$()?.next(user); }
    );
  }

  ngOnDestroy() {
    !!this.#authUnsub && this.#authUnsub();
  }
  //#endregion

  //#region functions
  tid_rot(index: any, item: ICorRota): string { return item?.titulo || ''; }

  #badUser(text: string = '') {
    !!text && this.#msgServ.send(text, 'warning', 'dialog');
    this.#logout('/login');
  }

  async #logout(goto: string = '') {
    // console.log(getAuth()?.currentUser);
    this.#appLojasStore.setState(null, THEME);
    this.#contasStore.setState(null);
    // if (!!getAuth()?.currentUser) {
    const NOME: string = this.#contasStore.getState()?.nome?.nome || 'Visitante';
    this.#snackBar.open(`${NOME} saiu.`);
    await this.#authServ.logout();
    // } // if

    if (!!goto) {
      if (goto === 'home') {
        this.#router.navigateByUrl(HOME_ROUTE);
      } else {
        this.#router.navigateByUrl(goto);
      } // else
    } // if
  }

  #addImgProdutoFromGaleria(produto: IProduto) {
    // console.log(produto);    
    const DIALOG_EXISTS = this.#modal.getDialogById('img-add');
    if (!DIALOG_EXISTS) {
      const DIALOG_REF: any = this.#modal.open(
        GaleriaGetModal,
        {
          panelClass: 'no-border-dialog-container',
          id: 'img-add',
          data: <IGaleriaGetParams>{
            produto: produto,
            excecoes: (produto.imgs || [])
              .map((i: IProImgProdutoMap) => i?.id || ''),
            sugestao: produto?.nome || '',
          }
        }
      );

      const SUB: Subscription = DIALOG_REF
        .afterClosed()
        .pipe(first(), finalize(() => SUB?.unsubscribe()))
        .pipe(filter((resp: IProGaleria) => !!resp))
        .subscribe(async (resp: IProGaleria) => {
          const PRODUTO_ID: string = produto?.id || '';
          // console.log(resp);
          let imgs: IProImgProdutoMap[] = (produto?.imgs || [])
            .concat([resp])
            .sort(compareValues('_pos.val'));

          const CHANGES: Partial<IProduto> = { imgs };
          // console.log(CHANGES);

          if (
            !!produto?.id
            && !!this.#vm?.loja?.__idInfo?.lojaPath
          ) {
            const SUB: Subscription = this.#loaderServ.showUntilCompleted(
              this.#produtosServ.update(this.#vm?.loja?.__idInfo?.lojaPath, PRODUTO_ID, CHANGES)
                .pipe(first(), finalize(() => SUB?.unsubscribe()))
            )
              .subscribe(() => {
                /* const PRODUTO: IProduto = {
                  // ...produto,
                  ...CHANGES,
                  id: PRODUTO_ID,
                }; */
                // console.log(PRODUTO);
                this.#snackBar.open(`Imagem adicionada ao produto ${produto?.nome}.`);
                // this.onModalCloseClick(PRODUTO);
              });
          } // if
        });
    } // if
  }
  //#endregion

  //#region methods
  onLogoutClick() {
    this.#logout('/login');
  }
  //#endregion

  /*
  //#region publics
  isLoading: boolean;
  loja: ILoja;
  rotas: ICorRota[] = [];
  //#endregion

  //#region conta
  private _conta: IConta;
  set conta(val: IConta) {
    this._conta = val;
    const ROLE: TOperadorRole = val?.operador?.role;
    const INTEGRADORA_ID: string = val?.operador?.integradora?.id
    if (!!ROLE) {
      this.rotas = this.#appServ
        .getRotas(ROLE, INTEGRADORA_ID)
        .filter((r: ICorRota) => !!r?.__valid?.status);
      this._verificaRotasLoja();
    } else {
      this.rotas = [];
    } // else
    // console.log(this.rotas);
    this.#contasStore.setState(val);
  }
  get conta(): IConta {
    return this._conta;
  }
  //#endregion

  //#region privates
  private #authUnsub: AuthUnsubscribe;
  private #messaging: CorFbMessaging;
  private _subs: Subscription[] = [];
  //#endregion

  //#region constructor
  constructor(
    private #appServ: AppService,
    private #authServ: CorFbAuthService,
    private #contasServ: ContasService,
    private #contasStore: ContasStore,
    private #fireAuth: Auth,
    private #loaderServ: CorLoaderService,
    private #lojasServ: LojasService,
    private #snackBar: MatSnackBar,
    private #router: Router,
    private _storageServ: StorageService,
  ) {
    this.#messaging = new CorFbMessaging(
      environment?.firebase?.config?.vapidKey,
      environment?.firebase?.region
    );
  }
  //#endregion

  //#region lifecycles
  ngOnInit() {
    setIon4Theme(THEME);

    this._subs.push(
      this.#loaderServ.isLoading$
        .subscribe((status: boolean) => this.isLoading = status),

      AppService.lojaStateChanged$
        .subscribe((loja: ILoja) => this.loja = loja),
    );

    this.#authUnsub && this.#authUnsub();
    this.#authUnsub = onAuthStateChanged(
      getAuth(),
      user => {
        // console.log(user);
        this._buscaConta(user);
      }
    );

    const LOJA_ID: string = this._storageServ.lojaIdGet() || '';
    // console.log(LOJA_ID);
    if (!!LOJA_ID) {
      const SUB: Subscription = this.#lojasServ.doc(LOJA_ID)
        .pipe(
          first(),
          finalize(() => SUB && SUB.unsubscribe())
        )
        .subscribe(
          async (loja: ILoja) => {
            // console.log(loja);
            this.#appServ.setLojaState(loja);
            this._verificaRotasLoja()
          });
    } // if
  };

  ngOnDestroy() {
    this.#authUnsub && this.#authUnsub();
    this._subs.forEach((s: Subscription) => s && s.unsubscribe());
  }
  //#endregion

  //#region functions
  private _verificaRotasLoja() {
    if (
      !!this.loja
      && (this.rotas || []).length
    ) {
      this.rotas = this.rotas
        .filter(
          (r: ICorRota) => {
            // console.log(r);
            if (r?.url === '/distribuidora') {
              // console.log(r);
              return !!this.loja?.distribuidora?.status;
            } else if (r?.url === '/setoresEntrega') {
              // console.log(r);
              return !this.loja?.distribuidora?.status;
            } // else
            return true;
          }
        );
    } // if
  }

  tid(index: any, item: any): number { return item?.id || ''; }

  private #badUser(
    // logout: boolean,
    text: string = '',
  ) {
    // this.lojas = null;
    // this._integradoraLojasServ.setState(null);
    this.conta = null;
    this.#appServ.setLojaState(null);
    this._storageServ.lojaIdRemove();
    this.#logout();
    text && Swal.fire({
      icon: "error",
      title: "Ooops...",
      html: `<h2>${text}</h2>`,
      backdrop: false,
      confirmButtonColor: NO_COLOR,
    })
  }

  private async #logout(goto: string = '') {
    await this.#authServ.logout();
    if (goto) {
      if (goto === 'home') {
        this.#router.navigateByUrl(HOME_ROUTE);
      } else {
        this.#router.navigateByUrl(goto);
      } // else
    } // if
  }

  private _buscaConta(user: any) {
    // console.log(logout, user);
    const UID: string = user?.uid || '';
    if (UID) {
      const SUB: Subscription = this.#contasServ.contaFromUid(UID)
        .pipe(
          first(),
          finalize(() => SUB?.unsubscribe())
        )
        .subscribe(
          (conta: IConta) => {
            // console.log(conta);
            if (!!conta) {
              const ROLE: TOperadorRole = conta?.operador?.role;
              // console.log(ROLE);
              if (['integradora', 'parceiro', 'dono', 'gerente', 'marketing'].includes(ROLE)) {
                this.conta = conta ? this.#contasServ.fix(conta) : null;
                const NOME_COMPLETO: string = this.conta?.__nomeCompleto || '';
                try {
                  this.#messaging.getTokenConta(UID, NOME_COMPLETO)
                    .then((status: boolean) => {
                      // console.log(status);
                      Notification.requestPermission()
                        .then(
                          status => { console.log(status); }
                        );
                    });
                  this.#snackBar.open(`Olá, ${NOME_COMPLETO}.`);
                  this.#router.navigateByUrl(HOME_ROUTE);
                } catch (error) {
                  console.error(error);
                } // catch
                this.#router.navigateByUrl(HOME_ROUTE);
              } else {
                this.#badUser('Conta inválida.');
              } // else
            } else {
              this.#badUser('Conta não encontrada.');
            } // else
          },
          (error: any) => {
            error && console.error(error)
            this.#badUser();
          }
        );
    } else {
      this.#badUser();
    } // else
  }
  //#endregion

  //#region methods
  onLogoutClick() {
    this.#logout('/login');
  }
  //#endregion
  */
}
