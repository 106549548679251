//#region ng
import {
  Pipe,
  PipeTransform,
  inject
} from '@angular/core';
//#endregion

//#region models
import { IProGaleria } from '../../_misc/_models/_interfaces/_cols';
//#endregion

//#region services
import { ProGaleriaService } from '../_services';
//#endregion

@Pipe({ name: 'galLfixes' })
export class ProGalLfixesPipe implements PipeTransform {
  //#region injects
  #galeriaServ = inject(ProGaleriaService);
  //#endregion

  transform(val: IProGaleria[]): IProGaleria[] {
    return this.#galeriaServ.lfixes(val);
  }
}
