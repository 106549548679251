//#region models
import { tcenter } from '../../../../_libs/_misc/_strings';
//#endregion

export class Cupom {
  #lcupom: number;
  #sep: string;

  constructor(
    lcupom: number,
    sep: string = ' '
  ) {
    this.#lcupom = lcupom >= 0 ? lcupom : 40;
    this.#sep = sep;
    // console.log(this.#lcupom, this.#sep);
  }

  div(sep: string = '-'): string {
    // console.log(sep, this.#lcupom);
    return sep.repeat(this.#lcupom);
  }

  center(
    txt: string,
    sep: string = ' '
  ): string {
    return tcenter(txt, this.#lcupom, sep);
  }

  txt(txt: string): string {
    return txt.trim().slice(0, this.#lcupom);
  }

  pre(label: string, txt: string): string {
    return (label.trim() + ' ' + txt)
      .padEnd(this.#lcupom, this.#sep)
      .slice(0, this.#lcupom);
  }

  split(
    t1: string,
    t2: string,
    fixed1: boolean
  ): string[] {
    let s1: string = t1.trim();
    let s2: string = t2.trim();

    if (fixed1) {
      const LEN: number = this.#lcupom - t1.length - 1;
      s2 = t2.length <= LEN
        ? t2.padStart(LEN, this.#sep)
        : t2.slice(0, LEN);
    } else {
      const LEN: number = this.#lcupom - t2.length - 1;
      s1 = t1.length <= LEN
        ? t1.padEnd(LEN, this.#sep)
        : t1.slice(0, LEN);
    } // else

    return [s1, s2];
  }

  col2(
    t1: string,
    t2: string,
  ): string[] {
    t1 = t1.trim();
    t2 = t2.trim();

    const L: number = Math.trunc(this.#lcupom / 2);
    const L1: number = L;
    const L2: number = L + this.#lcupom % 2;

    return [
      tcenter(t1, L1, this.#sep),
      tcenter(t2, L2, this.#sep),
    ];
  }

  col3(
    t1: string,
    t2: string,
    t3: string
  ): string[] {
    t1 = t1.trim();
    t2 = t2.trim();
    t3 = t3.trim();

    const L: number = Math.trunc(this.#lcupom / 3);
    const L1: number = L;
    const L2: number = L + this.#lcupom % 3;
    const L3: number = L;

    return [
      tcenter(t1, L1, this.#sep),
      tcenter(t2, L2, this.#sep),
      tcenter(t3, L3, this.#sep),
    ];
  }
}
