//#region ng
import {
  Component, inject, signal,
} from '@angular/core';
import { Router } from '@angular/router';
//#endregion

//#region firebase
import { Firestore } from '@angular/fire/firestore';
//#endregion

//#region 3rd
import {
  BehaviorSubject,
  Observable,
  Subject,
  Subscription,
  combineLatest
} from 'rxjs';
import {
  filter,
  map,
  takeUntil
} from 'rxjs/operators';
import {
  BsModalRef,
  BsModalService
} from 'ngx-bootstrap/modal';
//#endregion

//#region models
interface IVm {
  pedidos: IPedido[];
};
import {
  HOME_ROUTE,
  KEY_OFFSET_PEDIDOS,
  PEDIDOS_POR_PAG
} from '../../models/consts';
import {
  IConta,
  ILoja,
  IPedido
} from '../../_shared/_mercadeiro/_misc/_models/_interfaces/_cols';
// import { TOperadorRole } from '../../_shared/_mercadeiro/_misc/_models/_types';
import { CorAngularFirePaginator } from '../../_shared/_core/_ng/_models/_classes';
//#endregion

//#region libs
import { guid } from '../../_shared/_libs/_misc/_strings';
import { onDestroy } from '../../_shared/_core/_ng/_libs';
//#endregion

//#region services
import {
  ContasService,
  LojasService,
  PedidosService
} from '../../_shared/_mercadeiro/_ng/_services';
import {
  AppService,
  ROTAS
} from '../../services';
//#endregion

//#region stores
import {
  ContasStore,
  LojasStore
} from '../../_shared/_mercadeiro/_ng/_stores';
//#endregion

//#region components
import { AppPedidoDetalhesModal } from '../../modals';
import { compareValues } from 'src/app/_shared/_libs/_misc/_arrays';
//#endregion

@Component({
  selector: 'pedidos-page',
  templateUrl: './pedidos.page.html',
  styleUrls: ['./pedidos.page.scss']
})
export class PedidosPage {
  //#region actions
  #destroyAction$: Subject<void> = onDestroy();
  pagItemsPerPageAction$ = signal<BehaviorSubject<string>>(new BehaviorSubject<string>(PEDIDOS_POR_PAG));
  #pedidosAction$ = new BehaviorSubject<IPedido[]>(null);
  //#endregion

  //#region publics
  paginator: CorAngularFirePaginator<IPedido>;
  offsetStorageKey = signal<string>(KEY_OFFSET_PEDIDOS).asReadonly();
  vm$ = signal<Observable<IVm>>(null);
  // pag = signal<IPagInfo>(null);
  //#endregion

  //#region privates
  #bsPedidoModalRef: BsModalRef;
  #lojaPath: string;
  #pedidosUnsub: Subscription;
  //#endregion

  //#region injects
  #bsModalServ = inject(BsModalService);
  #db = inject(Firestore);
  // #contasStore = inject(ContasStore);
  #lojasStore = inject(LojasStore);
  #pedidosServ = inject(PedidosService);
  //#endregion

  //#region lifecycles
  ngOnInit() {
    combineLatest([
      this.#lojasStore.lojaStateChanged$,
      this.pagItemsPerPageAction$(),
    ])
      .pipe(
        filter(([loja, itemsPerPage]) => !!loja),
        map(([loja, itemsPerPage]) => {
          // console.log(loja, itemsPerPage);
          this.#lojaPath = loja?.__idInfo?.lojaPath || '';
          // console.log(this.#lojaPath);
          if (!!this.#lojaPath) {
            this.paginator = new CorAngularFirePaginator(
              this.#db,
              `${this.#lojaPath}/pedidos`,
              +itemsPerPage,
              [{ field: '_criadoEm', direction: 'desc' }],
              [],
              docs => {
                // console.log(docs);
                this.#pedidosAction$.next(
                  this.#pedidosServ.fixes(docs)
                    .sort(compareValues('_criadoEm', 'desc'))
                );
              },
              false
            );

            this.#pedidosUnsub?.unsubscribe();
            this.#pedidosUnsub = this.paginator.items$
              .subscribe(
                (pedidos: IPedido[]) => {
                  // console.log(pedidos);
                  const PEDIDOS: IPedido[] = this.#pedidosServ.fixes(pedidos)
                    .filter((e: IPedido) => !!e?.__displayInPagination)
                    .sort(compareValues('_criadoEm', 'desc'));
                  this.#pedidosAction$.next(PEDIDOS);
                }
              );
          } // if
        }),
        takeUntil(this.#destroyAction$)
      )
      .subscribe();

    this.vm$.set(
      this.#pedidosAction$
        .pipe(
          map((pedidos: IPedido[]) => {
            // console.log(pedidos);
            const VM: IVm = {
              pedidos
            };
            // console.log(VM);
            return VM;
          })
        )
    );
  }

  ngOnDestroy() {
    this.#pedidosUnsub?.unsubscribe();
    this.paginator?.unsub();
  }
  //#endregion

  //#region functions
  tid_ped(index: any, item: IPedido): string { return item?.id || ''; }
  //#endregion

  //#region methods
  onDetalhesClick(pedido: IPedido) {
    // console.log(pedido);
    const ID_MODAL: number = +guid(10, 10);
    this.#bsPedidoModalRef?.hide();
    this.#bsPedidoModalRef = this.#bsModalServ.show(
      AppPedidoDetalhesModal,
      {
        class: 'modal-dialog-centered modal-lg',
        id: ID_MODAL,
        initialState: {
          isMobile: false,
          idModal: ID_MODAL,
          pedido,
          lojaPath: this.#lojaPath
        },
      });

    /* const ID_MODAL: number = +guid(10, 10);
    /* this._bsCarrinhoModalRef = * / this.#bsModalServ.show(
        AppPedidoDetalhesModal,
        {
          class: 'modal-dialog-centered modal-lg',
          id: ID_MODAL,
          initialState: {
            isMobile: false,
            idModal: ID_MODAL,
            pedido: p,
            lojaPath: this.#lojaPath
          },
        }); */
  }
  //#endregion

  /*
  //#region publics
  conta: IConta;
  paginator: CorAngularFirePaginator<IPedido>;
  pedidos: IPedido[];
  storageKey: string = KEY_OFFSET_PEDIDOS;
  //#endregion

  //#region loja
  private _loja: ILoja;
  set loja(val: ILoja) {
    this._loja = !!val ? this._lojasServ.fix(val) : null;
    // console.log(val);
    this._refresh();
  }
  get loja(): ILoja {
    return this._loja;
  }
  //#endregion

  //#region itemsPerPage
  private _itemsPerPage: string = '';
  set itemsPerPage(val: string) {
    this._itemsPerPage = val;
    this._refresh();
  }
  get itemsPerPage(): string {
    return this._itemsPerPage;
  }
  //#endregion

  //#region privates
  private #lojaPath: string;
  private #pedidosUnsub: Subscription;
  private _subs: Subscription[] = [];
  //#endregion

  //#region constructor
  constructor(
    private #bsModalServ: BsModalService,
    private _contasServ: ContasService,
    private #contasStore: ContasStore,
    private #db: Firestore,
    private _lojasServ: LojasService,
    private #pedidosServ: PedidosService,
    private _router: Router,
  ) { }
  //#endregion

  //#region lifecycles
  ngOnInit(): void {
    this._subs.push(
      AppService.lojaStateChanged$
        .subscribe((loja: ILoja) => this.loja = loja),

      this.#contasStore.contaStateChanged$
        .subscribe(async (conta: IConta) => {
          // console.log(conta);
          this.conta = conta ? this._contasServ.fix(conta) : null;
          if (conta) {
            // const ROLE: TOperadorRole = get(conta, 'operador.role');
            !ROTAS?.pedidos?.__valid?.status &&
              this._router.navigateByUrl(HOME_ROUTE);
          } // if
        }),
    );
  }

  ngOnDestroy(): void {
    this._subs.forEach((s: Subscription) => s && s.unsubscribe());
    this.#pedidosUnsub && this.#pedidosUnsub.unsubscribe();
    !!this.paginator && this.paginator.unsub();
  }
  //#endregion

  //#region functions
  tid_ped(index: any, item: IPedido): string { return item?.id || ''; }

  private _refresh() {
    this.#lojaPath = this.loja?.__idInfo?.lojaPath || '';
    // console.log(this.#lojaPath);
    if (!!this.#lojaPath) {
      this.paginator = new CorAngularFirePaginator(
        this.#db,
        `${this.#lojaPath}/pedidos`,
        Number(this.itemsPerPage || '12'),
        [{ field: '_criadoEm', direction: 'desc' }],
        [/* { field: 'conta.id', op: '==', val: get(this.conta, 'id') || '' } * /],
        docs => {
          // console.log(docs);
          // !!docs && docs.forEach(
          //   (d: Partial<IPedido>) => {
          //     // console.log(d);
          //     if (!(this.pedidos || []).find((p: IPedido) => get(p, 'id') === get(d, 'id'))) {
          //       !!this.pedidos && this.pedidos.push(this.#pedidosServ.fix(d));
          //     } else {
          //       this.pedidos = (this.pedidos || [])
          //         .map(
          //           (p: IPedido) => {
          //             if (get(p, 'id') === get(d, 'id')) {
          //               return this.#pedidosServ.fix(d);
          //             } else {
          //               return p;
          //             } // else
          //           });
          //     } // else
          //   }
          // );
        },
        false
      );

      this.#pedidosUnsub && this.#pedidosUnsub.unsubscribe();
      this.#pedidosUnsub = this.paginator.items$
        .subscribe(
          (pedidos: IPedido[]) => {
            this.pedidos = this.#pedidosServ.fixes(pedidos)
              .filter((e: IPedido) => !!e?.__displayInPagination);
            // console.log(JSON.stringify(this.pedidos));
          }
        );

      /* this.#pedidosUnsub && this.#pedidosUnsub.unsubscribe();
      this.#pedidosUnsub = this.paginator.items$
        .subscribe(
          (pedidos: IPedido[]) => {
            // console.log(pedidos);
            const PEDIDOS: IPedido[] = (pedidos || [])
              .filter((e: IPedido) => !!get(e, '__displayInPagination'));
            this.pedidos = (this.pedidos || []).concat(PEDIDOS);
          }
        ); * /
    } // if
  }
  //#endregion

  //#region methods
  onDetalhesClick(p: IPedido) {
    // console.log(p);
    const ID_MODAL: number = +guid(10, 10);
    /* this._bsCarrinhoModalRef = * / this.#bsModalServ.show(
      AppPedidoDetalhesModal,
      {
        class: 'modal-dialog-centered modal-lg',
        id: ID_MODAL,
        initialState: {
          isMobile: false,
          idModal: ID_MODAL,
          pedido: p,
          lojaPath: this.#lojaPath
        },
      });
  }
  //#endregion
  */
}
