//#region ng
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
//#endregion

//#region mat
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatToolbarModule } from '@angular/material/toolbar';
//#endregion

//#region modules
// import { CorMatComponentsModule } from '../../_shared/_core/_ng-ui/_mat/cor-mat-components.module';
import { MePipesModule } from '../../_shared/_mercadeiro/_ng/_pipes/me-pipes.module';
import { MeMatComponentsModule } from '../../_shared/_mercadeiro/_ng-ui/_mat/me-mat-components.module';
//#endregion

//#region components
import { AppPedidoDetalhesModal } from '..';
//#endregion

@NgModule({
  imports: [
    CommonModule,
    // CorMatComponentsModule,
    MatButtonModule,
    MatIconModule,
    MatToolbarModule,
    MeMatComponentsModule,
    MePipesModule
  ],
  declarations: [
    AppPedidoDetalhesModal,
  ],
  exports: [
    AppPedidoDetalhesModal,
  ],
})
export class AppPedidoDetalhesModalModule { }
