//#region ng
import { Component } from '@angular/core';
//#endregion

@Component({
  selector: 'login-page',
  templateUrl: './login.page.html',
  styleUrls: ['./login.page.scss']
})
export class LoginPage { }
