<!-- <p>loja {{ loja | json }}</p> -->

<div *ngIf="!!formas()">
  <!-- <p>formas {{ formas | json }}</p> -->
  <table class="table"
         *ngIf="!!formas()?.length; else nenhumForma">
    <thead>
      <tr>
        <th scope="col"></th>
        <th scope="col">Forma</th>
        <th scope="col"
            class="text-end">
        </th>
      </tr>
    </thead>

    <tbody>
      <tr *ngFor="let f of formas(); trackBy:tid_for">
        <td class="img text-center">
          <img [src]="f?.__img"
               class="img-fluid">
        </td>

        <td>{{ f?._nome }}</td>

        <td class="text-end">
          <button mat-flat-button
                  (click)="onFormaEditClick(f)">
            <mat-icon>edit</mat-icon>
          </button>
        </td>
      </tr>
    </tbody>
  </table>
  <ng-template #nenhumForma>
    <div class="alert alert-light text-center"
         role="alert">
      Nenhuma forma de pagamento.
    </div>
  </ng-template>
</div>
