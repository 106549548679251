<div class="modal-body p-relative">
  <mat-toolbar class="header"
               color="primary">
    <mat-toolbar-row class="d-flex justify-content-between">
      <div class="my-auto">
        <h3> {{ produto()?.nome }} </h3>
      </div>

      <div class="my-auto">
        <button mat-icon-button
                (click)="onModalCloseClick()">
          <mat-icon>close</mat-icon>
        </button>
      </div>
    </mat-toolbar-row>
  </mat-toolbar>

  <!-- <p>tab {{ tab | json }}</p> -->
  <!-- <p>produto {{ produto | json }}</p> -->

  <div class="p-3">
    <div class="segment mb-4">
      <ul class="nav nav-pills justify-content-center">
        <li class="nav-item pointer"
            (click)="tab.set('produto')">
          <a class="nav-link"
             [class.active]="tab() === 'produto'">
            Produto
          </a>
        </li>

        <li class="nav-item pointer"
            (click)="tab.set('imgs')">
          <a class="nav-link"
             [class.active]="tab() === 'imgs'">
            Imagens
            <!-- <span class="o-50"> ( {{ integradora?._lojasRefs?.length }} )</span> -->
          </a>
        </li>
      </ul>
    </div>

    <div [ngSwitch]="tab()">
      <div *ngSwitchCase="'imgs'"
           class="m-auto"
           style="max-width: 700px">
        <div class="container-fluid">
          <div class="row">
            <div class="col-12 col-sm-8 offset-sm-2">
              <me-mat-produto-imgs [produto]="produto()" />
            </div>
          </div>
        </div>
      </div>

      <div *ngSwitchDefault>
        <me-mat-produto-info [produto]="produto()" />
      </div>
    </div>
  </div>
</div>
