<!-- <p>credenciados() {{ credenciados() | json }}</p> -->

<div *ngIf="!!credenciados()">
  <table class="table table-striped"
         *ngIf="!!credenciados()?.length; else nenhumCredenciado">
    <thead>
      <tr>
        <th scope="col"></th>

        <th scope="col">Nome / E-mail </th>

        <th scope="col"></th>
      </tr>
    </thead>

    <tbody>
      <tr *ngFor="let c of credenciados(); trackBy:tid_con"
          [class.off]="!c?.ativo?._status">
        <td>
          <img [src]="c?.__urlFoto"
               alt=""
               class="img-fluid avatar"
               *ngIf="c?.__urlFoto">
        </td>

        <td>
          {{ c?.__nomeCompleto }}
          <div *ngIf="c?.email">
            <small class="o-50">
              {{ c?.email }}
            </small>
          </div>
        </td>

        <td>
          <div class="d-flex">
            <span class="ms-auto">
              <button mat-icon-button
                      matTooltip="Editar credenciado"
                      (click)="onCredenciadoEditClick(c)">
                <mat-icon>edit</mat-icon>
              </button>

              <button mat-icon-button
                      class="danger tint"
                      matTooltip="Remover credenciado"
                      (click)="onCredenciadoDelClick(c)">
                <mat-icon>delete</mat-icon>
              </button>
            </span>
          </div>
        </td>
      </tr>
    </tbody>
  </table>
  <ng-template #nenhumCredenciado>
    <div class="alert alert-light text-center"
         role="alert">
      Nenhum credenciado.
    </div>
  </ng-template>
</div>
