//#region ng
import {
  inject,
  Injectable
} from '@angular/core';
//#endregion

//#region firebase
import {
  addDoc,
  collection,
  // collectionData,
  deleteDoc,
  doc,
  DocumentReference,
  Firestore,
  getDoc,
  getDocs,
  limit,
  query,
  Query,
  setDoc,
  where,
} from '@angular/fire/firestore';
//#endregion

//#region 3rd
import {
  from,
  Observable,
  ObservableInput,
  of,
  throwError,
} from "rxjs";
import {
  catchError,
  map,
  tap,
  // tap,
} from "rxjs/operators";
//#endregion

//#region models
import { environment } from 'src/environments/environment';
import { IProMarca, IProProduto } from '../../_misc/_models/_interfaces/_cols';
import {
  IMG_NO_IMG,
  ODOCS_TAG,
  THUMB_NO_IMG
} from '../../../_core/_misc/_models/consts';
//#endregion

//#region libs
import { checkImg } from '../../../_libs/_misc/_imgs';
//#endregion

//#region services
import { CorMessagesService } from '../../../_core/_ng/_services';
import { TNullable } from '../../../_core/_misc/_models/_types';
//#endregions

@Injectable({
  providedIn: 'root'
})
export class ProMarcasService {
  //#region injects
  #db = inject(Firestore);
  #msgServ = inject(CorMessagesService);
  //#endregion

  //#region misc
  fix(row: Partial<IProMarca>): IProMarca {
    return {
      // id
      // objectID?: string;
      id: row?.id || '',

      // user
      isLinha: !!row?.isLinha,
      img: {
        _res: Number(row?.img?._res) || 0,
        __img: row?.img?.__img || IMG_NO_IMG,
      },
      marca: !!row?.marca,
      nome: row?.nome || '',
      sel: !row?.sel,

      // system
      _criadoEm: row?._criadoEm || null,
      _hasProdutos: row?._hasProdutos || null,
      _modificadoEm: row?._modificadoEm || null,
      _ver: Number(row?._ver) || 0,

      // realtime
      __hasProdutos: {
        linha: !!row?.__hasProdutos?.linha,
        marca: !!row?.__hasProdutos?.marca,
        self: !!row?.__hasProdutos?.self,
      },
      __img: {
        img: row?.__img?.img || IMG_NO_IMG,
        thumb: row?.__img?.thumb || THUMB_NO_IMG,
      },
    };
  }

  lfix(row: Partial<IProMarca>): Promise<IProMarca> {
    // const R: IProMarca = this.fix(row);
    return new Promise(
      (resolve, reject) => {
        const MARCA_ID: string = row?.id || '';
        if (!MARCA_ID) {
          return resolve(this.fix(row)); // reject(new Error('Nenhuma marca indicada.'));
        } else {
          const IMG: string = `${environment.firebase.conecdata.storage.root}/galeria%2fimg__${MARCA_ID}.jpg?alt=media`;
          const THUMB: string = `${environment.firebase.conecdata.storage.root}/galeria%2fthumb__${MARCA_ID}.jpg?alt=media`;
          // console.log(IMG);
          checkImg(
            IMG,
            () => resolve(
              this.fix(
                {
                  ...row,
                  __img: {
                    ...row?.__img,
                    img: IMG,
                    thumb: THUMB
                  }
                }
              )
            ),
            () => resolve(
              this.fix(
                {
                  ...row,
                  __img: {
                    ...row?.__img,
                    img: IMG_NO_IMG,
                    thumb: THUMB_NO_IMG
                  }
                }
              )
            )
          );
        }
      }
    );
  }

  fixes(docs: Partial<IProMarca>[]): IProMarca[] {
    return (docs || []).map((doc: Partial<IProMarca>) => this.fix(doc));
  }
  //#endregion

  //#region C
  add(changes: any): Observable<any> {
    // if (get(changes, 'nome')) {
    // console.log(pedido);
    const PATH: string = '/_marcas';
    return (from(addDoc(collection(this.#db, PATH), changes)) as Observable<any>)
      .pipe(
        // map(() => { throw new Error(`Erro lendo ${PATH}.`); }),
        catchError<any, ObservableInput<any>>(
          (err: any) => this.#msgServ.onCatchError(err, 'Erro criando marca.')
        )
      );
  }
  //#endregion

  //#region R
  marcaHasProdutos(
    marcaId: string,
    isLinha?: boolean
  ): Observable<boolean> {
    // console.log(marcaId, isLinha);
    if (!!marcaId) {
      const PATH: string = '/__produtos';
      const Q: Query = query(
        collection(this.#db, PATH),
        where(!!isLinha ? 'marca.linha._id' : 'marca._id', '==', marcaId),
        limit(1)
      );
      /* return (collectionData(q, { idField: 'id' }) as Observable<IProProduto[]>) */
      return (from(getDocs(Q)) as Observable<any>)
        .pipe(
          // tap((colSnap: any) => console.log(colSnap)),
          map((colSnap: any) => {
            // console.log(colSnap);
            const MARCAS: IProMarca[] = [];
            colSnap.forEach((doc: any) => MARCAS.push(this.fix({ ...doc?.data(), id: doc?.id })));
            // console.log(MARCAS);
            return !!MARCAS?.length;
          }),
          // map(() => { throw new Error(`Erro lendo ${PATH}.`); }),
          catchError<boolean, ObservableInput<boolean>>(
            (err: any) => this.#msgServ.onCatchError(err, 'Erro carregando marcas.')
          )
        );
    } // if
    return of(false); // return throwError(() => 'Nenhuma marca indicada.');
  }

  marcaFromNome(nome: string): Observable<IProMarca> {
    const NOME: string = (nome || '')?.trim(); // nome.toUpperCase();
    if (!!NOME) {
      const PATH: string = '/_marcas';
      // console.log(PATH, NOME);
      const Q: Query = query(collection(this.#db, PATH), where('nome', '==', NOME), limit(1));
      // return (collectionData(q, { idField: 'id' }) as Observable<IProMarca[]>)
      return (from(getDocs(Q)) as Observable<any>)
        .pipe(
          map((colSnap: any) => {
            // console.log(colSnap);
            const MARCAS: IProMarca[] = [];
            colSnap.forEach((doc: any) => MARCAS.push(this.fix({ ...doc?.data(), id: doc?.id })));
            // console.log(MARCAS);
            return !!MARCAS?.length ? MARCAS[0] : null;
          }),
          // tap((docs: any) => console.log(docs)),
          // map(() => { throw new Error(`Erro lendo ${PATH}.`); }),
          catchError<IProMarca, ObservableInput<IProMarca>>(
            (err: any) => this.#msgServ.onCatchError(err, 'Erro procurando marca.')
          )
        );
    } // if
    return of(null); // return throwError(() => 'Nenhum nome indicado.');
  }

  docs(): Observable<IProMarca[]> {
    const PATH: string = `_marcas/${ODOCS_TAG}`;
    // return (docData(doc(this.#db, PATH)) as Observable<IProMarca[]>)
    return (from(getDoc(doc(this.#db, PATH))) as Observable<any>)
      .pipe(
        map((doc: any) => this.fixes(Object.values(doc?.data()?._odocs || {}) || [])),
        // map(() => { throw new Error(`Erro lendo ${PATH}.`); }),
        catchError<Partial<IProMarca[]>, ObservableInput<Partial<IProMarca[]>>>(
          (err: any) => this.#msgServ.onCatchError(err, 'Erro carregando marcas.')
        )
      );
  }
  //#endregion

  //#region U
  update(
    idMarca: string,
    changes: Partial<IProMarca>
  ): Observable<any> {
    if (!!idMarca) {
      const PATH: string = `/_marcas/${idMarca}`;
      // console.log(PATH);
      return from(setDoc(doc(this.#db, PATH), changes, { merge: true }))
        .pipe(
          // map(() => { throw new Error(`Erro modificando ${PATH}.`); }),
          catchError<void, ObservableInput<void>>(
            (err: any) => this.#msgServ.onCatchError(err, 'Erro modificando marca.')
          )
        );
    } // if
    return throwError(() => 'Nenhuma marca indicada.');
  }
  //#endregion

  //#region D
  delete(
    marcaId: string,
  ): Observable<any> {
    if (!!marcaId) {
      const PATH: string = `_marcas/${marcaId}`;
      const DOC_REF: DocumentReference = doc(this.#db, PATH);
      return from(deleteDoc(DOC_REF))
        .pipe(
          // map(() => { throw new Error(`Erro apagando ${PATH}.`); }),
          catchError<void, ObservableInput<void>>(
            (err: any) => this.#msgServ.onCatchError(err, 'Erro apagando marca.')
          )
        );
    } // if
    return throwError(() => 'Nenhuma marca indicada.');
  }
  //#endregion
}
