//#region ng
import {
  Component,
  inject,
  signal
} from '@angular/core';
import { Router } from '@angular/router';
//#endregion

//#region firebase
import {
  doc,
  Firestore,
  onSnapshot,
  Unsubscribe as FbUnsubscribe,
} from '@angular/fire/firestore';
//#endregion

//#region mat
import { MatTreeNestedDataSource } from '@angular/material/tree';
import { NestedTreeControl } from '@angular/cdk/tree';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
//#endregion

//#region 3rd
import {
  Subject,
  Subscription
} from 'rxjs';
import {
  first,
  finalize,
  filter,
  takeUntil,
  tap
} from 'rxjs/operators';
import {
  cloneDeep,
  get
} from 'lodash';
//#endregion

//#region models
import { HOME_ROUTE } from '../../models/consts';
import {
  IConta,
  IDepartamento,
  ILoja,
} from '../../_shared/_mercadeiro/_misc/_models/_interfaces/_cols';
// import { TOperadorRole } from '../../_shared/_mercadeiro/_misc/_models/_types';
import {
  ID_DEPTO_INDEFINIDO,
  ODOCS_TAG,
  // ID_DEPTO_INDEFINIDO
} from '../../_shared/_core/_misc/_models/consts';
import { IGaleriaGetParams } from '../../models/interfaces/params';
//#endregion

//#region libs
import { onDestroy } from '../../_shared/_core/_ng/_libs';
//#endregion

//#region services
import {
  ContasService,
  DepartamentosService,
  LojasService,
} from '../../_shared/_mercadeiro/_ng/_services';
import {
  AppService,
  ROTAS
} from '../../services';
import { CorLoaderService } from '../../_shared/_core/_ng/_services';
//#endregion

//#region stores
import {
  ContasStore,
  LojasStore
} from '../../_shared/_mercadeiro/_ng/_stores';
//#endregion

//#region components
import { CorMatConfirmDialog } from '../../_shared/_core/_ng-ui/_mat';
import { GaleriaGetModal } from '../../modals';
import { IProGaleria } from 'src/app/_shared/_produtos/_misc/_models/_interfaces/_cols';
import { compareValues } from 'src/app/_shared/_libs/_misc/_arrays';
//#endregion

@Component({
  selector: 'departamentos-page',
  templateUrl: './departamentos.page.html',
  styleUrls: ['./departamentos.page.scss'],
})
export class DepartamentosPage {
  //#region actions
  #destroyAction$: Subject<void> = onDestroy();
  //#endregion

  //#region publics
  deptoSel = signal<Partial<IDepartamento>>(null);
  dataSource = signal<MatTreeNestedDataSource<Partial<IDepartamento>>>(new MatTreeNestedDataSource<Partial<IDepartamento>>());
  departamentos = signal<IDepartamento[]>(null);
  preview = signal<IDepartamento[]>(null);
  treeControl = signal<NestedTreeControl<Partial<IDepartamento>>>(
    new NestedTreeControl<Partial<IDepartamento>>((node) => node?._subs));
  //#endregion

  //#region privates
  #deptosUnsub: FbUnsubscribe;
  #loja: ILoja;
  //#endregion

  //#region injects
  #db = inject(Firestore);
  #departamentosServ = inject(DepartamentosService);
  #loaderServ = inject(CorLoaderService);
  #lojasServ = inject(LojasService);
  #lojasStore = inject(LojasStore);
  #modal = inject(MatDialog);
  #snackBar = inject(MatSnackBar);
  //#endregion

  //#region lifecycles
  ngOnInit(): void {
    this.#lojasStore.lojaStateChanged$
      .pipe(
        filter((loja: ILoja) => !!loja),
        takeUntil(this.#destroyAction$)
      )
      .subscribe(async (loja: ILoja) => {
        // console.log(loja);
        this.#loja = !!loja ? this.#lojasServ.fix(loja) : null;
        const LOJA_PATH: string = this.#loja?.__idInfo?.lojaPath || '';
        if (!!LOJA_PATH) {
          const PATH: string = `${LOJA_PATH}/d1/${ODOCS_TAG}`;
          // console.log(PATH);
          !!this.#deptosUnsub && this.#deptosUnsub();
          this.#deptosUnsub = onSnapshot(
            doc(this.#db, PATH),
            snap => {
              const DEPARTAMENTOS: IDepartamento[] = ((Object.values(snap.get('_odocs') || {}) || []) as IDepartamento[])
                .filter((d: IDepartamento) => d?.id !== ID_DEPTO_INDEFINIDO);
              // console.log(DEPARTAMENTOS);
              this.departamentos.set(
                (this.#departamentosServ.fixes(DEPARTAMENTOS) || [])
                  .sort(compareValues('nome'))
              );
              const SUB: Subscription = this.#departamentosServ.lfixes(this.departamentos())
                .pipe(first(), finalize(() => SUB?.unsubscribe()))
                .subscribe(
                  (deptos: IDepartamento[]) => {
                    // console.log(deptos);
                    this.dataSource().data = (deptos || [])
                      .sort(compareValues('nome'));
                    this.#departamentosServ.geraMenu(
                      cloneDeep(this.departamentos()),
                      (deptos: IDepartamento[]) => { this.preview.set(deptos); }
                    );
                  });
            }
          );
        } // if
      });
  }

  ngOnDestroy(): void {
    !!this.#deptosUnsub && this.#deptosUnsub();
  }
  //#endregion

  //#region functions
  tid_dep(index: any, item: IDepartamento | Partial<IDepartamento>): string { return item?.id || ''; }
  //#endregion

  //#region methods
  onHasChildren = (_: number, node: Partial<IDepartamento>) => !!node?._subs && node?._subs?.length > 0;

  onImgClick(d: Partial<IDepartamento>) {
    // console.log(d);
    this.deptoSel.set(d);
  }

  onImgDelClick() {
    console.log(this.deptoSel());
    const DIALOG_EXISTS = this.#modal.getDialogById('del-img');
    if (!DIALOG_EXISTS) {
      const DIALOG_REF: any = this.#modal.open(
        CorMatConfirmDialog,
        {
          id: 'del-img',
          panelClass: 'no-border-dialog-container',
          data: {
            title: 'Excluindo imagem',
            msg: 'Você tem certeza?',
            cancelCaption: 'Não',
            confirmCaption: 'Sim',
          }
        }
      );

      const SUB: Subscription = DIALOG_REF
        .afterClosed()
        .pipe(first(), finalize(() => SUB?.unsubscribe()))
        // .pipe(tap(resp => console.log(resp),
        .pipe(filter(resp => !!resp))
        .subscribe(async (resp: any) => {
          console.log(resp);
          const DEPTO_ID: string = this.deptoSel()?.id || '';
          const LOJA_PATH: string = this.#loja?.__idInfo?.lojaPath || '';
          if (
            !!resp
            && !!DEPTO_ID
            && !!LOJA_PATH
          ) {
            const PATH: string = `${LOJA_PATH}/d1/${DEPTO_ID}`;
            console.log(PATH);
            const CHANGES: Partial<IDepartamento> = {
              img: {
                _res: 0,
                custom: '',
              }
            };
            const SUB: Subscription = this.#loaderServ.showUntilCompleted(
              this.#departamentosServ.update(LOJA_PATH, CHANGES, DEPTO_ID)
                .pipe(first(), finalize(() => SUB?.unsubscribe()))
            )
              .subscribe(() => {
                this.#snackBar.open(`Imagem removida do departamento ${this.deptoSel()?.nome}.`);
              });
          } // if
        });
    }
  }

  onImgAddClick() {
    const DIALOG_EXISTS = this.#modal.getDialogById('img-add');
    if (!DIALOG_EXISTS) {
      const DIALOG_REF: any = this.#modal.open(
        GaleriaGetModal,
        {
          panelClass: 'no-border-dialog-container',
          id: 'img-add',
          data: <IGaleriaGetParams>{
            sugestao: this.deptoSel()?.nome || '',
          }
        }
      );

      const SUB: Subscription = DIALOG_REF
        .afterClosed()
        .pipe(first(), finalize(() => SUB?.unsubscribe()))
        .pipe(filter((resp: any) => !!resp))
        .subscribe(async (resp: any) => {
          // console.log(resp);
          // console.log(this.deptoSel());
          const DEPTO_ID: string = this.deptoSel()?.id || '';
          const LOJA_PATH: string = this.#loja?.__idInfo?.lojaPath || '';
          if (
            !!DEPTO_ID
            && !!LOJA_PATH
          ) {
            const PATH: string = `${LOJA_PATH}/d1/${DEPTO_ID}`;
            console.log(PATH);
            const CHANGES: Partial<IDepartamento> = {
              img: {
                _res: resp?._res,
                custom: resp?.thumb || '',
              }
            };
            const SUB: Subscription = this.#loaderServ.showUntilCompleted(
              this.#departamentosServ.update(LOJA_PATH, CHANGES, DEPTO_ID)
                .pipe(first(), finalize(() => SUB?.unsubscribe()))
            )
              .subscribe(() => {
                this.#snackBar.open(`Imagem adicionada ao departamento ${this.deptoSel()?.nome}.`);
              });
          } // if
        });
    } // if
  }

  onOnlineToggle(d: Partial<IDepartamento>) {
    // console.log(d);
    const DIALOG_EXISTS = this.#modal.getDialogById('confirm-dialog');
    if (!DIALOG_EXISTS) {
      const DIALOG_REF: any = this.#modal.open(
        CorMatConfirmDialog,
        {
          id: 'confirm-dialog',
          panelClass: 'no-border-dialog-container',
          data: {
            title: d?.nome || '',
            msg: `Deseja tornar ${d?.ativo?.online ? 'OFFLINE' : 'ONLINE'} esse departamento ?`,
          }
        }
      );

      const SUB: Subscription = DIALOG_REF
        .afterClosed()
        .pipe(first(), finalize(() => SUB?.unsubscribe()))
        // .pipe(tap(resp => console.log(resp),
        .pipe(filter(resp => !!resp))
        .subscribe(async (resp: any) => {
          // console.log(resp);
          if (!!resp) {
            const DEPTO_ID: string = d?.id || '';
            const LOJA_PATH: string = this.#loja?.__idInfo?.lojaPath || '';
            // console.log(DEPTO_ID);
            console.log(DEPTO_ID, LOJA_PATH);
            if (!!DEPTO_ID && !!LOJA_PATH) {
              this.#loaderServ.showUntilCompleted(
                this.#departamentosServ.update(
                  LOJA_PATH,
                  { ativo: { online: !d?.ativo?.online } },
                  DEPTO_ID,
                )
                  .pipe(first(), finalize(() => SUB?.unsubscribe()))
              )
                .subscribe(() => {
                  // console.log(PRODUTO);
                  this.#snackBar.open(`Departamento ${d?.nome} modificado.`);
                });
            } // if
          } // if
        });
    } // if
  }

  onOcultoToggle(d: Partial<IDepartamento>) {
    // console.log(d);
    const DIALOG_EXISTS = this.#modal.getDialogById('confirm-dialog');
    if (!DIALOG_EXISTS) {
      const DIALOG_REF: any = this.#modal.open(
        CorMatConfirmDialog,
        {
          id: 'confirm-dialog',
          panelClass: 'no-border-dialog-container',
          data: {
            title: d?.nome || '',
            msg: `Deseja ${d?.oculto ? 'EXIBIR' : 'OCULTAR'} esse nível ?`,
          }
        }
      );

      const SUB: Subscription = DIALOG_REF
        .afterClosed()
        .pipe(first(), finalize(() => SUB?.unsubscribe()))
        // .pipe(tap(resp => console.log(resp),
        .pipe(filter(resp => !!resp))
        .subscribe(async (resp: any) => {
          // console.log(resp);
          if (!!resp) {
            const DEPTO_ID: string = d?.id || '';
            const LOJA_PATH: string = this.#loja?.__idInfo?.lojaPath || '';
            // console.log(DEPTO_ID);
            console.log(DEPTO_ID, LOJA_PATH);
            if (!!DEPTO_ID && !!LOJA_PATH) {
              this.#loaderServ.showUntilCompleted(
                this.#departamentosServ.update(
                  LOJA_PATH,
                  { oculto: !d?.oculto },
                  DEPTO_ID,
                )
                  .pipe(first(), finalize(() => SUB?.unsubscribe()))
              )
                .subscribe(() => {
                  // console.log(PRODUTO);
                  this.#snackBar.open(`Departamento ${d?.nome} modificado.`);
                });
            } // if
          } // if
        });
    } // if
  }
  //#endregion

  /*
  ngOnInit(): void {
    this._subs.push(
      this._contasStore.contaStateChanged$.subscribe(async (conta: IConta) => {
        // console.log(conta);
        this.conta = conta ? this._contasServ.fix(conta) : null;
        if (conta) {
          // const ROLE: TOperadorRole = get(conta, 'operador.role');
          !ROTAS?.departamentos?.__valid?.status &&
            this._router.navigateByUrl(HOME_ROUTE);
        } // if
      }),
    );
  } 
  */
}
