//#region ng
import { NgModule } from '@angular/core';
import {
  CommonModule,
  NgOptimizedImage
} from '@angular/common';
import {
  FormsModule,
  ReactiveFormsModule
} from '@angular/forms';
import { RouterModule } from '@angular/router';
//#endregion

//#region mat
import { MatBottomSheetModule } from '@angular/material/bottom-sheet';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatChipsModule } from '@angular/material/chips';
import { MatDialogModule } from '@angular/material/dialog';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatListModule } from '@angular/material/list';
import { MatMenuModule } from '@angular/material/menu';
import { MatRadioModule } from '@angular/material/radio';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatSliderModule } from '@angular/material/slider';
import { MatStepperModule } from '@angular/material/stepper';
import { MatTabsModule } from '@angular/material/tabs';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatTreeModule } from '@angular/material/tree';
//#endregion

//#region 3rd
// import { NgxMaskModule } from 'ngx-mask';
import {
  NgxMaskDirective,
  NgxMaskPipe,
  provideNgxMask
} from 'ngx-mask';
// import { Ng2BRPipesModule } from 'ng2-brpipes';
import { NgxPaginationModule } from 'ngx-pagination';
import { QRCodeModule } from 'angularx-qrcode';
//#endregion

//#region modules
import { CorMatComponentsModule } from '../_shared/_core/_ng-ui/_mat/cor-mat-components.module';
import { CorHtmlComponentsModule } from '../_shared/_core/_ng-ui/_html/cor-html-components.module';
import { MeMatComponentsModule } from '../_shared/_mercadeiro/_ng-ui/_mat/me-mat-components.module';
import { LojaInfoModalModule } from '../modals/loja-info/loja-info-modal.module';
import { CorPipesModule } from '../_shared/_core/_ng/_pipes/cor-pipes.module';
import { OperadorHabilitarModalModule } from '../modals/operador-habilitar/operador-habilitar-modal.module';
import { ComponentsModule } from '../components/components.module';
import { HorarioFormModalModule } from '../modals/horario-form/horario-form-modal.module';
import { SetorFormModalModule } from '../modals/setor-form/setor-form-modal.module';
import { BairroAddModalModule } from '../modals/bairro-add/bairro-add-modal.module';
import { CorDirectivesModule } from '../_shared/_core/_ng/_directives/cor-directives.module';
import { AppProdutosFiltrosModalModule } from '../modals/produtos-filtros/produtos-filtros-modal.module';
import { ProdutoEditModalModule } from '../modals/produto-edit/produto-edit-modal.module';
import { ProdutoInfoModalModule } from '../modals/produto-info/produto-info-modal.module';
import { MeHtmlComponentsModule } from '../_shared/_mercadeiro/_ng-ui/_html/me-html-components.module';
import { AppPedidoDetalhesModalModule } from '../modals/pedido-detalhes/pedido-detalhes-modal.module';
import { DistribuidoraFormaPgtoFormModalModule } from '../modals/distribuidora-forma-pgto-form/distribuidora-forma-pgto-form-modal.module';
import { CredenciadoHabilitarModalModule } from '../modals/credenciado-habilitar/credenciad-habilitar-modal.module';
import { DistribuidoraCredenciadoFormModalModule } from '../modals/distribuidora-credenciado-form/distribuidora-credenciado-form-modal.module';
import { MePipesModule } from '../_shared/_mercadeiro/_ng/_pipes/me-pipes.module';
import { GaleriaGetModalModule } from '../modals/galeria-get/galeria-get.module';
import { PTagFormModalModule } from '../modals/p-tag-form/p-tag-form-modal.module';
//#endregion

//#region components
import {
  ConfigPage,
  ConfigFormComponent,
  DashboardPage,
  DepartamentosPage,
  DistribuidoraPage,
  FormasPgtoPage,
  HorariosPage,
  LojasPage,
  LoginPage,
  MidiaPage,
  MovimentoPage,
  OperadoresPage,
  PedidosPage,
  ProdutosPage,
  ProdutosProdutoIconsComponent,
  ProdutosProdutosComponent,
  ProdutosPTagsComponent,
  SetoresEntregaPage,
  NotificacoesPage,
  HorariosBottomSheetComponent,
} from '.';
import {
  DistribuidoraCredenciadosComponent,
  DistribuidoraFormasPgtoComponent,
  DistribuidoraRotasLocalidadeComponent
} from './distribuidora';
//#endregion

@NgModule({
  imports: [
    AppProdutosFiltrosModalModule,
    AppPedidoDetalhesModalModule,
    BairroAddModalModule,
    CorDirectivesModule,
    CorHtmlComponentsModule,
    CorMatComponentsModule,
    CorPipesModule,
    CommonModule,
    ComponentsModule,
    DistribuidoraCredenciadoFormModalModule,
    DistribuidoraFormaPgtoFormModalModule,
    CredenciadoHabilitarModalModule,
    FormsModule,
    GaleriaGetModalModule,
    // LojaFormModalModule,
    MatCardModule,    
    HorarioFormModalModule,
    LojaInfoModalModule,
    MatBottomSheetModule,
    MatButtonModule,
    MatChipsModule,
    MatDialogModule,
    MatExpansionModule,
    MatFormFieldModule,
    MatIconModule,
    MatInputModule,
    MatListModule,
    MatTabsModule,
    MatMenuModule,
    MatRadioModule,
    MatSlideToggleModule,
    MatSliderModule,
    MatStepperModule,
    MatToolbarModule,
    MatTooltipModule,
    MatTreeModule,
    MeHtmlComponentsModule,
    MeMatComponentsModule,
    // Ng2BRPipesModule,
    MePipesModule,
    NgOptimizedImage,
    // NgxMaskModule,
    NgxMaskDirective,
    NgxMaskPipe,
    NgxPaginationModule,
    ProdutoInfoModalModule,
    PTagFormModalModule,
    QRCodeModule,
    ReactiveFormsModule,
    // RedeFormModalModule,
    RouterModule,
    SetorFormModalModule,
    OperadorHabilitarModalModule,
    ProdutoEditModalModule,
  ],
  declarations: [
    ConfigPage,
    DashboardPage,
    DepartamentosPage,
    DistribuidoraPage,
    FormasPgtoPage,
    HorariosBottomSheetComponent,
    HorariosPage,
    LojasPage,
    LoginPage,
    MidiaPage,
    MovimentoPage,
    NotificacoesPage,
    OperadoresPage,
    PedidosPage,
    ProdutosPage,
    ProdutosProdutoIconsComponent,
    ProdutosProdutosComponent,
    ProdutosPTagsComponent,
    SetoresEntregaPage,
    ConfigFormComponent,
    DistribuidoraCredenciadosComponent,
    DistribuidoraFormasPgtoComponent,
    DistribuidoraRotasLocalidadeComponent,
    ProdutosPTagsComponent,
    ProdutosProdutosComponent,
  ],
  exports: [
    ConfigPage,
    FormasPgtoPage,
    DashboardPage,
    DepartamentosPage,
    DistribuidoraPage,
    HorariosPage,
    // HorariosBottomSheetComponent,
    LojasPage,
    LoginPage,
    MidiaPage,
    MovimentoPage,
    NotificacoesPage,
    OperadoresPage,
    PedidosPage,
    ProdutosPage,
    SetoresEntregaPage,
  ],
})
export class PagesModule { }
