<h2>midia works!!!</h2>

<!-- <div class="box">
  <nav aria-label="breadcrumb">
    <ol class="breadcrumb">
      <li class="breadcrumb-item">
        <span class="icon-o-home primary tint o-50 pointer"
              routerLink="/dashboard"></span>
      </li>

      <li class="breadcrumb-item active">
        Mídia
      </li>

      <li class="ms-auto my-auto">
        <mat-slider min="50"
                    max="1000"
                    step="50"
                    [(ngModel)]="qrcodeSize">
          <input matSliderThumb>
        </mat-slider>
        <span class="o-50 ms-2">{{ qrcodeSize }}px</span>
      </li>
    </ol>
  </nav>

  <div *ngIf="!!loja && !!qrcodes">
    <div class="card">
      <div class="card-body">
        <h5 class="card-title">
          QR-Code loja
          <a class="o-50"
             [href]="qrcodes.loja.blob"
             download>
            <small>Download</small>
          </a>
        </h5>
        <h6 class="card-subtitle mb-2 text-muted">
          {{ qrcodes.loja.url }}
        </h6>

        <qrcode [width]="qrcodeSize"
                elementType="url"
                [qrdata]="qrcodes.loja.url"
                (qrCodeURL)="qrcodes.loja.blob = $event">
        </qrcode>
      </div>
    </div>

    <div class="card mt-2">
      <div class="card-body">
        <h5 class="card-title">
          QR-Code rede
          <a class="o-50"
             [href]="qrcodes.rede.blob"
             download>
            <small>Download</small>
          </a>
        </h5>
        <h6 class="card-subtitle mb-2 text-muted">
          {{ qrcodes.rede.url }}
        </h6>

        <qrcode [width]="qrcodeSize"
                elementType="url"
                [qrdata]="qrcodes.rede.url"
                (qrCodeURL)="qrcodes.rede.blob = $event">
        </qrcode>
      </div>
    </div>
  </div>
</div>
 -->
