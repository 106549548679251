//#region ng
import {
  inject,
  Injectable
} from '@angular/core';
//#endregion

//#region firebase
import {
  doc,
  Firestore,
  getDoc,
} from '@angular/fire/firestore';
//#endregion

//#region 3rd
import {
  from,
  Observable,
  ObservableInput,
  throwError,
} from "rxjs";
import {
  catchError,
  map,
  tap,
} from "rxjs/operators";
//#endregion

//#region models
import { environment } from 'src/environments/environment';
import {
  IMG_NO_IMG,
  ODOCS_TAG,
  THUMB_NO_IMG
} from '../../../_core/_misc/_models/consts';
import { IProGaleria } from '../../_misc/_models/_interfaces/_cols';
//#endregion

//#region services
import { CorMessagesService } from '../../../_core/_ng/_services';
//#endregion

@Injectable({
  providedIn: "root"
})
export class ProGaleriaService {
  //#region injects
  #db = inject(Firestore);
  #msgServ = inject(CorMessagesService);
  //#endregion

  //#region misc
  fix(row: Partial<IProGaleria>): IProGaleria {
    return {
      // id
      id: row?.id || '',

      // user
      hash: row?.hash || '',
      nome: row?.nome || '',

      // system
      _criadoEm: row?._criadoEm || null,
      _isMarca: !!row?._isMarca,
      _modificadoEm: row?._modificadoEm || null,
      _res: Number(row?._res) || 0,
      _ver: Number(row?._ver) || 0,

      // realtime
      __img: {
        img: row?.__img?.img || IMG_NO_IMG,
        thumb: row?.__img?.thumb || THUMB_NO_IMG,
      },
    };
  }

  lfix(row: Partial<IProGaleria>): IProGaleria {
    return this.fix(
      !!row._res
        ? {
          ...row,
          __img: {
            img: `${environment.firebase.conecdata.storage.root}/galeria%2fimg_${row?.id}.jpg?alt=media`,
            thumb: `${environment.firebase.conecdata.storage.root}/galeria%2fthumb_${row?.id}.jpg?alt=media`
          },
        }
        : row
    );
  }

  fixes(docs: Partial<IProGaleria>[]): IProGaleria[] {
    return (docs || []).map((doc: Partial<IProGaleria>) => this.fix(doc));
  }

  lfixes(docs: Partial<IProGaleria>[]): IProGaleria[] {
    return (docs || []).map((doc: Partial<IProGaleria>) => this.lfix(doc));
  }
  //#endregion

  //#region R
  docs(): Observable<IProGaleria[]> {
    const PATH: string = `_galeria/${ODOCS_TAG}`;
    // return (docData(doc(this.#db, PATH)) as Observable<IProGaleria[]>)
    return (from(getDoc(doc(this.#db, PATH))) as Observable<any>)
      .pipe(
        map((doc: any) => this.fixes(Object.values(doc?.data()?._odocs || {}) || [])),
        // map(() => { throw new Error(`Erro lendo ${PATH}.`); }),
        catchError<Partial<IProGaleria[]>, ObservableInput<Partial<IProGaleria[]>>>(
          (err: any) => this.#msgServ.onCatchError(err, 'Erro carregando galeria.')
        )
      );
  }
  //#endregion
}
