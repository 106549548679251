//#region ng
import {
  inject,
  Injectable
} from '@angular/core';
import { HttpClient } from "@angular/common/http";
//#endregion

//#region 3rd
import {
  Observable,
  ObservableInput,
} from "rxjs";
import {
  catchError,
  map,
  tap,
} from "rxjs/operators";
//#endregion

//#region models
const PRODUTOS_API = 'https://us-central1-produtos-d5cd0.cloudfunctions.net/v1';
import { IProGaleria } from '../_shared/_produtos/_misc/_models/_interfaces/_cols';
//#endregion

//#region services
import { ProGaleriaService } from '../_shared/_produtos/_ng/_services';
import { CorMessagesService } from '../_shared/_core/_ng/_services';
//#endregion

@Injectable({
  providedIn: "root"
})
export class AppGaleriaService {
  //#region injects
  // #db = inject(Firestore);
  #msgServ = inject(CorMessagesService);
  #galeriaServ = inject(ProGaleriaService);
  #http = inject(HttpClient);
  //#endregion

  //#region R
  // https://us-central1-produtos-d5cd0.cloudfunctions.net/v1/galeria
  galeria(): Observable<IProGaleria[]> {
    const PATH: string = `${PRODUTOS_API}/galeria`;
    const URL = encodeURI(PATH);
    return this.#http.get<IProGaleria[]>(URL)
      .pipe(
        // tap((doc: any) => console.log(doc)),
        map((doc: any) => this.#galeriaServ.fixes(Object.values(doc || {}) || [])),
        // map(() => { throw new Error(`Erro lendo ${PATH}.`); }),
        catchError<Partial<IProGaleria[]>, ObservableInput<Partial<IProGaleria[]>>>(
          (err: any) => this.#msgServ.onCatchError(err, 'Erro carregando galeria.')
        )
      );
  }
  //#endregion
}
