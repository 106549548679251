<!-- <p>ptags() {{ ptags() | json }}</p> -->

<div class="d-flex">
  <button type="button"
          class="btn btn-light btn-lg flex-1 mb-4"
          (click)="onTagEditClick()">
    <span class="icon-r-plus o-30 me-2"></span> Cadastrar tag
  </button>
</div>

<mat-chip-list>
  <mat-chip *ngFor="let t of ptags(); trackBy:tid_ptags"
            class="bg-{{t?.theme}}"
            (click)="onTagEditClick(t)">
    {{ t?.caption }}

    <button matChipRemove
            (click)="onDelTagClick(t)"
            *ngIf="!t?._isSistema">
      <mat-icon>cancel</mat-icon>
    </button>
  </mat-chip>
</mat-chip-list>
