<div class="modal-body">
  <mat-toolbar class="header"
               color="primary">
    <mat-toolbar-row class="d-flex justify-content-between">
      <div class="my-auto">
        <h3> {{ tipo() | titlecase }} <small class="o-70">( {{ dow() | dow }} )</small> </h3>
      </div>

      <div class="my-auto">
        <button mat-icon-button
                (click)="onModalCloseClick()">
          <mat-icon>close</mat-icon>
        </button>
      </div>
    </mat-toolbar-row>
  </mat-toolbar>

  <!-- <p>finalRef {{ finalRef?.errors | json }}</p> -->

  <form novalidate
        [formGroup]="horarioForm()"
        class="d-flex flex-column p-3"
        (ngSubmit)="onSubmitClick()"
        *ngIf="horarioForm()">
    <mat-form-field class="w-100 m-2"
                    appearance="outline">
      <mat-label>Inicio</mat-label>
      <input matInput
             type="time"
             class="text-end"
             [focus]="inicioFocusEvent$()"
             formControlName="inicio"
             onReturn>
      <mat-hint *ngIf="fv()?.error(inicioRef)">
        <span class="danger">
          {{ fv()?.error(inicioRef) }}
        </span>
      </mat-hint>
    </mat-form-field>

    <mat-form-field class="w-100 m-2"
                    appearance="outline">
      <mat-label>Final</mat-label>
      <input matInput
             type="time"
             class="text-end"
             formControlName="final"
             onReturn>
      <mat-hint *ngIf="fv()?.lerror(finalRef) || finalRef?.errors?.['final']">
        <span class="danger">
          {{ fv()?.error(finalRef) || 'Horário final deve ser maior que o inicial.' }}
        </span>
      </mat-hint>
    </mat-form-field>

    <mat-form-field class="w-100 m-2"
                    appearance="outline">
      <mat-label>
        Tempo estimado para separação
        <small class="o-50">
          (minutos)
        </small>
      </mat-label>
      <input matInput
             type="number"
             class="text-end"
             formControlName="tempoEstimadoSeparacaoMin"
             onReturn>
      <mat-hint *ngIf="fv()?.lerror(tesRef) || tesRef?.errors?.['tes']">
        <span class="danger">
          {{ fv()?.lerror(tesRef) || 'Inválido.' }}
        </span>
      </mat-hint>
    </mat-form-field>

    <div class="d-flex">
      <button type="submit"
              class="pointer flex-1"
              mat-raised-button
              color="primary"
              [disabled]="!horarioForm().valid">
        CONFIRMAR
      </button>
    </div>
  </form>
</div>
