//#region ng
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';
//#endregion

//#region 3rd
// import { PinchZoomModule } from 'ngx-pinch-zoom';
//#endregion

//#region mat
// import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatButtonModule } from '@angular/material/button';
// import { MatDialogModule } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
// import { MatSnackBarModule } from '@angular/material/snack-bar';
// import { MatSlideToggleModule } from '@angular/material/slide-toggle';
// import { MatStepperModule } from '@angular/material/stepper';
import { MatToolbarModule } from '@angular/material/toolbar';
// import { MatCheckboxModule } from '@angular/material/checkbox';
// import { MatMenuModule } from '@angular/material/menu';
// import { MatSelectModule } from '@angular/material/select';
// import { MatTooltipModule } from '@angular/material/tooltip';
// import {
//   MatSnackBarModule,
//   MAT_SNACK_BAR_DEFAULT_OPTIONS
// } from '@angular/material/snack-bar';
//#endregion

//#region modules
import { CorBsComponentsModule } from '../../_shared/_core/_ng-ui/_bs/cor-bs-components.module';
import { CorDirectivesModule } from '../../_shared/_core/_ng/_directives/cor-directives.module';
// import { CorMatComponentsModule } from '../../_shared/_core/_ng/_components/_mat/cor-mat-components.module';
// import { MeMatComponentsModule } from '../../_shared/_mercadeiro/_ng-ui/_mat/me-mat-components.module';
//#endregion

//#region components
import { PTagFormModal } from '..';
//#endregion

@NgModule({
  imports: [
    CommonModule,
    CorBsComponentsModule,
    CorDirectivesModule,
    // MeMatComponentsModule,
    // CorMatComponentsModule,
    // MatAutocompleteModule,
    MatButtonModule,
    // MatDialogModule,
    MatFormFieldModule,
    MatIconModule,
    MatInputModule,
    // MatSlideToggleModule,
    // MatSnackBarModule,
    // MatStepperModule,
    MatToolbarModule,
    // MatTooltipModule,
    // PinchZoomModule,
    ReactiveFormsModule,
    // MatMenuModule,
    // MatSelectModule,
  ],
  declarations: [
    PTagFormModal,
  ],
  exports: [
    PTagFormModal,
  ],
})
export class PTagFormModalModule { }
