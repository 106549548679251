//#region ng
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';
//#endregion

//#region mat
import { MatButtonModule } from '@angular/material/button';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatToolbarModule } from '@angular/material/toolbar';
//#endregion

//#region modules
// import { CorMatComponentsModule } from '../../_components/_mat/cor-mat-components.module';
import { CorDirectivesModule } from '../../_shared/_core/_ng/_directives/cor-directives.module';
import { ComponentsModule } from '../../components/components.module';
//#endregion

//#region components
import { DistribuidoraCredenciadoFormModal } from '..';
//#endregion

@NgModule({
  imports: [
    // CorMatComponentsModule,
    CorDirectivesModule,
    CommonModule,
    ComponentsModule,
    MatButtonModule,
    MatFormFieldModule,
    MatIconModule,
    MatInputModule,
    MatProgressBarModule,
    MatToolbarModule,
    MatSlideToggleModule,
    ReactiveFormsModule,
  ],
  declarations: [
    DistribuidoraCredenciadoFormModal,
  ],
  exports: [
    DistribuidoraCredenciadoFormModal,
  ],
})
export class DistribuidoraCredenciadoFormModalModule { }
