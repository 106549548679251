<mat-list role="list"
          *ngIf="horario">
  <mat-list-item role="listitem">
    <strong>{{ tipo | titlecase }} - {{ dow() | dow }}</strong>&nbsp;
    <small>( <app-horario2str [horario]="horario"></app-horario2str> )</small>
  </mat-list-item>

  <mat-divider class="mt-3"></mat-divider>

  <mat-list-item role="listitem"
                 (click)="onCloseLink('edit')"
                 class="pointer">
    <mat-icon matListIcon
              class="o-50">
      edit
    </mat-icon>
    <h3 matLine> Editar horário </h3>
  </mat-list-item>

  <!-- <mat-list-item role="listitem"
                 (click)="onCloseLink('clone')"
                 class="pointer">
    <mat-icon matListIcon
              class="o-50">
      access_time
    </mat-icon>
    <h3 matLine> Copiar horário </h3>
  </mat-list-item> -->

  <mat-list-item role="listitem"
                 (click)="onCloseLink('del')"
                 class="pointer">
    <mat-icon matListIcon
              class="o-50">
      delete
    </mat-icon>
    <h3 matLine> Excluir horário </h3>
  </mat-list-item>

  <mat-divider class="mt-3"></mat-divider>

  <mat-list-item role="listitem"
                 class="pointer"
                 (click)="onCloseLink()">
    <mat-icon matListIcon
              class="o-50">
      close
    </mat-icon>
    <h3 matLine> Cancelar </h3>
  </mat-list-item>
</mat-list>
