<div class="modal-body d-flex flex-column p-relative">
  <mat-toolbar class="header"
               color="primary">
    <mat-toolbar-row class="d-flex justify-content-between">
      <div class="my-auto">
        <h3>
          Habilitando operador
          <!-- &nbsp;
           <small *ngIf="!!contaEmailRef?.value"
                 class="o-50">
            ( {{ contaEmailRef?.value }} )
          </small> -->
        </h3>
      </div>

      <div class="my-auto">
        <button mat-icon-button
                (click)="onModalCloseClick()">
          <mat-icon>close</mat-icon>
        </button>
      </div>
    </mat-toolbar-row>
  </mat-toolbar>

  <cor-mat-messages />

  <!-- <p>tipoForm?.value {{ tipoForm?.value | json }}</p> -->
  <!-- <p>contaForm()?.value {{ contaForm()?.value | json }}</p> -->
  <!-- <p>changes() {{ changes() | json }}</p> -->

  <div class="content flex-1">
    <mat-stepper [linear]="true"
                 class="mx-2"
                 labelPosition="bottom"
                 (selectionChange)="onStepChanged($event)"
                 #stepper>

      <mat-step label="Tipo operador"
                state="tipoOperador"
                [stepControl]="operadorTipoFormRef?.operadorTipoForm()">
        <div class="d-flex my-2">
          <div class="ms-auto">
            <button type="button"
                    mat-flat-button
                    class="pointer ms-2"
                    color="primary"
                    [disabled]="!operadorTipoFormRef?.operadorTipoForm()?.valid"
                    matStepperNext>
              <span> Próximo </span>
              <mat-icon class="o-50">chevron_right</mat-icon>
            </button>
          </div>
        </div>

        <div class="mt-4">
          <app-operador-tipo-form />
        </div>
      </mat-step>

      <mat-step label="Conta"
                [stepControl]="contaForm()"
                state="conta">
        <div class="d-flex my-2">
          <div class="me-auto">
            <button type="button"
                    mat-flat-button
                    class="pointer"
                    color="light"
                    matStepperPrevious>
              <mat-icon class="o-50">chevron_left</mat-icon>
              <span> Anterior </span>
            </button>
          </div>
        </div>

        <div class="mt-4">
          <form [formGroup]="contaForm()"
                novalidate
                *ngIf="!!contaForm()">
            <div class="row">
              <div class="col">
                <mat-form-field class="w-100 mb-2"
                                appearance="outline">
                  <mat-label>E-mail operador</mat-label>
                  <input type="text"
                         matInput
                         formControlName="email"
                         [focus]="emailFocusEvent$()"
                         onReturn>
                  <mat-hint *ngIf="fv()?.error(contaEmailRef, 'email')">
                    <span class="danger"> {{ fv()?.error(contaEmailRef, 'email') }} </span>
                  </mat-hint>
                </mat-form-field>
              </div>
            </div>

            <div class="row">
              <div class="col">
                <div class="d-flex">
                  <button type="button"
                          class="pointer flex-1"
                          mat-raised-button
                          color="primary"
                          (click)="onVerificaEmailClick()"
                          [disabled]="!contaEmailRef.valid">
                    VERIFICA CONTA
                  </button>
                </div>
              </div>
            </div>
          </form>
        </div>
      </mat-step>

      <mat-step label="Resumo"
                state="resumo">
        <div class="d-flex my-2">
          <div class="me-auto">
            <button type="button"
                    mat-flat-button
                    class="pointer"
                    color="light"
                    matStepperPrevious>
              <mat-icon class="o-50">chevron_left</mat-icon>
              <span> Anterior </span>
            </button>
          </div>

          <div class="ms-auto">
            <button type="submit"
                    class="pointer w-100"
                    mat-raised-button
                    color="primary"
                    [disabled]="!changes()"
                    (click)="onHabilitarOperador()">
              HABILITAR OPERADOR
            </button>
          </div>
        </div>

        <ul class="list-group">
          <!-- <li class="list-group-item list-group-item-secondary">
            Conta
          </li> -->

          <li class="list-group-item">
            <span class="o-50">E-mail</span><br />
            <small>{{ contaEmailRef?.value }}</small>
          </li>

          <li class="list-group-item">
            <span class="o-50">Tipo conta</span><br />
            <small>{{ changes()?.operador?.role }}</small>
          </li>
        </ul>
      </mat-step>
    </mat-stepper>
  </div>

  <mat-toolbar class="header"
               color="light"
               *ngIf="!!operadorTipoFormRef?.operadorRoleRef?.value">
    <mat-toolbar-row>
      <nav class="d-flex">
        <ol class="breadcrumb my-auto">
          <li class="breadcrumb-item active"
              matTooltip="Rede">
            <a> <small> {{ operadorTipoFormRef?.operadorRoleRef?.value }}</small></a>
          </li>

          <li class="breadcrumb-item active"
              aria-current="page"
              matTooltip="E-mail parceiro"
              *ngIf="!!contaEmailRef?.value">
            <a>
              <small>
                 {{ contaEmailRef?.value }}
              </small>
            </a>
          </li>
        </ol>
      </nav>
    </mat-toolbar-row>
  </mat-toolbar>
</div>
