//#region ng
import {
  inject,
  Injectable
} from '@angular/core';
//#endregion

//#region firebase
import {
  doc,
  Firestore,
  getDoc,
} from '@angular/fire/firestore';
//#endregion

//#region 3rd
import {
  catchError,
  from,
  Observable,
  ObservableInput,
} from "rxjs";
import {
  map,
  tap,
} from "rxjs/operators";
//#endregion

//#region models
import { QTDE_TAG } from '../../../_core/_misc/_models/consts';
//#endregion

//#region services
import { CorMessagesService } from '../../../_core/_ng/_services';
import { IProQtdeMap } from '../../_misc/_models/_interfaces/_maps';
//#endregion

@Injectable({
  providedIn: 'root'
})
export class ProQtdeService {
  //#region injects
  #db = inject(Firestore);
  #msgServ = inject(CorMessagesService);
  //#endregion

  //#region misc
  fix(row: Partial<IProQtdeMap>): IProQtdeMap {
    return {
      __produtos: Number(row?.__produtos) || 0,
      _departamentos: Number(row?._departamentos) || 0,
      _galeria: Number(row?._galeria) || 0,
      _gtins: Number(row?._gtins) || 0,
      _marcas: Number(row?._marcas) || 0,
      _operadores: Number(row?._operadores) || 0,
    };
  }
  //#endregion

  //#region R
  doc(): Observable<IProQtdeMap> {
    // console.log('barcode', barcode);
    const PATH: string = `/_qtde/${QTDE_TAG}`;
    // return (docData(doc(this.#db, PATH)/* , { idField: 'id' } */) as Observable<IProQtdeMap>)
    return (from(getDoc(doc(this.#db, PATH))) as Observable<any>)
      .pipe(
        // tap((doc: any) => console.log(doc)),
        map((doc: any) => (!!doc.data() ? this.fix({ ...doc.data(), id: doc.id }) : null)),
        // map(() => { throw new Error(`Erro lendo ${PATH}.`); }),
        catchError<IProQtdeMap, ObservableInput<IProQtdeMap>>(
          (err: any) => this.#msgServ.onCatchError(err, 'Erro carregando qtdes.')
        )
      );
  }
  //#endregion
}
