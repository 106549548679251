//#region ng
import {
  Component,
  EventEmitter,
  inject,
  signal,
} from '@angular/core';
import { Router } from '@angular/router';
//#endregion

//#region firebase
import {
  doc,
  Firestore,
  onSnapshot,
  Unsubscribe as FbUnsubscribe,
} from '@angular/fire/firestore';
//#endregion

//#region mat
import { MatDialog } from '@angular/material/dialog';
//#endregion

//#region 3rd
import Fuse from 'fuse.js';
import {
  Observable,
  Subject,
  Subscription,
  combineLatest,
  of
} from 'rxjs';
import {
  debounceTime,
  distinctUntilChanged,
  map,
  startWith,
  switchMap,
  takeUntil,
  tap
} from 'rxjs/operators';
//#endregion

//#region models
interface IVm {
  conta: IConta;
  contas: IConta[];
  loja: ILoja;
  pag: {
    itemsPerPage: string;
    page: number;
  };
  // searchTerm: string;
};
import {
  HOME_ROUTE,
  KEY_OFFSET_OPERADORES
} from '../../models/consts';
import {
  IConta,
  ILoja
} from '../../_shared/_mercadeiro/_misc/_models/_interfaces/_cols';
// import { TOperadorRole } from '../../_shared/_mercadeiro/_misc/_models/_types';
import { CorAngularFirePaginator } from '../../_shared/_core/_ng/_models/_classes';
import { FOCUS_TIMEOUT, ODOCS_TAG } from '../../_shared/_core/_misc/_models/consts';
//#endregion

//#region libs
import { compareValues } from '../../_shared/_libs/_misc/_arrays';
import { onDestroy } from '../../_shared/_core/_ng/_libs';
//#endregion

//#region services
import {
  ContasService,
  LojasService
} from '../../_shared/_mercadeiro/_ng/_services';
import {
  AppService,
  ROTAS
} from '../../services';
import { CorLoaderService } from '../../_shared/_core/_ng/_services';
//#endregion

//#region stores
import {
  ContasStore,
  LojasStore
} from '../../_shared/_mercadeiro/_ng/_stores';
//#endregion

//#region components
import { OperadorHabilitarModal } from '../../modals';
//#endregion

@Component({
  selector: 'operadores-page',
  templateUrl: './operadores.page.html',
  styleUrls: ['./operadores.page.scss']
})
export class OperadoresPage {
  //#region actions
  #hitsAction$ = new Subject<IConta[]>();
  #destroyAction$: Subject<void> = onDestroy();
  pagItemsPerPageAction$ = new Subject<string>();
  pagPageAction$ = new Subject<number>();
  searchTermAction$ = new Subject<string>();
  //#endregion

  //#region publics
  offsetStorageKey = signal<string>(KEY_OFFSET_OPERADORES).asReadonly();
  vm$ = signal<Observable<IVm>>(null);
  //#endregion

  //#region privates
  #contas: IConta[];
  #contasUnsub: FbUnsubscribe;
  #vm: IVm;
  //#endregion

  //#region events
  searchFocusEvent$ = signal<EventEmitter<boolean>>(new EventEmitter<boolean>()).asReadonly();
  //#endregion

  //#region injects
  #contasServ = inject(ContasService);
  #contasStore = inject(ContasStore);
  #db = inject(Firestore);
  #loaderServ = inject(CorLoaderService);
  // #lojasServ = inject(LojasService);
  #lojasStore = inject(LojasStore);
  #modal = inject(MatDialog);
  #router = inject(Router);
  //#endregion

  //#region lifecycles
  ngOnInit() {
    this.#loaderServ.lstart();
    try {
      const PATH: string = `/_contas/${ODOCS_TAG}`;
      // console.log(PATH);
      this.#contasUnsub && this.#contasUnsub();
      this.#contasUnsub = onSnapshot(
        doc(this.#db, PATH),
        snap => {
          const CONTAS: IConta[] = [];
          // console.log(JSON.stringify(snap.data()));
          (Object.values(snap.get('_odocs') || {}) || [])
            .forEach((c: any) => {
              // console.log(JSON.stringify(c?.operador));
              true
                // && c?.operador?._lojasIds.includes(this._loja?.id)
                // && ['monitor', 'gerente', 'marketing'].includes(c?.operador?._role)
                && CONTAS.push(this.#contasServ.fix(c));
            });
          this.#contas = CONTAS.sort(compareValues('__nomeCompleto'));
          // console.log(this.#contas);
          this.#refresh('');
        }
      );
    } finally {
      this.#loaderServ.lstop();
    } // try-finally

    this.searchTermAction$
      .pipe(
        debounceTime(300),
        distinctUntilChanged(),
        switchMap((searchTerm: string) => { this.#refresh(searchTerm); return of(null); }),
        takeUntil(this.#destroyAction$)
      )
      .subscribe();

    this.vm$.set(
      combineLatest([
        this.pagItemsPerPageAction$.pipe(startWith('12')),
        this.pagPageAction$.pipe(startWith(1)),
        this.#hitsAction$.pipe(startWith([])),
        this.#contasStore.contaStateChanged$,
        this.#lojasStore.lojaStateChanged$
      ])
        .pipe(
          // tap(() => {
          // !!conta && !ROTAS?.operadores.__valid.status && this.#router.navigateByUrl(HOME_ROUTE);
          // }),
          map(([pagItemsPerPage, pagPage, hits, conta, loja]) => {
            // console.log(loja);
            !!conta && !ROTAS?.operadores.__valid.status && this.#router.navigateByUrl(HOME_ROUTE);
            // console.log(conta?.operador?.integradora?.id);
            // console.log(hits);
            // this._integradoraId = conta?.operador?.integradora?.id || '';
            this.#vm = {
              conta,
              contas: (hits || [])
                .filter((c: IConta) => {
                  // console.log(c?.email, c?.operador?._role, c?.operador?._lojasIds);
                  return c?.operador?._lojasIds.includes(loja?.id)
                    && ['monitor', 'gerente', 'marketing'].includes(c?.operador?._role)
                }),
              // .filter((c: IConta) => ),
              loja,
              pag: {
                itemsPerPage: pagItemsPerPage,
                page: pagPage
              },
            };
            // console.log(this.#vm);
            return this.#vm;
          }),
        )
    );
  }

  ngAfterViewInit() {
    this.#focus();
  }

  ngOnDestroy() {
    !!this.#contasUnsub && this.#contasUnsub();
  }
  //#endregion

  //#region functions
  #focus() {
    setTimeout(
      () => { this.searchFocusEvent$().emit(true); },
      FOCUS_TIMEOUT
    );
  }

  #refresh(searchTerm: string) {
    // console.log(searchTerm);
    // fuse-js
    if (!!searchTerm.trim()) {
      const FUSE: any = new Fuse(
        this.#contas,
        {
          includeScore: false,
          keys: [
            '__nomeCompleto',
          ],
          // minMatchCharLength: 3,
        }
      );
      const HITS: any[] = FUSE.search(searchTerm);
      // console.log(HITS);
      this.#hitsAction$.next(
        this.#contasServ.fixes(
          (HITS || [])
            .map(h => h?.item as IConta)
            .sort(compareValues('__nomeCompleto'))
        ) as IConta[]
      );
    } else {
      this.#hitsAction$.next(this.#contasServ
        .fixes(this.#contas)
        .sort(compareValues('__nomeCompleto')) as IConta[]
      );
    }

  }

  tid_con(index: any, item: IConta): string { return item?.id || ''; }
  //#endregion

  //#region methods
  onOperadorAddClick() {
    const DIALOG_EXISTS = this.#modal.getDialogById('operador-add');
    if (!DIALOG_EXISTS) {
      this.#modal.open(
        OperadorHabilitarModal,
        {
          id: 'operador-add',
          panelClass: 'no-border-dialog-container',
          data: {
            conta: this.#vm?.conta,
            loja: this.#vm?.loja,
          }
        }
      );
    } // if
  }

  onOperadorEditClick(c: IConta) {
    console.log(c);

  }

  onOperadorDelClick(c: IConta) {
    console.log(c);

  }
  //#endregion
  /*
  //#region publics
  conta: IConta;
  operadores: IConta[];
  paginator: CorAngularFirePaginator<IConta>;
  storageKey: string = KEY_OFFSET_OPERADORES;
  //#endregion

  //#region itemsPerPage
  private _itemsPerPage: string = '';
  set itemsPerPage(val: string) {
    this._itemsPerPage = val;
    this.#refresh();
  }
  get itemsPerPage(): string {
    return this._itemsPerPage;
  }
  //#endregion

  //#region privates
  private #contasUnsub: Subscription;
  private _loja: ILoja;
  private _subs: Subscription[] = [];
  //#endregion

  //#region lifecycles
  ngOnInit(): void {
    this._subs.push(
      this.#contasStore.contaStateChanged$
        .subscribe((conta: IConta) => {
          // console.log(conta);
          this.conta = conta ? this.#contasServ.fix(conta) : null;
          if (conta) {
            // const ROLE: TOperadorRole = get(conta, 'operador.role');
            !ROTAS?.operadores?.__valid?.status &&
              this.#router.navigateByUrl(HOME_ROUTE);
          } // if
        }),

      AppService.lojaStateChanged$
        .subscribe((loja: ILoja) => {
          // console.log(loja);
          this._loja = loja ? this.#lojasServ.fix(loja) : null;
          this.#refresh();
        }),
    );
  }

  ngOnDestroy(): void {
    this._subs.forEach((s: Subscription) => s && s.unsubscribe());
    this.#contasUnsub && this.#contasUnsub.unsubscribe();
    this.paginator.unsub();
  }
  //#endregion

  //#region functions
  tid(index: any, item: any): number { return item?.id || ''; }

  private #refresh() {
    const LOJA_ID: string = this._loja?.id || '';
    // console.log(LOJA_ID);
    if (LOJA_ID) {
      const NOTIFICACOES_PATH: string = `/_contas`;
      this.paginator = new CorAngularFirePaginator(
        this.#db,
        NOTIFICACOES_PATH,
        Number(this.itemsPerPage || '12'),
        [{ field: 'nome.nome', direction: 'asc' }],
        [
          { field: '_lojasIds', op: 'array-contains', val: LOJA_ID },
        ],
        (snaps: IConta[]) => {
          // console.log(snaps);
          (snaps || [])
            .forEach(
              (s: IConta) => {
                let item: IConta = (this.operadores || [])
                  .find((o: IConta) => o?.id === s?.id);
                if (!!item) {
                  item = { ...item, ...s };
                } else {
                  (this.operadores || []).push(this.#contasServ.fix(s));
                } // else
              }
            );
        }
      );

      this.#contasUnsub && this.#contasUnsub.unsubscribe();
      this.#contasUnsub = this.paginator.items$
        .subscribe(
          (notificacoes: IConta[]) => {
            this.operadores = this.#contasServ.fixes(notificacoes)
              .filter((e: IConta) => !!e?.__displayInPagination);
            // console.log(JSON.stringify(this.notificacoes));
          }
        );
    } // if
  }
  //#endregion

  //#region methods
  */
}
