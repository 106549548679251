//#region ng
import {
  Component,
  Inject,
  inject,
  signal,
} from "@angular/core";
//#endregion

//#region mat
import {
  MatDialogRef,
  MAT_DIALOG_DATA
} from '@angular/material/dialog';
//#endregion

//#region models
import { environment } from "src/environments/environment";
import { IProImgProdutoMap } from "../../_shared/_produtos/_misc/_models/_interfaces/_maps";
import { IGaleriaGetParams } from "../../models/interfaces/params";
import { IProGaleria } from "../../_shared/_produtos/_misc/_models/_interfaces/_cols";
//#endregion

//#region libs
import { nextId } from "../../_shared/_libs/_misc/_numbers";
//#endregion

@Component({
  selector: "app-galeria-get-modal",
  templateUrl: "galeria-get.modal.html",
  styleUrls: ["galeria-get.modal.scss"]
})
export class GaleriaGetModal {
  //#region publics
  sugestao = signal<string>('');
  excecoes = signal<string[]>([]);
  //#endregion

  //#region injects
  #dialogRef = inject(MatDialogRef<GaleriaGetModal>);
  //#endregion

  //#region constructor
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: IGaleriaGetParams,
  ) { }
  //#endregion

  //#region lifecycles
  ngOnInit() {
    this.sugestao.set(this.data?.sugestao || '');
    this.excecoes.set(this.data?.excecoes || []);
  }
  //#endregion

  //#region methods
  onModalCloseClick(i: IProImgProdutoMap = null) {
    this.#dialogRef.close(i);
  }

  // onSelected(i: IProImgProdutoMap) {
  onSelected(i: IProGaleria) {
    // console.log(i);
    const GALERIA_ID: string = i?.id || '';
    if (!!GALERIA_ID) {
      const I: IProImgProdutoMap = {
        id: GALERIA_ID,
        img: `${environment.firebase.conecdata.storage.root}/galeria%2fimg_${GALERIA_ID}.jpg?alt=media`,
        thumb: `${environment.firebase.conecdata.storage.root}/galeria%2fthumb_${GALERIA_ID}.jpg?alt=media`,
        _pos: { val: nextId() },
        _res: Number(i?._res) || 0,
      };
      // console.log(I);
      this.onModalCloseClick(I);
    } // if
  }
  //#endregion
}
