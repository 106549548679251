//#region ng
import {
  Component,
  Input,
  inject,
  signal,
} from '@angular/core';
import {
  AbstractControl,
  FormBuilder,
  FormGroup,
  Validators
} from '@angular/forms';
//#endregion

//#region models
import { IConta } from '../../_shared/_mercadeiro/_misc/_models/_interfaces/_cols';
//#endregion

//#region services
import { ContasService } from '../../_shared/_mercadeiro/_ng/_services';
//#endregion

@Component({
  selector: 'app-operador-tipo-form',
  templateUrl: './operador-tipo-form.component.html',
  styleUrls: ['./operador-tipo-form.component.scss']
})
export class OperadorTipoFormComponent {
  //#region props
  #conta: IConta;
  get conta(): IConta { return this.#conta; }
  @Input() set conta(val: IConta) {
    this.#conta = !!val ? this.#contasServ.fix(val) : null;
  }
  //#endregion

  //#region publics
  operadorTipoForm = signal<FormGroup>(null);
  //#endregion

  //#region injects
  #contasServ = inject(ContasService);
  #fb = inject(FormBuilder);
  //#endregion

  //#region lifecycles
  ngOnInit() {
    this.operadorTipoForm.set(
      this.#fb.group({
        operador: this.#fb.group({
          role: [this.conta?.operador?.role || '', [Validators.required]],
          _lojasIds: [null]
        }),
      })
    );
  }
  //#endregion

  //#region Controls getters
  get operadorRoleRef(): AbstractControl { return this.operadorTipoForm()?.get('operador.role'); }
  //#endregion
}
