<ng-container *ngIf="vm$() | async as vm">
  <div class="box">
    <nav aria-label="breadcrumb">
      <ol class="breadcrumb">
        <li class="breadcrumb-item">
          <span class="icon-o-home primary tint o-50 pointer"
                routerLink="/dashboard"></span>
        </li>

        <li class="breadcrumb-item active">
          Dashboard
        </li>
      </ol>
    </nav>

    <!-- <p>vm {{ vm | json }}</p> -->
    <!-- <p>vm?.lojaApi {{ vm?.lojaApi | json }}</p> -->

    <div class="d-flex dashboard">
      <div class="flex-1">
        <div class="cells">
          <div class="cell p-relative pointer"
               [class.wiggle]="!vm?.lojaApi?.produtos"
               routerLink="/produtos">
            <div class="text-start">
              <h6>PRODUTOS</h6>
              <h1>{{ vm?.lojaApi?.produtos || 0 }}</h1>
            </div>
            <div class="icon">
              <span class="icon-b-barcode rem-30"></span>
            </div>
          </div>

          <div class="cell p-relative pointer"
               [class.wiggle]="!vm?.lojaApi?.d1"
               routerLink="/departamentos">
            <div class="text-start">
              <h6>DEPARTAMENTOS 1</h6>
              <h1>{{ vm?.lojaApi?.d1 || 0 }}</h1>
            </div>
            <div class="icon">
              <span class="icon-ob-folder rem-30"></span>
            </div>
          </div>

          <div class="cell p-relative pointer"
               [class.wiggle]="!vm?.lojaApi?.d2"
               routerLink="/departamentos">
            <div class="text-start">
              <h6>DEPARTAMENTOS 2</h6>
              <h1>{{ vm?.lojaApi?.d2 || 0 }}</h1>
            </div>
            <div class="icon">
              <span class="icon-ob-folder rem-30"></span>
            </div>
          </div>

          <div class="cell p-relative pointer"
               routerLink="/departamentos">
            <div class="text-start">
              <h6>DEPARTAMENTOS 3</h6>
              <h1>{{ vm?.lojaApi?.d3 || 0 }}</h1>
            </div>
            <div class="icon">
              <span class="icon-ob-folder rem-30"></span>
            </div>
          </div>

          <div class="cell p-relative pointer"
               routerLink="/midia"
               *ngIf="vm?.rotas?.midia?.__valid?.status">
            <div class="text-start">
              <h6>GALERIA</h6>
              <h1>{{ vm?.loja?._qtde?.galeria || 0 }}</h1>
            </div>
            <div class="icon">
              <span class="icon-b-img rem-30"></span>
            </div>
          </div>

          <div class="cell p-relative pointer"
               routerLink="/horarios">
            <div class="text-start">
              <h6>HORÁRIOS FUNCIONAMENTO</h6>
              <h1>{{ vm?.loja?._qtde?.horariosFuncionamento }}</h1>
            </div>
            <div class="icon">
              <span class="icon-o-clock-2 rem-30"></span>
            </div>
          </div>

          <div class="cell p-relative pointer"
               routerLink="/horarios"
               *ngIf="['E', 'ER'].includes(vm?.loja?.entrega?.status)">
            <div class="text-start">
              <h6>HORÁRIOS ENTREGAS</h6>
              <h1>{{ vm?.loja?._qtde?.horariosEntrega }}</h1>
            </div>
            <div class="icon">
              <span class="icon-o-clock-2 rem-30"></span>
            </div>
          </div>

          <div class="cell p-relative pointer"
               routerLink="/setoresEntrega"
               *ngIf="['E', 'ER'].includes(vm?.loja?.entrega?.status)">
            <div class="text-start">
              <h6>SETORES ENTREGAS</h6>
              <h1>{{ vm?.loja?._qtde?.setoresEntrega }}</h1>
            </div>
            <div class="icon">
              <span class="icon-ob-pin rem-30"></span>
            </div>
          </div>
        </div>
      </div>

      <div class="info">
        <div class="d-flex flex-column">
          <div class="cell">
            <h5 class="primary">Status</h5>

            <div class="d-flex flex-1">
              <div class="flex-1">Online</div>
              <div>
                <mat-slide-toggle [checked]="vm?.loja?.ativo?._status"
                                  [disabled]="true">
                </mat-slide-toggle>
              </div>
            </div>

            <mat-divider class="my-2"></mat-divider>

            <h5 class="primary">Tipo</h5>

            <div class="d-flex flex-1">
              <div class="flex-1">{{ vm?.loja?.tipo?._nome }}</div>
            </div>

            <mat-divider class="my-2"></mat-divider>

            <h5 class="primary">Rede</h5>

            <div class="d-flex flex-1">
              <div class="flex-1">{{ vm?.loja?.rede?._nome }}</div>
            </div>

            <mat-divider class="my-2"></mat-divider>

            <h5 class="primary">Integradora</h5>

            <div class="d-flex flex-1">
              <div class="flex-1">{{ vm?.loja?.integradora?._nome }}</div>
            </div>

            <mat-divider class="my-2"></mat-divider>

            <h5 class="primary">Distribuidora</h5>

            <div class="d-flex flex-1">
              <div class="flex-1">Status</div>
              <mat-slide-toggle [checked]="vm?.loja?.distribuidora?.status"
                                [disabled]="true">
              </mat-slide-toggle>
            </div>

            <div class="d-flex">
              <div class="flex-1">Tipo rota</div>
              <strong>
                {{ vm?.lojaDistribuidoraTipoRota?.[vm?.loja?.distribuidora?.tipoRota] }}
              </strong>
            </div>

            <mat-divider class="my-2"></mat-divider>

            <h5 class="primary">Retirada</h5>

            <div class="d-flex flex-1">
              <div class="flex-1">Status</div>
              <mat-slide-toggle [checked]="['R', 'ER'].includes(vm?.loja?.entrega?.status)"
                                [disabled]="true">
              </mat-slide-toggle>
            </div>

            <mat-divider class="my-2"></mat-divider>

            <h5 class="primary">Entrega</h5>

            <div class="d-flex flex-1">
              <div class="flex-1">Status</div>
              <mat-slide-toggle [checked]="['E', 'ER'].includes(vm?.loja?.entrega?.status)"
                                [disabled]="true">
              </mat-slide-toggle>
            </div>

            <div class="d-flex flex-1"
                 [class.off]="['E', 'ER'].includes(vm?.loja?.entrega?.status)">
              <div class="flex-1">Roteiro</div>
              <mat-slide-toggle [checked]="vm?.loja?.entrega?._roteiro"
                                [disabled]="true">
              </mat-slide-toggle>
            </div>

            <mat-divider class="my-2"></mat-divider>

            <h5 class="primary">Taxa geral</h5>

            <div class="d-flex flex-1">
              <div class="flex-1">Status</div>
              <mat-slide-toggle [checked]="vm?.loja?.entrega?.taxaGeral?.status"
                                [disabled]="true">
              </mat-slide-toggle>
            </div>

            <div class="d-flex flex-1"
                 [class.off]="!vm?.loja?.entrega?.taxaGeral?.status">
              <div class="flex-1">Valor</div>
              <strong>
                {{ vm?.loja?.entrega?.taxaGeral?.valor | currency: "BRL":"symbol":"1.2-2" }}
              </strong>
            </div>

            <mat-divider class="my-2"></mat-divider>

            <h5 class="primary">Pedido mínimo</h5>

            <div class="d-flex">
              <div class="flex-1">Retirada</div>
              <strong>
                {{ vm?.loja?.pedidos?.minimo?.retirada | currency: "BRL":"symbol":"1.2-2" }}
              </strong>
            </div>

            <div class="d-flex"
                 [class.off]="!vm?.loja?.entrega?.status">
              <div class="flex-1">Entrega</div>
              <strong>
                {{ vm?.loja?.pedidos?.minimo?.entrega | currency: "BRL":"symbol":"1.2-2" }}
              </strong>
            </div>

            <mat-divider class="my-2"></mat-divider>

            <h5 class="primary">Taxa serviços</h5>

            <div class="d-flex flex-1">
              <div class="flex-1">Valor</div>
              <strong>
                {{ vm?.loja?.faturamento?.servicos?.valor | currency: "BRL":"symbol":"1.2-2" }}
              </strong>
            </div>

            <div class="d-flex flex-1">
              <div class="flex-1">
                Valor cliente
                <small class="o-50">
                  ( {{ vm?.loja?.faturamento?.servicos?.percCliente | number: "1.2-2" }}% )
                </small>
              </div>
              <strong>
                {{ vm?.loja?.faturamento?.servicos?.valor * vm?.loja?.faturamento?.servicos?.percCliente / 100 |
                currency:
                "BRL":"symbol":"1.2-2" }}
              </strong>
            </div>

            <div class="d-flex flex-1">
              <div class="flex-1">
                Valor loja
                <small class="o-50">
                  ( {{ 100 - vm?.loja?.faturamento?.servicos?.percCliente | number: "1.2-2" }}% )
                </small>
              </div>
              <strong>
                {{ vm?.loja?.faturamento?.servicos?.valor * (100 - vm?.loja?.faturamento?.servicos?.percCliente) / 100 |
                currency:
                "BRL":"symbol":"1.2-2" }}
              </strong>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</ng-container>
