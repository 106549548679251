//#region ng
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';
//#endregion

//#region 3rd
// import { PinchZoomModule } from 'ngx-pinch-zoom';
// import { NgxMaskModule } from 'ngx-mask';
// import { Ng2BRPipesModule } from 'ng2-brpipes';
//#endregion

//#region mat
// import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatButtonModule } from '@angular/material/button';
// import { MatDialogModule } from '@angular/material/dialog';
// import { MatCardModule } from '@angular/material/card';
// import { MatChipsModule } from '@angular/material/chips';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatRadioModule } from '@angular/material/radio';
// import { MatSnackBarModule } from '@angular/material/snack-bar';
// import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatStepperModule } from '@angular/material/stepper';
import { MatToolbarModule } from '@angular/material/toolbar';
// import { MatCheckboxModule } from '@angular/material/checkbox';
// import { MatMenuModule } from '@angular/material/menu';
// import { MatSelectModule } from '@angular/material/select';
// import { MatTooltipModule } from '@angular/material/tooltip';
// import {
//   MatSnackBarModule,
//   MAT_SNACK_BAR_DEFAULT_OPTIONS
// } from '@angular/material/snack-bar';
//#endregion

//#region modules
import { CorMatComponentsModule } from '../../_shared/_core/_ng-ui/_mat/cor-mat-components.module';
// import { ComponentsModule } from '../../components/components.module';
import { CorDirectivesModule } from '../../_shared/_core/_ng/_directives/cor-directives.module';
// import { CorMatComponentsModule } from '../../_shared/_core/_ng-ui/_mat/cor-mat-components.module';
// import { CorBsComponentsModule } from '../../_shared/_core/_ng-ui/_bs/cor-bs-components.module';
// import { MeMatComponentsModule } from '../../_shared/_mercadeiro/_ng-ui/_mat/me-mat-components.module';
// import { CorPipesModule } from '../../_shared/_core/_ng/_pipes/cor-pipes.module';
// import { CorDirectivesModule } from '../../_shared/_core/_ng/_directives/cor-directives.module';
import { ComponentsModule } from '../../components/components.module';
//#endregion

//#region components
import { OperadorHabilitarModal } from '..';
//#endregion

@NgModule({
  imports: [
    // CorBsComponentsModule,
    CorDirectivesModule,
    CorMatComponentsModule,
    // CorPipesModule,
    CommonModule,
    ComponentsModule,
    // MatAutocompleteModule,
    MatButtonModule,
    // MatCardModule,
    // MatChipsModule,
    // MatDialogModule,
    MatFormFieldModule,
    MatIconModule,
    MatInputModule,
    MatRadioModule,
    // MatSlideToggleModule,
    // MatSnackBarModule,
    MatStepperModule,
    MatToolbarModule,
    // MatTooltipModule,
    // MeMatComponentsModule,
    // PinchZoomModule,
    // Ng2BRPipesModule,
    // NgxMaskModule,
    ReactiveFormsModule,
    // MatMenuModule,
    // MatSelectModule,
  ],
  declarations: [
    OperadorHabilitarModal,
  ],
  exports: [
    OperadorHabilitarModal,
  ],
})
export class OperadorHabilitarModalModule { }
