//#region ng
import {
  EventEmitter,
  Injectable,
  inject,
  signal
} from '@angular/core';
//#endregion

//#region 3rd
import { BehaviorSubject } from 'rxjs';
//#endregion

//#region models
import { CorRota } from '../_shared/_core/_misc/_models/_classes';
import { ICorRota } from '../_shared/_core/_misc/_models/_interfaces/_misc';
import {
  IConta,
  ILoja,
  IProduto
} from '../_shared/_mercadeiro/_misc/_models/_interfaces/_cols';
import { IHorarioAgendamentoMap } from '../_shared/_mercadeiro/_misc/_models/_interfaces/_maps';
import { TOperadorRole } from '../_shared/_mercadeiro/_misc/_models/_types';
//#endregion

//#region services
import { LojasService } from '../_shared/_mercadeiro/_ng/_services';
import { StorageService } from './storage.service';
//#endregion

//#region models
export const ROTAS: any = {
  dashboard: new CorRota(
    'Dashboard',
    '/dashboard',
    'icon-o-home',
    [],
    '',
    { calcValid: () => true }
  ),
  notificacoes: new CorRota(
    'Notificações',
    '/notificacoes',
    'icon-o-bell-on',
    [],
    '',
    { calcValid: () => true }
  ),
  lojas: new CorRota(
    'Lojas',
    '/lojas',
    'icon-o-store',
    ['integradora', 'parceiro', 'dono', 'gerente', 'marketing'],
    ''
  ),
  produtos: new CorRota(
    'Produtos',
    '/produtos',
    'icon-b-barcode',
    ['integradora', 'parceiro', 'dono', 'gerente', 'marketing'],
    ''
  ),
  departamentos: new CorRota(
    'Departamentos',
    '/departamentos',
    'icon-ob-folder',
    ['integradora', 'parceiro', 'dono', 'gerente', 'marketing'],
    ''
  ),
  distribuidora: new CorRota(
    'Distribuidora',
    '/distribuidora',
    'icon-o-truck',
    ['integradora', 'parceiro', 'dono', 'gerente'],
    '',
  ),
  setoresEntrega: new CorRota(
    'Setores entrega',
    '/setoresEntrega',
    'icon-ob-pin',
    ['integradora', 'parceiro', 'dono', 'gerente'],
    '',
    // {
    //   calcValid: (data: object) => {
    //     console.log(data);
    //     return true;
    //   }
    // }
  ),
  horarios: new CorRota(
    'Horários',
    '/horarios',
    'icon-o-clock-2',
    ['integradora', 'parceiro', 'dono', 'gerente'],
    ''
  ),
  operadores: new CorRota(
    'Operadores',
    '/operadores',
    'icon-r-people',
    ['integradora', 'parceiro', 'dono'],
    ''
  ),
  formasPgto: new CorRota(
    'Formas pgto',
    '/formasPgto',
    'icon-b-dolar',
    ['integradora', 'parceiro', 'dono', 'gerente'],
    ''
  ),
  /* midia: new CorRota(
    'Midia',
    '/midia',
    'icon-o-play',
    ['integradora', 'dono', 'gerente', 'marketing'],
    ''
  ), */
  pedidos: new CorRota(
    'Pedidos',
    '/pedidos',
    'icon-o-list',
    ['integradora', 'parceiro', 'dono', 'gerente'],
    ''
  ),
  movimento: new CorRota(
    'Movimento',
    '/movimento',
    'icon-o-graph-cols',
    ['integradora', 'parceiro', 'dono'],
    ''
  ),
  config: new CorRota(
    'Config',
    '/config',
    'icon-s-cog',
    ['integradora', 'parceiro', 'dono'],
    ''
  ),
};
//#endregion

@Injectable({
  providedIn: 'root'
})
export class AppService {
  //#region lojaState
  // private _lojaState: Partial<ILoja>;
  // setLojaState(val: Partial<ILoja>) {
  //   // console.log(val);
  //   this._lojaState = !!val ? this.#lojasServ.fix(val) : null;
  //   const LOJA_ID: string = val?.id || '';
  //   // console.log(LOJA_ID);
  //   LOJA_ID && this.#storageServ.lojaIdSet(LOJA_ID);
  //   AppService.lojaStateChanged$.next(this._lojaState as ILoja);
  // }
  // getLojaState(): Partial<ILoja> {
  //   return this._lojaState;
  // }
  //#endregion

  //#region events
  // static lojaStateChanged$ = new BehaviorSubject<ILoja>(null);
  static operadorHabilitadoChanged$ = signal<EventEmitter<IConta>>(new EventEmitter<IConta>());
  static horarioChanged$ = signal<EventEmitter<IHorarioAgendamentoMap>>(new EventEmitter<IHorarioAgendamentoMap>());
  static addImgProdutoFromGaleria$ = signal<EventEmitter<IProduto>>(new EventEmitter<IProduto>());
  //#endregion

  //#region injects
  // #lojasServ = inject(LojasService);
  // #storageServ = inject(StorageService);
  //#endregion

  //#region functions
  getRotas(
    role: TOperadorRole,
    integradoraId: string,
    isDistribuidora: boolean,
  ): ICorRota[] {
    // console.log(role, integradoraId, isDistribuidora);
    const RET: ICorRota[] = [];
    // console.log(Object.keys(ROTAS));
    Object.keys(ROTAS)
      .forEach(
        (id: string) => {
          let rota: ICorRota = ROTAS[id];
          if (!!rota) {
            // console.log(ROTAS, id, role);
            rota.validate([role], integradoraId);
            switch (rota?.url) {
              case '/distribuidora':
                rota.__valid = {
                  status: rota?.__valid?.status && !!isDistribuidora,
                  isAdmin: rota?.__valid?.isAdmin,
                  hasRole: rota?.__valid?.hasRole,
                  hasCustomValidation: true,
                };
                break;

              case '/setoresEntrega':
                rota.__valid = {
                  status: rota?.__valid?.status && !isDistribuidora,
                  isAdmin: rota?.__valid?.isAdmin,
                  hasRole: rota?.__valid?.hasRole,
                  hasCustomValidation: true,
                };
                break;
            } // switch

            // console.log(rota);
            RET.push(rota);
          } // if
        }
      );
    // console.log(RET);

    return RET;
  }
}
