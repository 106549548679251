//#region ng
import {
  Component,
  Input,
  computed,
  inject,
  signal
} from '@angular/core';
import {
  FormGroup,
  FormBuilder,
  Validators,
  FormControl,
  ValidationErrors,
  AbstractControl
} from '@angular/forms';
import { Router } from '@angular/router';
//#endregion

//#region mat
import { MatSnackBar } from '@angular/material/snack-bar';
//#endregion

//#region 3rd
import {  Subscription } from 'rxjs';
import {
  first,
  finalize,
} from 'rxjs/operators';
import { get } from 'lodash';
//#endregion

//#region models
import { ILoja } from '../../../_shared/_mercadeiro/_misc/_models/_interfaces/_cols';
import { CorFormValidation } from '../../../_shared/_core/_ng/_models/_classes';
//#endregion

//#region custom validators
class CustomValidator {
  static percServCli(that: any) {
    return (control: FormControl): TNullable<ValidationErrors> => {
      const PERC: number = Number(control.value || '') || 0;
      const FORM_GROUP: FormGroup = get(control, '_parent');
      const VAL: number = FORM_GROUP?.value?.valor || 0;
      // console.log(PERC, VAL);
      // console.log(that);
      that.taxaServ =
      {
        loja: VAL * PERC / 100,
        cliente: VAL * (100 - PERC) / 100,
      };
      return PERC < 0 || PERC > 100 ? { percServCli: true } : null;
    }
  }
}
//#endregion

//#region services
import { LojasService } from '../../../_shared/_mercadeiro/_ng/_services';
import { CorLoaderService } from '../../../_shared/_core/_ng/_services';
//#endregion

//#region stores
import { LojasStore } from '../../../_shared/_mercadeiro/_ng/_stores';
import { TNullable } from 'src/app/_shared/_core/_misc/_models/_types';
//#endregion

@Component({
  selector: 'app-config-form',
  templateUrl: './config-form.component.html',
  styleUrls: ['./config-form.component.scss']
})
export class ConfigFormComponent {
  //#region inputs
  #loja: ILoja;
  get loja(): ILoja { return this.#loja; }
  @Input({ required: true }) set loja(val: ILoja) {
    this.#loja = !!val ? this.#lojasServ.fix(val) : null;
    // console.log(val);

    this.configForm.set(
      this.#fb.group({
        entrega: this.#fb.group({
          status: [val?.entrega?.status]
        }),
        email: [val?.email || ''],
        siteInstitucional: [val?.siteInstitucional || ''],
        faturamento: this.#fb.group({
          servicos: this.#fb.group({
            percCliente: [val?.faturamento?.servicos?.percCliente, [CustomValidator.percServCli(this)]],
            valor: [val?.faturamento?.servicos?.valor],
          }),
          // valorCarrinhoEntregaGratis: [0, [Validators.min(1)]],
        }),
        fones: this.#fb.group({
          celular: [val?.fones?.celular || ''],
          fixo: [val?.fones?.fixo || ''],
          mensagens: [val?.fones?.mensagens || ''],
        }),
        pedidos: this.#fb.group({
          minimo: this.#fb.group({
            entrega: [val?.pedidos?.minimo?.entrega],
            retirada: [val?.pedidos?.minimo?.retirada],
          }),
          limiteDiasAgendamento: this.#fb.group({
            entrega: [val?.pedidos?.limiteDiasAgendamento?.entrega],
            retirada: [val?.pedidos?.limiteDiasAgendamento?.retirada],
          }),
          valorCarrinhoEntregaGratis: [val?.pedidos?.valorCarrinhoEntregaGratis],
        }),
        redesSociais: this.#fb.group({
          facebook: [val?.redesSociais?.facebook || ''],
          instagram: [val?.redesSociais?.instagram || ''],
          twitter: [val?.redesSociais?.twitter || ''],
          youtube: [val?.redesSociais?.youtube || ''],
        }),
      })
    );

    !!val?.faturamento?.servicos?.valor
      ? this.percServCliRef?.enable()
      : this.percServCliRef?.disable();
  }
  //#endregion

  //#region publics
  configForm = signal<FormGroup>(null);
  fv = signal<CorFormValidation>(null);
  taxaServ: { loja: 0, cliente: 0 };
  //#endregion

  //#region injects
  #fb = inject(FormBuilder);
  #loaderServ = inject(CorLoaderService);
  #lojasServ = inject(LojasService);
  #lojasStore = inject(LojasStore);
  #router = inject(Router);
  #snackBar = inject(MatSnackBar);
  //#endregion

  //#region
  constructor() {
    this.fv.set(new CorFormValidation);
  }
  //#endregion

  //#region Controls getters
  get entregaRef(): AbstractControl { return this.configForm()?.get('entrega.status'); }
  get percServCliRef(): AbstractControl { return this.configForm()?.get('faturamento.servicos.ercCliente'); }
  get emailRef(): AbstractControl { return this.configForm()?.get('email'); }
  get foneCelRef(): AbstractControl { return this.configForm()?.get('fones.celular'); }
  get foneFixoRef(): AbstractControl { return this.configForm()?.get('fones.fixo'); }
  get foneMsgsRef(): AbstractControl { return this.configForm()?.get('fones.mensagens'); }
  //#endregion

  //#region functions
  formatLabel(value: number) { return value + '%'; }
  //#endregion

  //#region methods
  onSubmitClick() {
    // console.log(this.configForm()?.value);
    // const LOJA_PATH: string = get(this.loja, '__idInfo.lojaPath') || '';
    const LOJA_ID: string = this.loja?.id || '';
    // console.log(LOJA_ID, this.configForm()?.value);
    if (!!LOJA_ID) {
      const SUB: Subscription = this.#loaderServ.showUntilCompleted(
        this.#lojasServ.update(LOJA_ID, this.configForm()?.value)
          .pipe(first(), finalize(() => SUB?.unsubscribe()))
      )
        .subscribe(
          () => {
            this.#lojasStore.setState(
              {
                ...this.loja,
                ...this.configForm()?.value
              },
              this.loja?.rede?._tema
            );
            this.#snackBar.open(`Configuração gravada.`);
            this.#router.navigateByUrl('/dashboard');
          }
        );
    } // if
  }
  //#endregion
}
