<div class="modal-body">
  <mat-toolbar class="header"
               color="primary">
    <mat-toolbar-row class="d-flex justify-content-between">
      <div class="my-auto">
        <h3>{{ produto()?.nome }}</h3>
      </div>

      <div class="my-auto">
        <button mat-icon-button
                (click)="onModalCloseClick()">
          <mat-icon>close</mat-icon>
        </button>
      </div>
    </mat-toolbar-row>
  </mat-toolbar>

  <!-- <p>tab {{ tab() }}</p> -->
  <!-- <p>produto() {{ produto() | json }}</p> -->

  <div class="container-fluid p-3">
    <div class="mx-4 mt-2">
      <div class="d-flex"
           *ngIf="produto()?._base?.marca?.nome">
        <span class="ms-auto badge text-bg-light">
          {{ produto()?._base?.marca?.nome }}
          <small *ngIf="produto()?._base?.marca?.linha?.nome"
                 class="o-50">
            / {{ produto()?._base?.marca?.linha?.nome }}
          </small>
        </span>
      </div>

      <nav>
        <ol class="breadcrumb">
          <li class="breadcrumb-item active"
              *ngIf="!!produto()?.departamentos?.d1?.nome">
            {{ produto()?.departamentos?.d1?.nome }}
          </li>

          <li class="breadcrumb-item active"
              *ngIf="!!produto()?.departamentos?.d2?.nome">
            {{ produto()?.departamentos?.d2?.nome }}
          </li>

          <li class="breadcrumb-item active"
              *ngIf="!!produto()?.departamentos?.d3?.nome">
            {{ produto()?.departamentos?.d3?.nome }}
          </li>
        </ol>
      </nav>
    </div>

    <div class="row">
      <div class="col-6">
        <app-produto-form [produto]="produto()"
                          [lojaPath]="lojaPath()"
                          (submitted$)="onFormSubmitted()" />
      </div>

      <div class="col-6">
        <div class="segment mb-4 flex-1 d-flex justify-content-center">
          <ul class="nav justify-content-center nav-pills">
            <li class="nav-item"
                (click)="tab.set('imgs')">
              <a class="nav-link"
                 [class.active]="tab() === 'imgs'">
                Imagens
              </a>
            </li>

            <li class="nav-item"
                (click)="tab.set('info')"
                *ngIf="produto()?._base?.info">
              <a class="nav-link"
                 [class.active]="tab() === 'info'">
                Info
              </a>
            </li>
          </ul>
        </div>

        <div [ngSwitch]="tab()">
          <div *ngSwitchCase="'info'">
            <markdown [data]="produto()?._base?.info" />
          </div>

          <div *ngSwitchDefault>
            <me-mat-produto-imgs [produto]="produto()"
                                 [editable]="!produto()?._industrializado"
                                 [isMobile]="false"
                                 (add$)="onAddImgClick()"
                                 (del$)="onDelImgClick($event)"
                                 (left$)="onMoveImgClick($event, true)"
                                 (right$)="onMoveImgClick($event, false)" />
          </div>
        </div>
      </div>
    </div>
  </div>
</div>