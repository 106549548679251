//#region ng
import {
  Component,
} from '@angular/core';
import { Router } from '@angular/router';
import { SafeUrl } from '@angular/platform-browser';
//#endregion

//#region 3rd
import { Subscription } from 'rxjs';
import { set } from 'lodash';
//#endregion

//#region models
import { HOME_ROUTE } from '../../models/consts';
import {
  IConta,
  ILoja
} from '../../_shared/_mercadeiro/_misc/_models/_interfaces/_cols';
// import { TOperadorRole } from '../../_shared/_mercadeiro/_misc/_models/_types';
import { environment } from 'src/environments/environment';
//#endregion

//#region services
import {
  ContasService,
  LojasService
} from '../../_shared/_mercadeiro/_ng/_services';
import {
  AppService,
  ROTAS
} from '../../services';
//#endregion

//#region stores
import { ContasStore } from '../../_shared/_mercadeiro/_ng/_stores';
//#endregion

@Component({
  selector: 'midia-page',
  templateUrl: './midia.page.html',
  styleUrls: ['./midia.page.scss']
})
export class MidiaPage {
  /*
  //#region publics
  conta: IConta;
  loja: ILoja;
  qrcodes: {
    loja: {
      url: string;
      blob?: SafeUrl;
    },
    rede: {
      url: string;
      blob?: SafeUrl;
    }
  } = {
      loja: { url: '' },
      rede: { url: '' }
    };
  qrcodeSize: number = 200;
  //#endregion

  //#region privates
  private _subs: Subscription[] = [];
  //#endregion

  //#region constructor
  constructor(
    private _contasServ: ContasService,
    private _contasStore: ContasStore,
    private lojasServ: LojasService,
    private _router: Router,
  ) { }
  //#endregion

  //#region lifecycles
  ngOnInit(): void {
    this._subs.push(
      this._contasStore.contaStateChanged$
        .subscribe(async (conta: IConta) => {
          // console.log(conta);
          this.conta = conta ? this._contasServ.fix(conta) : null;
          if (conta) {
            // const ROLE: TOperadorRole = get(conta, 'operador.role');
            !ROTAS?.midia?.__valid?.status &&
              this._router.navigateByUrl(HOME_ROUTE);
          } // if
        }),

      AppService.lojaStateChanged$
        .subscribe((loja: ILoja) => {
          // console.log(loja.horarios);
          this.loja = !!loja ? this.lojasServ.fix(loja) : null;
          // console.log(this.loja);
          // this.qrcodes.loja.url = `${environment.url}b851219a--rede1--mg--uberaba/${!!loja?.entrega?.status ? 'true' : 'false'}`;
          set(
            this.qrcodes,
            'loja.url',
            `${environment.url}#/loja/${loja?.id}/${!!loja?.entrega?.status ? 'true' : 'false'}`
          );

          set(
            this.qrcodes,
            'rede.url',
            `${environment.url}#/lojas/rede=${loja?.rede?.id}`
          );
        }),
    );
  }

  ngOnDestroy(): void {
    this._subs.forEach((s: Subscription) => s && s.unsubscribe());
  }
  //#endregion


  //#region methods
  //#endregion
  */
}
