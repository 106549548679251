<div class="modal-body p-relative">
  <mat-toolbar class="header"
               color="primary">
    <mat-toolbar-row class="d-flex justify-content-between">
      <h3> {{ caption() }} </h3>

      <button mat-icon-button
              (click)="onModalCloseClick()">
        <mat-icon>close</mat-icon>
      </button>
    </mat-toolbar-row>
  </mat-toolbar>

  <!-- <p>pTag() {{ pTag() | json }}</p> -->
  <!-- <p>ptagForm()?.value {{ ptagForm()?.value | json }}</p> -->

  <div class="container-fluid">
    <form [formGroup]="ptagForm()"
          class="px-2 py-4"
          (ngSubmit)="onSubmit()"
          *ngIf="!!ptagForm()">

      <div class="row">
        <div class="col">
          <mat-form-field class="w-100 mb-2"
                          [class.off]="!!pTag()?._isSistema"
                          appearance="outline">
            <mat-label>Legenda</mat-label>
            <input type="text"
                   matInput
                   formControlName="caption"
                   [focus]="captionFocusEvent$()"
                   [readonly]="!!pTag()?._isSistema"
                   onReturn>
            <mat-hint *ngIf="fv()?.lerror(captionRef, 'caption')">
              <span class="danger">
                {{ fv()?.error(captionRef, 'caption') }}
              </span>
            </mat-hint>
          </mat-form-field>
        </div>
      </div>

      <div class="row">
        <div class="col">
          <mat-label>Tema</mat-label>
          <cor-bs-theme-sel [tema]="themeRef?.value"
                            (select$)="onTemaClick($event)" />
          <mat-hint *ngIf="fv()?.error(themeRef)">
            <small class="danger mv-top">
              {{ fv()?.error(themeRef) }}
            </small>
          </mat-hint>
        </div>
      </div>

      <div class="row">
        <div class="col d-flex">
          <button type="submit"
                  class="pointer w-100"
                  mat-raised-button
                  color="primary"
                  [disabled]="!ptagForm()?.valid">
            CONFIRMAR
          </button>
        </div>
      </div>
    </form>
  </div>
</div>