export * from './bairro-add/bairro-add.modal';
export * from './credenciado-habilitar/credenciado-habilitar.modal';
export * from './distribuidora-credenciado-form/distribuidora-credenciado-form.modal';
export * from './distribuidora-forma-pgto-form/distribuidora-forma-pgto-form.modal';
export * from './produtos-filtros/produtos-filtros.modal';
export * from './galeria-get/galeria-get.modal';
export * from './horario-form/horario-form.modal';
export * from './loja-info/loja-info.modal';
export * from './operador-habilitar/operador-habilitar.modal';
export * from './pedido-detalhes/pedido-detalhes.modal';
export * from './produto-edit/produto-edit.modal';
export * from './produto-edit/produto-form/produto-form.component';
export * from './produto-info/produto-info.modal';
export * from './p-tag-form/p-tag-form.modal';
export * from './setor-form/setor-form.modal';
