//#region models
import {
  IProGtin,
  IProProduto,
} from '../_models/_interfaces/_cols';
// import { IProImgProdutoMap } from '../_models/_interfaces/_maps';
//#endregion

/* export function geraProdutoBase(
  barcode: string,
  produto: Partial<IProProduto>,
  gtin: IProGtin | null,
  conecdataProdutosStorageRoot: string,
  inconsistencia: boolean,
): Partial<IProProduto> | null {
  const IMGS: IProImgProdutoMap[] = produto?._imgs || [];
  const D1_ID: string = produto?.departamentos?.d1?._id || '';
  const D2_ID: string = produto?.departamentos?.d2?._id || '';
  const D3_ID: string = produto?.departamentos?.d3?._id || '';
  const NOME: string = geraNomeCompleto(produto, gtin || null);
  if (
    !!barcode
    && !!produto
    && !!D1_ID
    && !!D2_ID
    && !produto?.nome
  ) {
    // img/imgs
    const RES: Number = Number(produto?.img?._res) || 0;
    const IMG: any = {
      img: !!RES && !!conecdataProdutosStorageRoot
        ? `${conecdataProdutosStorageRoot}produtos%2Fimg_${barcode}.jpg?alt=media`
        : '',
      thumb: !!RES && !!conecdataProdutosStorageRoot
        ? `${conecdataProdutosStorageRoot}produtos%2Fthumb_${barcode}.jpg?alt=media`
        : '',
      _res: RES,
    };

    const PRODUTO: Partial<IProProduto> = {
      // user
      // atacado: produto?.atacado || '',
      apresentacao: produto?.apresentacao || '',
      complemento: produto?.complemento || '',
      cor: produto?.cor || '',
      departamentos: {
        d1: {
          _id: D1_ID,
          nome: produto?.departamentos?.d1?.nome || '',
        },
        d2: {
          _id: D2_ID,
          nome: produto?.departamentos?.d2?.nome || '',
        },
        d3: {
          _id: D3_ID,
          nome: produto?.departamentos?.d3?.nome || '',
        },
      },
      // descricao: get(produto, 'descricao') || '',
      dimensoes: produto?.dimensoes || '',
      embalagem: produto?.embalagem || '',
      formato: produto?.formato || '',
      idade: produto?.idade || '',
      img: IMG,
      inconsistencia,
      info: produto?.info || '',
      _imgs: IMGS,
      marca: {
        _id: produto?.marca?._id || '',
        linha: {
          _id: produto?.marca?.linha?._id || '',
          nome: produto?.marca?.linha?.nome || '',
        },
        nome: produto?.marca?.nome || '',
      },
      material: produto?.material || '',
      modelo: produto?.modelo || '',
      nacionalidade: produto?.nacionalidade || '',
      ncm: produto?.ncm || '',
      nome: NOME,
      _nomeCompleto: NOME,
      produto: produto?.produto || '',
      qtde: produto?.qtde || '',
      ref: produto?.ref || '',
      sabor: produto?.sabor || '',
      tamanho: produto?.tamanho || '',
      _ver: Number(produto?._ver || 0),
      // verificado: true,
      volume: produto?.volume || '',
    };
    return PRODUTO;
  } // if

  return null;
} */

export function geraNomeCompleto(
  produto: Partial<IProProduto> | IProProduto,
  gtin: IProGtin | null = null,
  useVolume: boolean = false
): string {
  const ATACADO_EMBALAGEM: string = produto?.atacado?.embalagem || '';
  const ATACADO_QTDE: number = Number(produto?.atacado?.qtde) || 0;
  const APRESENTACAO: string = produto?.apresentacao || '';
  const COMPLEMENTO: string = produto?.complemento || '';
  const COR: string = produto?.cor || '';
  const FORMATO: string = produto?.formato || '';
  const IDADE: string = produto?.idade || '';
  const LINHA_NOME: string = produto?.marca?.linha?.nome || '';
  const MARCA_NOME: string = produto?.marca?.nome || '';
  const MODELO: string = produto?.modelo || '';
  const NACIONALIDADE: string = produto?.nacionalidade || '';
  const MATERIAL: string = produto?.material || '';
  const PRODUTO: string = produto?.produto || produto?.nome || '';
  const TAMANHO: string = produto?.tamanho || '';
  const QTDE: string = produto?.qtde || '';
  // console.log(QTDE);
  const REF: string = produto?.ref || '';
  // console.log(REF);
  const SABOR: string = produto?.sabor || '';
  // console.log(SABOR);
  const VOLUME: string = !!useVolume ? produto?.volume || '' : produto?._vol?.val || '';
  // console.log(VOLUME);
  const ATACADO: string = !!ATACADO_EMBALAGEM && ATACADO_QTDE > 0
    ? `${ATACADO_EMBALAGEM} ${ATACADO_QTDE} x `
    : '';
  // console.log(GTIN);

  const GTIN_EMBALAGEM: string = gtin?.embalagem || '';
  const GTIN_QTDE: number = Number(gtin?.qtde) || 0;
  const GTIN: string = !!GTIN_EMBALAGEM && !!GTIN_QTDE ? `${GTIN_EMBALAGEM} ${GTIN_QTDE} x ` : '';
  // console.log(GTIN);

  return `${ATACADO} ${GTIN} ${PRODUTO} ${!!APRESENTACAO ? APRESENTACAO : ''} ${!!FORMATO ? FORMATO : ''} ${!!MATERIAL ? MATERIAL : ''} ${!!TAMANHO ? TAMANHO : ''} ${!!MARCA_NOME ? MARCA_NOME : ''} ${!!LINHA_NOME ? LINHA_NOME : ''} ${!!MODELO ? MODELO : ''} ${!!SABOR ? SABOR : ''} ${!!COR ? COR : ''} ${!!NACIONALIDADE ? NACIONALIDADE : ''} ${!!IDADE ? IDADE : ''} ${!!REF ? 'Ref. ' + REF : ''} ${!!COMPLEMENTO ? COMPLEMENTO : ''} ${!!QTDE ? QTDE : ''} ${!!VOLUME ? VOLUME : ''}`
    .trim()
    .split(' ')
    .filter((s: string) => !!s)
    .join(' ');
  // .replace(/ {2}/g, ' ');
}
