//#region ng
import {
  Component,
  EventEmitter,
  Input,
  Output,
} from '@angular/core';
//#endregion

//#region models
import { IProduto } from '../../../_shared/_mercadeiro/_misc/_models/_interfaces/_cols';
//#endregion

@Component({
  selector: 'app-produtos-produto-icons',
  templateUrl: './produto-icons.component.html',
  styleUrls: ['./produto-icons.component.scss']
})
export class ProdutosProdutoIconsComponent {
  //#region inputs
  @Input({ required: true }) produto: IProduto;
  //#endregion

  //#region outputs
  @Output() imgClick$ = new EventEmitter<void>();
  //#endregion
}
