//#region ng
import {
  Component,
  EventEmitter,
  Input,
  Output,
  inject,
  signal
} from '@angular/core';
//#endregion

//#region 3rd
import {
  combineLatest,
  Subscription,
  Subject,
  Observable,
  BehaviorSubject,
} from 'rxjs';
import {
  debounceTime,
  distinctUntilChanged,
  first,
  finalize,
  map,
  startWith,
  takeUntil,
} from 'rxjs/operators';
import Fuse from 'fuse.js';
//#endregion

//#region models
const ITENS_PER_PAGE: string = '6';
interface IVm {
  galeria: IProGaleria[];
  pag: {
    itemsPerPage: string;
    page: number;
  };
  searchTermAction: string;
};
import { IProGaleria } from '../../_shared/_produtos/_misc/_models/_interfaces/_cols';
//#endregion

//#region libs
import { onDestroy } from 'src/app/_shared/_core/_ng/_libs';
//#endregion

//#region services
import { AppGaleriaService } from '../../services';
import { CorLoaderService } from 'src/app/_shared/_core/_ng/_services';
//#endregion

@Component({
  selector: 'app-galeria-get',
  templateUrl: './galeria-get.component.html',
  styleUrls: ['./galeria-get.component.scss']
})
export class GaleriaGetComponent {
  //#region actions
  #destroyAction$: Subject<void> = onDestroy();
  #hitsAction$ = new Subject<IProGaleria[]>();
  pagPageAction$ = signal<Subject<number>>(new Subject<number>()).asReadonly();
  // pagItemsPerPageAction$ = signal<Subject<string>>(new Subject<string>()).asReadonly();
  searchTermAction$ = signal<BehaviorSubject<string>>(new BehaviorSubject<string>('')).asReadonly();
  //#endregion

  //#region inputs
  @Input() excecoes: string[] = [];
  @Input() sugestao: string = '';
  //#endregion

  //#region outputs
  @Output() select$ = new EventEmitter<IProGaleria>();
  //#endregion

  //#region publics
  vm$ = signal<Observable<IVm>>(null);
  //#endregion

  //#region privates
  #galeria: IProGaleria[];
  #vm: IVm;
  //#endregion

  //#region events
  searchFocusEvent$ = signal<EventEmitter<boolean>>(new EventEmitter<boolean>()).asReadonly();
  //#endregion

  //#region injects
  #galeriaServ = inject(AppGaleriaService);
  #loaderServ = inject(CorLoaderService);
  //#endregion

  //#region lifecycles
  ngOnInit() {
    const SUB: Subscription = this.#loaderServ.showUntilCompleted(
      this.#galeriaServ.galeria()
        .pipe(first(), finalize(() => SUB?.unsubscribe()))
    )
      .subscribe((galeria: IProGaleria[]) => {
        this.#galeria = galeria;
        this.searchTermAction$()?.next(this.sugestao);
      });

    this.searchTermAction$()
      .pipe(
        // startWith(this.sugestao),
        debounceTime(500),
        distinctUntilChanged(),
      )
      .pipe(takeUntil(this.#destroyAction$))
      .subscribe((searchTerm: string) => {
        // console.log(searchTerm);

        if (!!searchTerm) {
          const FUSE: any = new Fuse(
            (this.#galeria || []),
            // (this.#marcas || []),
            {
              includeScore: false,
              keys: [
                'nome',
                'hash'
              ],
              // minMatchCharLength: 3,
            }
          );
          this.#hitsAction$.next(FUSE.search(searchTerm).map(h => h?.item));
        } else {
          this.#hitsAction$.next(this.#galeria);
        } // else
      });

    this.vm$.set(
      combineLatest([
        this.#hitsAction$.pipe(startWith([])),
        this.searchTermAction$(),
        this.pagPageAction$().pipe(startWith(1)),
      ])
        .pipe(
          map(([hits, searchTermAction, pagPage]) => {
            // console.log(hits?.length);
            this.#vm = {
              galeria: hits || [],
              pag: {
                itemsPerPage: ITENS_PER_PAGE,
                page: pagPage,
              },
              searchTermAction,
            };
            // console.log(this.#vm);
            return this.#vm;
          }),
        )
    );
  }
  //#endregion

  //#region functions
  tid_gal(index: any, item: Partial<IProGaleria>): string { return item?.id || ''; }
  //#endregion
}
