<div class="modal-body d-flex flex-column p-relative">
  <mat-toolbar class="header"
               color="primary">
    <mat-toolbar-row>
      <h3>
        Habilitando credenciado
        <small class="o-50"
               *ngIf="contaForm()?.valid">
          ( {{ contaEmailRef?.value }} )
        </small>
      </h3>

      <span class="flex-1"></span>

      <button mat-icon-button
              (click)="onModalCloseClick()">
        <mat-icon>close</mat-icon>
      </button>
    </mat-toolbar-row>
  </mat-toolbar>

  <!-- <p>tipoForm?.value {{ tipoForm?.value | json }}</p> -->
  <!-- <p>contaForm()?.value {{ contaForm()?.value | json }}</p> -->
  <!-- <p>loja {{ loja | json }}</p> -->
  <!-- <p>changes {{ changes | json }}</p> -->
  <!-- <p>conta {{ conta | json }}</p> -->
  <!-- <p>operador {{ operador | json }}</p> -->

  <div class="content flex-1">
    <mat-stepper [linear]="true"
                 class="mx-2"
                 labelPosition="bottom"
                 (selectionChange)="onStepChanged($event)"
                 #stepper>
      <mat-step label="Conta"
                [stepControl]="contaForm()"
                state="conta">
        <div class="mt-4">
          <form [formGroup]="contaForm()"
                novalidate>
            <div class="row">
              <div class="col">
                <mat-form-field class="w-100 mb-2"
                                appearance="outline">
                  <mat-label>E-mail credenciado</mat-label>
                  <input type="text"
                         matInput
                         formControlName="email"
                         [focus]="emailFocusEvent$()"
                         onReturn>
                  <mat-hint *ngIf="fv()?.error(contaEmailRef, 'email')">
                    <span class="danger"> {{ fv()?.error(contaEmailRef, 'email') }} </span>
                  </mat-hint>
                </mat-form-field>
              </div>
            </div>

            <div class="row">
              <div class="col">
                <div class="d-flex">
                  <div class="ms-auto">
                    <button type="button"
                            class="pointer"
                            mat-raised-button
                            color="primary"
                            (click)="onVerificaEmailClick()"
                            [disabled]="!contaEmailRef.valid">
                      VERIFICA CONTA
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </form>
        </div>
      </mat-step>

      <mat-step label="Formas pgto"
                state="formasPgto">
        <div class="d-flex my-2">
          <div class="me-auto">
            <button type="button"
                    mat-flat-button
                    class="pointer"
                    color="light"
                    matStepperPrevious>
              <mat-icon class="o-50">chevron_left</mat-icon>
              <span> Anterior </span>
            </button>
          </div>

          <div class="ms-auto">
            <button type="button"
                    mat-flat-button
                    class="pointer ms-2"
                    color="primary"
                    matStepperNext>
              <span> Próximo </span>
              <mat-icon class="o-50">chevron_right</mat-icon>
            </button>
          </div>
        </div>

        <div class="mt-4">
          <app-credenciado-formas-pgto-form [conta]="contaCredenciado()"
                                            [loja]="loja()" />
        </div>
      </mat-step>

      <mat-step label="Resumo"
                state="resumo">
        <div class="d-flex my-2">
          <div class="me-auto">
            <button type="button"
                    mat-flat-button
                    class="pointer"
                    color="light"
                    matStepperPrevious>
              <mat-icon class="o-50">chevron_left</mat-icon>
              <span> Anterior </span>
            </button>
          </div>

          <div class="ms-auto">
            <button type="submit"
                    class="pointer w-100"
                    mat-raised-button
                    color="primary"
                    (click)="onHabilitarCredenciado()">
              HABILITAR CREDENCIADO
            </button>
          </div>
        </div>

        <ul class="list-group">
          <li class="list-group-item list-group-item-secondary">
            Conta
          </li>

          <li class="list-group-item">
            <span class="o-50">E-mail</span><br />
            <small>{{ contaCredenciado()?.email || '-' }}</small>
          </li>

          <li class="list-group-item">
            <span class="o-50">Nome</span><br />
            <small>{{ contaCredenciado()?.__nomeCompleto || '-' }}</small>
          </li>

          <li class="list-group-item list-group-item-secondary">
            Formas de pagamento
          </li>

          <li class="list-group-item d-flex">
            <ul>
              <li *ngFor="let f of (formasFormRef?.formasSel || []); trackBy:tid_for">
                {{ f?._nome }}
              </li>
            </ul>
            <!-- <span class="o-50">Tipo</span>
            <div class="flex-1"></div>
            <small>{{ changes?.operador?.role || '-' }}</small> -->
          </li>
        </ul>
      </mat-step>
    </mat-stepper>
  </div>
</div>
