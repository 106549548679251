<div class="box">
  <nav aria-label="breadcrumb">
    <ol class="breadcrumb">
      <li class="breadcrumb-item">
        <span class="icon-o-home primary tint o-50 pointer"
              routerLink="/dashboard"></span>
      </li>

      <li class="breadcrumb-item active">
        Movimento
      </li>
    </ol>
  </nav>

  <!-- <p>pre() {{ pre() }}</p> -->

  <me-mat-mov-pedidos-chart [pre]="pre()"
                            [idModulo]="idModulo()"
                            (pedido$)="onPedidoClick($event)" />
</div>