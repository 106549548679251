//#region ng
import { NgModule } from '@angular/core';
import {
  CommonModule,
  NgOptimizedImage
} from '@angular/common';
//#endregion

//#region 3rd
import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';
//#endregion

//#region components
import {
  ProHtmlDepartamentoCardComponent,
  ProHtmlProdutosSkelComponent,
} from '.';
//#endregion

@NgModule({
  imports: [
    CommonModule,
    NgOptimizedImage,
    NgxSkeletonLoaderModule,
  ],
  declarations: [
    ProHtmlDepartamentoCardComponent,
    ProHtmlProdutosSkelComponent,
  ],
  exports: [
    ProHtmlDepartamentoCardComponent,
    ProHtmlProdutosSkelComponent,
  ],
})
export class ProHtmlComponentsModule { }
