//#region ng
import {
  inject,
  Injectable
} from '@angular/core';
import {
  HttpClient,
  HttpHeaders
} from "@angular/common/http";
//#endregion

//#region firebase
import {
  doc,
  Firestore,
  getDoc,
  setDoc,
} from '@angular/fire/firestore';
//#endregion

//#region 3rd
import {
  from,
  Observable,
  ObservableInput,
  throwError
} from "rxjs";
import {
  catchError,
  map,
  tap
} from "rxjs/operators";
//#endregion

//#region models
import { IProGtin } from '../../_misc/_models/_interfaces/_cols';
import { CorMessagesService } from '../../../_core/_ng/_services';
//#endregion

@Injectable({
  providedIn: "root"
})
export class ProGtinsService {
  //#region models
  readonly COSMOS_API = 'https://api.cosmos.bluesoft.com.br';
  // http://api.postmon.com.br/v1/cep/38017030
  //#endregion

  //#region injects
  #msgServ = inject(CorMessagesService);
  #db = inject(Firestore);
  #http = inject(HttpClient);
  //#endregion

  //#region misc
  fix(row: Partial<IProGtin>): IProGtin {
    return {
      // id
      id: row?.id || '',

      // user
      embalagem: row?.embalagem || '',
      gtinBase: row?.gtinBase || '',
      qtde: Number(row?.qtde) || 0,

      // system
      _criadoEm: row?._criadoEm || null,
      _ver: Number(row?._ver) || 0,
    };
  }

  fixes(docs: Partial<IProGtin>[]): IProGtin[] {
    return (docs || []).map((doc: Partial<IProGtin>) => this.fix(doc));
  }
  //#endregion

  //#region cosmos
  cosmos(gtin: string): Observable<any> {
    const HEADERS = new HttpHeaders(
      {
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',
        "Access-Control-Allow-Headers": "Origin,X-Requested-With,Content-Type,Accept",
        'Access-Control-Allow-Methods': 'GET,POST,OPTIONS,DELETE,PUT',
        'X-Cosmos-Token': 'I10GGYqoo5SPVPpt_SpbaQ',
      }
    );
    //   // .set('content-type', 'application/json')
    //   .set('Access-Control-Allow-Origin', '*')
    //   // .set('Access-Control-Allow-Methods', 'GET, POST, OPTIONS, PUT, PATCH, DELETE')
    //   // .set('Access-Control-Allow-Headers', 'X-Requested-With,content-type')
    //   .set('X-Cosmos-Token', 'I10GGYqoo5SPVPpt_SpbaQ')
    // // .set('Access-Control-Allow-Credentials', 'true')

    const URL = encodeURI(`${this.COSMOS_API}/gtins/${gtin}`);
    const OBS$: Observable<any> = this.#http.get(
      URL,
      { 'headers': HEADERS }
    );
    return OBS$;
    // return lastValueFrom(OBS$);
  }
  //#endregion

  //#region C
  add(
    idGtin: string,
    changes: Partial<IProGtin>
  ): Observable<any> {
    // console.log(endereco);
    if (!!idGtin) {
      const PATH: string = `_gtins/${idGtin}`;
      return from(setDoc(doc(this.#db, PATH), changes))
        .pipe(
          // map(() => { throw new Error(`Erro adicionando ${PATH}.`); }),
          catchError<void, ObservableInput<void>>(
            (err: any) => this.#msgServ.onCatchError(err, 'Erro adicionando gtin.')
          )
        );
    } // if
    return throwError(() => 'Nenhum GTIN indicado.');
  }
  //#endregion

  //#region R  
  doc(barcode: string): Observable<IProGtin> {
    // console.log('barcode', barcode);
    if (!!barcode) {
      const PATH: string = `/_gtins/${barcode}`;
      // console.log(PATH);
      // return (docData(doc(this.#db, PATH), { idField: 'id' }) as Observable<IProGtin>)
      return (from(getDoc(doc(this.#db, PATH))) as Observable<any>)
        .pipe(
          // tap(doc => console.log(doc)),
          map((doc: any) => (!!doc.data() ? this.fix({ ...doc.data(), id: doc.id }) : null)),
          // tap(doc => console.log(doc)),
          // map(() => { throw new Error(`Erro lendo ${PATH}.`); }),
          catchError<IProGtin, ObservableInput<IProGtin>>(
            (err: any) => this.#msgServ.onCatchError(err, 'Erro carregando gtin.')
          )
        );
    } // if
    return throwError(() => 'Nenhum código de barras indicado.');
  }
  //#endregion

  //#region U
  update(
    iProGtin: string,
    changes: Partial<IProGtin>
  ): Observable<any> {
    if (!!iProGtin) {
      // console.log(pedido);
      const PATH: string = `/_gtins/${iProGtin}`;
      return from(setDoc(doc(this.#db, PATH), changes, { merge: true }))
        .pipe(
          // map(() => { throw new Error(`Erro lendo ${PATH}.`); }),
          catchError<void, ObservableInput<void>>(
            (err: any) => this.#msgServ.onCatchError(err, 'Erro modificado gtin.')
          )
        );
    } // if
    return throwError(() => 'Nenhum gtin indicado.');
  }
  //#endregion
}
