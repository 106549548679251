<div class="modal-body">
  <mat-toolbar color="primary">
    <mat-toolbar-row class="d-flex justify-content-between">
      <h3> {{ credenciado()?.__nomeCompleto }} <!-- {{ credenciado?.id }} --> </h3>

      <button mat-icon-button
              (click)="onModalCloseClick()">
        <mat-icon>close</mat-icon>
      </button>
    </mat-toolbar-row>
  </mat-toolbar>

  <!-- <p>credenciado {{ credenciado | json }}</p> -->
  <!-- <p>loja {{ loja | json }}</p> -->

  <div class="p-3">
    <app-credenciado-formas-pgto-form [conta]="credenciado()"
                                      [loja]="loja()">
    </app-credenciado-formas-pgto-form>

    <div class="row">
      <div class="col d-flex">
        <button type="submit"
                class="flex-1 pointer"
                mat-raised-button
                color="primary"
                (click)="onSubmit()">
          CONFIRMAR
        </button>
      </div>
    </div>
  </div>
