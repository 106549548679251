//#region ng
import {
  inject,
  Injectable,
} from '@angular/core';
//#endregion

//#region firebase
import {
  doc,
  Firestore,
  getDoc,
} from '@angular/fire/firestore';
//#endregion

//#region 3rd
import {
  catchError,
  from,
  Observable,
  ObservableInput,
  of,
} from 'rxjs';
import { map } from 'rxjs/operators';
//#endregion

//#region models
import { IProOperador } from '../../_misc/_models/_interfaces/_cols';
import { TNullable } from '../../../_core/_misc/_models/_types';
//#endregion

//#region services
import { CorMessagesService } from '../../../_core/_ng/_services';
import { avatar } from '../../../_libs/_misc/_ui-avatar';
//#endregion

@Injectable({
  providedIn: 'root'
})
export class ProOperadoresService {
  //#region injects
  #msgServ = inject(CorMessagesService);
  #db = inject(Firestore);
  //#endregion

  //#region misc
  fix(row: Partial<IProOperador>): IProOperador {
    const NOME_NOME: string = row?.nome?.nome || '';
    const NOME_SOBRENOME: string = row?.nome?.sobrenome || '';
    const NOME_COMPLETO: string = `${NOME_NOME} ${NOME_SOBRENOME}`.trim();

    return {
      // id
      id: row?.id || '',

      // user
      ativo: {
        status: !!row?.ativo?.status,
        _status: !!row?.ativo?._status,
      },
      nome: {
        nome: NOME_NOME,
        sobrenome: NOME_SOBRENOME,
      },
      role: row?.role || null,

      // system
      _criadoEm: row?._criadoEm || null,

      // realtime
      __nomeCompleto: NOME_COMPLETO,
      __urlFoto: /* user?.photoURL || */ avatar({ name: NOME_COMPLETO }),
    };
  }

  fixes(docs: Partial<IProOperador>[]): IProOperador[] {
    return (docs || []).map((doc: Partial<IProOperador>) => this.fix(doc));
  }
  //#endregion

  //#region R
  operadorFromUid(uid: string): Observable<TNullable<IProOperador>> {
    if (!!uid) {
      const PATH: string = `_operadores/${uid}`;
      // console.log(PATH);
      // return (docData(doc(this.#db, PATH), { idField: 'id' }) as Observable<TNullable<IProOperador>>)
      return (from(getDoc(doc(this.#db, PATH))) as Observable<any>)
        .pipe(
          map((doc: any) => (!!doc.data() ? this.fix({ ...doc.data(), id: doc.id }) : null)),
          // map(() => { throw new Error(`Erro lendo ${PATH}.`); }),
          catchError<TNullable<IProOperador>, ObservableInput<TNullable<IProOperador>>>(
            (err: any) => this.#msgServ.onCatchError(err, 'Erro carregando conta.', 'warning', 'dialog')
          )
        );
    } // if
    return of(null); // throwError(() => 'Nenhuma conta indicada.');
  }
  //#endregion
}
