//#region ng
import {
  Component,
  signal
} from '@angular/core';
//#endregion

//#region models
import { THUMB_NO_IMG } from '../../../../_core/_misc/_models/consts';
//#endregion

@Component({
  selector: 'pro-html-produtos-skel',
  templateUrl: './produtos-skel.component.html',
  styleUrls: ['./produtos-skel.component.scss']
})
export class ProHtmlProdutosSkelComponent {
  //#region publics
  imgPlaceholder = signal<string>(THUMB_NO_IMG).asReadonly();
  //#endregion

}
