//#region ng
import {
  Component,
  EventEmitter,
  Inject,
  computed,
  inject,
  signal,
} from '@angular/core';
import {
  FormGroup,
  FormBuilder,
  Validators,
  FormControl,
  ValidationErrors,
  AbstractControl
} from '@angular/forms';
//#endregion

//#region mat
import {
  MatDialogRef,
  MAT_DIALOG_DATA
} from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
//#endregion

//#region firebase
import { getApp } from 'firebase/app';
import {
  getFunctions,
  httpsCallable
} from 'firebase/functions';
//#endregion

//#region 3rd
import { Subscription } from 'rxjs';
import {
  first,
  finalize
} from 'rxjs/operators';
//#endregion

//#region models
import { environment } from 'src/environments/environment';
import {
  ILoja,
  ISetorEntrega
} from '../../_shared/_mercadeiro/_misc/_models/_interfaces/_cols';
import { CorFormValidation } from '../../_shared/_core/_ng/_models/_classes';
// import { IEmailGetParams } from '../../../../_core/_misc/_models/_interfaces/_params/email-get';
import { FOCUS_TIMEOUT } from '../../_shared/_core/_misc/_models/consts';
import { ISetorFormParams } from '../../models/interfaces/params';
//#endregion

//#region custom validators
class CustomValidator {
  static percDesc() {
    return (control: FormControl): TNullable<ValidationErrors> => {
      const PERC: number = Number(control.value || '') || 0;
      // console.log(PERC);
      return PERC < 0 || PERC > 100 ? { percDesc: true } : null;
    }
  }
}
//#endregion

//#region services
import {
  LojasService,
  SetoresEntregaService
} from '../../_shared/_mercadeiro/_ng/_services';
import { CorLoaderService } from '../../_shared/_core/_ng/_services';
import { TNullable } from 'src/app/_shared/_core/_misc/_models/_types';
//#endregion

@Component({
  selector: 'setor-form-modal',
  templateUrl: './setor-form.modal.html',
  styleUrls: ['./setor-form.modal.scss']
})
export class SetorFormModal {
  //#region publics
  fv = signal<CorFormValidation>(null);
  loja = signal<ILoja>(null);
  setor = signal<ISetorEntrega>(null);
  novo = computed<boolean>(() => {
    const SETOR: TNullable<ISetorEntrega> = this.setor() || null;
    return !SETOR?.id;
  });
  caption = computed<string>(() => !!this.novo() ? 'Novo setor de entrega' : this.setor()?.nome || '');
  // setorForm = signal<FormGroup>(null);
  setorForm = computed<FormGroup>(
    () => {
      const NOVO: boolean = this.novo();
      const SETOR: ISetorEntrega = this.setor();
      // console.log(SETOR);      
      return this.#fb.group({
        ativo: this.#fb.group({
          status: [NOVO ? true : SETOR?.ativo?.status],
        }),
        nome: [SETOR?.nome || '', [Validators.required]],
        taxaEntrega: this.#fb.group({
          val: [SETOR?.taxaEntrega?.val || 0, [Validators.required]],
          desc: this.#fb.group({
            status: [!!SETOR?.taxaEntrega?.desc?.status],
            minTotalCarrinho: [SETOR?.taxaEntrega?.desc?.minTotalCarrinho || 0],
            perc: [SETOR?.taxaEntrega?.desc?.perc || 0, [CustomValidator.percDesc()]],
          }),
        }),
        // taxaEntrega: [SETOR?.taxaEntrega || ''],
      });
    }
  );
  //#endregion

  //#region methods
  nomeFocusEvent$ = signal<EventEmitter<boolean>>(new EventEmitter<boolean>());
  //#endregion

  //#region injects
  #dialogRef = inject(MatDialogRef<SetorFormModal>);
  #fb = inject(FormBuilder);
  #loaderServ = inject(CorLoaderService);
  #lojasServ = inject(LojasService);
  #snackBar = inject(MatSnackBar);
  #setoresServ = inject(SetoresEntregaService);
  //#endregion

  //#region constructor
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: ISetorFormParams,
  ) {
    this.fv.set(new CorFormValidation);
  }
  //#endregion

  //#region lifecycles
  ngOnInit(): void {
    // console.log(this.data);
    this.loja.set(!!this.data?.loja ? this.#lojasServ.fix(this.data?.loja) : null);
    this.setor.set(!!this.data?.setor ? this.#setoresServ.fix(this.data?.setor) : null);
  }

  ngAfterViewInit() {
    this.#focus();
  }
  //#endregion

  //#region Controls getters
  get nomeRef(): AbstractControl { return this.setorForm()?.get('nome'); }
  get taxaEntregaRef(): AbstractControl { return this.setorForm()?.get('taxaEntrega.val'); }
  get taxaEntregaDescPercRef(): AbstractControl { return this.setorForm()?.get('taxaEntrega.desc.perc'); }
  get taxaEntregaDescMinCarrinhoRef(): AbstractControl { return this.setorForm()?.get('taxaEntrega.minTotalCarrinho'); }
  //#endregion

  //#region functions
  #focus() {
    // console.log(this.isMobile);
    setTimeout(
      () => {
        this.nomeFocusEvent$().emit(true);
      },
      FOCUS_TIMEOUT
    );
  }
  //#endregion

  //#region methods
  onModalCloseClick(val: any = null) {
    this.#dialogRef.close(val);
  }

  async onSubmitClick() {
    // console.log(this.loja);
    const LOJA_ID = this.loja()?.id || '';
    const LOJA_PATH = this.loja()?.__idInfo?.lojaPath || '';
    const SETOR_ID = this.setor()?.id || '';
    // console.log(LOJA_ID, LOJA_PATH, SETOR_ID);
    if (!!LOJA_ID && !!LOJA_PATH) {
      const CHANGES: Partial<ISetorEntrega> = {
        ...this.setorForm()?.value,
        // _criadoPor: {
        //   id: get(this._conta, 'id') || '',
        //   nome: get(this._conta, '__nomeCompleto') || ''
        // }
      };
      this.#loaderServ.lstart();
      try {
        const functions = getFunctions(
          getApp(),
          environment?.firebase?.region
        );
        const fn = httpsCallable(functions, 'onValidateSetorEntrega');
        const RET: any = await fn({
          data: {
            setor: {
              ...CHANGES,
              id: SETOR_ID
            },
            lojaPath: LOJA_PATH
          }
        });
        // console.log(RET);
        const ERROR: string = RET?.data?.error || '';
        const ERRORS: any = RET?.data?.errors || {};
        // console.log(ERROR);
        // console.log(ERRORS);
        this.fv()?.setApiErrors(ERRORS?.setor);

        if (!ERROR) {
          const SUB: Subscription = this.#loaderServ.showUntilCompleted(
            this.#setoresServ.addUpdate(
              LOJA_ID,
              this.setor()?.id || '',
              this.setorForm()?.value
            )
              .pipe(first(), finalize(() => SUB?.unsubscribe()))
          )
            .subscribe(
              () => {
                this.#snackBar.open(`Setor ${this.novo ? 'criado' : 'modificado'}.`);
                // this.#lojasServ.setLojaState(this.lojaState);
                this.onModalCloseClick();
              }
            );
        } // if
      } finally {
        this.#loaderServ.lstop();
      } // try-finally
    } // if
  }
}
