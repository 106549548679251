<div class="box"
     *ngIf="!!loja()">
  <nav aria-label="breadcrumb">
    <ol class="breadcrumb">
      <li class="breadcrumb-item">
        <span class="icon-o-home primary tint o-50 pointer"
              routerLink="/dashboard"></span>
      </li>

      <li class="breadcrumb-item active">
        Horários
      </li>
    </ol>
  </nav>

  <!-- <p>loja() {{ loja() | json }}</p> -->
  <!-- <p>tiposHorarios() {{ tiposHorarios() | json }}</p> -->

  <div class="row mb-2"
       *ngFor="let tipoHorario of tiposHorarios(); trackBy:tid_tip">
    <div class="col">
      <mat-expansion-panel expanded="true">
        <mat-expansion-panel-header class="px-4 bg-primary tint">
          {{ tipoHorario | titlecase }}
        </mat-expansion-panel-header>

        <table class="table table-striped">
          <thead>
            <tr>
              <th scope="col">Dia semana</th>
              <th scope="col">Horários</th>
              <th scope="col small"></th>
            </tr>
          </thead>

          <tbody>
            <tr *ngFor="let dowHorario of [1, 2, 3, 4, 5, 6, 0]">
              <td>{{ dowHorario | dow }}</td>

              <td>
                <mat-chip-list>
                  <mat-chip *ngFor="let h of loja()?.horarios?.[tipoHorario]['dow' + dowHorario]"
                            (click)="onHorarioClick(tipoHorario, dowHorario, h)"
                            class="pointer">
                    <app-horario2str [horario]="h"></app-horario2str>
                  </mat-chip>
                </mat-chip-list>
                <div *ngIf="!loja()?.horarios?.[tipoHorario]['dow' + dowHorario].length">
                  Nenhum horário nesse dia.
                </div>
              </td>

              <td class="small">
                <div class="d-flex">
                  <div class="ms-auto">
                    <button mat-icon-button
                            matTooltip="Adicionar horário"
                            (click)="onHorarioEditClick(tipoHorario, dowHorario, null)">
                      <mat-icon>add</mat-icon>
                    </button>

                    <button mat-icon-button
                            matTooltip="Clonar dia"
                            [matMenuTriggerFor]="cloneMenu"
                            [disabled]="!loja()?.horarios?.[tipoHorario]['dow' + dowHorario].length"
                            (click)="onClonarClick(tipoHorario, dowHorario);">
                      <span class="icon-o-copy rem-12"></span>
                    </button>
                  </div>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </mat-expansion-panel>
    </div>
  </div>
</div>

<mat-menu #cloneMenu="matMenu">
  <!-- [disabled]="dowClone === copyFrom?.dow" -->
  <button mat-menu-item
          (click)="onCopiarClick(dowClone)"
          *ngFor="let dowClone of [1, 2, 3, 4, 5, 6, 0]">
    {{ dowClone | dow }}
  </button>
</mat-menu>
