<ng-container *ngIf="vm$() | async as vm">
  <div class="box">
    <nav aria-label="breadcrumb">
      <ol class="breadcrumb">
        <li class="breadcrumb-item">
          <span class="icon-o-home primary tint o-50 pointer"
                routerLink="/dashboard"></span>
        </li>

        <li class="breadcrumb-item active">
          Lojas
        </li>
      </ol>
    </nav>

    <!-- <p>vm {{ vm | json }}</p> -->
    <!-- <p>vm?.loja{{ vm?.loja | json }}</p> -->

    <me-mat-lojas [lojas]="vm?.lojas"
                  [idSelected]="vm?.loja?.id"
                  [viewStyleStorageKey]="keyViewStyleLojas()"
                  [offsetStorageKey]="keyOffsetLojas()"
                  checkBtnHint="Selecionar loja"
                  deleteBtnHint=""
                  editBtnHint=""
                  searchBtnHint="Detalhes da loja"
                  (search$)="onSearchClick($event)"
                  (check$)="onCheckClick($event)" />
  </div>
</ng-container>

<!-- <div class="box">
  <nav aria-label="breadcrumb">
    <ol class="breadcrumb">
      <li class="breadcrumb-item">
        <span class="icon-o-home primary tint o-50 pointer"
              routerLink="/dashboard"></span>
      </li>

      <li class="breadcrumb-item active">
        Lojas
      </li>
    </ol>
  </nav>

  <div class="segment">
    <ul class="nav nav-pills">
        <li class="nav-item pointer"
            (click)="tab = 'conta'">
          <a class="nav-link"
             [class.active]="tab === 'conta'">
            Conta
          </a>
        </li>

      <li class="nav-item pointer"
          (click)="tab = 'integradora'">
        <a class="nav-link"
           [class.active]="tab === 'integradora'">
          Integradora
        </a>
      </li>

      <li class="nav-item pointer"
          (click)="tab = 'rede'">
        <a class="nav-link"
           [class.active]="tab === 'rede'">
          Rede
        </a>
      </li>
    </ul>
  </div>

  <div [ngSwitch]="tab">
    <div *ngSwitchCase="'rede'">
      <me-mat-lojas-refs [lojasRefs]="rede?._lojasRefs"
                         viewStyle="table"
                         editBtnHint=""
                         searchBtnHint="Detalhes da loja"
                         checkBtnHint="Selecionar loja"
                         (search$)="onLojaInfoClick($event)"
                         (check$)="onLojaCheckClick($event)">
      </me-mat-lojas-refs>
    </div>

    <div *ngSwitchCase="'integradora'">
      <me-mat-lojas-refs [lojasRefs]="integradora?._lojasRefs"
                         viewStyle="table"
                         editBtnHint=""
                         searchBtnHint="Detalhes da loja"
                         checkBtnHint="Selecionar loja"
                         (search$)="onLojaInfoClick($event)"
                         (check$)="onLojaCheckClick($event)">
      </me-mat-lojas-refs>
    </div>

    <div *ngSwitchDefault>
      <me-mat-lojas-refs [lojasRefs]="conta?.operador?._lojasRefs"
                         viewStyle="table"
                         editBtnHint=""
                         searchBtnHint="Detalhes da loja"
                         checkBtnHint="Selecionar loja"
                         (search$)="onLojaInfoClick($event)"
                         (check$)="onLojaCheckClick($event)">
      </me-mat-lojas-refs>
    </div>
  </div>
</div>
 -->