//#region ng
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';
//#endregion

//#region mat
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatChipsModule } from '@angular/material/chips';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { MatStepperModule } from '@angular/material/stepper';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatToolbarModule } from '@angular/material/toolbar';
//#endregion

//#region 3rd
import { MarkdownModule } from 'ngx-markdown';
//#endregion

//#region modules
import { CorMatComponentsModule } from '../../_shared/_core/_ng-ui/_mat/cor-mat-components.module';
import { MeMatComponentsModule } from '../../_shared/_mercadeiro/_ng-ui/_mat/me-mat-components.module';
import { GaleriaGetModalModule } from '../galeria-get/galeria-get.module';
//#endregion

//#region components
import {
  ProdutoEditModal,
  ProdutoFormComponent
} from '..';
//#endregion

@NgModule({
  imports: [
    CorMatComponentsModule,
    // CorDirectivesModule,
    // CorPipesModule,
    // CorDirectivesModule,
    // CorMatComponentsModule,
    CommonModule,
    GaleriaGetModalModule,
    MarkdownModule,
    MatButtonModule,
    MatCardModule,
    MatCheckboxModule,
    MatChipsModule,
    MatFormFieldModule,
    MatIconModule,
    MatInputModule,
    MatToolbarModule,
    MatSelectModule,
    MatSlideToggleModule,
    MeMatComponentsModule,
    MatStepperModule,
    ReactiveFormsModule,
    // SlickCarouselModule,
    // SwiperModule,
  ],
  declarations: [
    ProdutoEditModal,
    ProdutoFormComponent,
  ],
  exports: [
    ProdutoEditModal,
  ],
})
export class ProdutoEditModalModule { }
