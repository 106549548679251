<div *ngIf="loja?.entrega?.status?.includes('E'); else semEntrega">
  <!-- <button mat-fab
          class="add-btn"
          color="primary"
          matTooltip="Adicionar setor"
          (click)="onLocalidadeAddClick()">
    <mat-icon>add</mat-icon>
  </button> -->

  <!-- <p>cep?.length {{ cep?.length }}</p> -->
  <!-- <p>localidades {{ localidades | json }}</p> -->

  <div class="card"
       *ngIf="!!localidades">
    <div class="card-header d-flex">
      <div class="my-auto flex-1">
        Localidades <small class="o-50">( {{ localidades()?.length }} )</small>
      </div>

      <div class="input-group cep">
        <input type="text"
               mask="00000-000"
               [showMaskTyped]="false"
               class="form-control text-center rem-13"
               [ngModel]="cep()"
               (ngModelChange)="cep.set($event)"
               placeholder="Adicionar CEP">
        <!-- [select]="cepFocusEvent$" -->
        <button class="btn btn-primary"
                matTooltip="Adicionar localidade."
                [disabled]="cep()?.length !== 8"
                type="button"
                (click)="onLocalidadeAddClick()">
          <span class="icon-b-plus"></span>
        </button>
      </div>
    </div>

    <div class="card-body">
      <mat-chip-list>
        <mat-chip *ngFor="let l of localidades(); trackBy:tid_loc">
          {{ l?.nome }}
          <button matChipRemove
                  matTooltip="Remover localidade."
                  (click)="onLocalidadeDelClick(l)">
            <mat-icon>cancel</mat-icon>
          </button>
        </mat-chip>
      </mat-chip-list>

      <div *ngIf="!localidades()?.length">
        <!-- <small class="o-50">Nenhuma localidade.</small> -->
        <div class="alert alert-light text-center"
             role="alert">
          Nenhuma localidade.
        </div>
      </div>
    </div>
  </div>
</div>
<ng-template #semEntrega>
  <div class="alert alert-light text-center"
       role="alert">
    Entregas desativadas.
  </div>
</ng-template>
