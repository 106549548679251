//#region ng
import {
  Component,
  EventEmitter,
  inject,
  Inject,
  signal,
  ViewChild
} from '@angular/core';
import {
  AbstractControl,
  FormBuilder,
  FormGroup,
  Validators
} from '@angular/forms';
//#endregion

//#region firebase
import {
  arrayUnion,
  Firestore,
} from '@angular/fire/firestore';
//#endregion

//#region mat
import { MatStepper } from '@angular/material/stepper';
import {
  MatDialogRef,
  MAT_DIALOG_DATA
} from '@angular/material/dialog';
//#endregion

//#region 3rd
import { Subscription } from 'rxjs';
import {
  finalize,
  first,
} from 'rxjs/operators';
//#endregion

//#region models
import { CorFormValidation } from '../../_shared/_core/_ng/_models/_classes';
import { FOCUS_TIMEOUT } from '../../_shared/_core/_misc/_models/consts';
import {
  IConta,
  IFormaPgto,
  ILoja
} from '../../_shared/_mercadeiro/_misc/_models/_interfaces/_cols';
import { ICredenciadoHabilitarParams } from '../../models/interfaces/params';
//#endregion

//#region services
import {
  ContasService,
  LojasService
} from '../../_shared/_mercadeiro/_ng/_services';
import { CorLoaderService } from '../../_shared/_core/_ng/_services';
//#endregion

//#region components
import { CredenciadoFormasPgtoFormComponent } from '../../components';
//#endregion

@Component({
  selector: 'app-credenciado-habilitar-modal',
  templateUrl: './credenciado-habilitar.modal.html',
  styleUrls: ['./credenciado-habilitar.modal.scss']
})
export class CredenciadoHabilitarModal {
  //#region viewchilds
  @ViewChild('stepper', { static: true }) stepperRef: MatStepper;
  @ViewChild(CredenciadoFormasPgtoFormComponent) formasFormRef: CredenciadoFormasPgtoFormComponent;
  //#endregion

  //#region publics
  contaCredenciado = signal<IConta>(null);
  contaForm = signal<FormGroup>(null);
  fv = signal<CorFormValidation>(null);
  loja = signal<ILoja>(null);
  //#endregion

  //#region methods
  emailFocusEvent$ = signal<EventEmitter<boolean>>(new EventEmitter<boolean>());
  //#endregion

  //#region injects
  #contasServ = inject(ContasService);
  #dialogRef = inject(MatDialogRef<CredenciadoHabilitarModal>);
  #fb = inject(FormBuilder);
  #loaderServ = inject(CorLoaderService);
  #lojasServ = inject(LojasService);
  //#endregion

  //#region constructor
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: ICredenciadoHabilitarParams,
  ) {
    this.fv.set(new CorFormValidation());
  }
  //#endregion

  //#region lifecycles
  ngOnInit() {
    this.loja.set(this.#lojasServ.fix(this.data?.loja));
    // this._conta = this.#contasServ.fix(this.data?.conta);

    this.contaForm.set(
      this.#fb.group({
        email: ['', [Validators.required, Validators.email]],
        id: ['', [Validators.required]],
      })
    );
  }

  ngAfterViewInit() {
    this._focus();
  }
  //#endregion

  //#region Controls getters
  get contaEmailRef(): AbstractControl { return this.contaForm()?.get('email'); }
  get contaIdRef(): AbstractControl { return this.contaForm()?.get('id'); }
  //#endregion

  //#region functions
  tid_for(index: any, item: IFormaPgto): string { return item?.id || ''; }

  _focus() {
    setTimeout(
      () => { this.emailFocusEvent$()?.emit(true); },
      FOCUS_TIMEOUT
    );
  }
  //#endregion

  //#region methods
  async onStepChanged(e: any) {
    // console.log(e);
    switch (+e?.selectedIndex) {
      case 0:
        this._focus();
        break;

      case 1:
        break;

      case 2:
        break;
    } // switch
  }

  async onVerificaEmailClick() {
    this.#loaderServ.lstart();
    try {
      // const RESP: any = await this._verificaUsuario(this.contaEmailRef.value);
      const RESP: any = await this.#contasServ.userFromEmail(this.contaEmailRef.value);
      // console.log(RESP);
      const USER: any = RESP?.data?.data || null;
      // console.log(USER);
      this.fv()?.setApiErrors({ email: '' });
      this.contaCredenciado.set(null);

      if (!!USER) {
        if (this.loja()?.distribuidora?.idsCredenciados?.includes(USER?.uid)) {
          this.fv()?.setApiErrors({ email: 'Conta já credenciada para esse distribuidor.' });
          // this.stepperRef.previous();
        } else {
          const SUB: Subscription = this.#loaderServ.showUntilCompleted(
            this.#contasServ.contaFromUid(USER?.uid)
              .pipe(first(), finalize(() => SUB?.unsubscribe()))
          )
            .subscribe(async (conta: IConta) => {
              // console.log(conta);
              if (!!conta) {
                this.contaCredenciado.set(this.#contasServ.fix(conta));
                this.contaIdRef.setValue(USER?.uid || '');
                this.stepperRef.next();
              } else {
                this.#loaderServ.lstop();
                this.fv()?.setApiErrors({ email: 'Conta não encontrada.' })
                this.stepperRef.previous();
                this._focus();
              } // else
            });
        } // else
      } else {
        this.fv()?.setApiErrors({ email: 'Cadastro não encontrado.' })
        this.stepperRef.previous();
        this._focus();
      } // if
    } finally {
      this.#loaderServ.lstop();
    } // try-finally
  }

  onModalCloseClick() {
    this.#dialogRef.close();
  }

  onHabilitarCredenciado() {
    const CONTA_ID: string = this.contaCredenciado()?.id || '';
    const LOJA_ID: string = this.loja()?.id || '';

    if (!!CONTA_ID && !!LOJA_ID) {
      const LOJA_CHANGES: any = {
        distribuidora: {
          idsCredenciados: arrayUnion(CONTA_ID)
        },
      };

      const CONTA_CHANGES: any = {
        credenciado: {
          [LOJA_ID]: {
            idsFormasPgto: (this?.formasFormRef?.formasSel || [])
              .map((f: IFormaPgto) => f?.id || '')
              .filter((id: string) => !!id)
          }
        }
      };
      // console.log(LOJA_CHANGES);
      // console.log(CONTA_CHANGES);

      const SUB: Subscription = this.#loaderServ.showUntilCompleted(
        this.#contasServ.update(CONTA_ID, CONTA_CHANGES)
          .pipe(first(), finalize(() => SUB?.unsubscribe()))
      )
        .subscribe(
          () => {
            this.#loaderServ.lstart();
            const SUB2: Subscription = this.#lojasServ.update(
              this.loja()?.id || '',
              LOJA_CHANGES
            )
              .pipe(first(), finalize(() => SUB2?.unsubscribe()))
              .subscribe(
                async () => {
                  this.onModalCloseClick();
                }
              );
          }
        );
    } // if
  }
  //#endregion
}
