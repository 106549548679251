//#region ng
import { Injectable, inject } from '@angular/core';
//#endregion

//#region 3rd
import { BehaviorSubject, Observable, Subscription } from 'rxjs';
import {
  finalize,
  first
} from 'rxjs/operators';
//#endregion

//#region models
import { ILoja } from '../_shared/_mercadeiro/_misc/_models/_interfaces/_cols';
import {
  KEY_LOJA_ID,
  THEME
} from '../models/consts';
//#endregion

//#region services
import {
  CorLoaderService,
  CorStorageService
} from '../_shared/_core/_ng/_services';
import { LojasService } from '../_shared/_mercadeiro/_ng/_services';
//#endregion

//#region stores
import { LojasStore } from '../_shared/_mercadeiro/_ng/_stores';
//#endregion

@Injectable({
  providedIn: 'root'
})
export class AppLojasStore {
  //#region lojaState
  #lojaState: ILoja | null;
  getState(): ILoja | null { return this.#lojaState; }
  setState(val: ILoja | null, defaultTheme: string) {
    this.#lojaState = val;
    this.#lojasStore.setState(val, defaultTheme);
    this.lojaSet(val?.id || '');
  }
  //#endregion

  //#region lojasContaState
  #lojasContaState: ILoja[];
  getLojasContaState(): ILoja[] | null { return this.#lojasContaState; }
  setLojasContaState(val: ILoja[]) {
    this.#lojasContaState = val;
    this.#lojasContaStateSubject$.next(val || []);
  }
  //#endregion

  //#region events
  // lojasContaStateChanged$
  #lojasContaStateSubject$ = new BehaviorSubject<ILoja[]>(null);
  lojasContaStateChanged$: Observable<ILoja[]> = this.#lojasContaStateSubject$?.asObservable();
  //#endregion

  //#region injects
  #loaderServ = inject(CorLoaderService);
  #lojasServ = inject(LojasService);
  #lojasStore = inject(LojasStore);
  #storageServ = inject(CorStorageService);
  //#endregion

  constructor() {
    // const LOJA_STATE: ILoja = this.lojaGet();
    // !!LOJA_STATE && this.setState(LOJA_STATE, THEME);
    const LOJA_ID: string = this.lojaIdGet();
    if (!!LOJA_ID) {
      const SUB: Subscription = this.#loaderServ.showUntilCompleted(
        this.#lojasServ.doc(LOJA_ID)
          .pipe(first(), finalize(() => SUB?.unsubscribe()))
      )
        .subscribe((loja: ILoja) => {
          // console.log(loja);
          this.#lojasStore.setState(!!loja ? this.#lojasServ.fix(loja) : null, THEME);
        });
    } else {
      this.setState(null, THEME);
    } // else
  }
  //#endregion

  //#region storage KEY_LOJA_ID
  lojaIdGet(): string {
    const VAL: string = this.#storageServ.ls_get(KEY_LOJA_ID);
    // console.log(VAL);
    return VAL;
  }

  lojaSet(lojaId: string): string {
    if (!!lojaId) {
      this.#storageServ.ls_set(
        KEY_LOJA_ID,
        lojaId
      );
      return this.lojaIdGet();
    } else {
      return this.lojaIdRemove();
    } // else
  }

  lojaIdRemove(): string {
    this.#storageServ.ls_remove(KEY_LOJA_ID);
    return this.lojaIdGet();
  }
  //#endregion
}
