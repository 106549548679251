<div class="modal-body p-relative">
  <mat-toolbar class="header"
               color="primary">
    <mat-toolbar-row class="d-flex justify-content-between">
      <div class="my-auto">
        <h3> Modificando filtros </h3>
      </div>

      <div class="my-auto">
        <button mat-icon-button
                (click)="onModalCloseClick()">
          <mat-icon>close</mat-icon>
        </button>
      </div>
    </mat-toolbar-row>
  </mat-toolbar>

  <!-- <p>pagFilters() {{ pagFilters() | json }}</p> -->

  <ul class="list-group">
    <li class="list-group-item d-flex justify-content-between"
        *ngFor="let f of pagFilters(); trackBy:tid_fil">
      <div class="my-auto">
        {{ f?.extra1 }}
      </div>

      <div class="my-auto">
        <cor-mat-tristate-toggle iconChecked="check"
                                 iconNone="delete_outline"
                                 iconUnchecked="close"
                                 [status]="f?.tristate"
                                 (change$)="f.tristate = $event" />
      </div>
    </li>
  </ul>

  <div class="p-2">
    <button type="button"
            class="pointer w-100"
            mat-raised-button
            color="primary"
            (click)="onSubmitClick()">
      FILTRAR
    </button>
  </div>
</div>
