//#region ng
import {
  inject,
  Injectable
} from '@angular/core';
// import { HttpClient } from '@angular/common/http';
//#endregion

//#region 3rd
import {
  from,
  Observable,
  ObservableInput,
  throwError,
} from 'rxjs';
import {
  catchError,
  map,
} from 'rxjs/operators';
//#endregion

//#region firebase
import {
  doc,
  Firestore,
  getDoc,
  setDoc,
} from '@angular/fire/firestore';
//#endregion

//#region models
import { environment } from 'src/environments/environment';
import { IProProduto } from '../../_misc/_models/_interfaces/_cols';
import {
  IMG_NO_IMG,
  THUMB_NO_IMG,
  PRODUTOS_STORAGE_ROOT
} from '../../../_core/_misc/_models/consts';
//#endregion

//#region libs
import { checkImg } from '../../../_libs/_misc/_imgs';
import { geraNomeCompleto } from '../../_misc/_libs';
import { ProGtinsService } from './gtins.service';
// import { IProImgProdutoMap } from '../../_misc/_models/_interfaces/_maps';
import { CorMessagesService } from '../../../_core/_ng/_services';
import { TNullable } from '../../../_core/_misc/_models/_types';
import { uniqueListBy } from '../../../_libs/_misc/_arrays/unique-list-by';
//#endregion

@Injectable({
  providedIn: "root"
})
export class ProProdutosService {
  //#region pag
  //  #pag: IPagServ = {
  //   // filter: '',
  //   first: null,
  //   last: null,
  //   offset: 12,
  //   order: {
  //     asc: true,
  //     field: 'nome',
  //   },
  //   extras: {
  //     lojaPath: '',
  //     filtros: [],
  //     idDepartamento: '',
  //     idSubdepartamento: '',
  //     apenasAtivos: false
  //   }
  // };
  // //#endregion

  //#region injects
  #db = inject(Firestore);
  #gtinsServ = inject(ProGtinsService);
  #msgServ = inject(CorMessagesService);
  //#endregion

  //#region misc
  fix(
    row: any,
    // ignoreImgs: boolean = false,
    // visibles: string[] = []
  ): IProProduto {
    const IMG_RES: number = Number(row?.img?._res) || 0;

    return {
      // id
      id: row?.id || '',

      // user
      // atacado: string;
      atacado: {
        embalagem: row?.atacado?.embalagem || '',
        qtde: Number(row?.atacado?.qtde) || 0,
      },
      apresentacao: row?.apresentacao || '',
      barcode: row?.barcode || '',
      complemento: row?.complemento || '',
      cor: row?.cor || '',
      departamentos: {
        d1: {
          _id: row?.departamentos?.d1?._id || '',
          nome: row?.departamentos?.d1?.nome || '',
        },
        d2: {
          _id: row?.departamentos?.d2?._id || '',
          nome: row?.departamentos?.d2?.nome || '',
        },
        d3: {
          _id: row?.departamentos?.d3?._id || '',
          nome: row?.departamentos?.d3?.nome || '',
        },
      },
      // descricao: row?.id || '',
      dimensoes: row?.dimensoes || '',
      embalagem: row?.embalagem || '',
      formato: row?.formato || '',
      idade: row?.idade || '',
      img: {
        id: row?.id || '',
        img: row?.img?.img || IMG_NO_IMG,
        thumb: row?.img?.thumb || THUMB_NO_IMG,
        _res: IMG_RES,
      },
      // inconsistencia: !!row?.inconsistencia,
      // inconsistente: !!row?.inconsistente,
      info: row?.info || '',
      marca: {
        _id: row?.marca?._id || '',
        linha: {
          _id: row?.marca?.linha?._id || '',
          nome: row?.marca?.linha?.nome || '',
        },
        nome: row?.marca?.nome || '',
      },
      material: row?.material || '',
      modelo: row?.modelo || '',
      nacionalidade: row?.nacionalidade || '',
      ncm: row?.ncm || '',
      nome: row?.nome || '',
      produto: row?.produto || '',
      qtde: row?.qtde || '',
      ref: row?.ref || null,
      sabor: row?.sabor || '',
      shelfLife: row?.shelfLife || '',
      tamanho: row?.tamanho || '',
      verificado: !!row?.verificado,
      // vol: {
      //   liquido: row?.volume?.liquido || '',
      //   drenado: row?.volume?.drenado || '',
      // },
      volume: row?.volume || '',

      // system
      /* _confirmado: {
        status: !!row?._confirmado?.status,
        em: row?._confirmado?.em || null,
        por: {
          id: row?._confirmadoEm?.por?.id || '',
          nome: row?._confirmadoEm?.por?.nome || '',
        },
      }, */
      _criadoEm: row?._criadoEm || null,
      _gtin: row?._gtin || null,
      _gtins: this.#gtinsServ.fixes(row?._gtins),
      _imgs: row?._imgs || [],
      // _modificadoEm: row?._modificadoEm || null,
      // _modificadoPor: row?._modificadoPor || '',
      _modificado: {
        status: !!row?._modificado?.status,
        em: row?._modificado?.em || null,
        por: {
          id: row?._modificadoEm?.por?.id || '',
          nome: row?._modificadoEm?.por?.nome || '',
        },
      },
      _nomeCompleto: row?.nome || geraNomeCompleto(row) || '',
      // _novo: boolean,
      _ver: Number(row?.atacado?._ver) || 0,

      // realtime
      __displayInPagination: !!row?.__displayInPagination,
      __imgs: (!!IMG_RES ? [row?.img] : [])
        .concat(!!row?.__imgs?.length ? row?.__imgs : [])
      // __nomeCompleto?: string;
    };

    /*
    row = row || {};
    let R: IProProduto = row;
    // console.log(row);
  
    // const ATACADO: string = get(row, 'atacado') || '';
    const COMPLEMENTO: string = row?.complemento || '';
    const LINHA_NOME: string = row?.marca?.linha?.nome || ''
    const MARCA_NOME: string = row?.marca?.nome || ''
    const MODELO: string = row?.modelo || '';
    const VOLUME: string = row?.volume || '';
    // console.log(ATACADO);
  
    // id
    // R.objectID = get(row, 'objectID') || null;
    R.id = row?.id || row?._id || '';
  
    // user
    R.atacado = {
      embalagem: row?.atacado?.embalagem || '',
      qtde: Number(row?.atacado?.qtde) || 0,
    };
    R.apresentacao = row?.apresentacao || '';
    R.barcode = row?.barcode || '';
    R.cor = row?.cor || '';
    R.complemento = COMPLEMENTO;
    R.departamentos = {
      d1: {
        _id: row?.departamentos?.d1?._id || '',
        nome: row?.departamentos?.d1?.nome || '',
      },
      d2: {
        _id: row?.departamentos?.d2?._id || '',
        nome: row?.departamentos?.d2?.nome || '',
      },
      d3: {
        _id: row?.departamentos?.d3?._id || '',
        nome: row?.departamentos?.d3?.nome || '',
      },
    };
    // R.descricao = get(row, 'descricao') || '';
    R.dimensoes = row?.dimensoes || '';
    R.embalagem = row?.embalagem || '';
    R.formato = row?.formato || '';
    R.idade = row?.idade || '';
    R.img = {
      __img: IMG_NO_IMG,
      __thumb: THUMB_NO_IMG,
      _res: parseFloat(row?.img?._res) || 0
    };
    R.inconsistencia = !!row?.inconsistencia;
    R.inconsistente = !!row?.inconsistente;
    R.info = row?.info || '';
    R.marca = {
      _id: row?.marca?._id || '',
      linha: {
        _id: row?.marca?.linha?._id || '',
        nome: LINHA_NOME
      },
      nome: MARCA_NOME,
    };
    R.material = row?.material || '';
    R.modelo = MODELO;
    R.nacionalidade = row?.nacionalidade || '';
    R.ncm = row?.ncm || '';
    R.qtde = row?.qtde || '';
    R.ref = row?.ref || '';
    R.sabor = row?.sabor || '';
    R.shelfLife = row?.shelfLife || '';
    R.tamanho = row?.tamanho || '';
    // R.tipo = get(row, 'tipo') || '';
    // R.unidade = {
    //   nome: get(row, 'unidade.nome') || '',
    //   qtde: parseFloat(get(row, 'unidade.qtde')) || 0
    // };
    R.verificado = !!row?.verificado
    R.volume = VOLUME;
  
    // system
    R._criadoEm = row?._criadoEm || null;
    R._gtins = row?._gtins || [];
    R._imgs = row?._imgs || [];
    R._modificadoPor = row?._modificadoPor || '';
    R._ver = row?._ver || 1.0;
  
    // realtimes
    R.__displayInPagination = !!row?.__displayInPagination;
    // console.log(R);
    const MARCA_ID: string = row?.marca?._id || '';
    // console.log(get(R.img, '_res'));
  
    const P_IMG: string = `${environment.firebase.conecdata.storage.root}produtos%2fimg_${R.id}.jpg?alt=media`;
    const P_THUMB: string = `${environment.firebase.conecdata.storage.root}produtos%2fthumb_${R.id}.jpg?alt=media`;
    const M_IMG: string = `${PRODUTOS_STORAGE_ROOT}/galeria%2Fimg__${MARCA_ID}.jpg?alt=media`;
    const M_THUMB: string = `${PRODUTOS_STORAGE_ROOT}/galeria%2Fthumb__${MARCA_ID}.jpg?alt=media`;
    const verificaMarca = () => {
      checkImg(
        M_IMG,
        () => {
          R.img.__img = M_IMG;
          R.img.__thumb = M_THUMB;
        },
        () => {
          R.img.__img = IMG_NO_IMG;
          R.img.__thumb = THUMB_NO_IMG;
        }
      );
    };
  
    if (
      !visibles?.length
      || visibles.includes(R.id || '')
    ) checkImg(
      P_IMG,
      () => {
        if (ignoreImgs) {
          verificaMarca();
        } else {
          R.img.__img = P_IMG;
          R.img.__thumb = P_THUMB;
        } // else
      },
      () => verificaMarca()
    );
    R._nomeCompleto = this.geraNomeCompleto(row);
  
    return R;
    */
  }

  lfix(
    row: Partial<IProProduto>,
    ignoreImgs: boolean
  ): Promise<TNullable<IProProduto>> {
    // console.log(ignoreImgs, row);
    return new Promise(
      (resolve, reject) => {
        const PRODUTO_ID: string = row?.id || '';
        const MARCA_ID: string = row?.marca?._id || '';
        if (!PRODUTO_ID) {
          // resolve(null);
          return resolve(this.fix(row)); // reject(new Error('Nenhum produto indicado.'));
        } else {
          const P_IMG: string = `${environment.firebase.conecdata.storage.root}/produtos%2fimg_${PRODUTO_ID}.jpg?alt=media`;
          const P_THUMB: string = `${environment.firebase.conecdata.storage.root}/produtos%2fthumb_${PRODUTO_ID}.jpg?alt=media`;
          // console.log(P_IMG);
          const M_IMG: string = `${PRODUTOS_STORAGE_ROOT}/galeria%2Fimg__${MARCA_ID}.jpg?alt=media`;
          const M_THUMB: string = `${PRODUTOS_STORAGE_ROOT}/galeria%2Fthumb__${MARCA_ID}.jpg?alt=media`;
          // console.log(M_IMG);
          const verificaMarca = () => {
            !!MARCA_ID && checkImg(
              M_THUMB,
              () => resolve(
                this.fix(
                  {
                    ...row,
                    img: {
                      ...row?.img,
                      img: M_IMG,
                      thumb: M_THUMB
                    }
                  }
                )
              ),
              () => resolve(
                this.fix(
                  {
                    ...row,
                    img: {
                      ...row?.img,
                      img: IMG_NO_IMG,
                      thumb: THUMB_NO_IMG
                    }
                  }
                )
              ),
            );
          };

          if (!!ignoreImgs) {
            verificaMarca()
          } else {
            checkImg(
              P_THUMB,
              () => {
                let produto: Partial<IProProduto> = {
                  ...row,
                  img: {
                    ...row?.img,
                    img: P_IMG,
                    thumb: P_THUMB
                  }
                };

                if (!!MARCA_ID) {
                  checkImg(
                    M_THUMB,
                    () => resolve(
                      this.fix(
                        {
                          ...row,
                          ...produto,
                          _imgs: uniqueListBy(
                            (row?._imgs || []).concat(
                              {
                                id: MARCA_ID,
                                img: M_IMG,
                                thumb: M_THUMB
                              }
                            ),
                            'id'
                          )
                        }
                      )
                    ),
                    () => resolve(this.fix(produto))
                  );
                } else
                  resolve(this.fix(produto));
              },
              () => verificaMarca(),
            );
          } // else
        } // else
      });
  }

  fixes(
    docs: any[],
  ): IProProduto[] {
    return (docs || []).map((doc: Partial<IProProduto>) => this.fix(doc/* , ignoreImgs, visibles */));
  }

  lfixes(
    docs: Partial<IProProduto>[],
    ignoreImgs: boolean
  ): Observable<IProProduto[]> {
    const PROMISES: Promise<IProProduto>[] = (docs || [])
      .map((doc: Partial<IProProduto>) => this.lfix(doc, ignoreImgs));
    return from(Promise.all(PROMISES));
  }

  /* geraNomeCompleto(produto: Partial<IProProduto> | IProProduto): string {
    return geraNomeCompleto(produto);
  } */
  //#endregion

  //#region R
  doc(barcode: string): Observable<IProProduto> {
    // console.log('barcode', barcode);
    if (!!barcode) {
      const PATH: string = `/_produtos/${barcode}`;
      // return (docData(doc(this.#db, PATH), { idField: 'id' }) as Observable<IProProduto>)
      return (from(getDoc(doc(this.#db, PATH))) as Observable<any>)
        .pipe(
          map((doc: any) => (!!doc.data() ? this.fix({ ...doc.data(), id: doc.id }) : null)),
          // map(() => { throw new Error(`Erro lendo ${PATH}.`); }),
          catchError<IProProduto, ObservableInput<IProProduto>>(
            (err: any) => this.#msgServ.onCatchError(err, 'Erro carregando produto.')
          )
        );
    } // if
    return throwError(() => 'Nenhum código de barras indicado.');
  }
  //#endregion

  //#region U
  update(
    produtoId: string,
    changes: Partial<IProProduto>
  ): Observable<any> {
    if (!!produtoId) {
      const PATH: string = `_produtos/${produtoId}`;
      return from(setDoc(doc(this.#db, PATH), changes, { merge: true }));
    } // if
    return throwError(() => 'Nenhum produto indicado.');
  }
  //#endregion
}
