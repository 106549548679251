//#region ng
import {
  NgModule,
  LOCALE_ID
} from '@angular/core';
import { registerLocaleData } from "@angular/common";
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HttpClientModule } from '@angular/common/http';
import { RouterModule } from '@angular/router';
import localePt from "@angular/common/locales/pt";
registerLocaleData(localePt);
//#endregion

//#region mat
import { MatTooltipModule } from '@angular/material/tooltip';
import {
  MatSnackBarModule,
  MAT_SNACK_BAR_DEFAULT_OPTIONS,
} from '@angular/material/snack-bar';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
//#endregion

//#region firebase
import {
  initializeApp,
  provideFirebaseApp,
} from '@angular/fire/app';
import {
  provideAuth,
  getAuth,
} from '@angular/fire/auth';
import {
  getFirestore,
  provideFirestore,
} from '@angular/fire/firestore';
import {
  getFunctions,
  provideFunctions,
} from '@angular/fire/functions';
//#endregion

//#region 3rd
import {
  NgxMaskDirective,
  NgxMaskPipe,
  provideNgxMask
} from 'ngx-mask';
import { ModalModule } from 'ngx-bootstrap/modal';
import { MarkdownModule } from 'ngx-markdown';
//#endregion

//#region models
import { environment } from 'src/environments/environment';
//#endregion

//#region modules
import { AppRoutingModule } from './app-routing.module';
import { MeMatComponentsModule } from './_shared/_mercadeiro/_ng-ui/_mat/me-mat-components.module';
import { PagesModule } from './pages/pages.module';
import { ComponentsModule } from './components/components.module';
import { CorMatComponentsModule } from './_shared/_core/_ng-ui/_mat/cor-mat-components.module';
import { MeBsComponentsModule } from './_shared/_mercadeiro/_ng-ui/_bs/me-bs-components.module';
//#endregion

//#region components
import { AppComponent } from './app.component';
//#endregion

@NgModule({
  imports: [
    AppRoutingModule,
    BrowserAnimationsModule,
    BrowserModule,
    ComponentsModule,
    CorMatComponentsModule,
    HttpClientModule,
    MarkdownModule.forRoot(),
    MatProgressSpinnerModule,
    MatSnackBarModule,
    MatTooltipModule,
    MeMatComponentsModule,
    MeBsComponentsModule,
    ModalModule.forRoot(),
    // NgxMaskModule.forRoot(),
    NgxMaskDirective,
    NgxMaskPipe,
    PagesModule,
    provideFirebaseApp(() => initializeApp(environment.firebase.config)),
    provideAuth(() => getAuth()),
    provideFirestore(() => getFirestore()),
    provideFunctions(() => getFunctions()),
    RouterModule
  ],
  declarations: [
    AppComponent,
  ],
  providers: [
    { provide: LOCALE_ID, useValue: "pt-BR" },
    { provide: MAT_SNACK_BAR_DEFAULT_OPTIONS, useValue: { duration: 2500 } },
    provideNgxMask()
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
