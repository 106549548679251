//#region ng
import {
  Component,
  Inject,
  inject,
  signal,
} from '@angular/core';
//#endregion

//#region mat
import {
  MatDialogRef,
  MAT_DIALOG_DATA
} from '@angular/material/dialog';
//#endregion

//#region models
type TTab = 'produto' | 'imgs';
import { IProduto } from '../../_shared/_mercadeiro/_misc/_models/_interfaces/_cols';
import { IProdutoInfoParams } from '../../models/interfaces/params';
//#endregion

//#region services
import { ProdutosService } from '../../_shared/_mercadeiro/_ng/_services';
//#endregion

@Component({
  selector: 'app-produto-info-modal',
  templateUrl: './produto-info.modal.html',
  styleUrls: ['./produto-info.modal.scss']
})
export class ProdutoInfoModal {
  //#region publics
  tab = signal<TTab>('produto');
  produto = signal<IProduto>(null);
  //#endregion

  //#region injects
  #dialogRef = inject(MatDialogRef<ProdutoInfoModal>);
  #produtosServ = inject(ProdutosService);
  //#endregion

  //#region constructor
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: IProdutoInfoParams,
  ) { }
  //#endregion

  //#region lifecycles
  ngOnInit() {
    this.produto.set(!!this.data?.produto ? this.#produtosServ.fix(this.data?.produto) : null);
  }
  //#endregion

  //#region methods
  onModalCloseClick() {
    this.#dialogRef.close();
  }
  //#endregion
}
