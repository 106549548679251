//#region ng
import {
  Component,
  inject,
  signal,
} from '@angular/core';
//#endregion

//#region 3rd
import {
  BehaviorSubject,
  Observable,
  Subject,
  Subscription,
  combineLatest
} from 'rxjs';
import {
  filter,
  map,
  switchMap,
  takeUntil,
  tap
} from 'rxjs/operators';
//#endregion

//#region models
interface IVm {
  lojaApi: ILojaApiQtde;
  conta: IConta;
  loja: ILoja;
  lojaDistribuidoraTipoRota: any,
  rotas: any;
};
import {
  IConta,
  ILoja,
  ILojaApiQtde
} from '../../_shared/_mercadeiro/_misc/_models/_interfaces/_cols';
import { LOJA_DISTRIBUIDORA_TIPO_ROTA } from '../../_shared/_mercadeiro/_misc/_models/consts';
//#endregion

//#region libs
import { onDestroy } from '../../_shared/_core/_ng/_libs';
//#endregion

//#region services
import { LojasService } from '../../_shared/_mercadeiro/_ng/_services';
import { ROTAS } from '../../services';
//#endregion

//#region stores
import {
  ContasStore,
  LojasStore
} from '../../_shared/_mercadeiro/_ng/_stores';
//#endregion

@Component({
  selector: 'dashboard-page',
  templateUrl: './dashboard.page.html',
  styleUrls: ['./dashboard.page.scss']
})
export class DashboardPage {
  //#region actions
  #destroyAction$: Subject<void> = onDestroy();
  #lojaApiAction$ = new BehaviorSubject<ILojaApiQtde>(null);
  //#endregion

  //#region publics
  vm$ = signal<Observable<IVm>>(null);
  //#endregion

  //#region injects
  #contasStore = inject(ContasStore);
  #lojasStore = inject(LojasStore);
  #lojasServ = inject(LojasService);
  //#endregion

  //#region lifecycles
  ngOnInit() {
    // let cliente = {
    //   nome: 'joao',
    //   id: 1234,
    //   idade: 34
    // };

    // let nome = cliente.nome;
    // let id = cliente.id;
    // let idade = cliente.idade;

    // let { nome, id, idade, nivelViadagem } = cliente;

    // let deptos = ['iddepto1', 'nomedepto1', 'iddepto2', 'nomedepto2', 'iddepto3', 'nomedepto3'];
    // const [D1, , D2, N2, D3, N3] = deptos;

    this.vm$.set(
      combineLatest([
        this.#contasStore.contaStateChanged$,
        this.#lojasStore.lojaStateChanged$,
        this.#lojaApiAction$,
      ])
        .pipe(
          map(([conta, loja, lojaApi]) => {
            // console.log(loja);
            // const CONTA_ID: string = this.conta?.id || '';
            const VM: IVm = {
              conta,
              loja,
              lojaApi,
              lojaDistribuidoraTipoRota: LOJA_DISTRIBUIDORA_TIPO_ROTA,
              rotas: ROTAS
            };
            // console.log(VM);
            return VM;
          })
        )
    );

    this.#lojasStore.lojaStateChanged$
      .pipe(
        filter((loja: ILoja) => !!loja?.__idInfo?.lojaPath),
        switchMap((loja: ILoja) => this.#lojasServ.apiQtde(loja?.__idInfo?.lojaPath || '')),
        // filter((lojaApi: ILojaApiQtde) => !!lojaApi),
        takeUntil(this.#destroyAction$),
      )
      .subscribe((lojaApi: ILojaApiQtde) => this.#lojaApiAction$.next(lojaApi));
  }
  //#endregion

  /*
  //#region publics
  api: any;
  conta: IConta;
  loja: ILoja;
  rotas: any;
  //#endregion

  //#region privates
  private _subs: Subscription[] = [];
  //#endregion

  //#region lifecycles
  ngOnInit(): void {
    this._subs.push(
      this.#contasStore.contaStateChanged$
        .subscribe((conta: IConta) => {
          this.conta = conta;
          this.rotas = ROTAS;
        }),
    );
  }

  ngOnDestroy(): void {
    this._subs.forEach((s: Subscription) => s && s.unsubscribe());
  }
  //#endregion
  */
}
