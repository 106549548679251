//#region ng
import {
  Component,
  Inject,
  inject,
  signal,
} from '@angular/core';
//#endregion

//#region mat
import {
  MatDialogRef,
  MAT_DIALOG_DATA
} from '@angular/material/dialog';
//#endregion

//#region models
const FILTERS: ICorMeiliFilter[] = [
  {
    id: 'fAtivoOk',
    icon: 'icon-o-thumbs-up',
    extra1: 'Ok',
    extra2: '=',
    // extra3: F_ATIVO_OK === 'true' ? 'Sim' : 'Não',
    attribute: 'ativo._status',
    op: '=',
    tristate: '',
    val: null
  },
  {
    id: 'fAtivoStatus',
    icon: 'icon-b-check',
    extra1: 'Ativo',
    extra2: '=',
    // extra3: F_ATIVO_STATUS === 'true' ? 'Sim' : 'Não',
    attribute: 'ativo.status',
    op: '=',
    tristate: '',
    val: null
  },
  {
    id: 'fAtivoOnline',
    icon: 'icon-b-wifi-on',
    extra1: 'Online',
    extra2: '=',
    // extra3: F_ATIVO_ONLINE === 'true' ? 'Sim' : 'Não',
    attribute: 'ativo.online',
    op: '=',
    tristate: '',
    val: null
  },
  {
    id: 'fConsistente',
    icon: 'icon-b-bang',
    extra1: 'Consistente',
    extra2: '=',
    // extra3: F_ATIVO_CONSISTENTE === 'true' ? 'Sim' : 'Não',
    attribute: 'ativo._consistente',
    op: '=',
    tristate: '',
    val: null
  },
  {
    id: 'fFracionadoStatus',
    icon: 'icon-b-cut',
    extra1: 'Fracionado',
    extra2: '=',
    // extra3: F_FRACIONADO_STATUS === 'true' ? 'Sim' : 'Não',
    attribute: 'fracionado._status',
    op: '=',
    tristate: '',
    val: null
  },
  {
    id: 'fAtacadoStatus',
    icon: 'icon-b-asterisk',
    extra1: 'Atacado',
    extra2: '=',
    // extra3: F_ATACADO_STATUS === 'true' ? 'Sim' : 'Não',
    attribute: 'atacado.status',
    op: '=',
    tristate: '',
    val: null
  },
  {
    id: 'fEstoqueCritico',
    icon: 'icon-o-blocked',
    extra1: 'Estoque crítico',
    extra2: '=',
    // extra3: F_ESTOQUE_CRITICO === 'true' ? 'Sim' : 'Não',
    attribute: 'estoqueCritico',
    op: '=',
    tristate: '',
    val: null
  },
  {
    id: 'fImg',
    icon: 'icon-b-img',
    extra1: 'Imagem',
    extra2: '=',
    // extra3: F_IMG === 'true' ? 'Sim' : 'Não',
    attribute: '_img',
    op: '=',
    tristate: '',
    val: null
  },
  {
    id: 'fIndustrializado',
    icon: 'icon-b-barcode',
    extra1: 'Industrializado',
    extra2: '=',
    // extra3: F_INDUSTRIALIZADO === 'true' ? 'Sim' : 'Não',
    attribute: '_industrializado',
    op: '=',
    tristate: '',
    val: null
  }
];
import { ICorMeiliFilter } from '../../_shared/_core/_misc/_models/_interfaces/_misc';
import { IProdutosFiltrosParams } from 'src/app/models/interfaces/params';
//#endregion

@Component({
  selector: 'app-produtos-filtros-modal',
  templateUrl: './produtos-filtros.modal.html',
  styleUrls: ['./produtos-filtros.modal.scss']
})
export class AppProdutosFiltrosModal {
  //#region publics
  pagFilters = signal<ICorMeiliFilter[]>([]);
  //#endregion

  //#region injects
  #dialogRef = inject(MatDialogRef<AppProdutosFiltrosModal>);
  //#endregion

  //#region constructor
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: IProdutosFiltrosParams,
  ) { }
  //#endregion

  //#region lifecycles
  ngOnInit() {
    // this.data?.pagFilters || []
    this.pagFilters.set(
      FILTERS
        .map((f: ICorMeiliFilter) => {
          const F: ICorMeiliFilter | null = this.data?.pagFilters
            .find((x: ICorMeiliFilter) => x?.id === f?.id) || null;
          // console.log({ F });
          return {
            ...f,
            tristate: !F ? '' : F?.tristate
          }
        })
    );
  }
  //#endregion

  //#region functions
  tid_fil(index: any, item: ICorMeiliFilter): string { return String(item?.id) || ''; }
  //#endregion

  //#region methods
  onModalCloseClick(val: any = null) {
    this.#dialogRef.close(val);
  }

  onSubmitClick() {
    const FILTERS: ICorMeiliFilter[] = this.pagFilters()
      .filter((f: ICorMeiliFilter) => f?.tristate !== '');
    // console.log({ FILTERS });
    this.onModalCloseClick(FILTERS);
  }
  //#endregion
}
