<!-- <p>conta {{ conta | json }}</p> -->
<!-- <p>loja {{ loja | json }}</p> -->
<!-- <p>formas {{ formas | json }}</p> -->
<!-- <p>formasSel {{ formasSel | json }}</p> -->

<div *ngIf="!!formas">
  <!-- <p>formas {{ formas | json }}</p> -->
  <table class="table">
    <thead>
      <tr>
        <th scope="col"></th>
        <th scope="col">Forma</th>
        <th scope="col"
            class="text-end">
        </th>
      </tr>
    </thead>

    <tbody>
      <tr *ngFor="let f of formas; trackBy:tid_for">
        <td class="img text-center">
          <img [src]="f?.__img"
               class="img-fluid">
        </td>

        <td>{{ f?._nome }}</td>

        <td class="text-end">
          <mat-slide-toggle [(ngModel)]="f.__sel.distribuidora"
                            (ngModelChange)="onSelChange($event)">
          </mat-slide-toggle>
        </td>
      </tr>
    </tbody>
  </table>

  <div *ngIf="!formas?.length">
    <div class="alert alert-light text-center"
         role="alert">
      Nenhuma forma de pagamento.
    </div>
  </div>
</div>
