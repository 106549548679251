//#region 3rd
import {
  intersectionWith,
  isEqual,
} from 'lodash';
//#endregion

//#region models
import {
  ICorRota,
  ICorRotaConfig,
  ICorRotaValid,
} from '../_interfaces/_misc';
//#endregion

export class CorRota implements ICorRota {
  //#region publics
  extras: any;
  icone: string;
  grupo: string;
  qtde: number = 0;
  roles: any[];
  titulo: string;
  url: string;
  __valid: ICorRotaValid = {
    status: false,
    isAdmin: false,
    hasRole: false,
    hasCustomValidation: false,
  };
  //#endregion

  //#region privates
  #config: ICorRotaConfig = {
    ignoreAdmin: false,
  };
  //#endregion

  constructor(
    titulo: string,
    url: string,
    icone: string,
    roles: any[],
    grupo?: string,
    config?: ICorRotaConfig,
    // extras?: any,
    // qtde: number,
    // calcValid?: () => boolean
  ) {
    // this.extras = extras || null;
    this.icone = icone || '';
    this.grupo = grupo || '';
    this.roles = roles || [];
    this.titulo = titulo || '';
    this.url = url || '';

    // config
    const CNF_IGNORE_ADMIN: any = config?.ignoreAdmin;
    if (CNF_IGNORE_ADMIN !== undefined) {
      this.#config.ignoreAdmin = CNF_IGNORE_ADMIN;
    } // if

    const CNF_CALC_VALID: any = config?.calcValid;
    if (CNF_CALC_VALID !== undefined) {
      this.#config.calcValid = CNF_CALC_VALID;
    } // if

    // this.qtde = qtde;
    // this._customCalcValid = calcValid;
    // if (calcValid) {
    //   this.__valid = calcValid();
    // }
    // else {
    //   this.__valid = false;
    // } // else

    this.validate();
  }

  validate(
    roles: any[] = [],
    extras?: any
  ): ICorRotaValid {
    this.__valid = {
      status: false,
      isAdmin: false,
      hasRole: false,
      hasCustomValidation: false,
    };
    this.__valid.hasCustomValidation = !!this.#config?.calcValid ?? false;
    const customValid: boolean = this.__valid.hasCustomValidation
      ? !!(this.#config?.calcValid?.(
        {
          grupo: this.grupo,
          opRoles: roles,
          routeRoles: this.roles,
          // extras: this.extras,
        },
        extras
      ))
      : false;
    // console.log(roles, this.roles);
    // console.log('this._customCalcValid', this._customCalcValid);
    // if (this.#config.calcValid) {
    //   this.__valid = this.#config.calcValid(roles);
    // } else {

    if (
      this.roles.length
      && !this.__valid.hasCustomValidation
    ) {
      if (roles.length) {
        const IGNORE_ADMIN: boolean = !!this.#config?.ignoreAdmin;
        this.__valid.isAdmin = roles[0] === '*' && !IGNORE_ADMIN;
        this.__valid.hasRole = !!((intersectionWith(this.roles, roles, isEqual) || []).length);
        // console.log(URL, ROLES, FOUND);
      } else {
        this.__valid.hasRole = false;
      } // else
    } // if
    this.__valid.status = this.__valid.hasRole || this.__valid.isAdmin || customValid;
    // } // else
    // console.log(this.__valid);
    return this.__valid;
  }
}
