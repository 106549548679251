//#region ng
import {
  Component,
  EventEmitter,
  effect,
  inject,
  signal,
} from '@angular/core';
import { Router } from '@angular/router';
//#endregion

//#region meilisearch
import { MeiliSearch } from 'meilisearch';
//#endregion

//#region mat
import { MatDialog } from '@angular/material/dialog';
//#endregion

//#region firebase
import {
  doc,
  Firestore,
  onSnapshot,
  Unsubscribe as FbUnsubscribe,
} from '@angular/fire/firestore';
//#endregion

//#region 3rd
import {
  combineLatest,
  from,
  Observable,
  Subject,
  Subscription,
} from 'rxjs';
import {
  filter,
  finalize,
  first,
  map,
  startWith,
  switchMap,
  takeUntil,
  tap,
} from 'rxjs/operators';
//#endregion

//#region models
import { TCorViewStyle } from '../../../_shared/_core/_misc/_models/_types';
interface IVm {
  searchTerm: string;
  pagFilters: ICorMeiliFilter[];
};
import { ICorMeiliFilter } from '../../../_shared/_core/_misc/_models/_interfaces/_misc';
import {
  HOME_ROUTE,
  KEY_OFFSET_PRODUTOS,
  KEY_VIEWSTYLE_PRODUTOS
} from '../../../models/consts';
import { FOCUS_TIMEOUT } from '../../../_shared/_core/_misc/_models/consts';
import {
  IConta,
  ILoja,
  IProduto
} from '../../../_shared/_mercadeiro/_misc/_models/_interfaces/_cols';
//#endregion

//#region libs
import { onDestroy } from '../../../_shared/_core/_ng/_libs';
import { calcMeiliNomeIndice } from '../../../_shared/_mercadeiro/_misc/_libs';
import { compareValues } from '../../../_shared/_libs/_misc/_arrays';
//#endregion

//#region services
import {
  AppService,
  ROTAS
} from '../../../services';
import { ProdutosService } from '../../../_shared/_mercadeiro/_ng/_services';
//#endregion

//#region stores
import {
  ContasStore,
  LojasStore
} from '../../../_shared/_mercadeiro/_ng/_stores';
//#endregion

//#region components
import {
  AppProdutosFiltrosModal,
  ProdutoEditModal,
  ProdutoInfoModal
} from '../../../modals';
//#endregion

@Component({
  selector: 'app-produtos-produtos',
  templateUrl: './produtos.component.html',
  styleUrls: ['./produtos.component.scss']
})
export class ProdutosProdutosComponent {
  //#region actions
  #destroyAction$: Subject<void> = onDestroy();
  searchTermAction$ = signal<Subject<string>>(new Subject<string>()).asReadonly();
  pagFiltersAction$ = signal<Subject<ICorMeiliFilter[]>>(new Subject<ICorMeiliFilter[]>).asReadonly();
  //#endregion

  //#region publics
  offsetStorageKey = signal<string>(KEY_OFFSET_PRODUTOS).asReadonly();
  pagFilters = signal<ICorMeiliFilter[]>([]);
  pagPage = signal<number>(1);
  pagItemsPerPage = signal<string>('12');
  produtos = signal<IProduto[]>(null);
  viewStyle = signal<TCorViewStyle>('cards');
  viewStyleStorageKey = signal<string>(KEY_VIEWSTYLE_PRODUTOS).asReadonly();
  vm$ = signal<Observable<IVm>>(null);
  //#endregion

  //#region privates
  #meilisearchClient: MeiliSearch;
  #nomeIndice: string;
  #lojaPath: string;
  #produtoUnsub: FbUnsubscribe;
  //#endregion

  //#region events
  searchFocusEvent$ = signal<EventEmitter<boolean>>(new EventEmitter<boolean>()).asReadonly();;
  //#endregion

  //#region injects
  #contasStore = inject(ContasStore);
  #db = inject(Firestore);
  #lojasStore = inject(LojasStore);
  #produtosServ = inject(ProdutosService);
  #modal = inject(MatDialog);
  #router = inject(Router);
  //#endregion

  //#region constructor
  constructor() {
    effect(() => this.pagFiltersAction$().next(this.pagFilters()));
  }
  //#endregion

  //#region lifecycles
  ngOnInit() {
    this.#contasStore.contaStateChanged$
      .pipe(takeUntil(this.#destroyAction$))
      .subscribe(
        (conta: IConta) => !!conta
          && !ROTAS?.produtos?.__valid?.status
          && this.#router.navigateByUrl(HOME_ROUTE)
      );

    this.#lojasStore.lojaStateChanged$
      .pipe(
        filter((loja: ILoja) => !!loja),
        tap(
          (loja: ILoja) => {
            // console.log(loja?.meilisearch);
            // if (!!loja) {
            this.#lojaPath = loja?.__idInfo?.lojaPath || '';
            const { host: HOST, searchKey: API_KEY } = loja?.meilisearch;
            // console.log(HOST, API_KEY);
            this.#meilisearchClient = new MeiliSearch(
              {
                host: HOST || '',
                apiKey: API_KEY || ''
              }
            );
            this.#nomeIndice = calcMeiliNomeIndice(loja?.id || '', 'produtos');
            this.searchTermAction$().next('');
            this._focus();
            // } // if
          }),
        takeUntil(this.#destroyAction$),
      )
      .subscribe();

    this.vm$.set(
      combineLatest([
        this.searchTermAction$().pipe(startWith('')),
        this.pagFiltersAction$().pipe(startWith([])),
      ])
        .pipe(
          switchMap(([searchTerm, pagFilters]) => {
            const FILTERS: any[] = pagFilters
              .map((f: ICorMeiliFilter) => `${f.attribute} ${f.op} ${f.tristate === 'checked'}`);
            // console.log({ FILTERS });
            return from(
              !!this.#meilisearchClient
                ? this.#meilisearchClient
                  .index(this.#nomeIndice)
                  .search(
                    searchTerm,
                    {
                      filter: FILTERS,
                      limit: 1000
                    }
                  )
                : Promise.resolve({ hits: [] })
            )
              .pipe(
                map((res) => {
                  this.produtos.set(
                    this.#produtosServ.fixes((res?.hits || []) as IProduto[])
                      .map((p: IProduto, i: number) => {
                        return {
                          ...p,
                          __relevancia: i + 1
                        }
                      })
                      .sort(compareValues('__relevancia', 'asc'))
                  );
                  this.pagPage.set(1);
                  const VM: IVm = {
                    searchTerm,
                    pagFilters
                  }
                  return VM;
                })
              )
          }),
        )
    );
  }

  ngAfterViewInit() {
    this._focus();
  }

  ngOnDestroy() {
    !!this.#produtoUnsub && this.#produtoUnsub();
  }
  //#endregion

  //#region functions
  private _focus() {
    setTimeout(
      () => { this.searchFocusEvent$().emit(true); },
      FOCUS_TIMEOUT
    );
  }

  tid_pag(index: any, item: ICorMeiliFilter): string { return String(item?.id) || ''; }

  tid_pro(index: any, item: IProduto): string { return item?.id || ''; }
  //#endregion

  //#region methods
  onAddImgClick(produto: IProduto) {
    // console.log(produto);
    const PRODUTO_ID: string = produto?.id || '';
    if (
      !!PRODUTO_ID
      && !!this.#lojaPath
    ) {
      !!this.#produtoUnsub && this.#produtoUnsub();
      const PATH: string = `${this.#lojaPath}/produtos/${PRODUTO_ID}`;
      // console.log(PATH);
      !!this.#produtoUnsub && this.#produtoUnsub();
      this.#produtoUnsub = onSnapshot(
        doc(this.#db, PATH),
        snap => {
          const PRODUTO: any = {
            id: snap.id,
            ...snap.data()
          };
          // console.log({ PRODUTO });
          this.produtos.update(
            (produtos: IProduto[]) => (produtos || [])
              .map(
                (p: IProduto) => this.#produtosServ.fix(
                  p?.id === PRODUTO?.id
                    ? {
                      ...PRODUTO,
                      __relevancia: p?.__relevancia,
                    }
                    : p
                )
              )
              .sort(compareValues('__relevancia', 'asc'))
          );
        }
      );
    } // if

    AppService.addImgProdutoFromGaleria$().emit(produto);
  }

  onAddFiltroClick() {
    const DIALOG_EXISTS = this.#modal.getDialogById('filtro-add');
    if (!DIALOG_EXISTS) {
      const DIALOG_REF: any = this.#modal.open(
        AppProdutosFiltrosModal,
        {
          id: 'filtro-add',
          panelClass: 'no-border-dialog-container',
          data: {
            pagFilters: this.pagFilters(),
          }
        }
      );

      const SUB: Subscription = DIALOG_REF
        .afterClosed()
        .pipe(first(), finalize(() => SUB?.unsubscribe()))
        .pipe(filter(x => !!x))
        .subscribe((resp: any) => {
          // console.log(resp);
          this.pagFilters.set(resp);
        });
    } // if
  }

  onDelFiltroClick(f: ICorMeiliFilter) {
    this.pagFilters.update((prev: ICorMeiliFilter[]) => (prev || []).filter((p: ICorMeiliFilter) => p?.id !== f?.id));
  }

  onProdutoConsultaClick(produto: IProduto) {
    // console.log(produto);
    const DIALOG_EXISTS = this.#modal.getDialogById('produto-info');
    if (!DIALOG_EXISTS) {
      this.#modal.open(
        ProdutoInfoModal,
        {
          panelClass: 'no-border-dialog-container',
          id: 'produto-info',
          data: {
            produto
          }
        }
      );
    } // if
  }

  onProdutoEditClick(produto: IProduto) {
    // console.log(produto);
    const PRODUTO_ID: string = produto?.id || '';
    if (
      !!PRODUTO_ID
      && !!this.#lojaPath
    ) {
      const PATH: string = `${this.#lojaPath}/produtos/${PRODUTO_ID}`;
      // console.log(PATH);
      !!this.#produtoUnsub && this.#produtoUnsub();
      this.#produtoUnsub = onSnapshot(
        doc(this.#db, PATH),
        snap => {
          const PRODUTO: any = {
            id: snap.id,
            ...snap.data()
          };
          // console.log({ PRODUTO });
          this.produtos.update(
            (produtos: IProduto[]) => (produtos || [])
              .map(
                (p: IProduto) => this.#produtosServ.fix(
                  p?.id === PRODUTO?.id
                    ? {
                      ...PRODUTO,
                      __relevancia: p?.__relevancia,
                    }
                    : p
                )
              )
              .sort(compareValues('__relevancia', 'asc'))
          );
        }
      );
    } // if

    const DIALOG_EXISTS = this.#modal.getDialogById('produto-form');
    if (!DIALOG_EXISTS) {
      // const DIALOG_REF: any =
      this.#modal.open(
        ProdutoEditModal,
        {
          panelClass: 'no-border-dialog-container',
          id: 'produto-form',
          data: {
            produto,
            lojaPath: this.#lojaPath,
          }
        }
      );
    } // if
  }
  //#endregion
}
