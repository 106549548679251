<div class="modal-body p-relative">
  <mat-toolbar class="header"
               color="primary">
    <mat-toolbar-row>
      <h3> {{ loja?.nome }} </h3>

      <span class="flex-1"></span>

      <button mat-icon-button
              (click)="onModalCloseClick()">
        <mat-icon>close</mat-icon>
      </button>
    </mat-toolbar-row>
  </mat-toolbar>

  <!-- <p>loja {{ loja | json }}</p> -->
  <!-- <p>conta {{ conta | json }}</p> -->

  <div class="p-3">
    <me-mat-loja-info [loja]="loja"
                      [conta]="conta">
    </me-mat-loja-info>
  </div>
</div>