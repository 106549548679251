<ng-container *ngIf="vm$() | async as vm">
  <div class="box">
    <nav aria-label="breadcrumb">
      <ol class="breadcrumb">
        <li class="breadcrumb-item">
          <span class="icon-o-home primary tint o-50 pointer"
                routerLink="/notificacoes">
          </span>
        </li>

        <li class="breadcrumb-item active">
          Pedidos
        </li>

        <li class="ms-auto"
            *ngIf="!!vm?.pedidos?.length">
          <cor-mat-pagination-nav [paginator]="paginator"
                                  [offsetStorageKey]="offsetStorageKey()"
                                  [isMobile]="true"
                                  (offsetChanged$)="pagItemsPerPageAction$().next($event)" />
        </li>
      </ol>
    </nav>

    <!-- <p>vm {{ vm | json }}</p> -->

    <div *ngIf="!!vm?.pedidos; else carregandoPedidos">
      <div *ngIf="!vm?.pedidos?.length; else pedidosEncontrados">
        <div class="alert alert-light text-center"
             role="alert">
          Nenhum pedido.
        </div>
      </div>
      <ng-template #pedidosEncontrados>
        <ul class="w-cards pedidos">
          <li *ngFor="let p of vm?.pedidos; trackBy:tid_ped">
            <me-mat-pedido-card [pedido]="p"
                                (detalhes$)="onDetalhesClick($event)" />
          </li>
        </ul>
      </ng-template>
    </div>
    <ng-template #carregandoPedidos>
      <me-html-pedidos-list-skel />
    </ng-template>
  </div>
</ng-container>
