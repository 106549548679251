//#region ng
import {
  Component,
  ViewChild,
  inject,
  signal,
} from '@angular/core';
import { Router } from '@angular/router';
import {
  FormGroup,
  FormBuilder,
  Validators,
  FormControl,
  ValidationErrors,
  AbstractControl
} from '@angular/forms';
//#endregion

//#region 3rd
import {
  BehaviorSubject,
  Observable,
  Subject,
  Subscription,
  combineLatest
} from 'rxjs';
import { filter, map, takeUntil } from 'rxjs/operators';
//#endregion

//#region models
interface IVm {
  conta: IConta;
  tab: TDistribuidoraTab;
}
type TDistribuidoraTab = 'rotas' | 'formas' | 'credenciados';
import {
  IConta,
  ILoja
} from '../../_shared/_mercadeiro/_misc/_models/_interfaces/_cols';
import { HOME_ROUTE } from '../../models/consts';
//#endregion

//#region libs
import { onDestroy } from '../../_shared/_core/_ng/_libs';
//#endregion

//#region services
import {
  ContasService,
  LojasService
} from '../../_shared/_mercadeiro/_ng/_services';
import {
  AppService,
  ROTAS
} from '../../services';
//#endregion

//#region stores
import {
  ContasStore,
  LojasStore
} from '../../_shared/_mercadeiro/_ng/_stores';
//#endregion

//#region components
import {
  DistribuidoraCredenciadosComponent,
  DistribuidoraFormasPgtoComponent
} from '.';
//#endregion

@Component({
  selector: 'distribuidora-page',
  templateUrl: './distribuidora.page.html',
  styleUrls: ['./distribuidora.page.scss']
})
export class DistribuidoraPage {
  //#region actions
  #destroyAction$: Subject<void> = onDestroy();
  tabAction$ = signal<BehaviorSubject<TDistribuidoraTab>>(new BehaviorSubject<TDistribuidoraTab>('rotas'));
  // isDistribuidora$ = signal<BehaviorSubject<boolean>>(new BehaviorSubject<boolean>(true));
  //#endregion

  //#region viewchilds
  @ViewChild(DistribuidoraFormasPgtoComponent) distribuidoraFormaPgtoRef: DistribuidoraFormasPgtoComponent;
  @ViewChild(DistribuidoraCredenciadosComponent) distribuidoraCredenciadosRef: DistribuidoraCredenciadosComponent;
  //#endregion

  //#region publics
  distribuidoraForm = signal<FormGroup>(null);
  loja = signal<ILoja>(null);
  vm$ = signal<Observable<IVm>>(null);
  //#endregion

  //#region injects
  // #contasServ = inject(ContasService);
  #contasStore = inject(ContasStore);
  #fb = inject(FormBuilder);
  // #lojasServ = inject(LojasService);
  #lojasStore = inject(LojasStore);
  #router = inject(Router);
  //#endregion

  //#region lifecycles
  ngOnInit() {
    this.vm$.set(
      combineLatest([
        this.tabAction$(),
        this.#contasStore.contaStateChanged$.pipe(filter((conta: IConta) => !!conta)),
        // this.isDistribuidora$(),
      ])
        .pipe(
          map(([tab, conta]) => {
            // console.log(tab, isDistribuidora);
            // console.log(conta);
            if (!!conta) {
              // console.log(ROTAS?.distribuidora?.__valid?.status);
              // const ROLE: TOperadorRole = get(conta, 'operador.role');
              !ROTAS?.distribuidora?.__valid?.status &&
                this.#router.navigateByUrl(HOME_ROUTE);
            } // if
            const VM: IVm = {
              conta,
              tab
            };
            // console.log(VM);
            return VM;
          })
        )
    );

    this.#lojasStore.lojaStateChanged$
      .pipe(takeUntil(this.#destroyAction$))
      .subscribe(
        (loja: ILoja) => {
          // console.log(loja?.distribuidora?.status);
          // console.log(loja?.distribuidora?.tipoRota);
          // this.isDistribuidora$().next(loja?.distribuidora?.status);
          this.loja.set(loja);
          this.distribuidoraForm.set(
            this.#fb.group({
              distribuidora: this.#fb.group({
                tipoRota: [loja?.distribuidora?.tipoRota || '']
              }),
            })
          );
          // this.tabAction$().next('rotas');
        }
      );
  }
  //#endregion

  //#region Controls getters
  get tipoRotaRef(): AbstractControl { return this.distribuidoraForm()?.get('distribuidora.tipoRota'); }
  //#endregion

  //#region methods
  onNovaFormaClick() {
    // console.log('onNovaFormaClick');
    this.distribuidoraFormaPgtoRef?.onFormaEditClick();
  }

  onNovoCredenciadoClick() {
    // console.log('onNovoCredenciadoClick');
    this.distribuidoraCredenciadosRef?.onCredenciadoHabilitarClick();
  }
  //#endregion

  /*

  //#region publics
  conta: IConta;
  loja: ILoja;
  //#endregion

  //#region privates
  private _subs: Subscription[] = [];
  //#endregion

  //#region lifecycles
  ngOnInit(): void {
    this._subs.push(
      this.#contasStore.contaStateChanged$
        .subscribe(async (conta: IConta) => {
          // console.log(conta);
          this.conta = conta ? this.#contasServ.fix(conta) : null;
          if (conta) {
            // const ROLE: TOperadorRole = get(conta, 'operador.role');
            !ROTAS?.config?.__valid?.status &&
              this.#router.navigateByUrl(HOME_ROUTE);
          } // if
        }),

      AppService.lojaStateChanged$
        .subscribe(async (loja: ILoja) => {
          // console.log(loja);
          this.loja = loja ? this.#lojasServ.fix(loja) : null;
          // console.log(this.loja);

          this.tab = 'rotas';
        }),
    );
  }
  //#endregion
  */
}
