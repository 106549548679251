<div class="modal-body">
  <mat-toolbar color="primary">
    <mat-toolbar-row class="d-flex justify-content-between">
      <div class="my-auto">
        <h3> {{ caption() }} </h3>
      </div>

      <div class="my-auto">
        <button mat-icon-button
                (click)="onModalCloseClick()">
          <mat-icon>close</mat-icon>
        </button>
      </div>
    </mat-toolbar-row>
  </mat-toolbar>

  <!-- <p>novo {{ novo }}</p> -->
  <!-- <p>nomeRef?.errors {{ nomeRef?.errors | json }}</p> -->
  <!-- <p>formaForm.value {{ formaForm.value | json }}</p> -->
  <!-- <p>imgRef?.errors {{ imgRef?.errors | json }}</p> -->
  <!-- <p>imgRef?.value {{ imgRef?.value | json }}</p> -->
  <!-- <p>isValidUrl(imgRef?.value) {{ isValidUrl(imgRef?.value) }}</p>s -->
  <!-- <p>caption {{ caption }}</p> -->
  <!-- <p>novo {{ novo }}</p> -->
  <!-- https://bomebarato.vteximg.com.br/arquivos/icon-boleto.png -->

  <div class="p-3">
    <form [formGroup]="formaForm()"
          class="px-2 py-4"
          (ngSubmit)="onSubmitClick()"
          *ngIf="!!formaForm()">

      <div class="d-flex">
        <div class="flex-1 me-2">
          <div>
            <mat-form-field class="w-100 mb-2"
                            appearance="outline">
              <mat-label>Nome</mat-label>
              <input type="text"
                     matInput
                     formControlName="_nome"
                     [focus]="nomeFocusEvent$()"
                     onReturn>
              <mat-hint *ngIf="fv()?.error(nomeRef)">
                <span class="danger">
                  {{ fv()?.error(nomeRef) }}
                </span>
              </mat-hint>
            </mat-form-field>
          </div>

          <div>
            <mat-form-field class="w-100 mb-2"
                            appearance="outline">
              <mat-label>URL imagem</mat-label>
              <input type="text"
                     matInput
                     formControlName="imgLink"
                     onReturn>
              <mat-hint *ngIf="fv()?.error(imgRef) || imgRef?.errors?.['url']">
                <span class="danger">
                  {{ fv()?.error(imgRef) || 'URL inválida.' }}
                </span>
              </mat-hint>
            </mat-form-field>
          </div>
        </div>

        <div class="thumb m-auto">
          <img [src]="imgRef?.value"
               class="img-fluid"
               *ngIf="!imgRef?.errors?.['url']">
        </div>
      </div>

      <div class="row">
        <div class="col d-flex">
          <button type="submit"
                  class="pointer flex-1"
                  mat-raised-button
                  color="primary"
                  [disabled]="!formaForm().valid">
            CONFIRMAR
          </button>
        </div>
      </div>
    </form>
  </div>
