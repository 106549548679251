<div class="d-flex"
     *ngIf="!!produto">
      <span class="icon flex-1 icon-o-thumbs-up"
            matTooltip="OK"
            [class.active]="produto?.ativo?._status">
      </span>

      <span class="icon flex-1 icon-b-check"
            matTooltip="Ativo"
            [class.active]="produto?.ativo?.status">
      </span>

      <span class="icon flex-1 icon-b-wifi-on"
            matTooltip="Online"
            [class.active]="!!produto?.ativo?.online">
      </span>

      <span class="icon flex-1 icon-b-bang"
            matTooltip="Consistente"
            [class.active]="!!produto?.ativo?._consistente">
      </span>

      <span class="icon flex-1 icon-b-barcode"
            matTooltip="Industrializado"
            [class.active]="!!produto?._industrializado">
      </span>

      <span class="icon flex-1 icon-b-cut"
            matTooltip="Fracionado"
            [class.active]="!!produto?.fracionado?._status">
      </span>

      <span class="icon flex-1 icon-b-asterisk"
            matTooltip="Atacado"
            [class.active]="!!produto?.atacado?.status">
      </span>

      <span class="icon flex-1 icon-o-blocked"
            matTooltip="Estoque crítico"
            [class.active]="!!produto?.estoqueCritico">
      </span>

      <span class="icon flex-1 icon-b-img"
            matTooltip="Imagem"
            [class.pointer]="!produto?._industrializado"
            [class.active]="!!produto?._img"
            (click)="!produto?._industrializado && imgClick$.emit()">
      </span>
</div>