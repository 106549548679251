//#region ng
import { NgModule } from '@angular/core';
import {
  RouterModule,
  Routes
} from '@angular/router';
import { HOME_ROUTE } from './models/consts';
//#endregion

//#region 3rd
import {
  AuthGuard,
  redirectUnauthorizedTo
} from '@angular/fire/auth-guard';
const redirectUnauthorizedToLogin = () => redirectUnauthorizedTo(['login']);
//#endregion

//#region models
const ROUTES: Routes = [
  {
    path: '',
    redirectTo: HOME_ROUTE,
    pathMatch: 'full'
  },
  {
    path: 'notificacoes',
    canActivate: [AuthGuard, LojaGuard],
    data: { authGuardPipe: redirectUnauthorizedToLogin },
    component: NotificacoesPage,
  },
  {
    path: 'config',
    canActivate: [AuthGuard, LojaGuard],
    data: { authGuardPipe: redirectUnauthorizedToLogin },
    component: ConfigPage,
  },
  {
    path: 'dashboard',
    canActivate: [AuthGuard, LojaGuard],
    data: { authGuardPipe: redirectUnauthorizedToLogin },
    component: DashboardPage
  },
  {
    path: 'departamentos',
    canActivate: [AuthGuard, LojaGuard],
    data: { authGuardPipe: redirectUnauthorizedToLogin },
    component: DepartamentosPage,
  },
  {
    path: 'distribuidora',
    canActivate: [AuthGuard, LojaGuard],
    data: { authGuardPipe: redirectUnauthorizedToLogin },
    component: DistribuidoraPage,
  },
  {
    path: 'formasPgto',
    canActivate: [AuthGuard, LojaGuard],
    data: { authGuardPipe: redirectUnauthorizedToLogin },
    component: FormasPgtoPage,
  },
  {
    path: 'horarios',
    canActivate: [AuthGuard, LojaGuard],
    data: { authGuardPipe: redirectUnauthorizedToLogin },
    component: HorariosPage,
  },
  {
    path: 'lojas',
    canActivate: [AuthGuard],
    data: { authGuardPipe: redirectUnauthorizedToLogin },
    component: LojasPage,
  },
  {
    path: 'login',
    component: LoginPage,
  },
  {
    path: 'midia',
    canActivate: [AuthGuard, LojaGuard],
    data: { authGuardPipe: redirectUnauthorizedToLogin },
    component: MidiaPage,
  },
  {
    path: 'movimento',
    canActivate: [AuthGuard, LojaGuard],
    data: { authGuardPipe: redirectUnauthorizedToLogin },
    component: MovimentoPage,
  },
  {
    path: 'notificacoes',
    canActivate: [AuthGuard, LojaGuard],
    data: { authGuardPipe: redirectUnauthorizedToLogin },
    component: PedidosPage,
  },
  {
    path: 'operadores',
    canActivate: [AuthGuard, LojaGuard],
    data: { authGuardPipe: redirectUnauthorizedToLogin },
    component: OperadoresPage,
  },
  {
    path: 'pedidos',
    canActivate: [AuthGuard, LojaGuard],
    data: { authGuardPipe: redirectUnauthorizedToLogin },
    component: PedidosPage,
  },
  {
    path: 'produtos',
    canActivate: [AuthGuard, LojaGuard],
    data: { authGuardPipe: redirectUnauthorizedToLogin },
    component: ProdutosPage,
  },
  {
    path: 'setoresEntrega',
    canActivate: [AuthGuard, LojaGuard],
    data: { authGuardPipe: redirectUnauthorizedToLogin },
    component: SetoresEntregaPage,
  },
  /* {
    path: 'lojas',
    canActivate: [AuthGuard],
    data: { authGuardPipe: redirectUnauthorizedToLogin },
    component: LojasComponent,
  }, */
  /* {
    path: 'loja',
    canActivate: [AuthGuard],
    data: { authGuardPipe: redirectUnauthorizedToLogin },
    component: LojaComponent,
  }, */
  /* {
    path: 'addLoja',
    canActivate: [AuthGuard],
    data: { authGuardPipe: redirectUnauthorizedToLogin },
    component: AddLojaComponent,
  }, */
  /* {
    path: 'integradoras',
    canActivate: [AuthGuard],
    data: { authGuardPipe: redirectUnauthorizedToLogin },
    component: IntegradorasComponent,
  }, */
  /* {
    path: 'parceiros',
    canActivate: [AuthGuard],
    data: { authGuardPipe: redirectUnauthorizedToLogin },
    component: ParceirosComponent,
  }, */
  /* {
    path: 'redes',
    canActivate: [AuthGuard],
    data: { authGuardPipe: redirectUnauthorizedToLogin },
    component: RedesComponent,
  }, */
];
//#endregion

//#region guards
import { LojaGuard } from './guards';
//#endregion

//#region components
import {
  ConfigPage,
  DashboardPage,
  DepartamentosPage,
  DistribuidoraPage,
  FormasPgtoPage,
  HorariosPage,
  LoginPage,
  LojasPage,
  MidiaPage,
  MovimentoPage,
  NotificacoesPage,
  OperadoresPage,
  PedidosPage,
  ProdutosPage,
  SetoresEntregaPage,
} from './pages';
//#endregion

@NgModule({
  imports: [RouterModule.forRoot(
    ROUTES,
    { useHash: true }
  )],
  exports: [RouterModule]
})
export class AppRoutingModule { }
