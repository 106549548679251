//#region ng
import {
  Component,
  Input,
  inject,
  signal,
} from '@angular/core';
import {
  FormGroup,
  FormBuilder,
  Validators,
  FormControl,
  ValidationErrors,
  AbstractControl
} from '@angular/forms';
import { Router } from '@angular/router';
//#endregion

//#region mat
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatDialog } from '@angular/material/dialog';
//#endregion

//#region firebase
import {
  arrayRemove,
  doc,
  Firestore,
  onSnapshot,
  Unsubscribe as FbUnsubscribe,
} from '@angular/fire/firestore';
//#endregion

//#region 3rd
import { get } from 'lodash';
import {
  Observable,
  Subject,
  Subscription,
  combineLatest
} from 'rxjs';
import {
  first,
  finalize,
  takeUntil,
  map,
  startWith
} from 'rxjs/operators';
//#endregion

//#region models
interface IVm {
  // setores: ISetorEntrega[];
  pag: {
    itemsPerPage: string;
    page: number;
  };
  // searchTerm: string;
  // viewStyle: TCorViewStyle;
};
import {
  ILoja,
  ISetorEntrega
} from '../../_shared/_mercadeiro/_misc/_models/_interfaces/_cols';
import {
  HOME_ROUTE,
  KEY_OFFSET_SETORES_ENTREGA,
  THEME
} from '../../models/consts';
import { ODOCS_TAG } from '../../_shared/_core/_misc/_models/consts';
import { TNullable } from '../../_shared/_core/_misc/_models/_types';
import { CorFormValidation } from '../../_shared/_core/_ng/_models/_classes';
//#endregion

//#region custom validators
class CustomValidator {
  static valorPos() {
    return (control: FormControl): TNullable<ValidationErrors> => {
      const NRO: number = Number(control.value || '');
      // console.log(NRO);
      return NRO < 0 ? { valor: true } : null;
    }
  }

  static descMinCarrinhoPos() {
    return (control: FormControl): TNullable<ValidationErrors> => {
      const NRO: number = Number(control.value || '');
      // console.log(NRO);
      return NRO < 0 ? { descMinCarrinho: true } : null;
    }
  }

  static percDesc() {
    return (control: FormControl): TNullable<ValidationErrors> => {
      const DESC_PERC: number = Number(control.value || '');
      // console.log(DESC_PERC);
      return DESC_PERC < 0 || DESC_PERC > 100 ? { percDesc: true } : null;

      // const FORM_GROUP: FormGroup = get(control, '_parent');
      // const DESC_STATUS: boolean = !!get(FORM_GROUP, 'value.status');
      // const DESC_PERC: number = get(FORM_GROUP, 'value.perc');
      // // console.log(FORM_GROUP);
      // // const PERC: number = Number(control.value || '') || 0;
      // // console.log(DESC_STATUS, DESC_PERC);
      // return !DESC_STATUS
      //   ? null
      //   : (DESC_PERC <= 0 || DESC_PERC > 100) ? { percDesc: true } : null;
    }
  }
}
//#endregion

//#region libs
import { compareValues } from '../../_shared/_libs/_misc/_arrays';
import { onDestroy } from '../../_shared/_core/_ng/_libs';
//#endregion

//#region services
import { CorLoaderService } from '../../_shared/_core/_ng/_services';
import {
  // ContasService,
  LojasService,
  SetoresEntregaService
} from '../../_shared/_mercadeiro/_ng/_services';
//#endregion

//#region stores
import { LojasStore } from '../../_shared/_mercadeiro/_ng/_stores';
//#endregion

//#region components
import {
  BairroAddModal,
  SetorFormModal
} from '../../modals';
//#endregion

@Component({
  selector: 'app-setores-entrega',
  templateUrl: './setores-entrega.component.html',
  styleUrls: ['./setores-entrega.component.scss']
})
export class SetoresEntregaComponent {
  //#region actions
  #destroyAction$: Subject<void> = onDestroy();
  pagPageAction$ = signal<Subject<number>>(new Subject<number>()).asReadonly();
  pagItemsPerPageAction$ = signal<Subject<string>>(new Subject<string>()).asReadonly();
  //#endregion

  //#region inputs
  #loja: ILoja;
  get loja(): ILoja { return this.#loja; }
  @Input({ required: true }) set loja(val: ILoja) {
    this.#loja = !!val ? this.#lojasServ.fix(val) : null;
    // console.log(val);
    this.taxaForm.set(
      this.#fb.group({
        entrega: this.#fb.group({
          taxaGeral: this.#fb.group({
            status: [!!val?.entrega?.taxaGeral?.status, [Validators.required]],
            valor: [Number(val?.entrega?.taxaGeral?.valor) || 0, [Validators.required, CustomValidator.valorPos()]],
            desc: this.#fb.group({
              status: [!!val?.entrega?.taxaGeral?.desc?.status, [Validators.required]],
              minTotalCarrinho: [Number(val?.entrega?.taxaGeral?.desc?.minTotalCarrinho) || 0, [Validators.required, CustomValidator.descMinCarrinhoPos()]],
              perc: [Number(val?.entrega?.taxaGeral?.desc?.perc) || 0, [Validators.required, CustomValidator.percDesc()]],
            }),
          }),
        }),
      })
    );

    const LOJA_PATH: string = this.loja?.__idInfo?.lojaPath || '';
    // console.log(LOJA_PATH);
    if (!!LOJA_PATH) {
      const PATH: string = `${LOJA_PATH}/setores-entrega/${ODOCS_TAG}`;
      // console.log(PATH);
      this.#setoresUnsub && this.#setoresUnsub();
      this.#setoresUnsub = onSnapshot(
        doc(this.#db, PATH),
        snap => {
          // console.log(JSON.stringify(snap.data()));
          const SETORES: ISetorEntrega[] = [];
          (Object.values(snap.get('_odocs') || {}) || [])
            .forEach((r: any) => { SETORES.push(this.#setoresServ.fix(r)); });
          this.setores.set(SETORES.sort(compareValues('nome')));
          // this.setores = this.#setoresServ.fixes(Object.values(snap.get('_odocs') || {}) || []);
          // this._montaEnderecos();
        }
      );
    } // if
  }
  //#endregion

  //#region publics
  fv = signal<CorFormValidation>(null);
  offsetStorageKey = signal<string>(KEY_OFFSET_SETORES_ENTREGA).asReadonly();
  setorMenu = signal<ISetorEntrega>(null);
  setores = signal<ISetorEntrega[]>(null);
  taxaForm = signal<FormGroup>(null);
  vm$ = signal<Observable<IVm>>(null);
  //#endregion

  //#region privates
  #setoresUnsub: FbUnsubscribe;
  //#endregion

  //#region injects
  #db = inject(Firestore);
  #fb = inject(FormBuilder);
  #loaderServ = inject(CorLoaderService);
  #lojasServ = inject(LojasService);
  #lojasStore = inject(LojasStore);
  #modal = inject(MatDialog);
  #setoresServ = inject(SetoresEntregaService);
  #snackBar = inject(MatSnackBar);
  #router = inject(Router);
  //#endregion

  //#region constructor
  constructor() {
    this.fv.set(new CorFormValidation);
  }
  //#endregion

  //#region lifecycles
  ngOnInit(): void {
    this.vm$.set(
      combineLatest([
        this.pagPageAction$().pipe(startWith(1)),
        this.pagItemsPerPageAction$().pipe(startWith('12')),
      ])
        .pipe(
          map(([pagPage, pagItemsPerPage]) => {
            // console.log(this.#lojas);
            // console.log(pagItemsPerPage);
            const VM: IVm = {
              pag: {
                itemsPerPage: pagItemsPerPage,
                page: pagPage,
              },
              // setores: []
            };
            // console.log(VM);
            return VM;
          }),
        )
    );

    this.statusTaxaRef
      .valueChanges
      .pipe(takeUntil(this.#destroyAction$))
      .subscribe(
        (status: boolean) => {
          // console.log(status);
          !!status ? this.valorTaxaRef.enable() : this.valorTaxaRef.disable();
        }
      );
  }

  ngOnDestroy(): void {
    // this._subs.forEach((s: Subscription) => s?.unsubscribe());
    this.#setoresUnsub && this.#setoresUnsub();
  }
  //#endregion

  //#region Controls getters
  get statusTaxaRef(): AbstractControl { return this.taxaForm()?.get('entrega.taxaGeral.status'); }
  get valorTaxaRef(): AbstractControl { return this.taxaForm()?.get('entrega.taxaGeral.valor'); }
  get taxaEntregaDescPercRef(): AbstractControl { return this.taxaForm()?.get('entrega.taxaGeral.desc.perc'); }
  //#endregion

  //#region functions
  tid_set(index: any, item: ISetorEntrega): string { return item?.id || ''; }
  //#endregion

  //#region methods
  onSetorEditClick(setor: ISetorEntrega = null) {
    const DIALOG_EXISTS = this.#modal.getDialogById('setor-form');
    if (!DIALOG_EXISTS) {
      // const DIALOG_REF: any =
      this.#modal.open(
        SetorFormModal,
        {
          panelClass: 'no-border-dialog-container',
          id: 'setor-form',
          data: {
            setor,
            loja: this.loja,
          }
        }
      );
    } // if
  }

  onSetorDelClick(setor: ISetorEntrega = null) {
    // console.log(setor);
    const LOJA_ID: string = this.loja?.id || '';
    const SETOR_ID: string = setor?.id || '';
    // console.log(LOJA_ID);
    if (!!LOJA_ID && !!SETOR_ID) {
      const SUB: Subscription = this.#loaderServ.showUntilCompleted(
        this.#setoresServ.del(LOJA_ID, SETOR_ID)
          .pipe(first(), finalize(() => SUB?.unsubscribe()))
      )
        .subscribe(() => this.#snackBar.open(`Setor removido.`));
    } // if
  }

  async onBairroAddClick(setor: ISetorEntrega) {
    const DIALOG_EXISTS = this.#modal.getDialogById('bairro-add');
    if (!DIALOG_EXISTS) {
      let excecoes: string[] = [];
      (this.setores() || []).forEach((s: ISetorEntrega) => excecoes = excecoes.concat(s?.bairros || []));
      // const DIALOG_REF: any =
      this.#modal.open(
        BairroAddModal,
        {
          panelClass: 'no-border-dialog-container',
          id: 'bairro-add',
          data: {
            excecoes,
            setor,
            loja: this.loja,
          }
        }
      );
    } // if
  }

  onBairroDelClick(s: ISetorEntrega, b: string) {
    const SETOR_ID: string = s?.id || '';
    // console.log(SETOR_ID);
    const LOJA_PATH: string = this.loja?.__idInfo?.lojaPath || '';
    // console.log(b, SETOR_ID, LOJA_PATH);
    if (!!b && !!SETOR_ID && !!LOJA_PATH) {
      const SUB: Subscription = this.#loaderServ.showUntilCompleted(
        this.#setoresServ.update(
          LOJA_PATH,
          SETOR_ID,
          { bairros: arrayRemove(b) }
        )
          .pipe(first(), finalize(() => SUB?.unsubscribe()))
      )
        .subscribe(() => this.#snackBar.open(`Bairro ${b} removido.`));
    } // if
  }

  onSubmitTaxaGeralClick() {
    // console.log(this.configForm.value);
    // const LOJA_PATH: string = get(this.loja, '__idInfo.lojaPath') || '';
    const LOJA_ID: string = this.loja?.id || '';
    // console.log(LOJA_ID);
    if (LOJA_ID) {
      const SUB: Subscription = this.#loaderServ.showUntilCompleted(
        this.#lojasServ.update(
          LOJA_ID,
          this.taxaForm()?.value
        )
          .pipe(first(), finalize(() => SUB?.unsubscribe()))
      )
        .subscribe(
          () => {
            if (!!LOJA_ID) {
              const SUB: Subscription = this.#loaderServ.showUntilCompleted(
                this.#lojasServ.doc(LOJA_ID)
                  .pipe(first(), finalize(() => SUB?.unsubscribe()))
              )
                .subscribe(
                  async (loja: ILoja) => {
                    // console.log(loja);
                    this.#lojasStore.setState(loja, THEME);
                    this.#snackBar.open(`Taxa geral gravada.`);
                    this.#router.navigateByUrl(HOME_ROUTE);
                  });
            } // if
          }
        );
    } // if
  }
  //#endregion
}
