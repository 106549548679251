<ng-container *ngIf="vm$() | async as vm">
  <!-- <p>vm {{ vm | json }}</p> -->
  <!-- <p>vm?.loja {{ vm?.loja | json }}</p> -->
  <!-- <p>vm?.formas {{ vm?.formas | json }}</p> -->

  <div class="box">
    <nav aria-label="breadcrumb">
      <ol class="breadcrumb">
        <li class="breadcrumb-item">
          <span class="icon-o-home primary tint o-50 pointer"
                routerLink="/dashboard">
          </span>
        </li>

        <li class="breadcrumb-item active">Formas pgto</li>
      </ol>
    </nav>

    <div class="segment">
      <ul class="nav nav-pills justify-content-center">
        <li class="nav-item"
            *ngFor="let t of tiposFormas(); trackBy:tids">
          <a class="nav-link"
             [class.active]="vm?.tab === t"
             (click)="onTabClick(t)">
            {{ nomesTiposFormas()?.[t] }}
          </a>
        </li>
      </ul>
    </div>

    <table class="table"
           *ngIf="!!vm?.formas">
      <thead>
        <tr>
          <th scope="col"></th>
          <th scope="col">Forma</th>
          <th scope="col"
              class="text-center"
              *ngIf="vm?.loja?.entrega?.status.includes('R')">
            Retirada
          </th>
          <th scope="col"
              class="text-center"
              *ngIf="vm?.loja?.entrega?.status.includes('E')">
            Entrega
          </th>
        </tr>
      </thead>

      <tbody>
        <tr *ngFor="let f of vm?.formas | formasPgtosLfixes | async; trackBy:tid_for">
          <td class="img text-center">
            <img [src]="f?.__img"
                 class="img-fluid">
          </td>

          <td>{{ f?._nome }}</td>

          <td class="text-center"
              *ngIf="vm?.loja?.entrega?.status.includes('R')">

            <mat-slide-toggle [ngModel]="f?.__sel?.retirada"
                              (click)="onFormaClick(f, true)">
            </mat-slide-toggle>
          </td>

          <td class="text-center"
              *ngIf="vm?.loja?.entrega?.status.includes('E')">

            <mat-slide-toggle [ngModel]="f?.__sel?.entrega"
                              (click)="onFormaClick(f, false)">
            </mat-slide-toggle>
          </td>
        </tr>
      </tbody>
    </table>

    <div class="alert alert-light text-center"
         *ngIf="!vm?.formas?.length"
         role="alert">
      <strong> Nenhuma forma de pgto.</strong>
    </div>

  </div>
</ng-container>

<!--



 -->
