//#region ng
import {
  Component,
  EventEmitter,
  inject,
  Input,
  Output,
} from '@angular/core';
//#endregion

//#region models
import { IProDepartamento } from '../../../_misc/_models/_interfaces/_cols';
import { TNullable } from '../../../../_core/_misc/_models/_types';
//#endregion

//#region services
import { ProDepartamentosService } from '../../../_ng/_services';
//#endregion

@Component({
  selector: 'pro-html-departamento-card',
  templateUrl: './departamento-card.component.html',
  styleUrls: ['./departamento-card.component.scss']
})
export class ProHtmlDepartamentoCardComponent {
  //#region props
  #departamento: TNullable<IProDepartamento>;
  get departamento(): TNullable<IProDepartamento> { return this.#departamento; }
  @Input({ required: true }) set departamento(val: TNullable<IProDepartamento>) {
    this.#departamento = !!val ? this.#departamentosServ.fix(val) : null;
  }
  //#endregion

  //#region outputs
  @Output() click$ = new EventEmitter<IProDepartamento>();
  //#endregion

  //#region injects
  #departamentosServ = inject(ProDepartamentosService);
  //#endregion
}
