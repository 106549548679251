<div class="box">
  <nav aria-label="breadcrumb">
    <ol class="breadcrumb">
      <li class="breadcrumb-item">
        <span class="icon-o-home primary tint o-50 pointer"
              routerLink="/dashboard"></span>
      </li>

      <li class="breadcrumb-item active">
        Departamentos
      </li>
    </ol>
  </nav>

  <!-- <p>preview() {{ preview() | json }}</p> -->
  <!-- <p>dataSource()?.data {{ dataSource()?.data | json }}</p> -->
  <!-- <p>deptoSel() {{ deptoSel() | json }}</p> -->

  <mat-toolbar color="light"
               *ngIf="preview() && !!departamentos()?.length">
    <button mat-button
            class="departamentos p-1 px-4"
            [matMenuTriggerFor]="deptosMenu">
      PREVISÃO DO MENU DE SELEÇÃO
      &nbsp;<span class="icon-r-chevron-d r-10 o-50"> </span>
    </button>
  </mat-toolbar>

  <mat-tree [dataSource]="dataSource()"
            [treeControl]="treeControl()"
            class="tree"
            *ngIf="dataSource()?.data">
    <mat-tree-node *matTreeNodeDef="let node"
                   matTreeNodeToggle>
      <span [class.off]="!node?.ativo?.online">{{ node?.nome }}</span>&nbsp;<small class="o-50"
             *ngIf="node?.nivel !== 3">( {{ node?._subs?.length
        }} )</small>
      <div *ngIf="node?.nivel === 1"
           class="d-flex">
        <small class="o-50 ms-2 pointer"
               matTooltip="Departamento {{ !!node?.img?.custom ? 'com' : 'sem' }} imagem"
               [matMenuTriggerFor]="imgMenu"
               (click)="onImgClick(node)"
               *ngIf="!node?._fromBase">
          <span class="icon-b-img"
                [class.o-30]="!node?.img?.custom"></span>
        </small>
        <small class="o-50 ms-2 pointer"
               matTooltip="Departamento {{ node?.ativo?.online ? 'online' : 'offline' }}"
               (click)="onOnlineToggle(node)">
          <span [ngClass]="{ 'icon-b-wifi-on': node?.ativo?.online, 'icon-b-wifi-off': !node?.ativo?.online  }"></span>
        </small>
      </div>
    </mat-tree-node>
    <mat-nested-tree-node *matTreeNodeDef="let node; when: onHasChildren">
      <div class="mat-tree-node">
        <button mat-icon-button
                matTreeNodeToggle>
          <mat-icon class="mat-icon-rtl-mirror">
            {{ treeControl()?.isExpanded(node) ? 'chevron_left' : 'chevron_right' }}
          </mat-icon>
        </button>
        <span [class.off]="!node?.ativo?.online">{{ node?.nome }} <!-- {{ !!node?.img?.custom }} --></span>&nbsp;<small
               class="o-50"
               *ngIf="node?.nivel !== 3">( {{
          node?._subs?.length }} )</small>
        <div *ngIf="node?.nivel === 1"
             class="d-flex">
          <small class="o-50 ms-2 pointer"
                 matTooltip="Nível {{ !!node?.oculto ? 'oculto' : 'exibido' }}"
                 (click)="onOcultoToggle(node)"
                 *ngIf="!!node?._fromBase">
            <span [ngClass]="{ 'icon-r-eye-blocked': node.oculto, 'icon-r-eye': !node.oculto }"></span>
          </small>
          <small class="o-50 ms-2 pointer"
                 matTooltip="Departamento {{ !!node?.img?.custom ? 'com' : 'sem' }} imagem"
                 [matMenuTriggerFor]="imgMenu"
                 (click)="onImgClick(node)"
                 *ngIf="!node?._fromBase">
            <span class="icon-b-img"
                  [class.o-30]="!node?.img?.custom"></span>
          </small>
          <small class="o-50 ms-2 pointer"
                 matTooltip="Departamento {{ !!node?.ativo?.online ? 'online' : 'offline' }}"
                 (click)="onOnlineToggle(node)">
            <span [ngClass]="{ 'icon-b-wifi-on': node?.ativo?.online, 'icon-b-wifi-off': !node?.ativo?.online }"></span>
          </small>
        </div>
      </div>
      <div [class.tree-invisible]="!treeControl()?.isExpanded(node)"
           role="group">
        <ng-container matTreeNodeOutlet></ng-container>
      </div>
    </mat-nested-tree-node>
  </mat-tree>

  <div class="alert alert-light text-center"
       *ngIf="!departamentos()?.length"
       role="alert">
    <strong> Nenhum departamento.</strong>
  </div>

  <!-- Início menus -->
  <mat-menu #imgMenu="matMenu">
    <div class="menu-img d-flex p-3 w-100">
      <div class="m-auto">
        <img [src]="deptoSel()?.img?.__img"
             class="img-fluid">
      </div>
    </div>

    <div class="btn-group d-flex p-2"
         role="group">
      <button type="button"
              matTooltip="Adicionar imagem"
              class="btn btn-primary tint flex-1"
              (click)="onImgAddClick()">
        <div class="icon-r-search"></div>
      </button>

      <button type="button"
              matTooltip="Remover imagem"
              class="btn btn-primary tint flex-1"
              [disabled]="!deptoSel()?.img?.custom"
              (click)="onImgDelClick()">
        <div class="icon-o-trashbin"></div>
      </button>
    </div>
  </mat-menu>

  <mat-menu #deptosMenu="matMenu">
    <div *ngFor="let d1 of preview(); trackBy:tid_dep">
      <div *ngIf="d1._subs.length; else noD2">
        <button mat-menu-item
                [matMenuTriggerFor]="D2menu">
          {{ d1?.nome }}
        </button>
      </div>
      <ng-template #noD2>
        <button mat-menu-item>
          {{ d1?.nome }}
        </button>
      </ng-template>

      <mat-menu #D2menu="matMenu">
        <div *ngFor="let d2 of d1?._subs; trackBy:tid_dep">
          <div *ngIf="d2._subs.length; else noD3">
            <button mat-menu-item
                    [matMenuTriggerFor]="D3menu">
              {{ d2?.nome }}
            </button>
          </div>
          <ng-template #noD3>
            <button mat-menu-item>
              {{ d2?.nome }}
            </button>
          </ng-template>

          <mat-menu #D3menu="matMenu">
            <button *ngFor="let d3 of d2?._subs; trackBy:tid_dep"
                    mat-menu-item>
              {{ d3?.nome }}
            </button>
          </mat-menu>
        </div>
      </mat-menu>
    </div>
  </mat-menu>
  <!-- Final menus -->