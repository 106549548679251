//#region ng
import {
  Component,
  Inject,
  ViewChild,
  inject,
  signal,
} from '@angular/core';
//#endregion

//#region mat
import {
  MatDialogRef,
  MAT_DIALOG_DATA
} from '@angular/material/dialog';
//#endregion

//#region 3rd
import { Subscription } from 'rxjs';
import {
  finalize,
  first,
} from 'rxjs/operators';
//#endregion

//#region models
import {
  IConta,
  IFormaPgto,
  ILoja,
} from '../../_shared/_mercadeiro/_misc/_models/_interfaces/_cols';
import { IDistribuidoraCredenciadoFormParams } from '../../models/interfaces/params';
//#endregion

//#region services
import {
  ContasService,
  LojasService,
} from '../../_shared/_mercadeiro/_ng/_services';
import { CorLoaderService } from '../../_shared/_core/_ng/_services';
//#endregion

//#region components
import { CredenciadoFormasPgtoFormComponent } from '../../components';
//#endregion

@Component({
  selector: 'distribuidora-credenciado-form-modal',
  templateUrl: './distribuidora-credenciado-form.modal.html',
  styleUrls: ['./distribuidora-credenciado-form.modal.scss']
})
export class DistribuidoraCredenciadoFormModal {
  //#region viewchilds
  @ViewChild(CredenciadoFormasPgtoFormComponent) formasFormRef: CredenciadoFormasPgtoFormComponent;
  //#endregion

  //#region publics
  // caption: string;
  credenciado = signal<IConta>(null);
  loja = signal<ILoja>(null);
  //#endregion

  //#region injects
  #contasServ = inject(ContasService);
  #dialogRef = inject(MatDialogRef<DistribuidoraCredenciadoFormModal>);
  #loaderServ = inject(CorLoaderService);
  #lojasServ = inject(LojasService);
  //#endregion

  //#region constructor
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: IDistribuidoraCredenciadoFormParams,
  ) { }
  //#endregion

  //#region lifecycles
  ngOnInit(): void {
    this.credenciado.set(!!this.data?.credenciado ? this.#contasServ.fix(this.data?.credenciado) : null);
    this.loja.set(!!this.data?.loja ? this.#lojasServ.fix(this.data?.loja) : null);
  }
  //#endregion

  //#region methods
  onModalCloseClick(val: any = null) {
    this.#dialogRef.close(val);
  }

  onSubmit() {
    const CONTA_ID: string = this.credenciado()?.id || '';
    const LOJA_ID: string = this.loja()?.id || '';

    if (!!CONTA_ID) {
      const CONTA_CHANGES: any = {
        credenciado: {
          [LOJA_ID]: {
            idsFormasPgto: (this?.formasFormRef?.formasSel || [])
              .map((f: IFormaPgto) => f?.id || '')
              .filter((id: string) => !!id)
          }
        }
      };
      // console.log(CONTA_CHANGES);

      const SUB: Subscription = this.#loaderServ.showUntilCompleted(
        this.#contasServ.update(
          CONTA_ID,
          CONTA_CHANGES
        )
          .pipe(first(), finalize(() => SUB?.unsubscribe()))
      )
        .subscribe(() => this.onModalCloseClick());
    } // if
  }
  //#endregion
}
