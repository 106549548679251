//#region ng
import {
  inject,
  Injectable
} from '@angular/core';
//#endregion

//#region firebase
import {
  addDoc,
  collection,
  collectionData,
  deleteDoc,
  doc,
  // docData,
  DocumentReference,
  Firestore,
  getDoc,
  getDocs,
  limit,
  query,
  Query,
  setDoc,
  where,
} from '@angular/fire/firestore';
//#endregion

//#region 3rd
import {
  catchError,
  from,
  Observable,
  ObservableInput,
  of,
  throwError,
} from "rxjs";
import {
  map,
  tap,
} from "rxjs/operators";
import { set } from 'lodash';
//#endregion

//#region models
import { environment } from 'src/environments/environment';
import { IProDepartamento, IProProduto } from '../../_misc/_models/_interfaces/_cols';
import {
  IMG_NO_IMG,
  ODOCS_TAG
} from '../../../_core/_misc/_models/consts';
//#endregion

//#region libs
import { checkImg } from '../../../_libs/_misc/_imgs';
//#endregions

//#region services
import { CorMessagesService } from '../../../_core/_ng/_services';
import { TProDepartamentoNivel } from '../../_misc/_models/_types';
//#endregion

@Injectable({
  providedIn: 'root'
})
export class ProDepartamentosService {
  //#region injects
  #db = inject(Firestore);
  #msgServ = inject(CorMessagesService);
  //#endregion

  //#region misc
  fix(row: Partial<IProDepartamento>): IProDepartamento {
    const R: IProDepartamento = {
      // id
      id: row?.id || '',

      // user
      nivel: row?.nivel || null,
      nome: row?.nome || '',

      // system
      _criadoEm: row?._criadoEm || null,
      _hasProdutos: row?._hasProdutos || null,
      img: {
        _res: Number(row?.img?._res) || 0,
        // status: !!row?._img?.status,
        __img: row?.img?.__img || IMG_NO_IMG,
      },
      _ver: Number(row?._ver) || 0,
      _modificadoEm: row?._modificadoEm || null,

      // realtime
      // __img: {
      //   img: row?.__img?.img || IMG_OPEN_FOLDER,
      //   thumb: row?.__img?.thumb || IMG_OPEN_FOLDER,
      // },
      __hasProdutos: {
        d1: !!row?.__hasProdutos?.d1,
        d2: !!row?.__hasProdutos?.d2,
        d3: !!row?.__hasProdutos?.d3,
        self: !!row?.__hasProdutos?.self,
      },
      __parent: row?.__parent || null,
      // __parent: {
      //   id: row?.__parent?.id || '',
      //   nome: row?.__parent?.nome || '',
      // },
    };

    // realtime
    // R._img.__img = IMG_OPEN_FOLDER;
    // const IMG: string = `${environment?.firebase?.conecdata?.storage?.root}departamentos%2F${R?.id}.png?alt=media`;
    // checkImg(
    //   IMG,
    //   () => set(R, '_img.__img', IMG),
    //   () => set(R, '_img.__img', IMG_OPEN_FOLDER)
    // );

    return R;
  }

  lfix(row: Partial<IProDepartamento>): Promise<IProDepartamento> {
    // const R: IProDepartamento = this.fix(row);
    return new Promise(
      (resolve, reject) => {
        const DEPTO_ID: string = row?.id || '';
        if (!DEPTO_ID) {
          resolve(null); // )reject(new Error('Nenhum departamento indicado.'));
        } else {
          const IMG: string = `${environment?.firebase?.conecdata?.storage?.root}/departamentos%2F${row?.id}.png?alt=media`;
          // console.log(IMG);
          checkImg(
            IMG,
            () => resolve(
              this.fix(
                {
                  ...row,
                  img: {
                    ...row?.img,
                    __img: IMG,
                  }
                }
              )
            ),
            () => resolve(
              this.fix(
                {
                  ...row,
                  img: {
                    ...row?.img,
                    _res: 0,
                    __img: IMG_NO_IMG,
                  }
                }
              )
            )
          );
        }
      }
    );
  }

  fixes(docs: Partial<IProDepartamento>[]): IProDepartamento[] {
    return (docs || []).map((doc: Partial<IProDepartamento>) => this.fix(doc));
  }
  //#endregion

  //#region C
  add(changes: any): Observable<any> {
    // if (get(changes, 'nome')) {
    const PATH: string = '_departamentos';
    return (from(addDoc(collection(this.#db, PATH), changes)) as Observable<any>)
      .pipe(
        // map(() => { throw new Error(`Erro lendo ${PATH}.`); }),
        catchError<any, ObservableInput<any>>(
          (err: any) => this.#msgServ.onCatchError(err, 'Erro criando departamento.')
        )
      );
  }
  //#endregion

  //#region R
  departamentoHasProdutos(
    departamentoId: string,
    nivel?: TProDepartamentoNivel
  ): Observable<boolean> {
    // console.log(departamentoId, nivel, `departamentos.d${nivel}._id`);    
    if (!!departamentoId) {
      if ([1, 2, 3].includes(Number(nivel))) {
        const PATH: string = '/__produtos';
        const Q: Query = query(
          collection(this.#db, PATH),
          where(
            `departamentos.d${nivel}._id`,
            '==',
            departamentoId
          ),
          limit(1)
        );
        // return (collectionData(q, { idField: 'id' }) as Observable<IProProduto[]>)
        return (from(getDocs(Q)) as Observable<any>)
          .pipe(
            map((colSnap: any) => {
              // console.log(colSnap);
              const DEPTOS: IProDepartamento[] = [];
              colSnap.forEach((doc: any) => DEPTOS.push(this.fix({ ...doc?.data(), id: doc?.id })));
              // console.log(DEPTOS);
              return !!DEPTOS?.length;
            }),
            // map((docs: IProProduto[]) => !!docs?.length),
            // map(() => { throw new Error(`Erro lendo ${PATH}.`); }),
            catchError<boolean, ObservableInput<boolean>>(
              (err: any) => this.#msgServ.onCatchError(err, 'Erro carregando departamentos.')
            )
          );
      } // if
      return of(false); // return throwError(() => 'Nível de departamento inválido.');
    } // if
    return of(false); // return throwError(() => 'Nível de departamento inválido.');
  }

  doc(id: string): Observable<Partial<IProDepartamento>> {
    // console.log('barcode', barcode);
    if (!!id) {
      const PATH: string = `/_departamentos/${id}`;
      // return (docData(doc(this.#db, PATH), { idField: 'id' }) as Observable<Partial<IProDepartamento>>)
      return (from(getDoc(doc(this.#db, PATH))) as Observable<any>)
        .pipe(
          map((doc: any) => (!!doc.data() ? this.fix({ ...doc.data(), id: doc.id }) : null)),
          // map(() => { throw new Error(`Erro lendo ${PATH}.`); }),
          catchError<Partial<IProDepartamento>, ObservableInput<Partial<IProDepartamento>>>(
            (err: any) => this.#msgServ.onCatchError(err, 'Erro carregando departamento.')
          )
        );
    } // if
    return throwError(() => 'Nenhum departamento indicado.');
  }

  docs(): Observable<IProDepartamento[]> {
    const PATH: string = `_departamentos/${ODOCS_TAG}`;
    // return (docData(doc(this.#db, PATH)) as Observable<IProDepartamento[]>)
    return (from(getDoc(doc(this.#db, PATH))) as Observable<any>)
      .pipe(
        map((doc: any) => this.fixes(Object.values(doc?.data()?._odocs || {}) || [])),
        // map(() => { throw new Error(`Erro lendo ${PATH}.`); }),
        catchError<Partial<IProDepartamento[]>, ObservableInput<Partial<IProDepartamento[]>>>(
          (err: any) => this.#msgServ.onCatchError(err, 'Erro carregando departamentos.')
        )
      );
  }

  departamentoFromNome(nome: string): Observable<IProDepartamento> {
    const NOME: string = (nome || '')?.trim(); // nome.toUpperCase();
    if (!!NOME) {
      const PATH: string = '/_departamentos';
      // console.log(PATH, NOME);
      const Q: Query = query(collection(this.#db, PATH), where('nome', '==', NOME), limit(1));
      return (from(getDocs(Q)) as Observable<any>)
        // return (collectionData(q, { idField: 'id' }) as Observable<IProDepartamento[]>)
        .pipe(
          map((colSnap: any) => {
            // console.log(colSnap);
            const DEPTOS: IProDepartamento[] = [];
            colSnap.forEach((doc: any) => DEPTOS.push(this.fix({ ...doc?.data(), id: doc?.id })));
            // console.log(DEPTOS);
            return !!DEPTOS?.length ? DEPTOS[0] : null;
          }),
          // tap((docs: IProDepartamento[]) => console.log(docs)),
          // map((docs: IProDepartamento[]) => !!(docs || [])?.length ? this.fix(docs[0]) : null),
          // map(() => { throw new Error(`Erro lendo ${PATH}.`); }),
          catchError<IProDepartamento, ObservableInput<IProDepartamento>>(
            (err: any) => this.#msgServ.onCatchError(err, 'Erro procurando departamento.')
          )
        );
    } // if
    return of(null); // return throwError(() => 'Nenhum nome indicado.');
  }

  //#region U
  update(
    idDepartamento: string,
    changes: Partial<IProDepartamento>
  ): Observable<any> {
    if (!!idDepartamento) {
      // console.log(pedido);
      const PATH: string = `/_departamentos/${idDepartamento}`;
      return from(setDoc(doc(this.#db, PATH), changes, { merge: true }))
        .pipe(
          // map(() => { throw new Error(`Erro adicionando ${PATH}.`); }),
          catchError<void, ObservableInput<void>>(
            (err: any) => this.#msgServ.onCatchError(err, 'Erro modificando departamento.')
          )
        );
    } // if
    return throwError(() => 'Nenhum departamento indicado.');
  }
  //#endregion

  //#region D
  delete(
    departamentoId: string,
  ): Observable<any> {
    if (!!departamentoId) {
      const PATH: string = `_departamentos/${departamentoId}`;
      const DOC_REF: DocumentReference = doc(this.#db, PATH);
      return from(deleteDoc(DOC_REF))
        .pipe(
          // map(() => { throw new Error(`Erro apagando ${PATH}.`); }),
          catchError<void, ObservableInput<void>>(
            (err: any) => this.#msgServ.onCatchError(err, 'Erro apagando departamento.')
          )
        );
    } // if
    return throwError(() => 'Nenhum departamento indicado.');
  }
  //#endregion
}
