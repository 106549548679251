<ng-container *ngIf="vm$() | async as vm">
  <div class="box"
       *ngIf="!!vm?.loja">
    <nav aria-label="breadcrumb">
      <ol class="breadcrumb">
        <li class="breadcrumb-item">
          <span class="icon-o-home primary tint o-50 pointer"
                routerLink="/dashboard"></span>
        </li>

        <li class="breadcrumb-item active">
          Setores entrega
        </li>
      </ol>
    </nav>

    <!-- <p>vm {{ vm | json }}</p> -->

    <app-setores-entrega [loja]="vm?.loja" />
  </div>
</ng-container>
