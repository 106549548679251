//#region ng
import {
  Component,
  signal
} from '@angular/core';
//#endregion

//#region models
type TProdutoTabs = 'produtos' | 'p-tags';
//#endregion

@Component({
  selector: 'produtos-page',
  templateUrl: './produtos.page.html',
  styleUrls: ['./produtos.page.scss']
})
export class ProdutosPage {
  //#region publics
  tab = signal<TProdutoTabs>('produtos');
  //#endregion
}
