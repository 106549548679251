//#region ng
import {
  Component,
  inject,
  signal,
} from '@angular/core';
import { Router } from '@angular/router';
//#endregion

//#region 3rd
import {
  Observable,
  Subject,
} from 'rxjs';
import {
  map,
  takeUntil,
  tap
} from 'rxjs/operators';
//#endregion

//#region models
// interface IVm {
//   conta: IConta;
// };
import {
  IConta,
  ILoja
} from '../../_shared/_mercadeiro/_misc/_models/_interfaces/_cols';
import { HOME_ROUTE } from '../../models/consts';
import { ROTAS } from '../../services';
//#endregion

//#region libs
import { onDestroy } from '../../_shared/_core/_ng/_libs';
//#endregion

//#region stores
import {
  ContasStore,
  LojasStore
} from '../../_shared/_mercadeiro/_ng/_stores';
//#endregion

@Component({
  selector: 'config-page',
  templateUrl: './config.page.html',
  styleUrls: ['./config.page.scss']
})
export class ConfigPage {
  //#region actions
  #destroyAction$: Subject<void> = onDestroy();
  //#endregion

  //#region privates
  loja = signal<ILoja>(null);
  //#endregion

  //#region injects
  #contasStore = inject(ContasStore);
  #lojasStore = inject(LojasStore);
  #router = inject(Router);
  //#endregion

  //#region lifecycles
  ngOnInit() {
    this.#contasStore.contaStateChanged$
      .pipe(takeUntil(this.#destroyAction$))
      .subscribe(
        (conta: IConta) => !!conta
          && !ROTAS?.config?.__valid?.status
          && this.#router.navigateByUrl(HOME_ROUTE)
      );

    this.#lojasStore.lojaStateChanged$
      .pipe(
        tap((loja: ILoja) => {
          // console.log(loja);
          this.loja.set(loja);
        }),
        takeUntil(this.#destroyAction$),
      )
      .subscribe();
  }
  //#endregion
}
